import { useNavigate } from 'react-router-dom';

import { LoginButton } from '@/ui/pages/login/styles';
import { InstructionText } from '../change-password/steps/styles';

export default function ChangePasswordSuccessMessageStep(): JSX.Element {
  const navigate = useNavigate();

  return (
    <>
      <InstructionText className="font-20">
        Nova senha criada com sucesso!
      </InstructionText>
      <LoginButton
        onClick={() => navigate('/login')}
        className="margin-top large"
        type="button"
      >
        Login
      </LoginButton>
    </>
  );
}
