import { useContext, useEffect, useState } from 'react';
import { useQueries } from 'react-query';
import { useInView } from 'react-intersection-observer';
import { RequestQueryMaker } from '@/domain/helpers';
import { CardChart } from '@/ui/components';
import { LineBarChart } from '@/ui/components/recharts';
import RowsProps from './row-props';
import { NoDataFound } from '@/ui/pages/home/components';
import { ILineBarChart } from '@/domain/models';
import { NotificationContext } from '@/main';

export default function ThirdRow({
  homePresenter,
  isDarkTheme,
  filterParams,
  isAllowed,
}: RowsProps) {
  const { successNotification, errorNotification } =
    useContext(NotificationContext);
  const [refetchDailyTransactions, setRefetchDailyTransactions] =
    useState(true);
  const [refetchBestSellingProducts, setRefetchBestSellingProducts] =
    useState(true);
  const { ref, inView } = useInView({
    skip: !refetchDailyTransactions && !refetchBestSellingProducts,
  });

  const [
    {
      data: dailyTransactions,
      isLoading: isDailyTransactionsLoading,
      isFetching: isDailyTransactionsFetching,
    },
    {
      data: bestSellingProducts,
      isLoading: isBestSellingProductsLoading,
      isFetching: isBestSellingProductsFetching,
    },
    { data: hasExportBestSellingProductsInProgress = false },
  ] = useQueries([
    {
      queryKey: ['dailyTransactions', filterParams],
      queryFn: () =>
        homePresenter.getOverviewDailyTransactionsAsync(
          RequestQueryMaker(homePresenter.getParams(filterParams)),
        ),
      enabled: inView,
      onSuccess: () => setRefetchDailyTransactions(false),
    },
    {
      queryKey: ['bestSellingProducts', filterParams],
      queryFn: () =>
        homePresenter.getOverviewBestSellingProductsAsync(
          RequestQueryMaker(homePresenter.getParams(filterParams)),
        ),
      enabled: inView,
      onSuccess: () => setRefetchBestSellingProducts(false),
    },
    {
      queryKey: ['hasExportBestSellingProductsInProgress', filterParams],
      queryFn: () =>
        homePresenter.hasExportBestSellingProductsReportInProgress(),
      enabled: inView,
    },
  ]);

  const exportBestSellingsProducts = () => {
    homePresenter
      .exportBestSellingProductsReport(
        RequestQueryMaker(homePresenter.getParams(filterParams)),
      )
      .then(response => {
        successNotification(response);
      })
      .catch(error => {
        errorNotification(error.message);
      });
  };

  const isDailyTransactionsLoadingOrFetching =
    isDailyTransactionsLoading || isDailyTransactionsFetching;
  const isBestSellingProductsLoadingOrFetching =
    isBestSellingProductsLoading || isBestSellingProductsFetching;

  useEffect(() => {
    setRefetchDailyTransactions(true);
    setRefetchBestSellingProducts(true);
  }, [filterParams]);

  return (
    <>
      <CardChart
        title="Faturamento e transações por dia"
        reference={ref}
        isLoading={isDailyTransactionsLoadingOrFetching}
        isDarkTheme={isDarkTheme}
        position="4-1"
      >
        <NoDataFound
          data={dailyTransactions && dailyTransactions.length > 0}
          isLoading={isDailyTransactionsLoadingOrFetching}
        >
          <LineBarChart
            lineBarChartData={dailyTransactions as ILineBarChart[]}
          />
        </NoDataFound>
      </CardChart>
      <CardChart
        title="Faturamento e produtos mais vendidos"
        reference={ref}
        isLoading={isBestSellingProductsLoadingOrFetching}
        isDarkTheme={isDarkTheme}
        position="4-2"
        isExport={isAllowed}
        exportFunction={exportBestSellingsProducts}
        isExportInProgress={hasExportBestSellingProductsInProgress}
      >
        <NoDataFound
          data={
            Array.isArray(bestSellingProducts) && bestSellingProducts.length > 0
          }
          isLoading={isBestSellingProductsLoadingOrFetching}
        >
          <LineBarChart lineBarChartData={bestSellingProducts!} />
        </NoDataFound>
      </CardChart>
    </>
  );
}
