import createSagaMiddleware from 'redux-saga';
import { persistStore } from 'redux-persist';
import { persistedReducer } from './create-persistor';
import createStore from './create-store';
import rootSaga from './root-saga';

const sagaMiddleware = createSagaMiddleware();
const middlewares = [sagaMiddleware];

const store = createStore(persistedReducer, middlewares);
sagaMiddleware.run(rootSaga);

export { store };
export const persistedStore = persistStore(store);
