import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { StoreState } from '@/ui/store/models';
import rootReducer from './root-reducer';

export const persistedReducer = persistReducer<StoreState>(
  {
    key: 'root',
    storage,
    blacklist: ['navigation', 'filter', 'sidebar', 'user'],
  },
  rootReducer(),
);
