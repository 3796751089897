import {
  BannerHttpEndpoint,
  SearchCriteriaModel,
  SearchRequestModel,
  SearchResponseModel,
} from '@/domain/models';
import { WindowHelper } from '@/ui/helpers';
import { RowActionProps } from '@/ui/interfaces/props/table';
import { ColumnModel } from '@/presentation/models/table';
import {
  EditIcon,
  TogglePromotionIcon,
} from '@/ui/components/illustration/icons';
import { ITableServerSidePresenter } from '@/ui/components/table';
import { useContext } from 'react';
import { NotificationContext } from '@/main/notification-provider';
import { IBannerRepositoryPort, ISearchRepositoryPort } from '@/infra';

export default class BannerTablePresenter implements ITableServerSidePresenter {
  constructor(
    private readonly searchRepository: ISearchRepositoryPort,
    private readonly bannerRepository: IBannerRepositoryPort,
  ) {}

  private correlationsTypeOptions = [
    {
      id: 'PROMOTION',
      label: 'Promoção',
    },
    {
      id: 'LINK',
      label: 'Link',
    },
    {
      id: 'TEXT',
      label: 'Texto',
    },
  ];

  makeColumns(): ColumnModel<any>[] {
    return [
      new ColumnModel<any>('ID', 'id', {}),
      new ColumnModel<any>('Tipo de Aplicação', 'correlationType', {})
        .withRender((value: string) => (
          <>
            {this.correlationsTypeOptions.find(({ id }) => id === value)!.label}
          </>
        ))
        .withRenderTitleCustom(
          ({ correlationType }: any) =>
            this.correlationsTypeOptions.find(
              ({ id }) => id === correlationType,
            )!.label,
        ),
      new ColumnModel<any>('Início', 'dateBegin', {}),
      new ColumnModel<any>('Fim', 'dateEnd', {}),
      new ColumnModel<any>('Licença', 'licensedName', {}),
      new ColumnModel<any>('Coolers', 'coolers', {}),
      new ColumnModel<any>('Categorias', 'categories', {}),
      new ColumnModel<any>('Produtos', 'products', {}),
    ];
  }

  makeSearchRequestModel(): SearchRequestModel {
    return new SearchRequestModel(BannerHttpEndpoint.BANNERS, 'id', {
      isOrderByDesc: true,
      criterias: [
        new SearchCriteriaModel('banner', {
          value: '',
        }),
      ],
    });
  }

  makeLabelDisplayedResult(response: SearchResponseModel<any>): string {
    if (response === undefined) return '';
    if (response.page === undefined || response.page.total === 0) return '';

    return `Exibindo ${response.page.numberOfElements} banner${
      response.page.numberOfElements > 1 ? 's' : ''
    }`;
  }

  async search<T>(
    searchModel: SearchRequestModel,
  ): Promise<SearchResponseModel<T>> {
    return await this.searchRepository.getDataAsync<any>(
      searchModel,
      BannerHttpEndpoint.BANNERS,
    );
  }

  makeRowActions(): RowActionProps<any>[] {
    const { successNotification, errorNotification } =
      useContext(NotificationContext);

    return [
      {
        icon: rowData => <TogglePromotionIcon rowData={rowData.status} />,
        tooltip: 'Ativar/Desativar',
        enableClickOnRow: false,
        onClick: (_, rowData: any, refetchTable) => {
          this.bannerRepository
            .toggleActivateBanner(
              rowData?.id,
              rowData.status === 'ACTIVE' ? false : true,
            )
            .then(res => {
              if (res.success) {
                successNotification(
                  rowData.status === 'ACTIVE'
                    ? 'Banner desativado com sucesso'
                    : 'Banner ativado com sucesso',
                ),
                  refetchTable();

                return;
              }

              errorNotification(
                res?.data?.message ||
                  'Ocorreu um erro ao tentar ativar/desativar o banner, tente novamente mais tarde',
              );
            });
        },
      },
      {
        icon: () => <EditIcon />,
        tooltip: 'Editar',
        enableClickOnRow: false,
        onClick: (_, rowData: any) => {
          WindowHelper.redirect(`/banner/${rowData?.id}`);
        },
      },
    ];
  }

  moreActionsMenu?: any[];
}
