import { ContainerAction, TableCell } from './styles';
import { IconButton, Menu, MenuItem } from '@mui/material';
import { RowActionProps } from '@/ui/interfaces/props/table';
import { useContext, useState } from 'react';
import { MoreActionsIcon } from '@/ui/components';
import { NotificationContext } from '@/main';

interface BodyCellActionProps {
  isLastCell: boolean;
  actions?: RowActionProps<any>[];
  moreActionsMenu?: any[];
  rowData?: any;
  refetchTable: () => void;
}

export default function BodyCellAction(props: BodyCellActionProps) {
  const { actions, moreActionsMenu, isLastCell, rowData, refetchTable } = props;

  const hasActions: boolean = (actions && actions.length > 0) || false;
  const hasMoreActionsMenu: boolean =
    (moreActionsMenu && moreActionsMenu.length > 0) || false;

  const hasEnabledMoreActions = moreActionsMenu?.some(option =>
    typeof option.enabled === 'function'
      ? option.enabled(rowData)
      : option.enabled,
  );

  if ((!hasActions && !hasMoreActionsMenu) || !isLastCell) {
    return <></>;
  }

  const { successNotification, errorNotification } =
    useContext(NotificationContext);

  const isHidden = (action: RowActionProps<any>) =>
    action.hidden !== undefined && action.hidden(rowData);

  const isDisabled = (action: RowActionProps<any>) =>
    action.disabled !== undefined && action.disabled(rowData);

  const makeTitle = (action: RowActionProps<any>) =>
    isDisabled(action) ? action.tooltipDisabled : action.tooltip;

  const makeClassName = (action: RowActionProps<any>) =>
    isDisabled(action) ? 'action-disabled' : 'action';

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const iconButtonId = `long-button-${Math.random()
    .toString(36)
    .substring(2, 15)}`;

  return (
    <TableCell className="table-cell-action-body">
      <div className="row-action">
        {moreActionsMenu && moreActionsMenu.length > 0 && (
          <div>
            <IconButton
              title="Mais ações"
              id={iconButtonId}
              aria-controls={open ? iconButtonId + '-menu' : undefined}
              aria-expanded={open ? 'true' : undefined}
              aria-haspopup="true"
              onClick={handleClick}
              data-testid="more-actions"
              disabled={!hasEnabledMoreActions}
            >
              <MoreActionsIcon />
            </IconButton>
            <Menu
              id={iconButtonId + '-menu'}
              MenuListProps={{
                'aria-labelledby': iconButtonId,
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
            >
              {moreActionsMenu.map((option, index) => {
                const isEnabled =
                  typeof option.enabled === 'function'
                    ? option.enabled(rowData)
                    : option.enabled;
                return (
                  isEnabled && (
                    <MenuItem
                      key={option.label + index}
                      onClick={() => {
                        option.onClick(
                          rowData,
                          handleClose,
                          successNotification,
                          errorNotification,
                          refetchTable,
                        );
                      }}
                      disableRipple
                    >
                      {option.label}
                    </MenuItem>
                  )
                );
              })}
            </Menu>
          </div>
        )}
        {actions &&
          actions.map(
            (x, index) =>
              Boolean(!isHidden(x)) && (
                <ContainerAction
                  key={(makeTitle(x) || 'container-action') + index}
                  title={makeTitle(x)}
                  className={makeClassName(x)}
                >
                  <IconButton
                    title={makeTitle(x)}
                    onClick={event => {
                      event.stopPropagation();
                      x.onClick(event, rowData, refetchTable);
                    }}
                    disabled={isDisabled(x)}
                  >
                    {x.icon(rowData)}
                  </IconButton>
                </ContainerAction>
              ),
          )}
      </div>
    </TableCell>
  );
}

BodyCellAction.defaultProps = {
  actions: [],
  rowData: undefined,
};
