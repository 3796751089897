import styled from 'styled-components';
import { Column } from '../../layout';

export const StyledModalContainer = styled(Column)`
  background-color: ${props => props.theme.cardBg};
  color: ${props => props.theme.secondary};
  padding: 32px;
  width: 40vw;
  border-radius: 8px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  justify-content: center;
  text-align: center;

  &.nfce {
    width: 30vw;
  }

  &.order-image-container {
    width: 60vw;
  }

  &.order_remove_product {
    width: 30vw;
  }

  &.order_add_product {
    width: 50vw;
  }

  &.reset_user_password {
    width: 40vw;
  }
`;
