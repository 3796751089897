import { TableInstance, SortingRule } from 'react-table';
import { Checkbox, TableCell, TableRow } from '@mui/material';
import {
  TableCellSorting,
  TheadCellAction,
} from '@/ui/components/table/common';
import { ChangeEvent } from 'react';

interface ITheadMapRowProps {
  builder: TableInstance<any>;
  enableRowActions?: boolean;
  enableCellSorting?: boolean;
  hasRowSelection?: boolean;
  handleAddAllRows: () => void;
  handleRemoveAllRows: () => void;
  isAllSelected: boolean;
  sortBy: SortingRule<any>[];
}

export default function TheadMapRow(props: ITheadMapRowProps): JSX.Element {
  const { builder, enableRowActions, enableCellSorting, sortBy } = props;
  const { headerGroups } = builder;

  const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      props.handleAddAllRows();
    } else {
      props.handleRemoveAllRows();
    }
  };

  return (
    <>
      {headerGroups.map((headerGroup, index) => (
        <TableRow
          {...headerGroup.getHeaderGroupProps()}
          key={`header-groups-${index} ${headerGroup.id}`}
        >
          {props.hasRowSelection && (
            <TableCell
              padding="checkbox"
              style={{ width: '4%' }}
              key="head-selection"
            >
              <Checkbox
                sx={{
                  color: !props.isAllSelected
                    ? '#7f7f7f'
                    : '#fbcc0a' + ' !important',
                }}
                checked={props.isAllSelected}
                onChange={handleCheckboxChange}
                inputProps={{
                  'aria-label': 'select all rows',
                }}
              />
            </TableCell>
          )}
          {headerGroup.headers.map((column, columnIndex) => (
            <>
              {Boolean(enableCellSorting) && (
                <TableCellSorting
                  key={`table-cell-sorting-${columnIndex} ${column.id}`}
                  column={column}
                  sortBy={sortBy}
                />
              )}
              {Boolean(!enableCellSorting) && (
                <TableCell
                  {...column.getHeaderProps()}
                  align={(column as any)['align'] ?? 'left'}
                  style={{
                    width: column.width ?? '1%',
                    maxWidth: column.maxWidth ?? column.width,
                  }}
                  key={`table-cell-${columnIndex} ${column.id}`}
                >
                  {column.render('Header')}
                </TableCell>
              )}
              <TheadCellAction
                key={`thead-cell-action-${columnIndex} ${column.id}`}
                isLastCell={headerGroup.headers.length === columnIndex + 1}
                enableRowActions={enableRowActions as boolean}
              />
            </>
          ))}
        </TableRow>
      ))}
    </>
  );
}

TheadMapRow.defaultProps = {
  enableRowActions: false,
  enableCellSorting: false,
};
