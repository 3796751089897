import {
  ProgressBarWrapper,
  StyledCircle,
  StyledLabel,
  StyledProgressLabel,
  StyledSvgPi,
} from './style';

interface IProgressBarProps {
  progress: number;
  size?: number;
  trackWidth?: number;
  trackColor?: string;
  indicatorWidth?: number;
  indicatorColor?: string;
  indicatorCap?: 'butt' | 'round' | 'square' | 'inherit';
  label?: string;
  labelColor?: string;
  labelSize?: number;
  spinnerMode?: boolean;
  spinnerSpeed?: number;
}

export default function ProgressBar({
  progress,
  size = 48,
  trackWidth = 2,
  trackColor = `#ff5630`,
  indicatorWidth = 2,
  indicatorColor = `#36B37E`,
  indicatorCap = `round`,
  label = `Loading...`,
  labelColor = `#333`,
  labelSize = 14,
  spinnerMode = false,
  spinnerSpeed = 1,
}: IProgressBarProps) {
  const center = size / 2;
  const radius =
    center - (trackWidth > indicatorWidth ? trackWidth : indicatorWidth);
  const dashArray = 2 * Math.PI * radius;
  const dashOffset = dashArray * ((100 - progress) / 100);

  const hideLabel = size < 100 || !label.length || spinnerMode ? true : false;

  return (
    <ProgressBarWrapper size={size} data-testid="progress-bar-wrapper">
      <StyledSvgPi size={size}>
        <circle
          className="svg-pi-track"
          cx={center}
          cy={center}
          fill="transparent"
          r={radius}
          stroke={trackColor}
          strokeWidth={trackWidth}
        />
        <StyledCircle
          className={spinnerMode ? 'svg-pi-indicator--spinner' : ''}
          animationDuration={spinnerSpeed * 1000}
          cx={center}
          cy={center}
          fill="transparent"
          r={radius}
          stroke={indicatorColor}
          strokeWidth={indicatorWidth}
          strokeDasharray={dashArray}
          strokeDashoffset={dashOffset}
          strokeLinecap={indicatorCap}
        />
      </StyledSvgPi>
      <StyledLabel
        style={{ color: indicatorColor }}
        className="svg-pi-label__progress"
        labelSize={labelSize}
      >
        {`${progress > 100 ? 100 : progress}%`}
      </StyledLabel>
      {!hideLabel && (
        <StyledLabel
          style={{ color: labelColor }}
          className="svg-pi-label"
          labelSize={labelSize}
        >
          <span className="svg-pi-label__loading">{label}</span>
          {!spinnerMode && (
            <StyledProgressLabel
              style={{ color: indicatorColor }}
              className={`${
                progress.toString().length < 2 && 'svg-pi-label__progress10'
              } ${
                progress.toString().length === 3 && 'svg-pi-label__progress100'
              } ${
                progress.toString().length >= 2 &&
                progress.toString().length < 3 &&
                'svg-pi-label__progress'
              }`}
            >
              {`${progress > 100 ? 100 : progress}%`}
            </StyledProgressLabel>
          )}
        </StyledLabel>
      )}
    </ProgressBarWrapper>
  );
}
