const getBaseValue = (value: any) => {
  if (!value) {
    return '0';
  }

  if (!isNaN(value)) {
    return 4 * value + 'px';
  }

  return value;
};

export default getBaseValue;
