import styled from 'styled-components';

export const OrderProductTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;

  thead {
    font-size: 0.9rem;
    font-style: normal;
    line-height: normal;

    th {
      text-align: start;
      padding: 0.81rem 0;
      font-size: 0.85rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }

  tbody {
    tr {
      height: 4rem;
      &:nth-child(odd) {
        background-color: ${props => props.theme.tableNthChild};
      }

      &.removed {
        background: ${props => props.theme.removedProduct};
        text-decoration: line-through;
      }
    }

    td {
      text-align: start;
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      color: ${props => props.theme.table.text};

      &.align-end {
        text-align: end;
      }

      &.total {
        color: ${props => props.theme.secondary};
        padding-right: 1.14rem;
      }

      &.icon-container {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        height: 100%;
        padding-top: 1rem;
      }
    }
  }
`;

export const AddItemButton = styled.button`
  display: flex;
  padding: 0.1875rem 0.5rem;
  justify-content: center;
  align-items: center;
  border-radius: 0.625rem;
  border: 1px solid ${props => props.theme.table.text};
  cursor: pointer;
  font-size: 0.9rem;
  font-weight: 600;
  line-height: 1.375rem;
  letter-spacing: 0.02875rem;
  color: ${props => props.theme.table.text};
  background: none;
`;
