import { useSelector } from 'react-redux';
import { store } from '@/ui/store/config';
import { toggleThemeAction } from '@/ui/store/modules';
import LightModeIcon from '../../illustration/icons/light-mode-icon';
import DarkModeIcon from '../../illustration/icons/dark-mode-icon';
import { StoreState } from '@/ui/store/models';
import { CloseButton } from '../../illustration/modal/styles';

const ToggleThemeButton = () => {
  const darkTheme = useSelector((state: StoreState) => {
    return state.toggleTheme.darkTheme;
  });

  const handleTheme = () => {
    store.dispatch(toggleThemeAction());
  };

  return darkTheme ? (
    <CloseButton onClick={handleTheme} aria-label="Trocar para tema escuro">
      <DarkModeIcon />
    </CloseButton>
  ) : (
    <CloseButton onClick={handleTheme} aria-label="Trocar para tema claro">
      <LightModeIcon />
    </CloseButton>
  );
};

export default ToggleThemeButton;
