import { NotificationContext } from '@/main/notification-provider';
import { useContext } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { IAutocomplete } from '@/domain/models';
import { makePromotionListPresenter } from '@/main/factories';

export function usePromotionAutocompleteQuery(isPromotion: boolean) {
  const promotionRepository = makePromotionListPresenter();

  const { errorNotification } = useContext(NotificationContext);
  const queryClient = useQueryClient();

  const promotionAutocompleteData = queryClient.getQueryData<IAutocomplete[]>([
    'promotionAutocomplete',
  ]);

  return useQuery(
    ['promotionAutocomplete'],
    () => promotionRepository.getAutocomplete(),
    {
      initialData: promotionAutocompleteData,
      enabled: isPromotion && !promotionAutocompleteData,
      onError: (error: string) => {
        errorNotification(error);
      },
    },
  );
}
