import { ColumnModel } from '@/presentation/models/table';
import { useCallback, useMemo } from 'react';
import { Column } from 'react-table';
import TableColumnHelper from './table-column-helper';

export default class TableHelper {
  static getRowId() {
    return useCallback((row: any): string => {
      return row.id;
    }, []);
  }

  static makeColumn(columns: ColumnModel<any>[], currentColumns?: any) {
    return useMemo<Column<any>[]>(
      () => TableColumnHelper.makeModelToColumn(columns),
      [currentColumns],
    );
  }
}
