import StyledSvgIcon from './svg-icon.styles';

export default function CipherIcon() {
  return (
    <StyledSvgIcon
      width="12"
      height="20"
      viewBox="0 0 12 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 13.3337C1 15.1746 2.49238 16.667 4.33333 16.667H7.66667C9.50762 16.667 11 15.1746 11 13.3337C11 11.4927 9.50762 10.0003 7.66667 10.0003H4.33333C2.49238 10.0003 1 8.50794 1 6.66699C1 4.82604 2.49238 3.33366 4.33333 3.33366H7.66667C9.50762 3.33366 11 4.82604 11 6.66699M6 1.66699V18.3337"
        stroke="#FFDD3F"
        strokeWidth="1.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </StyledSvgIcon>
  );
}
