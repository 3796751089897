import { ContextType } from './context-type';

export const ContextGlobalSearchMap = new Map<string, string>([
  [ContextType.refrigerator_stock_by_cooler, 'location'],
  [ContextType.refrigerator_stock_by_product, 'productName'],
  [ContextType.refrigerator_list, 'location'],
  [ContextType.refrigerator_status_list, 'locality'],
  [ContextType.refrigerator_status_dashboard, 'locality'],
  [ContextType.order, 'orderNumber'],
  [ContextType.promotion_list, 'promotionName'],
  [ContextType.user, 'name'],
  [ContextType.product_category, 'description'],
  [ContextType.product_mix, 'name'],
  [ContextType.monitoring_opening_attempts, 'userName'],
  [ContextType.licensed_list, 'licensedName'],
  [ContextType.product_list, 'productName'],
]);
