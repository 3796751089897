import { Modal } from '@/ui/components';
import { Button } from '@/components/atoms';
import { ReactNode } from 'react';

import S from './styles';

interface ConfirmationModalProps {
  isOpen: boolean;
  onConfirm: () => void;
  onCancel: () => void;
  onClose: () => void;
  message?: string | ReactNode;
  confirmText?: string;
  cancelText?: string;
  maxWidth?: string;
  title: string | ReactNode;
}

function ConfirmationModal({
  isOpen,
  onCancel,
  onConfirm,
  onClose,
  title,
  message,
  confirmText,
  cancelText,
}: ConfirmationModalProps) {
  return (
    <Modal
      title={title}
      isOpen={isOpen}
      onClose={onClose}
      cancelButton={<Button onClick={onConfirm}>{confirmText}</Button>}
      canClose={false}
      okButton={
        <Button color="secondary" onClick={onCancel}>
          {cancelText}
        </Button>
      }
    >
      <S.ModalContent>{message}</S.ModalContent>
    </Modal>
  );
}

export default ConfirmationModal;
