import { IBannerFilters } from '@/domain/models';
import { IFilterOption } from '@/ui/context/context-filter/type';

export default class BannerFilterParams implements IBannerFilters {
  correlationType: IFilterOption;
  dateBegin: IFilterOption;
  dateEnd: IFilterOption;

  constructor(
    correlationType: string,
    correlationTypeLabel: string,
    dateBegin: any,
    dateEnd: any,
  ) {
    this.dateBegin = {
      id: dateBegin,
      label: dateBegin,
      name: 'dateBegin',
    };
    this.dateEnd = {
      id: dateEnd,
      label: dateEnd,
      name: 'dateEnd',
    };
    this.correlationType = {
      id: correlationType,
      label: correlationTypeLabel,
      name: 'correlationType',
    };
  }
}
