import StyledSvgIcon from './svg-icon.styles';

export default function ExportIcon() {
  return (
    <StyledSvgIcon
      width="17"
      height="20"
      viewBox="0 0 17 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.6663 10.417V5.66699C14.6663 4.26686 14.6663 3.5668 14.3939 3.03202C14.1542 2.56161 13.7717 2.17916 13.3013 1.93948C12.7665 1.66699 12.0665 1.66699 10.6663 1.66699H5.33301C3.93288 1.66699 3.23281 1.66699 2.69803 1.93948C2.22763 2.17916 1.84517 2.56161 1.60549 3.03202C1.33301 3.5668 1.33301 4.26686 1.33301 5.66699V14.3337C1.33301 15.7338 1.33301 16.4339 1.60549 16.9686C1.84517 17.439 2.22763 17.8215 2.69803 18.0612C3.23281 18.3337 3.93284 18.3337 5.33289 18.3337H8.41634M10.4997 15.8337L12.9997 18.3337M12.9997 18.3337L15.4997 15.8337M12.9997 18.3337V13.3337"
        stroke="#757575"
        strokeWidth="1.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </StyledSvgIcon>
  );
}
