import { useSelector } from 'react-redux';
import StyledSvgIcon from './svg-icon.styles';
import { useTheme } from 'styled-components';
import { StoreState } from '@/ui/store/models';

export default function BannerIcon() {
  const currentTheme = useTheme();

  const activeMenu = useSelector(
    (currentStore: StoreState) => currentStore.navigation.activeMenu,
  );

  return (
    <StyledSvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      style={{ fill: 'none' }}
    >
      <g clipPath="url(#clip0_4001_5987)">
        <path
          d="M21 4H3C1.9 4 1 4.9 1 6V18C1 19.1 1.9 20 3 20H21C22.1 20 23 19.1 23 18V6C23 4.9 22.1 4 21 4ZM21 18H3V6H21V18ZM14.5 11L11 15.51L8.5 12.5L5 17H19L14.5 11Z"
          fill={
            activeMenu === 'banner_list'
              ? currentTheme.secondary
              : currentTheme.quaternary
          }
          stroke="none"
        />
      </g>
      <defs>
        <clipPath id="clip0_4001_5987">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </StyledSvgIcon>
  );
}
