import contextFinancialDetails from './context-financial-details';

const contextFinancialDetailHttpEndpoint = new Map<string, string>([
  [contextFinancialDetails.bank_dispute, '/bank-dispute-details'],
  [
    contextFinancialDetails.condominium_transfer,
    '/condominium-transfer-details',
  ],
  [contextFinancialDetails.credit_card, '/credit-card-details'],
  [contextFinancialDetails.monthly_payments, '/monthly-payments'],
  [contextFinancialDetails.negotiation, '/negotiation-details'],
  [contextFinancialDetails.pix, '/pix-details'],
  [contextFinancialDetails.promotion_for_clients, '/promotions-details'],
  [contextFinancialDetails.refunded, '/refund-details'],
  [contextFinancialDetails.wallet, '/wallet-details'],
]);

export default contextFinancialDetailHttpEndpoint;
