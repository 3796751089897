enum GenderType {
  UNKNOWN = 'UNKNOWN',
  WOMEN = 'WOMEN',
  MEN = 'MEN',
  OTHER = 'OTHER',
}

const ContextGender = new Map<string, string>([
  [GenderType.UNKNOWN, 'Prefiro não informar'],
  [GenderType.WOMEN, 'Feminino'],
  [GenderType.MEN, 'Masculino'],
  [GenderType.OTHER, 'Outro'],
]);

export default ContextGender;
