import { ColapseIcon, IconButton, ProgressBar } from '@/ui/components';
import { CollapseButton, ProductImage } from '../../styles';
import IRefrigeratorProductStock from '@/domain/models/pages/refrigerator/stock/refrigerator-product-stock';
import { useTheme } from 'styled-components';
import { makeRefrigeratorStockPresenter } from '@/main/factories';
import { NumberHelper } from '@/domain/helpers';
import { Edit } from '@mui/icons-material';

interface IStockProductRowProps {
  handleColapse: any;
  product: IRefrigeratorProductStock;
  showProductDetail: IColapseState;
  handleModalOpen: (product: IRefrigeratorProductStock) => void;
}

interface IColapseState {
  isOpen: boolean;
  activeProductColapse: string | number;
}

export default function StockProductRow(
  props: IStockProductRowProps,
): JSX.Element {
  const { handleColapse, product, showProductDetail } = props;

  const refrigeratorPresenter = makeRefrigeratorStockPresenter();

  const theme = useTheme();

  return (
    <tr key={product.product}>
      <td className="first-item">
        <CollapseButton
          style={
            showProductDetail.activeProductColapse == product.productId
              ? {
                  transform: 'scaleY(-1)',
                }
              : {
                  transform: 'scaleY(1)',
                }
          }
          onClick={() => handleColapse(product.productId)}
        >
          <ColapseIcon />
        </CollapseButton>
        <div
          style={{
            paddingLeft: 45,
            marginLeft: 5,
            position: 'relative',
          }}
        >
          <ProductImage className="stock" src={product.productImg} />
          {product.productName}
        </div>
      </td>
      <td>{product.lastReposition}</td>
      <td>{product.orderCount}</td>
      <td>{product.consumed.toLocaleString('pt-BR')}</td>
      <td>{product.stockQuantity.toLocaleString('pt-BR')}</td>
      <td>{product.minimalPercentage.toLocaleString('pt-BR')}</td>
      <td>{product.expectedStock.toLocaleString('pt-BR')}</td>
      <td className="center">
        <ProgressBar
          trackColor={theme.lines}
          indicatorColor={refrigeratorPresenter.getIndicatorColor(
            product.stockPercentage,
          )}
          progress={
            product.stockPercentage > 100 ? 100 : product.stockPercentage
          }
        />
      </td>
      <td>{NumberHelper.makeMoney(product.avgProductValue)}</td>
      <td>
        <IconButton
          className="table"
          onClick={() => {
            props.handleModalOpen(product);
          }}
        >
          <Edit />
        </IconButton>
      </td>
    </tr>
  );
}
