import { useTheme } from 'styled-components';
import StyledSvgIcon from './svg-icon.styles';

export default function LikeIcon(status: any) {
  const currentTheme = useTheme();

  const isActive = status.status === 'ACTIVE';

  return (
    <StyledSvgIcon
      width="19"
      height="20"
      viewBox="0 0 19 20"
      fill={isActive ? '#36b37f8b' : 'none'}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.83366 18.3337V9.16699M1.66699 10.8337V16.667C1.66699 17.5875 2.41318 18.3337 3.33366 18.3337H14.5222C15.7561 18.3337 16.8055 17.4334 16.9931 16.2138L17.8906 10.3805C18.1235 8.86607 16.9518 7.50033 15.4196 7.50033H12.5003C12.0401 7.50033 11.667 7.12723 11.667 6.66699V3.72186C11.667 2.58699 10.747 1.66699 9.61213 1.66699C9.34144 1.66699 9.09614 1.8264 8.98621 2.07376L6.05361 8.67211C5.91986 8.97305 5.62142 9.16699 5.2921 9.16699H3.33366C2.41318 9.16699 1.66699 9.91318 1.66699 10.8337Z"
        stroke={isActive ? '#36B37E' : currentTheme.tableIconColors}
        strokeWidth="1.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </StyledSvgIcon>
  );
}
