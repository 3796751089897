import { useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { ContextType } from '@/domain/models';
import {
  ChangePasswordNewStep,
  MessageWithDrawingStep,
} from '@/ui/components/change-password/steps';
import { Column } from '@/ui/components/layout';
import { FilterContext } from '@/ui/context/context-filter';
import { store } from '@/ui/store/config';
import { toggleThemeAction } from '@/ui/store/modules';
import { StoreState } from '@/ui/store/models';
import { ButtonRowTypes } from '@/ui/components/change-password/steps/change-password-link-message';
import {
  ChangePasswordSuccessMessageStep,
  CircularProgress,
  LoginLayout,
  ProblemDrawing,
} from '@/ui/components';
import { AlignLoadingCenter } from '../password-recovery/styles';
import { useNewPasswordQueries } from '@/ui/hooks';

export interface INewPasswordFormData {
  password: string;
  passwordConfirmation: string;
}

export default function NewPassword(): JSX.Element {
  const {
    control,
    handleSubmit,
    watch,
    formState: { errors, isDirty },
  } = useForm<INewPasswordFormData>({
    mode: 'onChange',
  });

  const { setActivePage } = useContext(FilterContext);

  const isDarkTheme: boolean = useSelector((state: StoreState) => {
    return state.toggleTheme.darkTheme;
  });

  const [password, passwordConfirmation] = watch([
    'password',
    'passwordConfirmation',
  ]);

  const {
    isValidateTokenLoading,
    isTokenValid,
    showSuccessMessage,
    onSubmit,
    isChangePasswordNewLoading,
  } = useNewPasswordQueries();

  const isPasswordLengthRight = password && password.length === 6;
  const isBothPasswordsEqual = password && password === passwordConfirmation;

  useEffect(() => {
    setActivePage(ContextType.password_recovery);

    if (isDarkTheme) {
      store.dispatch(toggleThemeAction());
    }
  }, []);

  return (
    <LoginLayout>
      {isValidateTokenLoading ? (
        <AlignLoadingCenter>
          <CircularProgress variant="indeterminate" />
        </AlignLoadingCenter>
      ) : isTokenValid.isValid ? (
        <Column onSubmit={handleSubmit(onSubmit)} as="form" gap={6}>
          {showSuccessMessage ? (
            <ChangePasswordSuccessMessageStep />
          ) : (
            <ChangePasswordNewStep
              control={control}
              password={password}
              errors={errors}
              passwordLengthValidation={Boolean(isPasswordLengthRight)}
              passwordEqualValidation={Boolean(isBothPasswordsEqual)}
              isDirty={isDirty}
            />
          )}

          <AlignLoadingCenter>
            {isChangePasswordNewLoading && (
              <CircularProgress variant="indeterminate" />
            )}
          </AlignLoadingCenter>
        </Column>
      ) : (
        <MessageWithDrawingStep
          drawer={<ProblemDrawing />}
          primaryMessage={isTokenValid.message}
          secondaryMessage="Reinicie o processo para gerar um novo link."
          buttonRowType={ButtonRowTypes.invalidToken}
        />
      )}
    </LoginLayout>
  );
}
