import { NumberHelper } from '@/domain/helpers';
import { AddItemButton } from '../order-products-edit-table/styles';
import { CircularProgress } from '@/ui/components';

interface IOrderEditTotalValueRowProps {
  handleOpenAddProductModal: () => void;
  totalPayment: number;
  oldTotalPayment?: number;
  isLoadingCalculate: boolean;
}

export default function OrderEditTotalValueRow(
  props: IOrderEditTotalValueRowProps,
): JSX.Element {
  const {
    handleOpenAddProductModal,
    totalPayment,
    oldTotalPayment,
    isLoadingCalculate,
  } = props;

  const renderTotalPayment = () => {
    if (oldTotalPayment && oldTotalPayment != null) {
      return (
        <td>
          <span style={{ textDecoration: 'line-through' }}>
            {NumberHelper.makeMoney(oldTotalPayment!)}
          </span>{' '}
          {NumberHelper.makeMoney(totalPayment)}
        </td>
      );
    } else {
      return <td>{NumberHelper.makeMoney(totalPayment)}</td>;
    }
  };

  return (
    <tr>
      <td>
        <AddItemButton
          data-testid="add_product_button"
          onClick={() => handleOpenAddProductModal()}
        >
          Adicionar Item +
        </AddItemButton>
      </td>
      <td></td>
      <td className="align-end total">Total:</td>
      <td>
        {isLoadingCalculate ? (
          <CircularProgress size={20} variant="indeterminate" />
        ) : (
          renderTotalPayment()
        )}
      </td>
      <td></td>
    </tr>
  );
}
