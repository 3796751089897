/* eslint-disable */
import { RequiredValueHelper } from '@/domain/helpers';
import {
  HttpResponse,
  IAutocomplete,
  IImage,
  IProductsCategoryForm,
  IProductsMixForm,
} from '@/domain/models';
import { IProductsRepository } from '@/infra';
import {
  ProductCreate,
  ProductPartnerCreate,
} from '@/presentation/models/products';
import { IProductsPresenter } from '@/ui/pages';

export default class ProductsPresenter implements IProductsPresenter {
  constructor(private readonly productRepository: IProductsRepository) {}

  getTrueValues(obj: any) {
    const trueValues = [];
    for (const key in obj) {
      if (obj.hasOwnProperty(key) && obj[key] === true && key !== 'general') {
        trueValues.push(key);
      }
    }

    return trueValues;
  }

  async createCategory(description: string): Promise<HttpResponse> {
    const response =
      await this.productRepository.addProductCategory<IProductsCategoryForm>({
        description: description,
      });

    return response;
  }

  async updateCategory(
    description: string,
    categoryId: string,
  ): Promise<HttpResponse> {
    const response =
      await this.productRepository.updateProductCategory<IProductsCategoryForm>(
        { description: description },
        categoryId,
      );

    return response;
  }

  async getCategoryById(categoryId: any): Promise<HttpResponse> {
    const response =
      await this.productRepository.getProductCategoryById<IProductsCategoryForm>(
        categoryId,
      );

    return response;
  }

  async createMix(data: IProductsMixForm): Promise<HttpResponse> {
    const response =
      await this.productRepository.addProductMix<IProductsCategoryForm>(data);

    return response;
  }

  async updateMix(data: any, mixCode: string): Promise<HttpResponse> {
    const response =
      await this.productRepository.updateProductMix<IProductsCategoryForm>(
        mixCode,
        data,
      );

    return response;
  }

  async createProduct(
    data: any,
    productImage64: string,
  ): Promise<HttpResponse<any>> {
    const image = {
      image: productImage64,
      imageName: data.productImage.name,
      type: data.productImage.type,
    } as IImage;

    const createProduct = new ProductCreate(
      data.categoryId.id,
      data.code,
      data.costPrice,
      data.description,
      data.general,
      image,
      data.information,
      data.name,
      data.ncm,
      data.providerId.id,
      data.suggestedValue,
      data.mixData,
    );

    const response =
      await this.productRepository.addProduct<IProductsCategoryForm>(
        createProduct,
      );

    return response;
  }

  async createPartnerProduct(
    data: any,
    productImage64: string,
  ): Promise<HttpResponse<any>> {
    const image = {
      image: productImage64,
      imageName: data.productImage.name,
      type: data.productImage.type,
    } as IImage;

    const createProduct = new ProductPartnerCreate(
      data.categoryId.id,
      data.name,
      data.description,
      data.suggestedValue,
      data.information,
      image,
    );

    const response =
      await this.productRepository.addProductPartner<IProductsCategoryForm>(
        createProduct,
      );

    return response;
  }

  async updateProduct(
    data: any,
    productId: string,
    productImage64: string,
  ): Promise<HttpResponse<any>> {
    const image = {
      image: productImage64 ? productImage64 : data.image.image,
      imageName: data.productImage.name
        ? data.productImage.name
        : data.image.imageName,
      type: data.productImage.type ? data.productImage.type : data.image.type,
    } as IImage;

    const createProduct = new ProductCreate(
      data.categoryId.id,
      data.code,
      data.costPrice,
      data.description,
      data.general,
      image,
      data.information,
      data.name,
      data.ncm,
      data.providerId.id,
      data.suggestedValue,
      data.mixData,
    );

    const response =
      await this.productRepository.updateProduct<IProductsCategoryForm>(
        createProduct,
        productId,
      );

    return response;
  }

  async getMixes(): Promise<HttpResponse<any>> {
    const response = await this.productRepository.getMixes<any>();

    return response;
  }

  async getMixesAutocomplete(): Promise<IAutocomplete[]> {
    const response = await this.productRepository.getMixesAutocomplete<any>();

    return response.data;
  }

  async getMixById(mixCode: string): Promise<{
    mix: string;
    name: string;
    description: string;
  }> {
    const response = await this.productRepository.getMixById<any>(mixCode);

    return response.data;
  }

  async getProviderAutocomplete(): Promise<IAutocomplete[]> {
    const response =
      await this.productRepository.getProviderAutocomplete<any>();

    return response.data;
  }

  async getProductById(productId: string): Promise<HttpResponse<any>> {
    const response = await this.productRepository.getProductById<any>(
      productId,
    );

    return response;
  }

  async getProductAutocomplete(): Promise<HttpResponse<any>> {
    const response = await this.productRepository.getProductAutocomplete<any>();

    return response.data;
  }

  async getProductByOrder(orderId: any): Promise<HttpResponse<any>> {
    const response = await this.productRepository.getProductByOrder<any>(
      orderId,
    );

    return response.data;
  }

  async getCategoriesAutocomplete(): Promise<IAutocomplete[]> {
    const response =
      await this.productRepository.getCategoriesAutocomplete<any>();

    return response.data;
  }
}
