import { IPieChartData, IPieChartProps } from '@/domain/models/pages/home';
import { PieChartProps } from '@/presentation/';

const makePieChartProps = (
  id: number,
  colors: string[],
  data: IPieChartData[],
): IPieChartProps => {
  return new PieChartProps(id, colors, data);
};

export default makePieChartProps;
