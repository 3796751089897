import { Grid } from '@/ui/components/layout';
import IInformationInputProps from './information-inputs-props';
import {
  AutoCompleteField,
  CheckboxInput,
  DatePickerField,
  InputTextField,
} from '@/ui/components';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { makeRefrigeratorListPresenter } from '@/main/factories';
import makeProductsPresenter from '@/main/factories/presenters/pages/products/product-presenter-factory';
import { useQuery, useQueryClient } from 'react-query';
import { IAutocomplete } from '@/domain/models';

export default function RefrigeratorParameters({
  control,
  watch,
  isAllowedToEdit,
  isPartner,
  isEditing,
  licensedType,
}: IInformationInputProps) {
  const queryClient = useQueryClient();
  const [containsTransfer, processing] = watch([
    'containsTransfer',
    'processing',
  ]);

  const refrigeratorPresenter = makeRefrigeratorListPresenter();
  const productPresenter = makeProductsPresenter();

  const mixAutocompleteData =
    queryClient.getQueryData<Array<IAutocomplete>>('mixAutocomplete');

  const {
    data: mixAutocomplete = [],
    isLoading: isMixAutocompleteLoading,
    isFetching: isMixAutocompleteFetching,
  } = useQuery(
    'mixAutocomplete',
    () => productPresenter.getMixesAutocomplete(),
    {
      enabled: !mixAutocompleteData || mixAutocompleteData.length === 0,
      initialData: mixAutocompleteData as Array<IAutocomplete>,
      onSuccess: data => {
        queryClient.setQueryData('mixAutocomplete', data);
      },
    },
  );
  const isMixAutocompleteLoadingOrFetching =
    isMixAutocompleteLoading || isMixAutocompleteFetching;

  const isProcessingDisabled = () => {
    if (isAllowedToEdit) return false;

    if (isPartner && licensedType !== 'PARTNER_MANUAL') return false;

    return true;
  };

  return (
    <>
      <h5>Parâmetros do cooler</h5>
      <Grid
        columns={4}
        gap={{
          row: 6,
          column: 4,
        }}
      >
        <AutoCompleteField
          control={control}
          options={refrigeratorPresenter.refrigeratorProcessingOptions}
          name="processing"
          label="Revisão automática"
          isDisabled={isProcessingDisabled()}
          rules={{
            required: 'Revisão automática é obrigatória',
          }}
        />
        {processing?.id === 'true' && (
          <AutoCompleteField
            control={control}
            options={mixAutocomplete as Array<IAutocomplete>}
            isLoading={isMixAutocompleteLoadingOrFetching}
            name="mix"
            label="Mix"
            rules={{
              required: 'Mix é obrigatório',
            }}
            isDisabled={!isAllowedToEdit}
          />
        )}
        <AutoCompleteField
          control={control}
          options={refrigeratorPresenter.refrigeratorSituationOptions}
          name="situation"
          label="Situação"
          isDisabled={!isAllowedToEdit}
          rules={{
            required: 'Situação é obrigatório',
          }}
        />
        {isAllowedToEdit && isEditing && (
          <AutoCompleteField
            name="status"
            label="Status"
            control={control}
            options={refrigeratorPresenter.statusOptions}
            rules={{
              required: 'Situação é obrigatória',
            }}
          />
        )}
        <AutoCompleteField
          control={control}
          options={refrigeratorPresenter.refrigeratorModeOptions}
          name="coolerMode"
          label="Modo"
          isDisabled={!isAllowedToEdit}
        />
        <AutoCompleteField
          control={control}
          options={refrigeratorPresenter.acceptPromotionsModeOptions}
          name="acceptPromotion"
          label="Aceita promoções"
          rules={{
            required: 'Aceita promoções é obrigatório',
          }}
          isDisabled={!isAllowedToEdit}
        />
        <AutoCompleteField
          control={control}
          options={refrigeratorPresenter.refrigeratorTypeOptions}
          name="coolerType"
          label="Tipo"
          rules={{
            required: 'Tipo é obrigatório',
          }}
          isDisabled={!isAllowedToEdit}
        />
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br">
          <DatePickerField
            control={control}
            name="sendDate"
            label="Data de envio"
            readOnly={!isAllowedToEdit}
          />
        </LocalizationProvider>
        <CheckboxInput
          readOnly={!isAllowedToEdit}
          control={control}
          label="Há repasse ao condomínio"
          name="containsTransfer"
        />
        {containsTransfer && (
          <InputTextField
            readOnly={!isAllowedToEdit}
            control={control}
            name="transferPercentage"
            label="Valor do repasse"
            type="number"
            rules={{
              required: 'Valor do repasse é obrigatório',
            }}
          />
        )}
        <AutoCompleteField
          control={control}
          name="manualRevisionType"
          label="Revisão manual"
          options={refrigeratorPresenter.manualRevisionTypeOptions}
          isDisabled={!isAllowedToEdit}
        />
        <AutoCompleteField
          control={control}
          name="refrigeratorVoltage"
          label="Voltagem"
          options={refrigeratorPresenter.refrigeratorVoltageOptions}
          isDisabled={!isAllowedToEdit}
        />
        <AutoCompleteField
          control={control}
          name="hasCrop"
          label="Câmeras tipo fish eye?"
          options={refrigeratorPresenter.refrigeratorProcessingOptions}
          isDisabled={!isAllowedToEdit}
          rules={{
            required: 'Informar se possui câmeras fish eye',
          }}
        />
      </Grid>
    </>
  );
}
