import { ILineChart } from '@/domain/models';

export default class LineChart implements ILineChart {
  name: string;
  firstLineName: string;
  firstLineValue: number;
  secondLineName: string;
  secondLineValue: string;

  constructor(
    name: string,
    firstLineName: string,
    firstLineValue: number,
    secondLineName: string,
    secondLineValue: string,
  ) {
    this.name = name;
    this.firstLineName = firstLineName;
    this.firstLineValue = firstLineValue;
    this.secondLineName = secondLineName;
    this.secondLineValue = secondLineValue;
  }
}
