import { NavigationAppState } from '@/ui/store/models';
import { Types, SelectNavigationAppAction } from './types';

export default function navigation(
  state = new NavigationAppState('' as any, '' as any, '' as any),
  action: SelectNavigationAppAction,
): NavigationAppState {
  switch (action.type) {
    case Types.SELECT_NAVIGATION:
      return action.payload.data;
    default:
      return state;
  }
}
