import { Controller, Control } from 'react-hook-form';
import { RadioGroup, FormControlLabel, Radio } from '@mui/material';
import { BpCheckedIcon, BpIcon, RadioStyle } from './style';
import React from 'react';

interface IRadioGroupProps {
  control: Control<any>;
  radios: IRadios[];
  name: string;
  required?: boolean;
  flexDirection?: 'row' | 'column';
  paddingLeft?: string;
}

interface IRadios {
  label: string | React.ReactNode | JSX.Element;
  value: string;
}

const RadioGroupInput = ({
  control,
  radios,
  name,
  required,
  flexDirection = 'column',
  paddingLeft,
}: IRadioGroupProps) => {
  return (
    <div>
      <Controller
        name={name}
        control={control}
        rules={{
          required: required,
        }}
        render={({ field }) => (
          <RadioGroup
            {...field}
            aria-label="option"
            name="option"
            sx={{
              flexDirection,
              paddingLeft,
            }}
          >
            {radios.map((radio, index) => (
              <FormControlLabel
                key={radio.value + index}
                value={radio.value}
                required={required}
                control={
                  <Radio
                    checkedIcon={<BpCheckedIcon />}
                    icon={<BpIcon />}
                    sx={RadioStyle}
                    required={required}
                  />
                }
                label={radio.label}
              />
            ))}
          </RadioGroup>
        )}
      />
    </div>
  );
};

export default RadioGroupInput;
