import React from 'react';
import { Controller } from 'react-hook-form';
import { FormControlLabel } from '@mui/material';
import { SwitchStyled } from './styles';

interface SwitchProps {
  name: string;
  label: string;
  control?: any;
  labelPlacement?: 'start' | 'end' | 'top' | 'bottom';
  interceptOnChange?: (value: any, onChange?: any) => any;
}

const Switch: React.FC<SwitchProps> = ({
  name,
  label,
  control,
  labelPlacement = 'start',
  interceptOnChange,
}) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { value, onChange, ...field } }) => (
        <FormControlLabel
          value={value}
          sx={{ padding: 0, marginLeft: 0, justifyContent: 'start' }}
          labelPlacement={labelPlacement}
          label={label}
          {...field}
          control={
            <SwitchStyled
              checked={value}
              sx={{
                padding: 1,
              }}
              onChange={(e: any) => {
                if (interceptOnChange) {
                  return interceptOnChange(e.target.checked, onChange);
                }
                return onChange(e.target.checked);
              }}
              inputProps={{ 'aria-label': 'ant design' }}
              {...field}
            />
          }
        />
      )}
    />
  );
};

export default Switch;
