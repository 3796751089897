import Masonry from '@mui/lab/Masonry';
import { useBreakpoints } from '@/ui/helpers';

interface IMasonry {
  children: React.ReactElement[];
}

export default function MasonryContainer({ children }: IMasonry) {
  const { isXs } = useBreakpoints();

  return (
    <>
      <Masonry
        data-testid="masonry"
        sx={{ margin: 0, minHeight: 200 }}
        columns={isXs ? 1 : 2}
        spacing={isXs ? 3 : 1}
      >
        {children}
      </Masonry>
    </>
  );
}
