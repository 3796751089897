import { RequestQueryMaker } from '@/domain/helpers';
import { CardChart } from '@/ui/components';
import { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import RowsProps from './row-props';
import { useQuery } from 'react-query';
import { ReBarChart } from '@/ui/components/recharts';
import { NoDataFound } from '@/ui/pages/home/components';

export default function FourthRow({
  homePresenter,
  isDarkTheme,
  filterParams,
}: RowsProps) {
  const [refetchPosBilling, setRefetchPosBilling] = useState(true);
  const { ref, inView } = useInView({
    skip: !refetchPosBilling,
  });

  const {
    data: posBilling,
    isLoading: isPosBillingLoading,
    isFetching: isPosBillingFetching,
  } = useQuery(
    ['posBilling', filterParams],
    () =>
      homePresenter.getOverviewPosBillingAsync(
        RequestQueryMaker(homePresenter.getParams(filterParams)),
      ),
    {
      keepPreviousData: true,
      enabled: inView,
      refetchOnWindowFocus: false,
      onSuccess: () => setRefetchPosBilling(false),
    },
  );

  const isPosBillingLoadingOrFetching =
    isPosBillingLoading || isPosBillingFetching;

  useEffect(() => {
    setRefetchPosBilling(true);
  }, [filterParams]);

  return (
    <CardChart
      reference={ref}
      title="Faturamento bruto por geladeira"
      isDarkTheme={isDarkTheme}
      isLoading={isPosBillingLoadingOrFetching}
      position="5"
    >
      <NoDataFound
        isLoading={isPosBillingLoadingOrFetching}
        data={Array.isArray(posBilling) && posBilling.length > 0}
      >
        <ReBarChart barChartData={posBilling} />
      </NoDataFound>
    </CardChart>
  );
}
