import { useContext, useEffect } from 'react';
import { ContextType, TableIcons } from '@/domain/models';
import makeRefrigeratorTablePresenter from '@/main/factories/presenters/pages/refrigerator/refrigerator-table-presenter-factory';
import { FilterContext } from '@/ui/context/context-filter';
import selectNavigationAction from '@/ui/store/modules/app/navigation/actions';
import { store } from '@/ui/store/config';
import { TableServerSide } from '@/ui/components';
import StockDetail from './stock-detail';
import Default from '../../default';
import { NavigationAppState } from '@/ui/store/models';

export default function RefrigeratorList() {
  const { setActivePage } = useContext(FilterContext);

  const refrigeratorTablePresenter = makeRefrigeratorTablePresenter();

  useEffect(() => {
    setActivePage(ContextType.refrigerator_list);
    store.dispatch(
      selectNavigationAction(
        new NavigationAppState(
          ContextType.refrigerator_list,
          ContextType.refrigerator,
          TableIcons.REFRIGERATOR,
        ),
      ),
    );
  }, []);

  return (
    <Default contextType={ContextType.refrigerator_list}>
      <StockDetail />
      <TableServerSide
        presenter={refrigeratorTablePresenter}
        rowProps={{
          enableRowActions: true,
          identityPropertyName: 'id',
          rowActions: refrigeratorTablePresenter.makeRowActions(),
        }}
        paginationProps={{
          enablePagination: true,
          pageIndex: 0,
          rowsPerPage: 25,
          rowsPerPageOptions: [25, 50, 75, 100],
        }}
      />
    </Default>
  );
}
