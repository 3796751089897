import styled from 'styled-components';
import { Tab } from '@mui/material';

const StyledTab = styled(Tab)`
  && {
    color: ${props => `${props.theme.quaternary}`};
    padding-bottom: 18px;
    padding-top: 18px;
    text-transform: none;
    font-weight: 500;
    font-size: 14px;
    transition: color 400ms;
    outline: none !important;
    background-color: tomato;

    button {
      &:active,
      &:focus,
      :focus-visible {
        outline: none !important;
      }
    }

    .Mui-selected {
      color: ${props => `${props.theme.secondary}`};
      transition: color 400ms ease-out;
      border: none;

      &:active,
      &:focus,
      :focus-visible {
        outline: none !important;
      }
    }

    a {
      text-decoration: none;
      transition: color 400ms ease-out;
      border: none;
    }
  }
` as typeof Tab;

export default StyledTab;
