import { IPromotionFilterParams } from '@/presentation/models/promotion/promotion-filter';

const promotionDefaultValues: IPromotionFilterParams = {
  promotionName: null,
  promotionType: null,
  promotionCategory: null,
  promotionProduct: null,
  status: null,
  promotionStartDate: null,
  promotionEndDate: null,
  licensedId: null,
  coolerId: null,
  id: null,
  useType: null,
  page: {
    id: '0',
    name: 'page',
    label: '0',
  },
  limit: {
    id: 25,
    name: 'limit',
    label: '25',
  },
  column: {
    id: 'id',
    name: 'column',
    label: 'id',
  },
  sort: {
    id: 'DESC',
    name: 'sort',
    label: 'DESC',
  },
};

export default promotionDefaultValues;
