import useMediaQuery from './media-query-helper';

export default function useBreakpoints() {
  const breakpoints = {
    isXs: useMediaQuery('(max-width: 640px)'),
    isSm: useMediaQuery('(min-width: 641px) and (max-width: 768px)'),
    isMd: useMediaQuery('(min-width: 769px) and (max-width: 1024px)'),
    isLg: useMediaQuery('(min-width: 1025px)'),
    isMdSm: useMediaQuery('(min-width: 1400px) and (max-width: 1599px)'),
    isLgMd: useMediaQuery('(min-width: 1600px) and (max-width: 1799px)'),
    isLgg: useMediaQuery('(min-width: 1800px)'),
    isUltraLg: useMediaQuery('(min-width: 2000px)'),
    isCrmMd: useMediaQuery('(min-width: 1400px) and (max-width: 1523px)'),
    isCrmLg: useMediaQuery('(min-width: 1523px) and (max-width: 1636px)'),
    active: 'xs',
  };
  if (breakpoints.isXs) breakpoints.active = 'xs';
  if (breakpoints.isSm) breakpoints.active = 'sm';
  if (breakpoints.isMd) breakpoints.active = 'md';
  if (breakpoints.isLg) breakpoints.active = 'lg';
  if (breakpoints.isUltraLg) breakpoints.active = 'ulg';

  return breakpoints;
}
