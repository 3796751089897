import { useContext, useEffect } from 'react';
import { ContextType, TableIcons } from '@/domain/models';
import makeMonitoringTablePresenter from '@/main/factories/presenters/pages/monitoring/monitoring-table-presenter-factory';
import selectNavigationAction from '@/ui/store/modules/app/navigation/actions';
import { store } from '@/ui/store/config';
import { TableServerSide } from '@/ui/components';
import { FilterContext } from '@/ui/context/context-filter';
import Default from '../../default';
import { NavigationAppState } from '@/ui/store/models';

export default function OpeningAttempts(): JSX.Element {
  const monitoringTablePresenter = makeMonitoringTablePresenter();
  const { setActivePage } = useContext(FilterContext);

  useEffect(() => {
    setActivePage(ContextType.monitoring_opening_attempts);
    store.dispatch(
      selectNavigationAction(
        new NavigationAppState(
          ContextType.monitoring_opening_attempts,
          ContextType.monitoring_opening_attempts,
          TableIcons.MONITORING,
        ),
      ),
    );
  }, []);

  return (
    <Default contextType={ContextType.monitoring_opening_attempts}>
      <TableServerSide
        presenter={monitoringTablePresenter}
        rowProps={{
          identityPropertyName: 'id',
          rowActions: monitoringTablePresenter.makeRowActions(),
        }}
        paginationProps={{
          enablePagination: true,
          pageIndex: 0,
          rowsPerPage: 25,
          rowsPerPageOptions: [25, 50, 75, 100],
        }}
      />
    </Default>
  );
}
