import { useBreakpoints } from '@/ui/helpers';
import { Row } from '../../layout';
import { ImgContainer, LoginSection, TakeText } from '@/ui/pages/login/styles';
import { TakeLogoText } from '../icons';

interface ILoginLayoutProps {
  children: JSX.Element | React.ReactNode | React.ReactNode[] | JSX.Element[];
}

export default function LoginLayout(props: ILoginLayoutProps): JSX.Element {
  const { children } = props;

  const { isXs } = useBreakpoints();

  return (
    <Row>
      {Boolean(!isXs) && (
        <ImgContainer>
          <TakeLogoText />
          <TakeText>
            Take é tecnologia,
            <br /> inovação e comodidade,
            <br /> 24h por dia.
          </TakeText>
        </ImgContainer>
      )}
      <LoginSection>{children}</LoginSection>
    </Row>
  );
}
