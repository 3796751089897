import { ContextType } from './context-type';

export const ContextDefaultValidColumns = new Map<string, string[]>([
  [
    ContextType.banner_list,
    [
      'id',
      'correlationType',
      'dateBegin',
      'dateEnd',
      'licensedName',
      'coolers',
      'categories',
      'products',
    ],
  ],
]);
