import { useBreakpoints } from '@/ui/helpers';

export default function TakeLogoText() {
  const { isXs } = useBreakpoints();

  return (
    <svg
      width="169"
      height="72"
      viewBox="0 0 169 72"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2222_283342)">
        <path
          d="M102.52 47.173C105.939 47.173 108.639 44.2909 108.639 40.8726C108.639 37.4543 105.939 34.5722 102.52 34.5722C99.1022 34.5722 96.402 37.4543 96.402 40.8726C96.402 44.2909 98.9202 47.173 102.52 47.173ZM101.438 27.5536C104.857 27.5536 107.38 28.9947 108.639 30.9719V29.3537C108.639 28.8127 109.18 28.2717 109.721 28.2717H114.939C115.48 28.2717 116.021 28.8127 116.021 29.3537V52.9324C116.021 53.4734 115.48 54.0144 114.939 54.0144H109.721C109.18 54.0144 108.639 53.4734 108.639 52.9324V50.5913C107.38 52.5734 104.68 54.0096 101.438 54.0096C94.9561 54.0096 89.0196 48.7912 89.0196 40.6907C88.8377 32.5901 94.9609 27.5488 101.438 27.5488V27.5536Z"
          fill={isXs ? '#000' : '#fff'}
        />
        <path
          d="M126.642 52.8921C126.642 53.4331 126.101 53.9741 125.56 53.9741H120.342C119.801 53.9741 119.26 53.4331 119.26 52.8921V18.3691C119.26 17.8281 119.801 17.2871 120.342 17.2871H125.56C126.101 17.2871 126.642 17.8281 126.642 18.3691V36.9113H128.442L134.202 28.9927C134.561 28.2745 135.102 28.0926 135.643 28.0926H141.225C142.307 28.0926 142.666 28.9927 141.943 29.8927L134.743 39.2524L143.025 52.241C143.566 53.1411 143.207 54.0411 142.125 54.0411H136.366C135.648 54.0411 135.107 53.6821 134.747 53.1411L128.629 42.8526H126.647V52.8921H126.642Z"
          fill={isXs ? '#000' : '#fff'}
        />
        <path
          d="M160.66 37.9896C159.942 34.9303 157.601 33.4893 155.078 33.4893C152.555 33.4893 150.396 35.1075 149.496 37.9896H160.656H160.66ZM168.043 39.9716V41.4127C168.043 41.9537 167.861 42.6718 166.784 42.6718H149.683C150.042 45.731 152.565 47.8902 155.624 47.8902C157.965 47.8902 159.583 46.9902 161.024 45.5491C161.383 45.1901 161.742 44.831 162.283 45.3672L166.243 47.8854C166.784 48.2445 166.784 48.9674 166.425 49.5036C164.443 52.2038 161.024 54.363 155.442 54.363C147.523 54.363 142.123 48.6036 142.123 41.2212C142.123 33.8388 147.705 27.9022 155.442 27.9022C163.002 27.5431 168.043 33.4845 168.043 39.962V39.9716Z"
          fill={isXs ? '#000' : '#fff'}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M72.4823 25.3876V18.3691C72.4823 17.8281 73.0233 17.2871 73.5643 17.2871H78.7827C79.3237 17.2871 79.8647 17.8281 79.8647 18.3691V25.3876H87.9365C88.4775 25.3876 89.0185 25.9286 89.0185 26.4696V30.5343C89.0185 31.0753 88.4775 31.6163 87.9365 31.6163H79.8647V40.8754C79.8647 44.2745 82.3494 47.1375 85.9114 47.1758C85.9114 47.1758 85.9353 47.1758 85.9449 47.1758H85.9832C86.5002 47.2237 86.9934 47.7407 86.9934 48.253V52.9256C86.9934 53.4427 86.5002 53.9598 85.9832 54.0028V54.0124H84.9012C78.4189 54.0124 72.4823 48.794 72.4823 40.6934V31.6163H69.9257C69.3847 31.6163 68.8438 31.0753 68.8438 30.5343V26.4696C68.8438 25.9286 69.3847 25.3876 69.9257 25.3876H72.4823Z"
          fill={isXs ? '#000' : '#fff'}
        />
        <path
          d="M21.6014 54.0036C19.6194 54.0036 18.0012 52.3854 18.0012 50.4034V21.6014C18.0012 19.6194 19.6194 18.0012 21.6014 18.0012H32.4022C34.3842 18.0012 36.0024 19.6194 36.0024 21.6014V50.4034C36.0024 52.3854 34.3842 54.0036 32.4022 54.0036H21.6014ZM50.4033 0H21.6014C19.6194 0 18.0012 1.61819 18.0012 3.60024V14.401C18.0012 16.383 16.383 18.0012 14.401 18.0012H3.60024C1.61819 18.0012 0 19.6194 0 21.6014V68.4045C0 70.3866 1.61819 72.0048 3.60024 72.0048H32.4022C34.3842 72.0048 36.0024 70.3866 36.0024 68.4045V57.6038C36.0024 55.6218 37.6206 54.0036 39.6026 54.0036H50.4033C52.3854 54.0036 54.0036 52.3854 54.0036 50.4034V3.60024C54.0036 1.61819 52.3854 0 50.4033 0Z"
          fill="#FFCD00"
        />
      </g>
      <defs>
        <clipPath id="clip0_2222_283342">
          <rect width="168.043" height="72" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
