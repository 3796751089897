import { useContext, useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { useParams } from 'react-router-dom';

import { IUserNewPassword, NewPasswordMessagesMap } from '@/domain/models';
import { NotificationContext } from '@/main';
import makeUserPresenter from '@/main/factories/presenters/pages/user/user-presenter-factory';
import { INewPasswordFormData } from '@/ui/pages/new-password/new-password';

interface IIsTokenValid {
  isValid: boolean;
  message: string;
}
interface IUseNewPasswordQueries {
  isValidateTokenLoading: boolean;
  isTokenValid: IIsTokenValid;
  showSuccessMessage: boolean;
  onSubmit: (data: INewPasswordFormData) => void;
  isChangePasswordNewLoading: boolean;
}

export default function useNewPasswordQueries(): IUseNewPasswordQueries {
  const [isTokenValid, setIsTokenValid] = useState<IIsTokenValid>({
    isValid: true,
    message: '',
  });
  const [showSuccessMessage, setShowSuccessMessage] = useState<boolean>(false);

  const userPresenter = makeUserPresenter();

  const { errorNotification } = useContext(NotificationContext);

  const { token } = useParams();

  const { mutate: changePasswordNew, isLoading: isChangePasswordNewLoading } =
    useMutation(
      (userNewPassword: IUserNewPassword) =>
        userPresenter.userChangePasswordAsync(userNewPassword),
      {
        onSuccess: res => {
          if (res.success) {
            setShowSuccessMessage(true);
          }
          if (res.error) {
            errorNotification(res.error.message);
          }
        },
      },
    );

  const { mutate: validateToken, isLoading: isValidateTokenLoading } =
    useMutation(
      (token: string) => userPresenter.userValidateTokenAsync(token),
      {
        onSuccess: res => {
          if (!res.success) {
            setIsTokenValid({
              isValid: false,
              message: NewPasswordMessagesMap.get(res.data.message)!,
            });
          }
        },
      },
    );

  const onSubmit = (data: INewPasswordFormData) => {
    changePasswordNew({
      password: data.password,
      token: token!,
    });
  };

  useEffect(() => {
    validateToken(token!);
  }, []);

  return {
    isValidateTokenLoading,
    isTokenValid,
    showSuccessMessage,
    onSubmit,
    isChangePasswordNewLoading,
  };
}
