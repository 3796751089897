import { IFilterOption } from '@/ui/context/context-filter/type';

export interface IUserFilterParams {
  code: IFilterOption | null;
  cpf: IFilterOption | null;
  email: IFilterOption | null;
  name: IFilterOption | null;
  telephone: IFilterOption | null;
  licensed: IFilterOption | null;
  page?: IFilterOption | null;
  limit?: IFilterOption | null;
  sort?: IFilterOption | null;
  column?: IFilterOption | null;
}

export default class UserFilterParam {
  code: IFilterOption;
  cpf: IFilterOption;
  email: IFilterOption;
  name: IFilterOption;
  telephone: IFilterOption;
  licensed: IFilterOption;

  constructor(
    code: string | any,
    cpf: string | any,
    email: string | any,
    name: string | any,
    telephone: string | any,
    telephoneLabel: string | any,
    licensedId: string | any,
    licensedLabel: string | any,
  ) {
    this.code = {
      name: 'code',
      id: code,
      label: code,
    };
    this.cpf = {
      name: 'cpf',
      id: cpf,
      label: cpf,
    };
    this.email = {
      name: 'email',
      id: email,
      label: email,
    };
    this.name = {
      name: 'name',
      id: name,
      label: name,
    };
    this.telephone = {
      name: 'telephone',
      id: telephone,
      label: telephoneLabel,
    };
    this.licensed = {
      name: 'licensedId',
      id: licensedId,
      label: licensedLabel,
    };
  }
}
