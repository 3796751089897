import { Modal } from '@/ui/components';
import { store } from '@/ui/store/config';
import { StoreState } from '@/ui/store/models';
import { sidebarOpenAction } from '@/ui/store/modules';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

export default function ProblemReasonModal() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleClose = () => {
    store.dispatch(
      sidebarOpenAction({
        sidebarName: '',
        sidebarState: { right: false },
        elementId: '',
        elementData: '',
      }),
    );
    setIsModalOpen(false);
  };
  const modal = useSelector((store: StoreState) => store.sidebar);

  useEffect(() => {
    if (modal.sidebarName === 'problem_reason' && modal.elementData) {
      setIsModalOpen(modal.sidebarState.right);
    }
  }, [modal]);

  return (
    <Modal
      isOpen={isModalOpen}
      onClose={handleClose}
      title="Motivo do problema"
      content={modal.elementData}
    />
  );
}
