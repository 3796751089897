import {
  HttpMethod,
  HttpRequest,
  IWalletUpdateBalance,
  WalletHttpEndpoint,
} from '@/domain/models';
import { IHttpServicePort, IWalletRepositoryPort } from '@/infra/ports';

export class WalletRepositoryAdapter implements IWalletRepositoryPort {
  constructor(private readonly httpServicePort: IHttpServicePort) {}

  async updateWalletBalance<T>(data: IWalletUpdateBalance) {
    const request = new HttpRequest({
      url: WalletHttpEndpoint.WALLET_UPDATE,
      method: HttpMethod.post,
      auth: true,
      body: data,
    });

    return await this.httpServicePort.request<T>(request);
  }
}
