import { useTheme } from 'styled-components';
import StyledSvgIcon from './svg-icon.styles';

export default function OrdinationArrowDown() {
  const theme = useTheme();

  return (
    <StyledSvgIcon
      width="10"
      height="10"
      viewBox="0 0 30 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      alignmentBaseline="middle"
      transform="translate(2, 3)"
    >
      <path
        d="M8 1V15M8 15L15 8M8 15L1 8"
        stroke={theme.lines}
        strokeWidth="1.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </StyledSvgIcon>
  );
}
