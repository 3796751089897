import { GoAlert } from 'react-icons/go';
import { GrRefresh } from 'react-icons/gr';
import { ButtonResetCooler } from '@/ui/components';
import S from './styles';

type AlertResetCoolerButtonProps = {
  refrigeratorId: string;
};

const AlertResetCoolerButton = ({
  refrigeratorId,
}: AlertResetCoolerButtonProps) => {
  return (
    <S.WarningCard>
      <S.WarningIcon>
        <GoAlert width={48} height={48} />
      </S.WarningIcon>
      <S.WarningContent>
        <p>
          Quando há renovação de uma geladeira, é necessário restaurá-la para as
          configurações de fábrica. Isso é feito ao baixar cooler. Os dados
          anteriores são arquivados e podem ser consultados posteriormente,
          garantindo que a geladeira esteja pronta para um novo cliente ou
          fornecedor.
        </p>
        <ButtonResetCooler coolerId={refrigeratorId} endIcon={<GrRefresh />} />
      </S.WarningContent>
    </S.WarningCard>
  );
};

export default AlertResetCoolerButton;
