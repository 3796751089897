import StyledSvgIcon from './svg-icon.styles';

export default function ChargeIcon() {
  return (
    <StyledSvgIcon
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.0827 8.5H0.916016M9.54102 17.5L17.016 17.5C18.0895 17.5 18.6262 17.5 19.0362 17.282C19.3968 17.0903 19.69 16.7843 19.8738 16.408C20.0827 15.9802 20.0827 15.4201 20.0827 14.3V6.7C20.0827 5.5799 20.0827 5.01984 19.8738 4.59202C19.69 4.2157 19.3968 3.90974 19.0362 3.71799C18.6262 3.5 18.0895 3.5 17.016 3.5H15.291M9.54102 17.5L11.4577 19.5M9.54102 17.5L11.4577 15.5M5.70768 17.5H3.98268C2.90925 17.5 2.37253 17.5 1.96253 17.282C1.60189 17.0903 1.30868 16.7843 1.12492 16.408C0.916016 15.9802 0.916016 15.4201 0.916016 14.3V6.7C0.916016 5.5799 0.916016 5.01984 1.12492 4.59202C1.30868 4.21569 1.60189 3.90973 1.96253 3.71799C2.37253 3.5 2.90925 3.5 3.98268 3.5H11.4577M11.4577 3.5L9.54102 5.5M11.4577 3.5L9.54102 1.5"
        stroke="#36B37E"
        strokeWidth="1.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </StyledSvgIcon>
  );
}
