import { DocumentHelper } from '@/domain/helpers';
import { IChangePasswordCpfStepProps } from '@/ui/interfaces';
import { MaskedInputTextField } from '../../input';
import { Column } from '../../layout';

export default function ChangePasswordCpfStep(
  props: IChangePasswordCpfStepProps,
): JSX.Element {
  const { control } = props;

  return (
    <Column margin={{ bottom: 5 }} width="80%">
      <p style={{ fontSize: 20 }}>Recupere sua senha</p>
      <MaskedInputTextField
        control={control}
        name="cpf"
        label="CPF"
        mask="999.999.999-99"
        dataTestId="cpf"
        rules={{
          required: {
            value: true,
            message: 'CPF obrigatório',
          },
          validate: {
            isAValidCpf: (item: string) =>
              DocumentHelper.checkCpf(item) || 'CPF inválido',
          },
        }}
      />
    </Column>
  );
}
