import { NumberHelper } from '@/domain/helpers';
import { TooltipContainer } from './styles';

export default function ChartTooltip({ payload }: any): JSX.Element {
  const isRevenue = (itemName: string) => {
    return itemName === 'Faturamento' || itemName === 'Faturamento bruto';
  };

  return (
    <>
      {payload && payload.length > 0 ? (
        <TooltipContainer>
          <h3>
            {payload && payload.length > 0 && payload[0].payload.tooltipName}
          </h3>
          {payload &&
            payload.length > 0 &&
            payload.map((chartItem: any, index: number) => (
              <p key={chartItem.dataKey + index}>
                {chartItem.name}
                {': '}
                {isRevenue(chartItem.name)
                  ? NumberHelper.makeMoney(chartItem.value)
                  : chartItem.value}
              </p>
            ))}
        </TooltipContainer>
      ) : (
        <></>
      )}
    </>
  );
}
