import { ILoginRepositoryPort } from '@/infra';
import { ILoginPresenter } from '@/ui/pages';

export default class LoginPresenter implements ILoginPresenter {
  constructor(private readonly loginRepository: ILoginRepositoryPort) {}

  async getUserTokenAsync(credentials: object): Promise<any> {
    const response = await this.loginRepository.postUserCredentialsAsync<any>(
      credentials,
    );

    return response;
  }
}
