import { Dispatch, SetStateAction } from 'react';
import ISidebarPresenter from '@/ui/layouts/sidebar/sidebar-presenter';

export default class SidebarPresenter implements ISidebarPresenter {
  handleDrawerOpen(setOpen: Dispatch<SetStateAction<boolean>>): void {
    setOpen(true);
  }

  handleDrawerClose(setOpen: Dispatch<SetStateAction<boolean>>): void {
    setOpen(false);
  }
}
