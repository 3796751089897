import { RequestQueryMaker } from '@/domain/helpers';
import { IRefrigeratorStatusListResponse } from '@/domain/models';
import { IRefrigeratorStatusRepositoryPort } from '@/infra';
import { IRefrigeratorStatusPresenter } from '@/ui/pages';
import { useInfiniteQuery } from 'react-query';

export interface ColumnProps<T> {
  title: string;
  dataIndex: keyof T;
  key: keyof T;
  render?: (value: any, rowData: T) => JSX.Element;
  width: string;
}

export default function useRefrigeratorStatusPresenter(
  refrigeratorStatusRepository: IRefrigeratorStatusRepositoryPort,
): IRefrigeratorStatusPresenter {
  const getStatusList = (
    getFiltersObject: () => any,
    setShouldReRenderTable: (value: boolean) => void,
  ) => {
    const pageFilters = getFiltersObject();

    const fetchStatusList = async ({ pageParam: token }: any) => {
      const filters = RequestQueryMaker({
        ...pageFilters,
        token,
      });

      const res =
        await refrigeratorStatusRepository.getRefrigeratorStatusAsync<IRefrigeratorStatusListResponse>(
          filters,
        );

      return res;
    };

    return useInfiniteQuery(['refrigerator-status-list'], fetchStatusList, {
      getNextPageParam: lastPage => lastPage.data.token,
      onSuccess: _ => {
        setShouldReRenderTable(false);
      },
      staleTime: 1000 * 60 * 5,
    });
  };

  return {
    getStatusList,
  };
}
