import axios from 'axios';
import qs from 'query-string';

import { AuthenticationHelper } from '@/domain/helpers';
import { WindowHelper } from '@/ui/helpers';

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  responseType: 'json',
  headers: {
    'Content-Type': 'application/json',
  },
  paramsSerializer: function (params) {
    return qs.stringify(params, { arrayFormat: 'bracket' });
  },
});

instance.interceptors.request.use(config => {
  if (!AuthenticationHelper.isAuthenticated()) {
    WindowHelper.redirect('/login');
    throw new axios.Cancel('Token has expired');
  }

  const token = AuthenticationHelper.getTokenToString();
  config.headers!.Authorization = `Bearer ${token}`;
  return config;
});

const getInstance = () => instance;

export default getInstance;
