import styled from 'styled-components';

const Label = styled.label`
  color: ${({ theme }) => theme.quaternary};
  font-size: 12px;
`;

const CoolersBox = styled.div<{ label: string; isDarkTheme: boolean }>`
  border: 1px solid ${({ theme }) => theme.lines};
  position: relative;
  border-radius: 12px;
  padding-top: 8px;

  &::after {
    content: '${({ label }) => label}';
    position: absolute;
    top: -12px;
    left: 8px;
    background-color: ${({ isDarkTheme }) =>
      isDarkTheme ? '#333333' : '#F7F7F7'};
    padding: 4px 6px;
    font-size: 14px;
  }
`;

export { Label, CoolersBox };
