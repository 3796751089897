export enum EnglishFilters {
  INSTALLED = 'INSTALLED',
  INPRODUCTION = 'INPRODUCTION',
  DELIVERED = 'DELIVERED',
  MAINTENANCE = 'MAINTENANCE',
  INSTOCK = 'INSTOCK',
  OUTOFOPERATION = 'OUTOFOPERATION',
  INTRANSIT = 'INTRANSIT',
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  SUCCESS = 'SUCCESS',
  PROBLEMS = 'PROBLEMS',
  AWAITING_REVIEW = 'AWAITING_REVIEW',
  REPLACEMENT_PRODUCT = 'REPLACEMENT_PRODUCT',
  CONTESTATION_APPROVED = 'CONTESTATION_APPROVED',
  CONTESTATION_DENIED = 'CONTESTATION_DENIED',
  MANUAL_BILLING = 'MANUAL_BILLING',
  BANK_DISPUTE = 'BANK_DISPUTE',
  REFUNDED = 'REFUNDED',
  OPEN = 'OPEN',
  CANCELED = 'CANCELED',
  ABOVE_AMOUNT = 'ABOVE_AMOUNT',
  ABOVE_VALUE = 'ABOVE_VALUE',
  FIRST_BUY = 'FIRST_BUY',
  PERCENTAGE = 'PERCENTAGE',
  VALUE = 'VALUE',
  CONTESTATION_IN_PROGRESS = 'CONTESTATION_IN_PROGRESS',
  ASC = 'ASC',
  DESC = 'DESC',
  CASHBACK = 'CASHBACK',
  UNLIMITED = 'UNLIMITED',
  ONE_TIME = 'ONE_TIME',
  PER_DAY = 'PER_DAY',
}

export const TranslateFilters = new Map<string, string>([
  [EnglishFilters.INSTALLED, 'Instalado'],
  [EnglishFilters.INPRODUCTION, 'Em produção'],
  [EnglishFilters.DELIVERED, 'Entregue'],
  [EnglishFilters.MAINTENANCE, 'Manutenção'],
  [EnglishFilters.INSTOCK, 'Em estoque'],
  [EnglishFilters.OUTOFOPERATION, 'Fora de operação'],
  [EnglishFilters.INTRANSIT, 'Em transito'],
  [EnglishFilters.ACTIVE, 'Ativo'],
  [EnglishFilters.INACTIVE, 'Inativo'],
  [EnglishFilters.SUCCESS, 'Processado'],
  [EnglishFilters.PROBLEMS, 'Problema'],
  [EnglishFilters.OPEN, 'Enviando'],
  [EnglishFilters.AWAITING_REVIEW, 'Processando'],
  [EnglishFilters.CONTESTATION_IN_PROGRESS, 'Em contestação'],
  [EnglishFilters.CANCELED, 'Cancelado'],
  [EnglishFilters.REPLACEMENT_PRODUCT, 'Reposição'],
  [EnglishFilters.CONTESTATION_APPROVED, 'Contestação aprovada'],
  [EnglishFilters.CONTESTATION_DENIED, 'Contestação recusada'],
  [EnglishFilters.MANUAL_BILLING, 'Fatura manual'],
  [EnglishFilters.BANK_DISPUTE, 'Contestação bancária'],
  [EnglishFilters.REFUNDED, 'Ressarcido'],
  [EnglishFilters.ABOVE_AMOUNT, 'Leve e pague'],
  [EnglishFilters.ABOVE_VALUE, 'Acima de (valor)'],
  [EnglishFilters.CASHBACK, '% de Cashback'],
  [EnglishFilters.FIRST_BUY, 'Primeira compra'],
  [EnglishFilters.PERCENTAGE, '% de Desconto'],
  [EnglishFilters.VALUE, 'R$'],
  [EnglishFilters.ASC, 'Crescente'],
  [EnglishFilters.DESC, 'Decrescente'],
  [EnglishFilters.UNLIMITED, 'Ilimitado'],
  [EnglishFilters.ONE_TIME, 'Uso único'],
  [EnglishFilters.PER_DAY, 'Por dia'],
]);
