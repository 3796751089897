import { IOrderFilterParamsType } from '@/presentation/models/order/order-filter-params';

const orderDefaultValues: IOrderFilterParamsType = {
  cooler: null,
  orderNumber: null,
  orderStatus: null,
  userName: null,
  cpf: null,
  licensedId: null,
  orderDateStart: null,
  orderDateEnd: null,
  paymentDateStart: null,
  paymentDateEnd: null,
  orderType: null,
  page: {
    id: '0',
    name: 'page',
    label: '0',
  },
  limit: {
    id: 25,
    name: 'limit',
    label: '25',
  },
  column: {
    id: 'order',
    name: 'column',
    label: 'order',
  },
  sort: {
    id: 'DESC',
    name: 'sort',
    label: 'DESC',
  },
};

export default orderDefaultValues;
