const RequestQueryMaker = (args: object) => {
  if (!args) return '';

  const argEntities = Object.entries(args);

  const paramsList = argEntities.map(
    (arg: any) => arg[1] && arg[0] + '=' + arg[1] + '&',
  );

  const url = `?${paramsList.map((param: any) => param && param)}`;

  return url.replace(/,/g, '');
};

export default RequestQueryMaker;
