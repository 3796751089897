import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { SubmitHandler } from 'react-hook-form';
import { ContextType, IUserSaveInputs, TableIcons } from '@/domain/models';
import { Button } from '@/ui/components';
import { useBreakpoints } from '@/ui/helpers';
import { store } from '@/ui/store/config';
import selectNavigationAction from '@/ui/store/modules/app/navigation/actions';
import Default from '../../default';
import { Column } from '@/ui/components/layout';
import { NavigationAppState } from '@/ui/store/models';
import { useUserSaveHook } from '@/ui/hooks';
import { AuthenticationHelper, PermissionHelper } from '@/domain/helpers';
import { DevTool } from '@hookform/devtools';
import {
  UserAddress,
  UserData,
  UserPermissions,
  WalletBalance,
} from './components';
import { Divider } from '@/ui/layouts/header/sub-header/styles';

export default function UserSave(): JSX.Element {
  const { userId } = useParams();
  const isUpdate = !!userId;

  const roles: string = AuthenticationHelper.getToken().auth;
  const permissionHelper = new PermissionHelper(roles);

  const isAllowedToEdit = permissionHelper.isTakeAndGo();

  const {
    userForm,
    licensedsQuery,
    statesQuery,
    citiesQuery,
    rolesOptions,
    genderOptions,
    updateUser,
    createUser,
  } = useUserSaveHook(userId);

  const {
    formState: { isValid, isDirty, isSubmitting },
    control,
    watch,
    handleSubmit,
  } = userForm;

  const isDisabled = !isValid || !isDirty || isSubmitting;

  useEffect(() => {
    if (!isUpdate) {
      store.dispatch(
        selectNavigationAction(
          new NavigationAppState(
            ContextType.user_save,
            ContextType.user,
            TableIcons.USER,
          ),
        ),
      );
    }
  }, []);

  const onSubmit: SubmitHandler<IUserSaveInputs> = userFormData => {
    if (isUpdate) {
      updateUser(userFormData);
      return;
    }

    createUser(userFormData);
  };

  const { isXs } = useBreakpoints();

  const walletBalance = watch('walletBalance');

  return (
    <Default contextType={ContextType.user_save} isSubmitDisabled={isDisabled}>
      <Column
        gap={2}
        as="form"
        id="user-form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <UserData control={control} genderOptions={genderOptions} />
        <UserAddress
          control={control}
          watch={watch}
          statesQuery={statesQuery}
          citiesQuery={citiesQuery}
        />
        {isAllowedToEdit && (
          <UserPermissions
            control={control}
            licensedsQuery={licensedsQuery}
            rolesOptions={rolesOptions}
          />
        )}
        {isXs && <Button type="submit" text="Atualizar" />}
      </Column>
      {isAllowedToEdit && (
        <Column
          gap={2}
          as="form"
          id="user-form"
          onSubmit={handleSubmit(onSubmit)}
          margin={{ top: 2 }}
        >
          <Divider orientation="horizontal" />
          {userId && (
            <WalletBalance userId={userId} walletBalance={walletBalance} />
          )}
        </Column>
      )}
      <DevTool control={control} />
    </Default>
  );
}
