import { IChangePasswordRecoveryOptionProps } from '@/ui/interfaces';
import { Button, RadioGroupInput } from '../../input';
import { RadioLabelStrong, RadioUserData } from './styles';

export default function ChangePasswordRecoveryOptionStep(
  props: IChangePasswordRecoveryOptionProps,
): JSX.Element {
  const { control, changePasswordState, isValid } = props;

  const emailLabel = (
    <>
      <RadioLabelStrong>E-mail </RadioLabelStrong>
      <RadioUserData>{changePasswordState.data.email}</RadioUserData>
    </>
  );

  const smsLabel = (
    <>
      <RadioLabelStrong>SMS </RadioLabelStrong>
      <RadioUserData>{changePasswordState.data.phone}</RadioUserData>
    </>
  );

  return (
    <>
      <p className="body-extra-large">
        Como deseja receber o link de
        <br /> recuperação?
      </p>
      <RadioGroupInput
        required
        name="recoveryOption"
        control={control}
        radios={[
          { value: 'EMAIL', label: emailLabel },
          { value: 'SMS', label: smsLabel },
        ]}
      />
      <Button
        disabled={!isValid}
        dataTestId="send-button"
        text="Enviar"
        className={`medium-2 mt-3 no-margin-left`}
        type="submit"
      />
    </>
  );
}
