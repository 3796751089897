import styled from 'styled-components';
import { Row } from '../../layout';

interface ILegendList {
  elementColor?: any;
}

export const LegendList = styled(Row)`
  gap: 20px;
  list-style: none;
  margin-top: 50px;
`;

export const LegendItem = styled(Row)<ILegendList>`
  font-size: 12px;
  color: ${props => props.theme.chartLegend};
  gap: 5px;
  align-items: center;

  div {
    background-color: ${props => {
      return props.elementColor;
    }};
    border-radius: 50%;
    width: 10px;
    height: 10px;
  }
`;
