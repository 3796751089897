import { ContextIconMap, MenuModel } from '@/domain/models';
import IMenuPresenter from '@/ui/components/navigation/menu-presenter';

export default class MenuPresenter implements IMenuPresenter {
  getContextType(menu: MenuModel) {
    return ContextIconMap.get(menu.contextType);
  }

  makeContextTypeRoute(menu: MenuModel) {
    return menu.submenus.length > 0
      ? menu.submenus[0].contextType
      : menu.contextType;
  }
}
