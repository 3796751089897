import { Switch } from '@mui/material';
import styled from 'styled-components';

const SwitchStyled = styled(Switch)({
  height: 40,
  padding: 0,
  display: 'flex',
  width: 42,
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(0px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    marginTop: 3,
    marginLeft: 4,
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#1A1A1A',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: '#fbcc0a',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 10 11 / 20%)',
    width: 14,
    height: 14,
    borderRadius: '100%',
    transition: 'width 2s',
  },
  '& .MuiSwitch-track': {
    width: 40,
    height: 22,
    borderRadius: 16,
    opacity: 1,
    backgroundColor: '#D1D1D1',
    boxSizing: 'border-box',
    padding: 0,
  },
  '& .MuiSwitch-root': {
    padding: 0,
  },
});

export { SwitchStyled };
