import { FilterAppState } from '@/ui/store/models';
import { Types, FilterAppAction } from './types';

export default function filter(
  state = new FilterAppState(
    {
      page: 1,
      limit: 25,
    },
    {},
    { cooler: '' },
  ),
  action: FilterAppAction,
): FilterAppState {
  switch (action.type) {
    case Types.FILTER:
      return new FilterAppState(
        action.payload.data,
        {
          isSearchAction: action.payload.isSearchAction,
        },
        action.payload.selectedValue,
      );
    default:
      return state;
  }
}
