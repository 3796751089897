import styled from 'styled-components';

export const OrderDetailContainer = styled.div`
  display: flex;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: start;
`;

export const InfoColumn = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  min-width: 16rem;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 1.05rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;
  letter-spacing: 0.00938rem;

  p {
    margin: 0.34rem 0;
    text-align: start;
    align-items: center;
    justify-content: center;
    color: ${props => props.theme.secondary};
    max-width: 128ch;
  }

  & .title {
    font-size: 0.9rem;
    font-style: normal;
    font-weight: 400;
    line-height: 0.85rem;
    letter-spacing: 0.00938rem;
    margin-bottom: 0px;
    color: ${props => props.theme.table.text};
  }

  & .align-start {
    text-align: start;
    align-items: start;
  }

  &.add-product {
    min-width: 0rem;
  }

  & .licensed {
    white-space: nowrap;
  }
`;

export const OrderImagesModalButton = styled.button`
  background-color: #fbcc0a;
  border-radius: 100%;
  border: none;
  cursor: pointer;
  width: 4.25rem;
  height: 4.25rem;
`;
