import { parsePhoneNumber } from 'react-phone-number-input';
import {
  IAutocomplete,
  ILicensed,
  ILicensedDTO,
  ILicensedEdit,
} from '@/domain/models';
import { ILicensedRepositoryPort } from '@/infra';
import { ILicensedForm, ILicensedPresenter } from '@/ui/pages/licenseds';
import { BanksList, RegimeTypesList } from '../../resources';

export default class LicensedPresenter implements ILicensedPresenter {
  banksAccountTypesList: IAutocomplete[] = ['Corrente', 'Poupança'].map(
    (type, index) => {
      return {
        id: index.toString(),
        label: type,
      };
    },
  );
  juridicalPersonTypesList: IAutocomplete[] = [
    'Pessoa Física',
    'Pessoa Jurídica',
  ].map((person, index) => {
    return {
      id: index.toString(),
      label: person,
    };
  });
  banksList: IAutocomplete[] = BanksList;
  regimeTypesList: IAutocomplete[] = RegimeTypesList;

  constructor(private readonly licensedRepository: ILicensedRepositoryPort) {}

  async getLicensedAutoComplete(): Promise<Array<IAutocomplete>> {
    const response = await this.licensedRepository.getAutoComplete<
      Array<IAutocomplete>
    >();

    return response.data!;
  }

  async getLicensedList(params?: string): Promise<Array<ILicensed>> {
    const response = await this.licensedRepository.getLicensedsList<
      Array<ILicensed>
    >(params);

    return response.data!;
  }

  async getLicensedById(licensedId: number): Promise<ILicensedEdit> {
    const response =
      await this.licensedRepository.getLicensedById<ILicensedEdit>(licensedId);

    if (!response.success) {
      return Promise.reject(
        response.error?.message || 'Erro ao buscar licenciados',
      );
    }

    return response.data!;
  }

  async createLicensed(data: ILicensedDTO): Promise<any> {
    const response = await this.licensedRepository.createLicensed<any>(data);

    if (!response.success) {
      return Promise.reject(
        response.error?.message || 'Erro ao criar licenciado',
      );
    }

    return response.data;
  }

  async updateLicensed(data: ILicensedDTO, licensedId: number): Promise<any> {
    const response = await this.licensedRepository.updateLicensed<any>(
      data,
      licensedId,
    );

    if (!response.success) {
      return Promise.reject(
        response.error?.message || 'Erro ao atualizar licenciado',
      );
    }

    return response.data!;
  }

  async getFranchiseeTypes(): Promise<Array<IAutocomplete>> {
    const response = await this.licensedRepository.getFranchiseeTypes<
      Array<IAutocomplete>
    >();

    return response.data!;
  }

  formatDataToSave({
    cnpj,
    phone,
    regimeType,
    juridicalPerson,
    street,
    number,
    complement,
    zipCode,
    state,
    city,
    district,
    bank,
    bankAgency,
    bankCC,
    bankAccountType,
    commission,
    franchiseeType,
    sacPhone,
    ...data
  }: ILicensedForm): ILicensedDTO {
    const phoneNumber = String(parsePhoneNumber(phone, 'BR')?.nationalNumber);
    const sacPhoneNumber =
      (franchiseeType as IAutocomplete).id !== 'LICENSED'
        ? String(parsePhoneNumber(sacPhone, 'BR')?.nationalNumber)
        : null;

    return {
      ...data,
      sacPhone: sacPhoneNumber,
      cnpj: cnpj.replace(/[^\d]+/g, ''),
      phone: phoneNumber,
      regimeType: regimeType.id,
      juridicalPerson: juridicalPerson.label,
      commission: Number(commission),
      address: {
        street,
        number,
        complement: complement || null,
        zipCode,
        city: {
          id: Number(city.id),
          name: city.label,
          state: (state as IAutocomplete).label,
        },
        district,
      },
      bank: bank.label,
      bankAgency: bankAgency,
      bankCC: bankCC,
      bankAccountType: bankAccountType.label,
      franchiseeType: (franchiseeType as IAutocomplete).id,
    };
  }

  formatDataToEdit({
    name,
    companyName,
    cnpj,
    regimeType,
    juridicalPerson,
    subscription,
    email,
    phone,
    street,
    number,
    complement,
    zipCode,
    district,
    cityId,
    cityName,
    cityState: state,
    commission,
    bank,
    bankAgency,
    bankCC,
    bankAccountType,
    franchiseeType,
    sacPhone,
  }: ILicensedEdit): ILicensedForm {
    const phoneNumber = String(parsePhoneNumber(phone, 'BR')?.formatNational());
    const sacPhoneNumber = sacPhone
      ? String(parsePhoneNumber(sacPhone, 'BR')?.formatNational())
      : '';

    return {
      name,
      companyName,
      cnpj,
      subscription,
      email,
      phone: phoneNumber,
      commission,
      street,
      number,
      complement,
      zipCode,
      district,
      bankCC,
      bankAgency,
      franchiseeType,
      sacPhone: sacPhoneNumber,
      regimeType: this.regimeTypesList.find(
        regime => regime.id === regimeType,
      )!,
      juridicalPerson: this.juridicalPersonTypesList.find(
        person => person.label === juridicalPerson,
      )!,
      bank: this.banksList.find(bankItem => bankItem.label === bank)!,
      bankAccountType: this.banksAccountTypesList.find(
        bankAccount => bankAccount.label === bankAccountType,
      )!,
      state,
      city: {
        id: cityId.toString(),
        label: cityName,
      },
    };
  }
}
