import { CardChart, Table } from '@/ui/components';
import { ITopCustomers } from '@/domain/models';
import { NumberHelper } from '@/domain/helpers';
import { NoDataFound } from '../../components';

interface ICrmTopUsersProps {
  topCustomers: ITopCustomers[];
  isLoading: boolean;
  isDarkTheme: boolean;
}

export default function CrmTopUsers({
  topCustomers,
  isLoading,
  isDarkTheme,
}: ICrmTopUsersProps): JSX.Element {
  return (
    <CardChart
      isDarkTheme={isDarkTheme}
      title="Top 5 maiores consumidores"
      isLoading={isLoading}
    >
      <NoDataFound
        isLoading={isLoading}
        data={Array.isArray(topCustomers) && topCustomers.length > 0}
      >
        <Table>
          <thead style={{ borderBottom: '1px solid white' }}>
            <tr className="table-head">
              <th></th>
              <th>Clientes</th>
              <th>Valor consumido</th>
            </tr>
          </thead>
          <tbody>
            {topCustomers &&
              topCustomers.map((customer: ITopCustomers, index) => (
                <tr className="content-row" key={customer.name + index}>
                  <td className="color">{index + 1}</td>
                  <td className="color">{customer.name}</td>
                  <td>{NumberHelper.makeMoney(customer.value)}</td>
                </tr>
              ))}
          </tbody>
        </Table>
      </NoDataFound>
    </CardChart>
  );
}
