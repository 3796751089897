import { IAutocomplete } from '@/domain/models';
import { checkboxStyles } from '@/ui/components/input/checkbox/styles';
import { Checkbox, FormControlLabel } from '@mui/material';
import { UseFormSetValue } from 'react-hook-form';

interface ICoolersCheckboxProps {
  cooler: IAutocomplete;
  coolersValue: IAutocomplete[];
  isDisabled?: boolean;
  setValue: UseFormSetValue<any>;
}

export default function CoolersCheckbox({
  cooler,
  coolersValue,
  isDisabled = false,
  setValue,
}: ICoolersCheckboxProps) {
  const styles = checkboxStyles(isDisabled, true);

  return (
    <FormControlLabel
      data-tooltip-id="coolers"
      data-tooltip-content={cooler.label}
      data-tooltip-hidden={isDisabled}
      key={cooler.id}
      label={cooler.label}
      sx={styles}
      control={
        <Checkbox
          style={styles}
          key={cooler.id}
          sx={styles}
          disabled={isDisabled}
          checked={coolersValue.some(
            (coolerChecked: any) => coolerChecked.id === cooler.id,
          )}
          onChange={(event: any) => {
            if (event.target.checked) {
              const coolers = coolersValue.concat(cooler);
              setValue('coolers', coolers, {
                shouldDirty: true,
                shouldTouch: true,
                shouldValidate: true,
              });
            } else {
              const coolers = coolersValue;
              const index = coolers.indexOf(
                coolers.find((res: any) => res.id === cooler.id)!,
              );

              if (index > -1) {
                coolers.splice(index, 1);
                setValue('coolers', coolers, {
                  shouldDirty: true,
                  shouldTouch: true,
                  shouldValidate: true,
                });
              }
            }
          }}
        />
      }
    />
  );
}
