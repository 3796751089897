import styled, { keyframes } from 'styled-components';

export const ProgressBarWrapper = styled.div<{ size: number }>`
  position: relative;
  width: ${props => props.size}px;
  height: ${props => props.size}px;
`;

export const StyledSvgPi = styled.svg<{ size: number }>`
  transform: rotate(-90deg);
  width: ${props => props.size}px;
  height: ${props => props.size}px;
`;

export const StyledCircle = styled.circle<{ animationDuration: number }>`
  &.svg-pi-indicator--spinner {
    animation: ${keyframes`
            0% {
                transform: rotate(0);
            }
            100% {
                transform: rotate(360deg);
            }
        `} ${props => props.animationDuration}ms linear infinite;
    transform-origin: center;
  }
`;

interface IStyledLabel {
  labelSize: number;
}

export const StyledLabel = styled.div<IStyledLabel>`
  position: absolute;
  top: 50%;
  left: 52%;
  transform: translate(-50%, -50%);
  text-align: center;
  font-size: ${props => props.labelSize}px;
`;

export const StyledProgressLabel = styled.span`
  width: 48px;
  height: 48px;
  font-style: normal;
  font-weight: 400;
  position: absolute;
  top: 30%;
  left: 29%;
  color: #ffffff;

  &.svg-pi-label__progress {
    &10 {
      & {
        top: 30%;
        left: 29%;
      }
    }
    &100 {
      & {
        top: 30%;
        left: 16%;
      }
    }
  }
`;
