import { NotificationContext } from '@/main';
import makeOrderListPresenter from '@/main/factories/presenters/pages/order/order-list-presenter-factory';
import { Button, CircularProgress, Modal } from '@/ui/components';
import { Row } from '@/ui/components/layout';
import { store } from '@/ui/store/config';
import { StoreState } from '@/ui/store/models';
import { sidebarOpenAction } from '@/ui/store/modules';
import { useContext, useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { useSelector } from 'react-redux';

export default function ReprocessOrderModal({
  rerenderTable,
}: {
  rerenderTable: () => void;
}) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleClose = () => {
    store.dispatch(
      sidebarOpenAction({
        sidebarName: '',
        sidebarState: { right: false },
        elementId: '',
        elementData: '',
      }),
    );
    setIsModalOpen(false);
  };
  const { successNotification, errorNotification } =
    useContext(NotificationContext);

  const modal = useSelector((store: StoreState) => store.sidebar);

  useEffect(() => {
    if (modal.sidebarName === 'reprocess_order' && modal.elementData) {
      setIsModalOpen(modal.sidebarState.right);
    }
  }, [modal]);

  const orderPresenter = makeOrderListPresenter();

  const { mutateAsync, isLoading } = useMutation(
    () => orderPresenter.postReprocessOrder(modal.elementId as number),
    {
      onSuccess: data => {
        if (Boolean(data.status) && data.status !== 200) {
          return errorNotification('Erro ao aplicar reembolso!');
        }

        rerenderTable();
        handleClose();
        successNotification(data || 'Reembolso aplicado com sucesso!');
      },
    },
  );

  return (
    <Modal
      isOpen={isModalOpen}
      onClose={handleClose}
      title="Reprocessar pedido"
      content={
        isLoading ? (
          <Row align="center" justify="center">
            <CircularProgress variant="indeterminate" size={26} />
          </Row>
        ) : (
          `Deseja reprocessar o pedido ${modal.elementId}?`
        )
      }
      cancelButton={<Button text="Cancelar" onClick={handleClose} />}
      okButton={
        <Button
          disabled={isLoading}
          className="outlined"
          text="Reprocessar"
          onClick={() => {
            mutateAsync();
          }}
        />
      }
    />
  );
}
