import { useContext } from 'react';
import { useQuery } from 'react-query';

import {
  AuthenticationHelper,
  PermissionHelper,
  RequestQueryMaker,
} from '@/domain/helpers';
import { IFinancial } from '@/domain/models';
import makeFinancialPresenter from '@/main/factories/presenters/pages/financial/finantial-presenter-factory';
import { FilterContext } from '@/ui/context/context-filter';
import { FilterType } from '@/ui/context/context-filter/type';
import { IUseFinancialQueries } from '@/ui/interfaces';

export default function useFinancialQueries(): IUseFinancialQueries {
  const financialPresenter = makeFinancialPresenter();

  const { getFiltersObject } = useContext(FilterContext);
  const pageFilters: FilterType<'financial'> = getFiltersObject();

  const roles = AuthenticationHelper.getToken().auth;
  const permissionHelper = new PermissionHelper(roles);
  const isTakeAndGo = permissionHelper.isTakeAndGo();

  const showMockedAndDontMakeRequest = (): boolean => {
    if (!isTakeAndGo || (isTakeAndGo && pageFilters?.licensedId)) {
      return false;
    }

    return true;
  };

  const {
    data: financialData,
    isLoading: isLoading,
    isFetching: isFetching,
  } = useQuery(
    ['financialData', pageFilters],
    () =>
      financialPresenter.getFinancialReportAsync(
        RequestQueryMaker(pageFilters),
      ),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      enabled: !showMockedAndDontMakeRequest(),
    },
  );

  const { data: isExportButtonDisabled = false } = useQuery(
    ['hasExportFinancialReportInProgress', pageFilters],
    () => financialPresenter.hasExportFinancialReportInProgressAsync(),
  );

  const { data: defaultMonthlyPayment } = useQuery(
    ['defaultMonthlyPayment', pageFilters?.licensedId],
    () =>
      financialPresenter.getDefaultMonthlyPaymentAsync(
        pageFilters?.licensedId as any,
      ),
    {
      enabled: !!pageFilters?.licensedId,
    },
  );

  const handleRenderFinancialReport = (): IFinancial => {
    return showMockedAndDontMakeRequest()
      ? financialPresenter.financialMock
      : financialData?.orderConsolidation != null &&
        financialData?.orderConsolidation != undefined
      ? financialData!
      : financialPresenter.financialMock;
  };

  return {
    isExportButtonDisabled,
    isLoading,
    isFetching,
    handleRenderFinancialReport,
    defaultMonthlyPayment: defaultMonthlyPayment,
  };
}
