import { FinancialContext } from '../financial-context';
import { IInfoModalProps } from './info-modal-type';

const FinancialInfoModals = new Map<string, IInfoModalProps>([
  [
    FinancialContext.FECHAMENTO_MENSAL,
    {
      title: FinancialContext.FECHAMENTO_MENSAL,
      content: (
        <>
          <div>
            Para o <span className="subtitle">Fechamento Mensal</span>
            utilizamos os valores de{' '}
            <span className="subtitle">pedidos pagos dentro do mês/ano</span>
            aplicado no filtro. São levados em consideração além da data de
            pagamento os seguintes status:
          </div>
          <ul>
            <li>Processado</li>
            <li>Contestação Recusada</li>
            <li>Contestação Aprovada</li>
            <li>Em contestação</li>
          </ul>
          <div>
            <span className="subtitle">Promoções:</span>são os valores pagos em
            promoções dos pedidos pagos no período filtrado.
          </div>
          <ul>
            <li>
              <span className="subtitle">Bruto:</span>
              valor total de promoções aplicadas em pedidos pagos.
            </li>
            <li>
              <span className="subtitle">Líquido:</span>
              valor de promoções pagas pela Take para o licenciado levando em
              consideração o subsídio da Take.
            </li>
          </ul>
          <div>
            <span className="subtitle">Wallet:</span>são os valores pagos em
            wallet dos pedidos pagos naquele mês.
          </div>
          <ul>
            <li>
              <span className="subtitle">Bruto:</span>
              valor total pago com saldo da wallet;
            </li>
            <li>
              <span className="subtitle">Líquido:</span>
              valor bruto menos as taxas (comissão e transação Wallet).
            </li>
          </ul>
          <div>
            <span className="subtitle">PIX:</span>são os valores pagos em wallet
            dos pedidos pagos naquele mês.
          </div>
          <ul>
            <li>
              <span className="subtitle">Bruto:</span>
              valor total pago com saldo da wallet;
            </li>
            <li>
              <span className="subtitle">Líquido:</span>
              valor bruto menos as taxas (comissãos e transação PIX).
            </li>
          </ul>
        </>
      ),
    },
  ],
]);

export default FinancialInfoModals;
