export enum DashboardType {
  overview = '1',
  overviewGrossValueVsNetValue = '2',
  overviewGrossTotalValue = '3',
  overviewTotalUsersVsActive = '4',
  overviewNetTotalValue = '5',
  overviewDailyTransactions = '6',
  overviewBestSellingProducts = '7',
  overviewPosBilling = '8',
}

export enum RatingType {
  ratingAverageRating = '9',
  ratingReviewQuantity = '10',
  ratingWhatCanImprove = '11',
  ratingAverageRatingByReviews = '12',
  ratingAverageRatingByRefrigerators = '13',
}

export enum RefrigeratorType {
  refrigeratorStock = '14',
  refrigeratorList = '15',
  refrigeratorProductEdit = '20',
}

export enum OrderType {
  orderList = '16',
  orderDetail = '17',
}

export enum PromotionType {
  promotion = '18',
}

export enum UserType {
  user = '19',
}
