import { Row } from '@/ui/components/layout';
import styled from 'styled-components';

export const Header = styled(Row)`
  background-color: ${props => `${props.theme.primary}`};
  box-shadow: 0;
  transition: background-color 400ms;
  gap: 24px;
  padding: 0 16px;
  align-items: center;
  justify-content: space-between;
  border-bottom: 0.5px solid ${props => `${props.theme.lines}`};
  min-height: 60px;
  box-sizing: border-box;
  color: ${props => props.theme.secondary};
`;

export const HeaderContainer = styled.div`
  position: relative;
  width: 100%;
`;

export const PageTitle = styled.span`
  width: 50ch;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
