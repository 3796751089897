import { CircularProgress } from '@/ui/components/feedback';
import { ContainerEmptyResult, ContainerProgress, TableBody } from './styles';

interface TableBodyOperationsProps {
  isLoading: boolean;
  count: number;
  rowsPerPage: number;
}

export default function TableBodyOperations(props: TableBodyOperationsProps) {
  const { isLoading, count } = props;
  return (
    <>
      {Boolean(isLoading) && (
        <TableBody className="table-body-circular-progress">
          <ContainerProgress>
            <CircularProgress variant="indeterminate" />
          </ContainerProgress>
        </TableBody>
      )}
      {Boolean(!isLoading && count === 0) && (
        <TableBody className="table-body-empty-result">
          <ContainerEmptyResult>
            <>Nenhum resultado encontrado</>
          </ContainerEmptyResult>
        </TableBody>
      )}
    </>
  );
}
