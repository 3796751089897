import { IAutocomplete, IUserSaveInputs } from '@/domain/models';
import {
  AutoCompleteField,
  InputTextField,
  MaskedInputTextField,
} from '@/ui/components';
import { Grid } from '@/ui/components/layout';
import { Control, UseFormWatch } from 'react-hook-form';
import { UseQueryResult } from 'react-query';

interface IUserAddress {
  control: Control<IUserSaveInputs>;
  watch: UseFormWatch<IUserSaveInputs>;
  statesQuery: UseQueryResult<IAutocomplete[]>;
  citiesQuery: UseQueryResult<IAutocomplete[]>;
}

export default function UserAddress({
  control,
  watch,
  statesQuery,
  citiesQuery,
}: IUserAddress) {
  const { data: states = [], isLoading: isStatesLoading } = statesQuery;
  const { data: cities = [], isLoading: isCitiesLoading } = citiesQuery;

  const [address] = watch(['address']);

  const hasSomeAddressField = () => {
    if (address) {
      return Object.keys(address).some(key => {
        if (key === 'id') return false;
        return !!address[key as keyof IUserSaveInputs['address']];
      });
    }

    return false;
  };

  return (
    <>
      <h5>Endereço do usuário</h5>
      <Grid
        columns={4}
        gap={{
          row: 6,
          column: 4,
        }}
      >
        <MaskedInputTextField
          control={control}
          name="address.zipCode"
          mask="99999-999"
          label="CEP"
          rules={{
            required: hasSomeAddressField() && 'CEP é obrigatório',
          }}
        />
        <InputTextField
          control={control}
          name="address.street"
          label="Endereço"
          rules={{
            required: hasSomeAddressField() && 'Endereço é obrigatório',
          }}
        />
        <InputTextField
          control={control}
          name="address.number"
          label="Número"
          rules={{
            required: hasSomeAddressField() && 'Número é obrigatório',
          }}
        />
        <AutoCompleteField
          control={control}
          options={states}
          isLoading={isStatesLoading}
          name="address.state"
          label="Estado"
          rules={{
            required: hasSomeAddressField() && 'Estado é obrigatório',
          }}
        />
        {address?.state && (
          <AutoCompleteField
            control={control}
            options={cities}
            isLoading={isCitiesLoading}
            name="address.city"
            label="Cidade"
            isDisabled={!address?.state}
            rules={{
              deps: ['address.state'],
              required: 'Cidade é obrigatório',
            }}
          />
        )}
        <InputTextField
          control={control}
          name="address.district"
          label="Bairro"
          rules={{
            required: hasSomeAddressField() && 'Bairro é obrigatório',
          }}
        />
        <InputTextField
          control={control}
          name="address.complement"
          label="Complemento"
        />
      </Grid>
    </>
  );
}
