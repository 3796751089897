import HttpEndpoint from '../http-endpoint';

export default class ProductListHttpEndpoint {
  static PRODUCT_BASE = `${HttpEndpoint.API_VERSION}/products`;
  static PRODUCT_CATEGORY = `${ProductListHttpEndpoint.PRODUCT_BASE}/categories`;
  static PRODUCT_PROVIDER = `${ProductListHttpEndpoint.PRODUCT_BASE}/provider/autocomplete`;
  static PRODUCT_MIX = `${HttpEndpoint.API_VERSION}/mixes`;
  static PRODUCT_MIX_AUTOCOMPLETE = `${ProductListHttpEndpoint.PRODUCT_MIX}/autocomplete`;
  static PRODUCT_AUTOCOMPLETE = `${ProductListHttpEndpoint.PRODUCT_BASE}/autocomplete`;
  static PRODUCT_AUTOCOMPLETE_BY_ORDER = `${HttpEndpoint.API_VERSION}/coolers/orders/products/`;
  static PRODUCT_CATEGORY_AUTOCOMPLETE = `${ProductListHttpEndpoint.PRODUCT_CATEGORY}/autocomplete`;
}
