import { IPromotionSaveInputs } from '@/domain/models';
import {
  Control,
  UseFormResetField,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form';
import { useState } from 'react';
import {
  Button,
  // DragDrop,
  Modal,
  MultipleValuesInput,
  Switch,
} from '@/ui/components';
import { DocumentHelper } from '@/domain/helpers';
import { Grid } from '@/ui/components/layout';

export default function PromotionCPF({
  control,
  watch,
  // isDeveloper,
  setValue,
  resetField,
}: {
  control: Control<IPromotionSaveInputs, any>;
  watch: UseFormWatch<IPromotionSaveInputs>;
  // isDeveloper: boolean;
  setValue: UseFormSetValue<IPromotionSaveInputs>;
  resetField: UseFormResetField<IPromotionSaveInputs>;
}) {
  // const [file, setFile] = useState();
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [cpfSwitch, users] = watch(['cpfSwitch', 'users']);
  const intercept = !!users && users.length > 0;

  const interceptCpfSwitch = (value: boolean) => {
    if (cpfSwitch && !value) {
      setIsConfirmModalOpen(true);
    }
  };

  return (
    <>
      <h5>
        <Switch
          control={control}
          label="Selecionar CPFs"
          name="cpfSwitch"
          interceptOnChange={intercept ? interceptCpfSwitch : undefined}
        />
      </h5>
      {cpfSwitch && (
        <>
          {/* {isDeveloper && (
                        <>
                            <FormGrid
                                style={{
                                    gridTemplateColumns: 'repeat(2, 1fr)',
                                }}
                            >
                                <DragDrop
                                    control={control}
                                    fileTypes={['CSV', 'XLS', 'XLSX']}
                                    acceptedFormatsText={
                                        'Formatos aceitos: CSV, XLS e XLSX'
                                    }
                                    name="cpfFile"
                                />
                            </FormGrid>
                            <FormGrid
                                style={{
                                    gridTemplateColumns: 'repeat(2, 1fr)',
                                }}
                            >
                                <PromotionFormSectionTitle className="spacing">
                                    <Line />
                                    Ou
                                    <Line />
                                </PromotionFormSectionTitle>
                            </FormGrid>
                        </>
                    )} */}
          <Grid columns={2} gap={6}>
            <MultipleValuesInput
              control={control}
              name="users"
              label="CPFs"
              isCpf
              rules={{
                required: 'Escreva pelo menos um CPF',
                validate: {
                  isAValidCpf: (data: any[]) =>
                    data.some((item: string) =>
                      DocumentHelper.checkCpf(item),
                    ) || 'CPF inválido',
                },
              }}
            />
          </Grid>
          <Modal
            isOpen={isConfirmModalOpen}
            onClose={() => setIsConfirmModalOpen(false)}
            title="Desativar seleção de CPFs"
            okButton={
              <Button
                text="Apagar"
                className="danger"
                onClick={() => {
                  setValue('users', [], {
                    shouldDirty: true,
                    shouldTouch: true,
                  });
                  resetField('users', {
                    keepError: false,
                    defaultValue: [],
                  });
                  setValue('cpfSwitch', false, {
                    shouldDirty: true,
                    shouldTouch: true,
                  });
                  setIsConfirmModalOpen(false);
                }}
                type="button"
              />
            }
            cancelButton={
              <Button
                text="Cancelar"
                className="outlined"
                onClick={() => setIsConfirmModalOpen(false)}
                type="button"
              />
            }
          >
            <p>
              Ao desativar a seleção de CPFs, todos os CPFs selecionados serão
              apagados. <strong>Deseja continuar?</strong>
            </p>
          </Modal>
        </>
      )}
    </>
  );
}
