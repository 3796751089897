import styled from 'styled-components';

export const ProductTableHeadDetail = styled.tr`
  height: 2.5rem;
  padding: 0.8125rem 0rem 0.8125rem 1rem;
  gap: 0.5rem;
  flex-shrink: 0;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.25);
  background: ${props => props.theme.productDetailHead} !important;
  color: var(--primary-dark-6, #757575);
  font-size: 0.85rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  th {
    text-align: start;

    &.first-item {
      padding-left: 45px;
    }

    &.align-center {
      text-align: center;
    }
  }
`;
