import { ContextType } from '@/domain/models/app';

export default class SubMenu {
  id: number;
  isEnable: boolean;
  isSubmenu: boolean;
  contextType: ContextType;
  onlyTake: boolean;
  target: string;
  takeAndGoAccess: boolean;
  isPartner: boolean;

  constructor({
    id = 0,
    isEnable = true,
    contextType = ContextType.none,
    onlyTake = false,
    target = '_self',
    takeAndGoAccess = true,
    isPartner = false,
  }) {
    this.id = id;
    this.isEnable = isEnable;
    this.isSubmenu = true;
    this.contextType = contextType;
    this.onlyTake = onlyTake;
    this.target = target;
    this.takeAndGoAccess = takeAndGoAccess;
    this.isPartner = isPartner;
  }
}
