import {
  ContextClassMap,
  IAverageTicketByPublic,
  ITopCustomers,
  IUserCard,
} from '@/domain/models';
import CrmTopUsers from './crm-top-users';
import UserInfoCard from './user-info-card';
import AverageTicketByPublic from './average-ticket-by-public';
import { Grid } from '@/ui/components/layout';
import { useBreakpoints } from '@/ui/helpers';

interface ICustomerRelationshipManagementProps {
  userStatistics: IUserCard[];
  isUserStatisticsLoading: boolean;
  averageTicketByPublic: IAverageTicketByPublic[];
  isAverageTicketByPublicLoading: boolean;
  topCustomers: ITopCustomers[];
  isTopCustomersLoading: boolean;
  isDarkTheme: boolean;
}

export default function CustomerRelationshipManagement({
  userStatistics,
  isUserStatisticsLoading,
  averageTicketByPublic,
  isAverageTicketByPublicLoading,
  topCustomers,
  isTopCustomersLoading,
  isDarkTheme,
}: ICustomerRelationshipManagementProps): JSX.Element {
  const userStatisticsData = isUserStatisticsLoading
    ? Array.from({ length: 4 }, (v, i) => i)
    : userStatistics;

  const { isXs } = useBreakpoints();

  const chartCardsColumns = isXs ? 1 : 2;
  return (
    <Grid columns={1} rows={1} gap={{ row: 2, column: 2 }}>
      <Grid columns={chartCardsColumns} rows={1} gap={{ row: 2, column: 2 }}>
        <CrmTopUsers
          isDarkTheme={isDarkTheme}
          isLoading={isTopCustomersLoading}
          topCustomers={topCustomers}
        />
        <AverageTicketByPublic
          crmPieChartData={averageTicketByPublic}
          isDarkTheme={isDarkTheme}
          isLoading={isAverageTicketByPublicLoading}
        />
      </Grid>
      <Grid columns={isXs ? 1 : 4} rows={1} gap={{ row: 2, column: 2 }}>
        {userStatisticsData &&
          userStatisticsData.map((userInfo: any, index: number) => (
            <UserInfoCard
              isLoading={isUserStatisticsLoading}
              isDarkTheme={isDarkTheme}
              key={index + userInfo.name + userInfo.value}
              userInfo={userInfo}
              className={ContextClassMap.get(index)!}
              index={index}
            />
          ))}
      </Grid>
    </Grid>
  );
}
