import { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { NotificationContext } from '@/main';
import {
  ContextType,
  IAutocomplete,
  ILicensedDTO,
  TableIcons,
} from '@/domain/models';
import { Button, CircularProgress, Modal } from '@/ui/components';
import { useBreakpoints } from '@/ui/helpers';
import Default from '../default';
import { store } from '@/ui/store/config';
import selectNavigationAction from '@/ui/store/modules/app/navigation/actions';
import ILicensedForm from './licensed-form-type';
import { makeLicensedPresenter, makeLocationPresenter } from '@/main/factories';
import { Column, Grid, Row } from '@/ui/components/layout';
import { NavigationAppState } from '@/ui/store/models';
import {
  LicensedAddress,
  LicensedBankInformation,
  LicensedData,
  LicensedFormComponentProps,
} from './components';

export default function LicensedForm(): JSX.Element {
  const queryClient = useQueryClient();
  const { licensedId } = useParams();
  const { successNotification, errorNotification } =
    useContext(NotificationContext);
  const navigate = useNavigate();

  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);

  const isUpdate = !!licensedId;

  const licensedPresenter = makeLicensedPresenter();
  const locationPresenter = makeLocationPresenter();

  const { isXs } = useBreakpoints();

  const {
    handleSubmit,
    control,
    formState: { isDirty, isValid },
    reset,
    watch,
  } = useForm<ILicensedForm>({
    mode: 'all',
    reValidateMode: 'onChange',
    criteriaMode: 'all',
  });

  const [state] = watch(['state']);
  const isSubmitDisabled = !isDirty || !isValid;

  const { data: states = [], isLoading: isStatesLoading } = useQuery(
    'states',
    () => locationPresenter.getStates(),
  );

  const { data: franchiseeTypes = [], isLoading: isFranchiseeTypesLoading } =
    useQuery('franchiseeTypes', () => licensedPresenter.getFranchiseeTypes());

  const {
    data: licensedDataFromQuery,
    isLoading,
    isFetching,
  } = useQuery(
    ['licensed', licensedId],
    () => licensedPresenter.getLicensedById(Number(licensedId)),
    {
      enabled: isUpdate && !isStatesLoading && !isFranchiseeTypesLoading,
      onSuccess: data => {
        const licensed = licensedPresenter.formatDataToEdit(data);
        licensed.state = states.find(state => state.label === licensed.state)!;
        licensed.franchiseeType = franchiseeTypes.find(
          type => type.id === licensed.franchiseeType,
        )!;

        reset(licensed);

        store.dispatch(
          selectNavigationAction(
            new NavigationAppState(
              ContextType.licensed_edit,
              ContextType.licensed_list,
              TableIcons.LICENSED,
              licensed.name,
            ),
          ),
        );
      },
      onError: (err: string) => {
        errorNotification(err);
        navigate('../licensed');
      },
    },
  );

  const { data: cities = [], isLoading: isCitiesLoading } = useQuery(
    ['cities', state],
    () => locationPresenter.getCities((state as IAutocomplete).id),
  );

  const { mutate: createLicensed, isLoading: isCreating } = useMutation(
    (data: ILicensedDTO) => licensedPresenter.createLicensed(data),
    {
      onSuccess: (data: any) => {
        const id = String(data.body);
        successNotification('Licenciado criado com sucesso');
        queryClient.invalidateQueries('licensedAutoComplete');
        navigate(`../licensed/${id}`);
      },
      onError: () => {
        errorNotification('Erro ao criar licenciado');
      },
    },
  );

  const { mutate: updateLicensed, isLoading: isUpdating } = useMutation(
    (data: ILicensedDTO) =>
      licensedPresenter.updateLicensed(data, Number(licensedId)),
    {
      onSuccess: () => {
        successNotification('Licenciado atualizado com sucesso');
        queryClient.invalidateQueries(['licensed', licensedId]);
      },
      onError: () => {
        errorNotification('Erro ao atualizar licenciado');
      },
    },
  );

  const handleSave = (data: ILicensedForm) => {
    const formattedData = licensedPresenter.formatDataToSave(data);

    if (isUpdate) {
      return updateLicensed(formattedData);
    }

    return createLicensed(formattedData);
  };

  const handleSubmitButton: SubmitHandler<ILicensedForm> = data => {
    if (
      licensedDataFromQuery?.franchiseeType === 'PARTNER_AUTO' &&
      (data.franchiseeType as IAutocomplete)?.id === 'PARTNER_MANUAL'
    ) {
      setIsConfirmModalOpen(true);
      return;
    }

    handleSave(data);
  };

  const saveLicensed: SubmitHandler<ILicensedForm> = data => {
    setIsConfirmModalOpen(false);
    handleSave(data);
  };

  const isLicensedLoading = isLoading || isFetching || isCreating || isUpdating;

  useEffect(() => {
    !isUpdate &&
      store.dispatch(
        selectNavigationAction(
          new NavigationAppState(
            ContextType.licensed_save,
            ContextType.licensed_list,
            TableIcons.LICENSED,
          ),
        ),
      );
  }, []);

  const components = [LicensedData, LicensedAddress, LicensedBankInformation];

  const componentsProps: LicensedFormComponentProps = {
    control,
    watch,
    licensedPresenter,
    statesAutocomplete: {
      data: states,
      isLoading: isStatesLoading,
    },
    citiesAutocomplete: {
      data: cities,
      isLoading: isCitiesLoading,
    },
    franchiseeTypesAutocomplete: {
      data: franchiseeTypes,
      isLoading: isFranchiseeTypesLoading,
    },
  };

  return (
    <Default
      contextType={ContextType.licensed_save}
      isSubmitDisabled={isSubmitDisabled}
    >
      <Column
        as="form"
        onSubmit={handleSubmit(handleSubmitButton)}
        id="licensed-form"
      >
        {isLicensedLoading ? (
          <Row margin={{ top: 10 }} justify="center">
            <CircularProgress variant="indeterminate" />
          </Row>
        ) : (
          <>
            {components.map(Component => (
              <Component key={Component.name} {...componentsProps} />
            ))}
            <Grid responsiveType="input" margin={{ top: 6 }}>
              {isXs && (
                <Button
                  type="submit"
                  text="Salvar"
                  disabled={isSubmitDisabled}
                />
              )}
            </Grid>
          </>
        )}
      </Column>
      {isConfirmModalOpen && (
        <Modal
          title="Atenção"
          isOpen={isConfirmModalOpen}
          onClose={() => setIsConfirmModalOpen(false)}
          canClose={false}
          okButton={
            <Button
              text="Continuar e salvar"
              onClick={handleSubmit(saveLicensed)}
            />
          }
          cancelButton={
            <Button
              className="base"
              text="Retornar"
              onClick={() => setIsConfirmModalOpen(false)}
            />
          }
          content={
            <>
              Você está alterando o tipo do licenciado, a ação limpará produtos
              cadastrados das geladeiras que estão processando automaticamente e
              serão alteradas para manual, esta mudança é irreversível. Deseja
              continuar?
            </>
          }
        />
      )}
    </Default>
  );
}
