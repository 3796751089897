import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useCount } from '@/ui/hooks';
import { Button } from '../../input';
import { Column, Row } from '../../layout';
import { InstructionText, LinkSendMessageText } from './styles';
import styled from 'styled-components';

export enum ButtonRowTypes {
  invalidToken = 'invalidToken',
  linkSend = 'linkSend',
}

interface IMessageWithDrawingProps {
  drawer: JSX.Element | React.ReactNode;
  primaryMessage: string;
  secondaryMessage: string;
  buttonRowType: ButtonRowTypes;
}

export default function MessageWithDrawingStep(
  props: IMessageWithDrawingProps,
): JSX.Element {
  const { drawer, primaryMessage, secondaryMessage, buttonRowType } = props;

  const [initialCountValue, setInitialCountValue] = useState<number>(60);

  const count = useCount({ initialValue: initialCountValue, endValue: 0 });

  const navigate = useNavigate();

  const showCount = count > 0 ? `(${count})` : ' ';

  const renderButtons = (): JSX.Element => {
    switch (buttonRowType) {
      case ButtonRowTypes.linkSend:
        return (
          <Row
            wrap="wrap"
            justify="center"
            margin={{ top: 2 }}
            width="100%"
            gap={3}
          >
            <Button
              onClick={() => setInitialCountValue(initialCountValue - 1)}
              disabled={!!count}
              text={`Reenviar ${showCount}`}
              className="medium outlined white"
              type="submit"
              dataTestId="resend-button"
            />
            <Button
              onClick={() => navigate('/login')}
              text="Concluir"
              className="medium"
              type="button"
              dataTestId="conclude-button"
            />
          </Row>
        );
      case ButtonRowTypes.invalidToken:
        return (
          <Row margin={{ top: 2 }} width="100%">
            <Button
              onClick={() => navigate('/login')}
              text="Voltar"
              className="large"
              type="button"
              dataTestId="go-back-button"
            />
          </Row>
        );
      default:
        return <></>;
    }
  };

  const Container = styled.div`
    width: 23.75rem;
    height: 23rem;
    overflow: auto;
  `;

  return (
    <Column align="center" justify="center" gap={1}>
      <Container>{drawer}</Container>
      <LinkSendMessageText data-testid="primary-message">
        {primaryMessage}
      </LinkSendMessageText>
      <InstructionText data-testid="secondary-message">
        {secondaryMessage}
      </InstructionText>
      {renderButtons()}
    </Column>
  );
}
