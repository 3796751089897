import { IOrdination } from '@/domain/models';
import { IOrdinationButtonPresenter } from '@/ui/components/illustration/ordination-button';

export default class OrdinationButtonPresenter
  implements IOrdinationButtonPresenter
{
  public showOrdinationArrowButton = (
    columnName: string,
    ordination: IOrdination,
  ): any => {
    return (
      (Object.values(ordination).some(val => val === true) &&
        ordination.column === columnName) ||
      (ordination.show.showColumnName == columnName &&
        ordination.show.showing == 'show')
    );
  };
}
