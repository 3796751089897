import { createGlobalStyle } from 'styled-components';
import typography from './typography';

export default createGlobalStyle`
    * {
        font-family: Roboto;
    }

    * body {
        margin: 0;
        padding: 0;
        height: 100%;
        min-height: 100vh;
        width: 100%;
        margin: 0;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        background-color: ${props => `${props.theme.bodyBg}`}!important;

        ::-webkit-scrollbar {
        border-radius: 10px;
        width: 6px;
        border: 1px;

        @media(max-width: 650px) {
            width: 5px;
        }
        }
        ::-webkit-scrollbar {
            border-radius: 10px;
            width: 6px;
            border: 1px;

            @media(max-width: 650px) {
                width: 5px;
            }
        }

        .recharts-legend-item { font-size: '10px'; }

        /* Track */
        ::-webkit-scrollbar-track {
            background: #3e3a3b;
            border-radius: 10px;
            max-height: 50%;
        }

        /* Handle */
        ::-webkit-scrollbar-thumb {
            background: #888;
            border-radius: 10px;
            width: 6px;
            max-height: 50%;

            @media(max-width: 650px) {
                width: 5px;
            }
        }

        /* Handle on hover */
        ::-webkit-scrollbar-thumb:hover {
            background: #555;
        }
    }

    --toastify-color-success: #36B37E;

    html {
        background-color: ${props => `${props.theme.primary}`};

        ::-webkit-scrollbar {
        border-radius: 10px;
        width: 6px;
        border: 1px;

        @media(max-width: 650px) {
            width: 5px;
        }
        }
    ::-webkit-scrollbar {
        border-radius: 10px;
        width: 6px;
        border: 1px;

        @media(max-width: 650px) {
            width: 5px;
        }
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: #3e3a3b;
        border-radius: 10px;
        max-height: 50%;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: #888;
        border-radius: 10px;
        width: 10px;
        max-height: 50%;

        @media(max-width: 650px) {
            width: 5px;
        }
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: #555;
    }

    }

    :root {
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
    }

    input[type=number] {
    -moz-appearance: textfield;
    }
    --toastify-color-light: #fff;
    --toastify-color-dark: #121212;
    --toastify-color-info: #3498db;
    --toastify-color-success: #36B37E;
    --toastify-color-warning: #f1c40f;
    --toastify-color-error: #e74c3c;
    --toastify-color-transparent: rgba(255, 255, 255, 0.7);

    --toastify-icon-color-info: var(--toastify-color-info);
    --toastify-icon-color-success: var(--toastify-color-success);
    --toastify-icon-color-warning: var(--toastify-color-warning);
    --toastify-icon-color-error: var(--toastify-color-error);

    --toastify-toast-width: 320px;
    --toastify-toast-background: #fff;
    --toastify-toast-min-height: 64px;
    --toastify-toast-max-height: 800px;
    --toastify-z-index: 9999;

    --toastify-text-color-light: #757575;
    --toastify-text-color-dark: #fff;

    //Used only for colored theme
    --toastify-text-color-info: #fff;
    --toastify-text-color-success: #fff;
    --toastify-text-color-warning: #fff;
    --toastify-text-color-error: #fff;

    --toastify-spinner-color: #616161;
    --toastify-spinner-color-empty-area: #e0e0e0;

    // Used when no type is provided
    // toast("**hello**")
    --toastify-color-progress-light: linear-gradient(
        to right,
        #36B37E,
        #5ac8fa,
        #007aff,
        #34aadc,
        #5856d6,
        #ff2d55
    );
    // Used when no type is provided
    --toastify-color-progress-dark: #bb86fc;
    --toastify-color-progress-info: var(--toastify-color-info);
    --toastify-color-progress-success: var(--toastify-color-success);
    --toastify-color-progress-warning: var(--toastify-color-warning);
    --toastify-color-progress-error: var(--toastify-color-error);

        &&.MuiButtonBase-root &.MuiCheckbox-root &.Mui-checked {
            color: #fff;
        }
    }

    ${typography}


    #notistack-snackbar {
        font-size: 16px;
        z-index: 99999;

        .MuiSvgIcon-root {
            margin-right: 10px;
            z-index: 99999;
        }
    }

    .MuiSnackbar-root {
        z-index: 99999;
    }

    .MuiIconButton-root.notistack-snackbar-close svg {
        color: #fff!important
    }

    .tertiary {
        background-color: #FFCD00;
    }

    && .MuiList-root {
        background: ${props => props.theme.cardBg};
        border-radius: 8px;
    }

    && .MuiMenu-list {
        background: ${props => props.theme.cardBg};
        border-radius: 8px;
    }

    && .MuiPaper-root {
        background: none;
    }

    && .MuiMenuItem-root {
        
        font-size: 14px;
        color: ${props => props.theme.secondary} !important;
        background-color: ${props => props.theme.primary};
    }

    && .MuiButtonBase {
        color: ${props => props.theme.secondary}
    }

    && .MuiPopover-paper {
        background: none !important;
    }

    && .MuiMenuItem-root & .Mui-selected & .MuiButtonBase-root {
        background-color: ${props => props.theme.primary};
        
    }

    && .MuiOutlinedInput-root .Mui-focused .MuiOutlinedInput-input {
        color: ${props => props.theme.tertiary};
        
    }

    && .MuiInputLabel-root .Mui-focused {
        color: ${props => props.theme.tertiary};
        
    }

    && .Mui-focused {
        color: ${props => props.theme.secondary};
    }

    &&.MuiButtonBase-root &.MuiCheckbox-root &.Mui-checked {
        color: #fff;
    }

    &&.MuiInputBase-root &.MuiTablePagination-select {
        margin-right: 0px;
        
    }

    ::-webkit-scrollbar {
        border-radius: 10px;
        width: 6px;
        border: 1px;

        @media(max-width: 650px) {
            width: 5px;
            height: 5px !important;
        }
        }
    ::-webkit-scrollbar {
        border-radius: 10px;
        width: 6px;
        border: 1px;

        @media(max-width: 650px) {
            width: 5px;
        }
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: #3e3a3b;
        border-radius: 10px;
        max-height: 50%;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: #888;
        border-radius: 10px;
        width: 10px;
        max-height: 50%;

        @media(max-width: 650px) {
            width: 5px;
        }
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: #555;
    }

    & textarea {
        background-color: ${props => props.theme.primary};
        margin-top: 10px;
        border-radius: 16px;
        border-color: ${props => props.theme.lines};
        padding: 10px;
        color: ${props => props.theme.quaternary};
        font-size: 16px;
    }

    textarea:focus {
        outline: none !important;
        border: 2px solid #FFCD00;
    }
`;
