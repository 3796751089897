import { PaginationModel, SearchRequestModel } from '@/domain/models';

export default class SearchResponseModel<T> {
  searchModel?: SearchRequestModel;
  page: PaginationModel;
  data: T;

  constructor(
    data: T,
    page: PaginationModel,
    searchModel = undefined as SearchRequestModel | undefined,
  ) {
    this.data = data;
    this.page = page;
    this.searchModel = searchModel;
  }

  static makeEmpty(): SearchResponseModel<any> {
    return new SearchResponseModel<any>([] as any[], new PaginationModel({}));
  }
}
