import { Control, Controller, RegisterOptions } from 'react-hook-form';
import { Autocomplete, CircularProgress } from '@mui/material';
import defaultInputStyles from '../input-styles';
import { IAutocomplete } from '@/domain/models';
import StyledTextField from '../styled-text-field';

interface IAutocompleteProps {
  control: Control<any, any>;
  options: IAutocomplete[];
  name: string;
  label: string;
  defaultValue?: IAutocomplete | string;
  rules?: RegisterOptions;
  isDisabled?: boolean;
  interceptOnChange?: (data: any) => any;
  dataTestId?: string;
  isLoading?: boolean;
}

export default function AutoCompleteField({
  control,
  options,
  name,
  label,
  defaultValue,
  rules,
  isDisabled = false,
  interceptOnChange,
  dataTestId,
  isLoading = false,
}: IAutocompleteProps) {
  function instanceOfIAutocomplete(object: any): object is IAutocomplete {
    return 'id' in object || 'label' in object;
  }

  const { inputStyles, componentProps, textFieldStyles } =
    defaultInputStyles(isDisabled);

  const findDefaultOption = (value: IAutocomplete | string) => {
    if (!isLoading && options) {
      if (value) {
        if (typeof value === 'object' && instanceOfIAutocomplete(value)) {
          return options.find(({ id }) => {
            if (typeof id === 'number') {
              return id === Number(value.id);
            }

            return id === value.id;
          });
        } else if (typeof defaultValue === 'string') {
          return options.find(({ id }) => {
            if (typeof id === 'number') {
              return id === Number(value);
            }

            return id === value;
          });
        }
      }
    }

    return null;
  };

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({
        field: { ref, onChange, ...field },
        fieldState: { error },
      }) => (
        <Autocomplete
          options={!isLoading || !isDisabled ? options : []}
          sx={inputStyles}
          componentsProps={componentProps}
          getOptionLabel={value => value.label}
          onChange={(_, data) => {
            if (interceptOnChange) {
              return interceptOnChange(data);
            }
            onChange(data);
          }}
          {...field}
          value={findDefaultOption(field.value)}
          disabled={isLoading || isDisabled}
          readOnly={isLoading || isDisabled}
          loading={isLoading}
          loadingText="Carregando..."
          renderInput={params => (
            <StyledTextField
              data-testid={dataTestId && dataTestId}
              sx={textFieldStyles}
              inputRef={ref}
              label={label}
              error={!!error}
              helperText={error ? error?.message : null}
              required={!!rules?.required}
              {...params}
              disabled={isDisabled || isLoading}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {isLoading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
              {...field}
            />
          )}
        />
      )}
    />
  );
}
