import { ContextRoutePath } from './context-route-path';
import { ContextType } from './context-type';

export const ContextRouteMap = new Map<string, string>([
  [ContextType.none, ''],
  [ContextType.mural_take, ContextRoutePath.mural_take],
  [ContextType.uptime_downtime_apis, ContextRoutePath.uptime_downtime_apis],
  [ContextType.order, ContextRoutePath.order],
  [ContextType.home_overview, ContextRoutePath.home_overview],
  [ContextType.home_rating, ContextRoutePath.home_rating],
  // [ContextType.refrigerator_status, ContextRoutePath.refrigerator_status],
  [ContextType.refrigerator_stock, ContextRoutePath.refrigerator_stock],
  [ContextType.refrigerator_list, ContextRoutePath.refrigerator_list],
  [
    ContextType.refrigerator_status_list,
    ContextRoutePath.refrigerator_status_list,
  ],
  [
    ContextType.refrigerator_status_dashboard,
    ContextRoutePath.refrigerator_status_dashboard,
  ],
  [ContextType.promotion_list, ContextRoutePath.promotion_list],
  [ContextType.user, ContextRoutePath.user],
  [ContextType.user_save, ContextRoutePath.user_save],
  [ContextType.user_edit, ContextRoutePath.user_edit],
  [ContextType.financial, ContextRoutePath.financial],
  [ContextType.product_list, ContextRoutePath.product_list],
  [ContextType.product_save, ContextRoutePath.product_save],
  [ContextType.product_mix, ContextRoutePath.product_mix],
  [ContextType.product_category, ContextRoutePath.product_category],
  [ContextType.order_edit, ContextRoutePath.order_edit],
  [
    ContextType.monitoring_opening_attempts,
    ContextRoutePath.monitoring_opening_attempts,
  ],
  [ContextType.licensed_list, ContextRoutePath.licensed_list],
  [ContextType.licensed_save, ContextRoutePath.licensed_save],
  [ContextType.licensed_edit, ContextRoutePath.licensed_edit],
  [ContextType.banner_save, ContextRoutePath.banner_save],
  [ContextType.banner_list, ContextRoutePath.banner_list],
  [ContextType.banner_edit, ContextRoutePath.banner_edit],
]);
