import { useTheme } from 'styled-components';
import StyledSvgIcon from './svg-icon.styles';
import { useSelector } from 'react-redux';
import { StoreState } from '@/ui/store/models';

export default function ProductsMenuIcon(): JSX.Element {
  const currentTheme = useTheme();
  const activeMenu = useSelector(
    (currentStore: StoreState) => currentStore.navigation.activeMenu,
  );

  return (
    <StyledSvgIcon
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke={
        activeMenu === 'products'
          ? currentTheme.secondary
          : currentTheme.quaternary
      }
    >
      <path
        d="M13.3337 7.50008V5.00008C13.3337 3.15913 11.8413 1.66675 10.0003 1.66675C8.15938 1.66675 6.66699 3.15913 6.66699 5.00008V7.50008M2.99366 8.62672L2.49366 13.9601C2.3515 15.4765 2.28042 16.2347 2.53204 16.8203C2.75308 17.3348 3.14043 17.7601 3.63201 18.0282C4.1916 18.3334 4.95313 18.3334 6.4762 18.3334H13.5245C15.0475 18.3334 15.8091 18.3334 16.3686 18.0282C16.8602 17.7601 17.2476 17.3348 17.4686 16.8203C17.7202 16.2347 17.6492 15.4765 17.507 13.9601L17.007 8.62672C16.8869 7.3462 16.8269 6.70595 16.5389 6.22188C16.2853 5.79557 15.9106 5.45434 15.4625 5.24162C14.9537 5.00008 14.3106 5.00008 13.0245 5.00008L6.9762 5.00008C5.69007 5.00008 5.047 5.00008 4.53818 5.24162C4.09005 5.45434 3.71533 5.79557 3.46171 6.22188C3.17374 6.70594 3.11371 7.3462 2.99366 8.62672Z"
        stroke={
          activeMenu === 'products'
            ? currentTheme.secondary
            : currentTheme.quaternary
        }
        strokeWidth="1.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </StyledSvgIcon>
  );
}
