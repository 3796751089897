import { Grid } from '@/ui/components/layout';
import LicensedFormComponentProps from './licensed-form-component-props';
import {
  AutoCompleteField,
  InputTextField,
  MaskedInputTextField,
} from '@/ui/components';

export default function LicensedAddress({
  control,
  watch,
  statesAutocomplete,
  citiesAutocomplete,
}: LicensedFormComponentProps) {
  const [state] = watch(['state']);

  return (
    <>
      <h5>Endereço do licenciado</h5>
      <Grid columns={4} gap={6} responsiveType="input">
        <MaskedInputTextField
          control={control}
          name="zipCode"
          label="CEP"
          mask="99999-999"
          rules={{
            required: 'CEP é obrigatório',
          }}
        />
        <InputTextField
          control={control}
          name="street"
          label="Endereço"
          rules={{
            required: 'Endereço é obrigatório',
          }}
        />
        <InputTextField
          control={control}
          name="number"
          label="Número"
          rules={{
            required: 'Número é obrigatório',
          }}
        />
        <InputTextField
          control={control}
          name="complement"
          label="Complemento"
        />
        <AutoCompleteField
          control={control}
          options={statesAutocomplete.data}
          isLoading={statesAutocomplete.isLoading}
          name="state"
          label="Estado"
          rules={{
            required: 'Estado é obrigatório',
          }}
        />
        <AutoCompleteField
          control={control}
          options={citiesAutocomplete.data}
          isDisabled={!state}
          isLoading={citiesAutocomplete.isLoading}
          name="city"
          label="Cidade"
          rules={{
            required: 'Cidade é obrigatório',
          }}
        />
        <InputTextField
          control={control}
          name="district"
          label="Bairro"
          rules={{
            required: 'Bairro é obrigatório',
          }}
        />
      </Grid>
    </>
  );
}
