import { ReactNode, SyntheticEvent } from 'react';
import { useSelector } from 'react-redux';
import { useTheme } from 'styled-components';
import { Box } from '@mui/material';
import { StoreState } from '@/ui/store/models';
import { StyledTab, StyledTabs, TabPanel } from './styles';

export default function Tabs({
  value,
  setValue,
  tabs,
  children,
  handleTabChange,
}: {
  value: number;
  setValue: (value: number) => void;
  tabs: string[];
  children: ReactNode;
  handleTabChange?: (event: SyntheticEvent, newValue: number) => void;
}) {
  const isDarkTheme = useSelector((store: StoreState) => {
    return store.toggleTheme.darkTheme;
  });
  const currentTheme = useTheme();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    if (handleTabChange) {
      return handleTabChange(event, newValue);
    }

    setValue(newValue);
  };

  return (
    <>
      <Box
        sx={{
          bgcolor: isDarkTheme ? currentTheme.primary : currentTheme.bodyBg,
          borderBottom: '1px solid ' + currentTheme.lines,
          width: '100%',
        }}
      >
        <StyledTabs
          value={value}
          onChange={handleChange}
          isDarkTheme={isDarkTheme}
        >
          {tabs.map((tab, index) => (
            <StyledTab
              key={tab + index}
              label={tab}
              isDarkTheme={isDarkTheme}
            />
          ))}
        </StyledTabs>
      </Box>
      <Box sx={{ p: 1 }} />
      {tabs.map((tab, index) => (
        <TabPanel
          key={tab + index}
          value={value}
          index={index}
          isDarkTheme={isDarkTheme}
        >
          {children}
        </TabPanel>
      ))}
    </>
  );
}
