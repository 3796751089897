import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import {
  IRefrigeratorStockDetail,
  RefrigeratorStockDetailProduct,
} from '@/domain/models';
import { makeRefrigeratorStockPresenter } from '@/main/factories';
import { StoreState } from '@/ui/store/models';
import { NoProductsFound, OrderDetailTitle } from '@/ui/pages/order/styles';
import CloseIcon from '@/ui/components/illustration/icons/close-icon';
import {
  CircularProgress,
  CloseButton,
  Drawer,
  FilterSidebarHeader,
  OrderDetailContent,
  StyledDivider,
  Table,
  TableContainer,
} from '@/ui/components';
import { ProductImage } from '../../stock/styles';
import { Row } from '@/ui/components/layout';
type Anchor = 'right';

export default function StockDetail() {
  const [stockDetail, setStockDetail] = useState<any>([]);
  const [stockDetailLoading, setStockDetailLoading] = useState(true);
  const refrigeratorPresenter = makeRefrigeratorStockPresenter();
  const [isStockDetailOpen, setIsStockDetailOpen] = useState<any>({
    right: false,
  });
  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event &&
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return;
      }

      setIsStockDetailOpen({ ...isStockDetailOpen, [anchor]: open });
    };

  const sidebar = useSelector((store: StoreState) => store.sidebar);

  useEffect(() => {
    if (sidebar.sidebarName === 'refrigerator_stock_detail') {
      setIsStockDetailOpen(sidebar.sidebarState);
      setStockDetailLoading(true);
      refrigeratorPresenter
        .getRefrigeratorStockDetailAsync(sidebar.elementId)
        .then((result: IRefrigeratorStockDetail) => setStockDetail(result))
        .then(() => setStockDetailLoading(false));
    }
  }, [sidebar]);

  const list = (anchor: Anchor) => (
    <Drawer role="filter">
      <FilterSidebarHeader className="order">
        <Row justify="space-between" align="center" gap={4} grow={1}>
          <OrderDetailTitle>
            <h2 style={{ marginTop: 16, marginBottom: 16 }}>
              {stockDetail && !stockDetailLoading && stockDetail.pointOfSale}
            </h2>
            <p
              style={{
                margin: '0 10px',
                textAlign: 'center',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {stockDetail && !stockDetailLoading && stockDetail.device}
            </p>
          </OrderDetailTitle>
          <CloseButton
            className="sidebar-close-icon"
            onClick={toggleDrawer(anchor, false)}
          >
            <CloseIcon />
          </CloseButton>
        </Row>
      </FilterSidebarHeader>
      <StyledDivider />
      <OrderDetailContent>
        {!stockDetailLoading ? (
          <TableContainer>
            {stockDetail.stockDetailProducts &&
            stockDetail.stockDetailProducts.length > 0 ? (
              <Table className="xs m-0">
                <thead>
                  <tr className="table-head">
                    <th>Produto</th>
                    <th>Código</th>
                    <th>Nome</th>
                    <th>Quantidade</th>
                  </tr>
                </thead>
                <tbody>
                  {stockDetail.stockDetailProducts &&
                    stockDetail.stockDetailProducts.map(
                      (
                        product: RefrigeratorStockDetailProduct,
                        index: number,
                      ) => (
                        <tr
                          key={product.productCode + index}
                          className="content-row"
                        >
                          <td className="start">
                            <ProductImage
                              className="product-detail-img"
                              src={product.productImg}
                              alt="Produto"
                            />
                          </td>
                          <td className="product-code">
                            {product.productCode}
                          </td>
                          <td>{product.productName}</td>
                          <td>{product.quantity}</td>
                        </tr>
                      ),
                    )}
                </tbody>
              </Table>
            ) : (
              <NoProductsFound>Nenhum produto encontrado</NoProductsFound>
            )}
          </TableContainer>
        ) : (
          <Row align="center" justify="center">
            <CircularProgress variant="indeterminate" />
          </Row>
        )}
      </OrderDetailContent>
    </Drawer>
  );

  return (
    <div>
      {(['right'] as const).map((anchor, index) => (
        <>
          <SwipeableDrawer
            key={anchor + index}
            anchor={anchor}
            open={isStockDetailOpen[anchor]}
            onClose={toggleDrawer(anchor, false)}
            onOpen={toggleDrawer(anchor, true)}
          >
            {list(anchor)}
          </SwipeableDrawer>
        </>
      ))}
    </div>
  );
}
