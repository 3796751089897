import { IOpeningAttemptsFilterParams } from '@/presentation/models/monitoring/opening-attempts-filter';

const monitoringOpeningAttemptsDefaultValues: IOpeningAttemptsFilterParams = {
  coolerId: null,
  licensedId: null,
  beginDate: null,
  endDate: null,
  userName: null,
  userCPF: null,
  page: {
    id: '0',
    name: 'page',
    label: '0',
  },
  limit: {
    id: 25,
    name: 'limit',
    label: '25',
  },
  column: {
    id: 'beginDate',
    name: 'column',
    label: 'beginDate',
  },
  sort: {
    id: 'DESC',
    name: 'sort',
    label: 'DESC',
  },
};

export default monitoringOpeningAttemptsDefaultValues;
