import { useContext, useEffect } from 'react';
import { ContextType, TableIcons } from '@/domain/models';
import makeOrderTablePresenter from '@/main/factories/presenters/pages/order/order-table-presenter-factory';
import { store } from '@/ui/store/config';
import selectNavigationAction from '@/ui/store/modules/app/navigation/actions';
import { TableServerSide } from '@/ui/components';
import { FilterContext } from '@/ui/context/context-filter';
import Default from '../default';
import OrderDetail from './order-detail';
import {
  DeadbeatReportModal,
  ManualOrderReview,
  NfceModal,
  OrderImageModal,
  ProblemReasonModal,
  OrderRefundModal,
  ReprocessOrderModal,
} from './components';
import { NavigationAppState } from '@/ui/store/models';

export default function OrderList(): JSX.Element {
  const { setActivePage, setShouldReRenderTable } = useContext(FilterContext);

  const orderTablePresenter = makeOrderTablePresenter();

  useEffect(() => {
    setActivePage(ContextType.order);
    store.dispatch(
      selectNavigationAction(
        new NavigationAppState(
          ContextType.order,
          ContextType.order,
          TableIcons.ORDER,
        ),
      ),
    );
  }, []);

  return (
    <Default contextType={ContextType.order}>
      <ReprocessOrderModal rerenderTable={() => setShouldReRenderTable(true)} />
      <ProblemReasonModal />
      <OrderRefundModal rerenderTable={() => setShouldReRenderTable(true)} />
      <OrderImageModal />
      <DeadbeatReportModal />
      <ManualOrderReview rerenderTable={() => setShouldReRenderTable(true)} />
      <OrderDetail />
      <NfceModal />
      <TableServerSide
        presenter={orderTablePresenter}
        rowProps={{
          enableRowActions: true,
          identityPropertyName: 'order',
          rowActions: orderTablePresenter.makeRowActions(),
          moreActionsMenu: orderTablePresenter.moreActionsMenu,
        }}
        paginationProps={{
          enablePagination: true,
          pageIndex: 0,
          rowsPerPage: 25,
          rowsPerPageOptions: [25, 50, 75, 100],
        }}
        hasRowSelection
      />
    </Default>
  );
}
