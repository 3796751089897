import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import { IOrderDetail } from '@/domain/models';
import makeOrderListPresenter from '@/main/factories/presenters/pages/order/order-list-presenter-factory';
import { StoreState } from '@/ui/store/models';
import {
  CircularProgress,
  CloseButton,
  CloseIcon,
  Drawer,
  FilterSidebarHeader,
  OrderDetailContent,
  StyledDivider,
  Table,
  TableContainer,
} from '@/ui/components';
import { NoProductsFound, OrderDetailTitle } from './styles';
import { ProductImage } from '../refrigerator/stock/styles';
import { Row } from '@/ui/components/layout';
type Anchor = 'right';

export default function OrderDetail() {
  const [orderDetail, setOrderDetail] = useState<any>();
  const [orderDetailLoading, setOrderDetailLoading] = useState<boolean>(true);
  const [isOrderDetailOpen, setIsOrderDetailOpen] = useState<any>({
    right: false,
  });

  const sidebar = useSelector((store: StoreState) => store.sidebar);

  const orderListPresenter = makeOrderListPresenter();

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event &&
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return;
      }
      setIsOrderDetailOpen({ ...isOrderDetailOpen, [anchor]: open });
    };

  useEffect(() => {
    if (sidebar.sidebarName === 'order_detail') {
      setIsOrderDetailOpen(sidebar.sidebarState);
      setOrderDetailLoading(true);
      orderListPresenter
        .getOrderDetail(sidebar.elementId)
        .then((result: any) => setOrderDetail(result))
        .then(() => setOrderDetailLoading(false));
    }
  }, [sidebar]);

  const list = (anchor: Anchor) => (
    <Drawer role="filter">
      <FilterSidebarHeader className="order">
        <Row justify="space-between" align="center" gap={4} grow={1}>
          <OrderDetailTitle justify="start">
            <h2 style={{ marginTop: 16, marginBottom: 16 }}>
              {sidebar.elementData?.location}
            </h2>
            <p
              style={{
                margin: '0 10px',
                textAlign: 'center',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              #{sidebar.elementData?.orderId}
            </p>
          </OrderDetailTitle>
          <CloseButton
            className="sidebar-close-icon"
            onClick={toggleDrawer(anchor, false)}
          >
            <CloseIcon />
          </CloseButton>
        </Row>
      </FilterSidebarHeader>
      <div style={{ width: '100%' }}>
        <StyledDivider />
      </div>
      <OrderDetailContent>
        <TableContainer>
          {orderDetailLoading ? (
            <Row align="center" justify="center">
              <CircularProgress variant="indeterminate" />
            </Row>
          ) : orderDetail && orderDetail.length > 0 ? (
            <Table className="xs m-0">
              <thead>
                <tr className="table-head">
                  <th>Produto</th>
                  <th>Código</th>
                  <th>Nome</th>
                  <th>Quantidade</th>
                  <th>Valor</th>
                </tr>
              </thead>
              <tbody>
                {orderDetail &&
                  orderDetail.map((product: IOrderDetail, index: number) => (
                    <tr
                      key={product.productCode + index + product.productName}
                      className="content-row"
                    >
                      <td className="start">
                        <ProductImage
                          className="product-detail-img"
                          src={product.productImg}
                          alt="Produto"
                        />
                      </td>
                      <td className="product-code">{product.productCode}</td>
                      <td>{product.productName}</td>
                      <td>{product.quantity}</td>
                      <td>
                        {Intl.NumberFormat('pt-br', {
                          style: 'currency',
                          currency: 'BRL',
                        }).format(+product.productValue)}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          ) : (
            <NoProductsFound>Nenhum produto encontrado</NoProductsFound>
          )}
        </TableContainer>
      </OrderDetailContent>
    </Drawer>
  );

  return (
    <div>
      {(['right'] as const).map((anchor, index) => (
        <>
          <SwipeableDrawer
            key={anchor + index}
            anchor={anchor}
            open={isOrderDetailOpen[anchor]}
            onClose={toggleDrawer(anchor, false)}
            onOpen={toggleDrawer(anchor, true)}
          >
            {list(anchor)}
          </SwipeableDrawer>
        </>
      ))}
    </div>
  );
}
