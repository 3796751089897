import { IAutocomplete, IRefrigeratorEditProduct } from '@/domain/models';
import { UseQueryResult } from 'react-query';
import AddProductForm from './add-product-form';
import { useMemo } from 'react';
import ProductsTable from './products-table';

interface IRefrigeratorProductsFormProps {
  refrigeratorProductsQuery: UseQueryResult<Array<IAutocomplete>>;
  allProductsQuery: UseQueryResult<Array<IRefrigeratorEditProduct>>;
  addRefrigeratorProduct: any;
  updateProduct: any;
  deleteProduct: any;
}

export default function RefrigeratorProductsForm({
  refrigeratorProductsQuery,
  allProductsQuery,
  addRefrigeratorProduct,
  updateProduct,
  deleteProduct,
}: IRefrigeratorProductsFormProps) {
  const { data: refrigeratorProducts } = allProductsQuery;
  const { data: allProducts } = refrigeratorProductsQuery;

  const productsOptions: Array<IAutocomplete> = useMemo(() => {
    if (refrigeratorProducts && allProducts) {
      return allProducts.filter(product => {
        return !refrigeratorProducts.find(
          refrigeratorProduct =>
            Number(refrigeratorProduct.productId) === Number(product.id),
        );
      });
    }

    return allProducts || [];
  }, [allProducts, refrigeratorProducts]);

  return (
    <>
      <AddProductForm
        productOptions={productsOptions}
        addRefrigeratorProduct={addRefrigeratorProduct}
      />
      <ProductsTable
        refrigeratorProductsQuery={allProductsQuery}
        updateProduct={updateProduct}
        deleteProduct={deleteProduct}
      />
    </>
  );
}
