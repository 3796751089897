import { useContext, useEffect } from 'react';
import { parsePhoneNumber } from 'react-phone-number-input';
import { SubmitHandler, useForm } from 'react-hook-form';
import { UserFilterParams } from '@/presentation';
import { IFilterDrawer } from '@/ui/interfaces';
import {
  InputTextField,
  MaskedInputTextField,
  Filter,
  InputPhoneNumber,
  AutoCompleteField,
} from '@/ui/components';
import { FilterContext } from '@/ui/context/context-filter';
import 'dayjs/locale/pt-br';
import { useQueryClient } from 'react-query';
import { IAutocomplete, IUserFilterInputs } from '@/domain/models';
import { AuthenticationHelper, PermissionHelper } from '@/domain/helpers';

export default function UserFilter({ filter, setFilter }: IFilterDrawer) {
  const { getFilters, resetFilter, setResetFilter, updateFilters } =
    useContext(FilterContext);
  const filtersPage = getFilters();

  const token = AuthenticationHelper.getToken();
  const permissionHelper = new PermissionHelper(token.auth);
  const isAllowed =
    permissionHelper.isTakeAndGo() || permissionHelper.isDeveloper();

  const queryClient = useQueryClient();

  const licensedAutoCompleteData = queryClient.getQueryData<
    Array<IAutocomplete>
  >('licensedAutoComplete');

  const formatPhone = () => {
    if (!filtersPage?.telephone?.id) return '';

    if (
      filtersPage?.telephone?.id &&
      !parsePhoneNumber(filtersPage?.telephone?.id, 'BR')?.isValid()
    ) {
      return filtersPage?.telephone?.id;
    }

    if (
      filtersPage?.telephone?.id &&
      parsePhoneNumber(filtersPage?.telephone?.id, 'BR')?.isValid()
    ) {
      return parsePhoneNumber(
        filtersPage?.telephone?.id,
        'BR',
      )?.formatNational();
    }

    return null;
  };

  const resetValues = {
    licensedId: filtersPage?.licensed ? filtersPage?.licensed : null,
    name: filtersPage?.name ? filtersPage?.name.id : '',
    email: filtersPage?.email ? filtersPage?.email.id : '',
    telephone: formatPhone(),
    code: filtersPage?.code ? filtersPage?.code.id : '',
    cpf: filtersPage?.cpf ? filtersPage?.cpf.id : '',
  };

  const { handleSubmit, control, reset } = useForm<IUserFilterInputs>({
    defaultValues: resetValues,
  });
  const submitHandler = (data: IUserFilterInputs) => {
    const userFilterParams = new UserFilterParams(
      data.code,
      data.cpf,
      data.email,
      data.name,
      data.telephone
        ? String(parsePhoneNumber(data.telephone as any, 'BR')?.nationalNumber)
        : '',
      data.telephone,
      data.licensedId ? data.licensedId.id : '',
      data.licensedId ? data.licensedId.label : '',
    );

    updateFilters({ ...filtersPage, ...userFilterParams }, true);
    setFilter({ right: false });
    reset();
  };

  const onSubmit: SubmitHandler<IUserFilterInputs> = data =>
    submitHandler(data);

  useEffect(() => {
    reset(resetValues);
  }, [filtersPage]);

  useEffect(() => {
    if (resetFilter) {
      reset(resetValues);
      setResetFilter(false);
    }
  }, [resetFilter]);

  return (
    <Filter
      filter={filter}
      setFilter={setFilter}
      handleSubmit={handleSubmit}
      onSubmit={onSubmit}
    >
      {isAllowed && (
        <AutoCompleteField
          dataTestId="licensed-autocomplete"
          control={control}
          options={licensedAutoCompleteData as IAutocomplete[]}
          name="licensedId"
          label="Licenciado"
          data-testid="licensed-autocomplete"
        />
      )}
      <InputTextField control={control} name="name" label="Nome" />
      <InputTextField
        control={control}
        name="email"
        label="E-mail"
        type="email"
      />
      <InputPhoneNumber control={control} name="telephone" label="Telefone" />
      <InputTextField control={control} name="code" label="Código próprio" />
      <MaskedInputTextField
        control={control}
        mask="999.999.999-99"
        name="cpf"
        label="CPF"
      />
    </Filter>
  );
}
