import { IWalletUpdateBalance } from '@/domain/models';
import { IWalletRepositoryPort } from '@/infra';

export default class WalletPresenter {
  constructor(private readonly walletRepository: IWalletRepositoryPort) {}

  async updateWalletBalance(data: IWalletUpdateBalance) {
    return await this.walletRepository.updateWalletBalance(data);
  }
}
