import { Fragment } from 'react';
import IHomeOverviewPresenter from '../../home-overview-presenter';
import { FirstRow, SecondRow, ThirdRow, FourthRow } from './rows';

interface OverviewChartsProps {
  isDarkTheme: boolean;
  homePresenter: IHomeOverviewPresenter;
  filterParams: any;
  isAllowed: boolean;
}

export default function OverviewCharts({
  isDarkTheme,
  homePresenter,
  filterParams,
  isAllowed,
}: OverviewChartsProps) {
  const rows = [FirstRow, SecondRow, ThirdRow, FourthRow];

  return (
    <Fragment>
      {rows.map((Row, index) => (
        <Row
          isAllowed={isAllowed}
          key={index + Row.name}
          isDarkTheme={isDarkTheme}
          homePresenter={homePresenter}
          filterParams={filterParams}
        />
      ))}
    </Fragment>
  );
}
