export default class SidebarAppState {
  sidebarName: string;
  sidebarState: { right: boolean };
  elementId: string | number;
  elementData?: any;

  constructor({
    sidebarName = '',
    sidebarState = { right: false },
    elementId = '',
    elementData = {},
  }) {
    this.sidebarName = sidebarName;
    this.sidebarState = sidebarState;
    this.elementId = elementId;
    this.elementData = elementData;
  }
}
