import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#fbcc0a',
      contrastText: '#312e2f',
    },
    secondary: {
      main: '#fff',
      contrastText: '#fbcc0a',
    },
    success: {
      main: '#388e3c',
      contrastText: '#fff',
    },
    error: {
      main: '#d32f2f',
      contrastText: '#fff',
    },
    text: {
      primary: '#1e88e5',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'initial',
          cursor: 'pointer',
          padding: '14px 16px',
          borderRadius: '8px',
          fontSize: '14px',
          lineHeight: '140%',
          boxShadow: 'none',
          whiteSpace: 'nowrap',

          '&:disabled': {
            backgroundColor: '#e8e8e8',
            color: '#bababa',
          },
        },
        containedPrimary: {
          backgroundColor: '#fbcc1a',
          color: '#312e2f',

          '&:hover': {
            backgroundColor: '#ffe580',
          },

          '&:disabled': {
            backgroundColor: '#e8e8e8',
            color: '#bababa',
          },
        },
        containedSecondary: {
          backgroundColor: '#fff',
          color: '#312E2F',
          boxShadow: 'inset 0 0 0 2px #fbcc0a',

          '&:hover': {
            boxShadow: 'inset 0 0 0 2px #ffe580',
            backgroundColor: '#fff',
          },

          '&:disabled': {
            boxShadow: 'none',
          },
        },
        containedSuccess: {
          backgroundColor: '#388e3c',
          color: '#fff',
          '&:hover': {
            backgroundColor: '#1b5e20',
          },
        },
        containedError: {
          backgroundColor: '#d32f2f',
          color: '#fff',
          '&:hover': {
            backgroundColor: '#9a0007',
          },
        },
        textPrimary: {
          textAlign: 'left',
          justifyContent: 'flex-start',
          padding: 0,
          color: '#1f70f2',
          '&:hover': {
            color: '#1565c0',
            background: 'transparent',
          },
          '&:disabled': {
            backgroundColor: 'transparent',
            color: '#bababa',
          },
        },
      },
    },
  },
});

export default theme;
