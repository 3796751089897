export enum useCase {
  UNLIMITED = 'UNLIMITED',
  ONE_TIME = 'ONE_TIME',
  PER_DAY = 'PER_DAY',
  FIRST_BUY = 'FIRST_BUY',
}

export const UseCaseLabelMap = new Map<string, string>([
  [useCase.UNLIMITED, 'Ilimitado'],
  [useCase.ONE_TIME, 'Uso único'],
  [useCase.PER_DAY, 'Por dia'],
  [useCase.FIRST_BUY, 'Primeira Compra'],
]);
