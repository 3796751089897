import { useContext, useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import moment from 'moment';
import { useQuery, useQueryClient } from 'react-query';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/pt-br';
import {
  AuthenticationHelper,
  DateHelper,
  PermissionHelper,
  RequestQueryMaker,
} from '@/domain/helpers';
import { IAutocomplete } from '@/domain/models';
import { FinancialFilterParams } from '@/presentation/presenters/pages/financial';
import { makeRefrigeratorListPresenter } from '@/main/factories';
import { IFilterDrawer } from '@/ui/interfaces';
import {
  DateTitle,
  DatePickerField,
  Filter,
  AutoCompleteField,
} from '@/ui/components';
import { FilterContext } from '@/ui/context/context-filter';

export default function FinancialFilter({ filter, setFilter }: IFilterDrawer) {
  const { getFilters, resetFilter, setResetFilter, updateFilters } =
    useContext(FilterContext);
  const filtersPage = getFilters();

  const roles = AuthenticationHelper.getToken().auth;
  const permissionHelper = new PermissionHelper(roles);
  const refrigeratorListPresenter = makeRefrigeratorListPresenter();

  const isAllowed =
    permissionHelper.isTakeAndGo() || permissionHelper.isDeveloper();

  const date = new Date();
  const lastDay = moment(
    new Date(date.getFullYear(), date.getMonth() + 1, 0),
  ).format('DD/MM/YYYY');

  const resetValues = {
    coolerId: filtersPage?.coolerId ? filtersPage?.coolerId : null,
    licensedId: filtersPage?.licensedId ? filtersPage?.licensedId : null,
    date: filtersPage?.date
      ? DateHelper.getDefaultDate(filtersPage?.date.id)
      : lastDay,
  };

  const { handleSubmit, control, setValue, watch, reset } = useForm<{
    coolerId: { id: string; label: string } | null;
    licensedId: { id: string; label: string } | null;
    date: any;
  }>({
    mode: 'onChange',
    defaultValues: resetValues,
  });

  const [licensedId] = watch(['licensedId']);

  const onSubmit: SubmitHandler<{
    coolerId: { id: string; label: string } | null;
    licensedId: { id: string; label: string } | null;
    date: any;
  }> = data => submitHandler(data);

  const submitHandler = (data: any) => {
    const date = data.date ? data.date : lastDay;

    const financialFilterParams = new FinancialFilterParams(
      DateHelper.formatDateToFinancialFilter(DateHelper.formatDate(date)),
      data.coolerId ? data.coolerId.id : '',
      data.licensedId ? data.licensedId.id : '',
      data.coolerId ? data.coolerId.label : '',
      data.licensedId ? data.licensedId.label : '',
    );

    updateFilters({ ...filtersPage, ...financialFilterParams });
    setFilter({ right: false });
  };

  const queryClient = useQueryClient();

  const licensedAutoCompleteData = queryClient.getQueryData<
    Array<IAutocomplete>
  >('licensedAutoComplete');
  const coolerAutoCompleteData =
    queryClient.getQueryData<Array<IAutocomplete>>('coolerAutoComplete');

  const {
    data: coolerAutoComplete = [],
    isLoading: isCoolerLoading,
    isFetching: isCoolerFetching,
  } = useQuery(
    ['coolerAutoComplete', licensedId],
    () =>
      refrigeratorListPresenter.getCoolerFilterAutocompleteAsync(
        RequestQueryMaker({
          licensedId: licensedId?.id,
          active: '0',
        }),
      ),
    {
      onSuccess: (data: IAutocomplete[]) => {
        const cooler =
          filtersPage?.coolerId &&
          data.find((item: any) => item.id === filtersPage?.coolerId.id);
        cooler && setValue('coolerId', cooler);
      },
      enabled: !coolerAutoCompleteData,
      initialData: coolerAutoCompleteData,
    },
  );

  const isCoolerAutoCompleteLoading = isCoolerLoading || isCoolerFetching;

  useEffect(() => {
    reset(resetValues);
  }, [filtersPage]);

  useEffect(() => {
    if (resetFilter) {
      reset(resetValues);
      setResetFilter(false);
    }
  }, [resetFilter]);

  return (
    <Filter
      filter={filter}
      setFilter={setFilter}
      handleSubmit={handleSubmit as any}
      onSubmit={onSubmit}
    >
      <LocalizationProvider adapterLocale="pt-br" dateAdapter={AdapterDayjs}>
        <DateTitle>Mês e ano</DateTitle>
        <DatePickerField
          mask="MM/YYYY"
          views={['month', 'year']}
          control={control}
          name="date"
          label="mês / ano"
          dataTestId="date-picker"
          placeholder="mm/aaaa"
        />
      </LocalizationProvider>
      {isAllowed && (
        <AutoCompleteField
          control={control}
          options={licensedAutoCompleteData as IAutocomplete[]}
          name="licensedId"
          label="Licenciado"
          dataTestId="licensed-autocomplete"
        />
      )}
      <AutoCompleteField
        isDisabled={isAllowed ? !licensedId : false}
        control={control}
        options={coolerAutoComplete}
        name="coolerId"
        label="Cooler"
        dataTestId="cooler-autocomplete"
        isLoading={isCoolerAutoCompleteLoading}
      />
    </Filter>
  );
}
