import { RequestQueryMaker, StringHelper } from '@/domain/helpers';
import {
  SearchRequestModel,
  SearchResponseModel,
  HttpRequest,
  HttpMethod,
  HttpEndpoint,
  PaginationModel,
  SearchResponseServer,
} from '@/domain/models';
import { IHttpServicePort, ISearchRepositoryPort } from '@/infra/ports';

export class SearchRepositoryAdapter implements ISearchRepositoryPort {
  constructor(private readonly httpServicePort: IHttpServicePort) {}

  async getDataAsync<T>(
    request: any,
    endpoint: string,
  ): Promise<SearchResponseModel<T>> {
    const httpRequest = new HttpRequest({
      url: `${endpoint}${RequestQueryMaker(request)}`,
      method: HttpMethod.get,
      auth: true,
    });

    const response = await this.httpServicePort.request<SearchResponseServer>(
      httpRequest,
    );

    return new SearchResponseModel<T>(
      response.data?.content ?? [],
      new PaginationModel({
        from: request.pagination?.from || 0,
        size: request.pagination?.size || 0,
        total: response.data?.totalElements ?? 0,
        totalPages: response.data?.totalPages ?? 0,
        numberOfElements: response.data?.numberOfElements || 0,
      }),
      request,
    );
  }

  private makeQueryString(request: SearchRequestModel): string {
    const { criterias, orderBy, pagination } = request;

    const filters = criterias
      .map(filter => `${filter.Key}=${filter.Value}`)
      .join('&');

    const page = pagination.from <= 0 ? 0 : pagination.from / pagination.size!;

    return StringHelper.format(
      HttpEndpoint.SEARCH_QUERY_STRING,
      filters,
      `${page}`,
      `${pagination.size}`,
      orderBy.field,
      orderBy.isOrderByDesc ? 'DESC' : 'ASC',
    );
  }
}
