import {
  HttpMethod,
  HttpResponse,
  TelemeteryHttpEndpoint,
} from '@/domain/models';
import {
  IHttpServicePort,
  IRefrigeratorStatusRepositoryPort,
} from '@/infra/ports';

export class RefrigeratorStatusRepositoryAdapter
  implements IRefrigeratorStatusRepositoryPort
{
  constructor(private readonly httpServicePort: IHttpServicePort) {}

  async getIndicatorsAsync<T>(): Promise<HttpResponse<T>> {
    return this.httpServicePort.request<T>({
      url: TelemeteryHttpEndpoint.REFRIGERATOR_INDICATORS,
      method: HttpMethod.get,
      auth: true,
      notify: true,
    });
  }
  async getRefrigeratorStatusAsync<T>(
    filters: string,
  ): Promise<HttpResponse<T>> {
    return this.httpServicePort.request<T>({
      url: `${TelemeteryHttpEndpoint.REFRIGERATOR_STATUS_LIST}${filters}`,
      method: HttpMethod.get,
      auth: true,
      notify: true,
    });
  }
}
