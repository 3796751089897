import { HttpError, HttpServerError } from '@/domain/models/services';
import { NotificationHelper } from '@/ui/helpers';
import {
  Message,
  SeverityLevel,
  SeverityLevelDescription,
} from '@/domain/models';
import { AxiosError } from 'axios';

export default class HttpErrorHelper {
  static normalizeError(
    axiosError: AxiosError<HttpServerError>,
    notify: boolean,
  ): HttpError {
    if (axiosError.response?.status === 500) {
      return this.makeErrorMessageByServer(
        axiosError.response?.data,
        axiosError.response?.status as number,
        SeverityLevel.error,
      );
    }

    const error = this.makeErrorMessageByServer(
      axiosError.response?.data,
      axiosError.response?.status as number,
      SeverityLevel.warning,
    );

    if (notify) {
      const severity = SeverityLevelDescription.get(error.severity);
      NotificationHelper.notify(error.message, severity);
    }

    return error;
  }

  private static makeErrorMessageByServer(
    serverError: HttpServerError | undefined,
    status: number,
    severity: SeverityLevel,
  ): HttpError {
    function messageError(
      status: number,
      messageFromError: string | undefined,
    ) {
      if (messageFromError === undefined || messageFromError === null) {
        return Message.unexpected;
      }
      if (status === 500) {
        return Message.unexpected;
      }
      return messageFromError;
    }

    const message = messageError(status, serverError?.message);
    return new HttpError(message, status, severity);
  }
}
