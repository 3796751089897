import styled from 'styled-components';

export const CustomCheckbox = styled.div`
  /* Customize the label (the container) */
  .container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    user-select: none;
    background: ${props => props.theme.columnFilter};
    box-shadow: ${props => props.theme.cardBoxShadow};
    height: 80px;
    border-radius: 20px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
  }

  .inactive {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    user-select: none;
    background: ${props => props.theme.bodyBg};
    height: 80px;
    border-radius: 20px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    color: ${props => props.theme.quaternary};
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
  }

  && .container .inactive {
    background: ${props => props.theme.lines};
  }

  /* Hide the browser's default checkbox */
  .inactive input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 20%;
    right: 20px;
    height: 25px;
    width: 25px;
  }

  /* On mouse-over, add a grey background color */
  .container:hover input ~ .checkmark {
    color: ${props => props.theme.secondary};
    width: 20px;
    height: 20px;
    z-index: 999;
  }

  /* When the checkbox is checked, add a blue background */
  .container input:checked ~ .checkmark {
    color: ${props => props.theme.secondary};
    border-color: ${props => props.theme.secondary};
    width: 20px;
    height: 20px;
    z-index: 999;
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    position: absolute;
    display: none;
    border-color: ${props => props.theme.secondary};
  }

  /* Show the checkmark when checked */
  .container input:checked ~ .checkmark:after {
    border-color: ${props => props.theme.secondary};
    display: block;
    content: '';
  }

  /* Style the checkmark/indicator */
  .container .checkmark:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  .inactive:hover input ~ .checkmark {
    color: ${props => props.theme.bodyBg};
    width: 20px;
    height: 20px;
    z-index: 999;
  }

  /* When the checkbox is checked, add a blue background */
  .inactive input:checked ~ .checkmark {
    color: ${props => props.theme.bodyBg};
    width: 20px;
    height: 20px;
    z-index: 999;
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  .inactive input:checked ~ .checkmark:after {
    display: block;
    content: '';
  }

  /* Style the checkmark/indicator */
  .inactive .checkmark:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  .text {
    position: absolute;
    top: 8px;
  }
`;
