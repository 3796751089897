import { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { useQuery, useQueryClient } from 'react-query';
import { IAutocomplete } from '@/domain/models';
import Roles from '@/domain/models/helpers/roles-enum';
import { AuthenticationHelper, PermissionHelper } from '@/domain/helpers';
import { makeLicensedPresenter } from '@/main/factories';
import { AutoCompleteField, Button, Modal } from '@/ui/components';
import { Column, Row } from '@/ui/components/layout';
import { FilterContext } from '@/ui/context/context-filter';

interface FilterLicensedModalProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

export default function FilterLicensedModal({
  isOpen,
  setIsOpen,
}: FilterLicensedModalProps) {
  const queryClient = useQueryClient();

  const { getFilters, updateFilters } = useContext(FilterContext);

  const filterPage = getFilters();

  function dispatchFilters(licensed: any) {
    const licensedValue = {
      id: licensed.id,
      name: 'licensedId',
      label: licensed.label,
    };

    window.localStorage.setItem('licensedId', licensed.id);
    window.localStorage.setItem('licensedName', licensed.label);

    updateFilters({ ...filterPage, licensedId: licensedValue });
  }

  const licensedPresenter = makeLicensedPresenter();

  const licensedAutoCompleteData = queryClient.getQueryData<
    Array<IAutocomplete>
  >('licensedAutoComplete');

  const { data: licensedAutocomplete = [], isLoading } = useQuery(
    ['licensedAutoComplete'],
    () => licensedPresenter.getLicensedAutoComplete(),
    {
      onSuccess: data => {
        queryClient.setQueryData('licensedAutoComplete', data);
        if (filterPage.licensedId) {
          return;
        }

        const token = AuthenticationHelper.getToken();
        const permissionHelper = new PermissionHelper(token.auth);
        const isAllowed =
          permissionHelper.isTakeAndGo() || permissionHelper.isDeveloper();
        if (permissionHelper.hasAnyRole([Roles.FRANCHISEE]) && !isAllowed) {
          const licensed = data.find(
            (item: any) => item.id === token.licensed_id,
          );
          dispatchFilters(licensed);
        }
      },
      enabled: !licensedAutoCompleteData,
    },
  );

  const {
    handleSubmit,
    control,
    formState: { isDirty, isValid },
  } = useForm<{
    licensed: IAutocomplete;
  }>({
    mode: 'all',
  });

  const isSubmitDisabled = !isDirty || !isValid;

  const submit = handleSubmit(({ licensed }) => {
    dispatchFilters(licensed);
    setIsOpen(false);
  });

  const OkButton = () => (
    <Row align="center" justify="end">
      <Button
        dataTestId="apply-filter"
        text="Filtrar"
        type="submit"
        disabled={isSubmitDisabled}
      />
    </Row>
  );

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => null}
      title="Escolher Licenciado"
      okButton={<></>}
      canClose={false}
    >
      <Column as="form" onSubmit={submit} gap={4}>
        <AutoCompleteField
          name="licensed"
          control={control}
          label="Licenciado"
          options={licensedAutocomplete}
          isLoading={isLoading}
          rules={{
            required: {
              value: true,
              message: 'Por favor, selecione o licenciado desejado',
            },
          }}
        />

        <OkButton />
      </Column>
    </Modal>
  );
}
