import MDEditor from '@uiw/react-md-editor';
import styled from 'styled-components';

export const StyledMDEditor = styled(MDEditor)`
  background: ${props => props.theme.bodyBg};
  border-radius: 4px;
  --md-editor-box-shadow-color: ${props => props.theme.lines};
  color: ${props => props.theme.secondary};

  textarea:focus {
    border: none;
  }

  .w-md-editor-area .w-md-editor-input {
    background: ${props => props.theme.bodyBg};
    background-color: ${props => props.theme.bodyBg};
  }

  .w-md-editor-toolbar {
    background: ${props => props.theme.bodyBg};

    ul {
      li {
        button {
          svg {
            width: 16px;
            height: 16px;

            path {
              fill: ${props => props.theme.secondary};
            }
          }

          polygon {
            fill: ${props => props.theme.secondary};
          }
        }
      }
    }
  }

  .wmde-markdown {
    background-color: ${props => props.theme.bodyBg};
  }

  .wmde-markdown hr {
    background-color: #252525;
    border-bottom: #000;
  }
`;
