import styled from 'styled-components';

export const LinkSendMessageText = styled.h1`
  color: var(--monochromatic-black, #000);
  text-align: center;
  font-size: 32px;
  font-weight: 700;
  white-space: break-spaces;
`;

export const InstructionText = styled.p`
  color: var(--monochromatic-black, #000);
  text-align: center;
  font-style: normal;

  &.font-20 {
    font-size: 20px;
  }
`;

export const RadioLabelStrong = styled.span`
  color: var(--mono-black, #000);
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%; /* 19.6px */
`;

export const RadioUserData = styled.span`
  color: var(--primary-dark-6, #757575);
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  margin-top: 38px;
`;
