import styled from 'styled-components';
import { TableCell as TableCellUI } from '@mui/material';

export const TableCell = styled(TableCellUI)`
  .row-action {
    display: flex;
    width: min-content;

    .action-disabled {
      cursor: no-drop;
    }
  }
`;

export const ContainerAction = styled.div`
  align-self: center;
`;
