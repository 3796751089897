import {
  HttpMethod,
  HttpRequest,
  HttpResponse,
  LicensedHttpEndpoint,
} from '@/domain/models';
import { IHttpServicePort, ILicensedRepositoryPort } from '@/infra/ports';

export class LicensedRepository implements ILicensedRepositoryPort {
  constructor(private readonly httpServicePort: IHttpServicePort) {}

  async getAutoComplete<T>(licensedId?: any): Promise<HttpResponse<T>> {
    const containLicensedFilter = licensedId ? `?${licensedId}` : '';

    const request = new HttpRequest({
      url: LicensedHttpEndpoint.LICENSED_AUTOCOMPLETE + containLicensedFilter,
      method: HttpMethod.get,
      auth: true,
    });

    return this.httpServicePort.request<T>(request);
  }

  async getLicensedsList<T>(params?: string): Promise<HttpResponse<T>> {
    const request = new HttpRequest({
      url: LicensedHttpEndpoint.LICENSED_BASE + params,
      method: HttpMethod.get,
      auth: true,
    });

    return this.httpServicePort.request<T>(request);
  }

  async getLicensedById<T>(licensedId: number): Promise<HttpResponse<T>> {
    const request = new HttpRequest({
      url: `${LicensedHttpEndpoint.LICENSED_BASE}/${licensedId}`,
      method: HttpMethod.get,
      auth: true,
    });

    return this.httpServicePort.request<T>(request);
  }

  async createLicensed<T>(data: any): Promise<HttpResponse<T>> {
    const request = new HttpRequest({
      url: LicensedHttpEndpoint.LICENSED_BASE,
      method: HttpMethod.post,
      auth: true,
      body: data,
    });

    return this.httpServicePort.request<T>(request);
  }

  async updateLicensed<T>(
    data: any,
    licensedId: number,
  ): Promise<HttpResponse<T>> {
    const request = new HttpRequest({
      url: `${LicensedHttpEndpoint.LICENSED_BASE}/${licensedId}`,
      method: HttpMethod.put,
      auth: true,
      body: data,
    });

    return this.httpServicePort.request<T>(request);
  }

  async getFranchiseeTypes<T>(): Promise<HttpResponse<T>> {
    const request = new HttpRequest({
      url: LicensedHttpEndpoint.LICENSED_FRANCHISEE_TYPES,
      method: HttpMethod.get,
      auth: true,
    });

    return this.httpServicePort.request<T>(request);
  }
}
