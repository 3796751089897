import { RequestQueryMaker } from '@/domain/helpers';
import { Correlation, IAutocomplete } from '@/domain/models';
import {
  makePromotionListPresenter,
  makeRefrigeratorListPresenter,
} from '@/main/factories';
import { IBannerManagementForm } from '@/presentation';
import {
  AutoCompleteField,
  MultipleValuesAutoCompleteField,
} from '@/ui/components';
import { Grid } from '@/ui/components/layout';
import { useState } from 'react';
import { Control, UseFormResetField, UseFormWatch } from 'react-hook-form';
import { useQueries, useQueryClient } from 'react-query';

export default function BannerDevices({
  control,
  watch,
  resetField,
  isTakeAndGo,
}: {
  control: Control<IBannerManagementForm>;
  watch: UseFormWatch<IBannerManagementForm>;
  resetField: UseFormResetField<IBannerManagementForm>;
  isTakeAndGo: boolean;
}) {
  const [categories, setCategories] = useState<IAutocomplete[]>([]);
  const [products, setProducts] = useState<IAutocomplete[]>([]);

  const refrigeratorPresenter = makeRefrigeratorListPresenter();
  const promotionPresenter = makePromotionListPresenter();

  const transformMatrizToArray = (
    matriz: IAutocomplete[][],
  ): IAutocomplete[] => {
    const array: IAutocomplete[] = [];

    for (const row of matriz) {
      for (const item of row) {
        if (!array.some(({ id }) => id === item.id)) {
          array.push(item as IAutocomplete);
        }
      }
    }

    return array;
  };

  const queryClient = useQueryClient();

  const licensedAutoCompleteData =
    queryClient.getQueryData<Array<IAutocomplete>>('licensedAutoComplete') ||
    [];

  const [licensed, coolers, categoriesValue, productsValue, correlationType] =
    watch(['licensed', 'coolers', 'categories', 'products', 'correlationType']);

  const [
    {
      data: coolersAutocomplete = [],
      isLoading: isCoolersLoading,
      isFetching: isCoolersFetching,
    },
    { isLoading: isCategoriesLoading, isFetching: isCategoriesFetching },
    { isLoading: isProductsLoading, isFetching: isProductsFetching },
    {
      isLoading: isCategoriesByCoolerLoading,
      isFetching: isCategoriesByCoolerFetching,
    },
    {
      isLoading: isProductsByCoolerLoading,
      isFetching: isProductsByCoolerFetching,
    },
  ] = useQueries([
    {
      queryKey: ['coolers-autocomplete', licensed?.id],
      queryFn: () =>
        refrigeratorPresenter.getCoolerFilterAutocompleteAsync(
          RequestQueryMaker({
            active: '0',
            licensedId: licensed?.id,
          }),
        ),
    },
    {
      queryKey: ['categories-autocomplete', licensed?.id],
      queryFn: () =>
        promotionPresenter.getCategoriesAsync(
          RequestQueryMaker({ licensedId: licensed?.id }),
        ),
      enabled: Array.isArray(coolers) && coolers.length === 0,
      onSuccess: (data: IAutocomplete[]) => setCategories(data),
    },
    {
      queryKey: ['products-autocomplete', licensed?.id],
      queryFn: () =>
        promotionPresenter.getProductsAsync(
          RequestQueryMaker({ licensedId: licensed?.id }),
        ),
      enabled: Array.isArray(coolers) && coolers.length === 0,
      onSuccess: (data: IAutocomplete[]) => setProducts(data),
    },
    {
      queryKey: ['categories-autocomplete-by-cooler', coolers, licensed?.id],
      queryFn: () =>
        Promise.all(
          (coolers as IAutocomplete[])
            .map(({ id }) =>
              promotionPresenter
                .getCategoriesByCoolerIdAsync(
                  id,
                  RequestQueryMaker({
                    licensedId: licensed?.id,
                  }),
                )
                .then(response => response),
            )
            .flat(),
        ),
      enabled: Array.isArray(coolers) && coolers.length > 0,
      onSuccess: (data: IAutocomplete[][]) => {
        const array = transformMatrizToArray(data);
        setCategories(array as any);
        const categoriesDefaultValue = [];

        for (const categoryValue of categoriesValue!) {
          const category = array.some(
            ({ label }) => label === categoryValue.label,
          );

          if (category) {
            categoriesDefaultValue.push(categoryValue);
          }
        }

        resetField('categories', {
          defaultValue: categoriesDefaultValue,
        });
      },
    },
    {
      queryKey: ['products-autocomplete-by-cooler', coolers, licensed?.id],
      queryFn: (): Promise<IAutocomplete[][]> =>
        Promise.all(
          (coolers as IAutocomplete[])
            .map(
              ({ id }): Promise<IAutocomplete[]> =>
                promotionPresenter
                  .getProductsByCoolerIdAsync(
                    id,
                    RequestQueryMaker({
                      licensedId: licensed?.id,
                    }),
                  )
                  .then(response => response),
            )
            .flat(),
        ),
      enabled: Array.isArray(coolers) && coolers.length > 0,
      onSuccess: (data: IAutocomplete[][]) => {
        const array = transformMatrizToArray(data);
        setProducts(array as any);
        const productsDefaultValue = [];

        for (const productValue of productsValue) {
          const product = array.some(
            ({ label }) => label === productValue.label,
          );

          if (product) {
            productsDefaultValue.push(productValue);
          }
        }

        resetField('products', {
          defaultValue: productsDefaultValue,
        });
      },
    },
  ]);

  const isCoolersLoadingOrFetching = isCoolersFetching || isCoolersLoading;
  const isCategoriesLoadingOrFetching =
    isCategoriesLoading ||
    isCategoriesFetching ||
    isCategoriesByCoolerLoading ||
    isCategoriesByCoolerFetching;
  const isProductsLoadingOrFetching =
    isProductsLoading ||
    isProductsFetching ||
    isProductsByCoolerLoading ||
    isProductsByCoolerFetching;

  return (
    <>
      <h5>Público alvo</h5>
      <Grid columns={4} gap={6} responsiveType="input">
        {isTakeAndGo && (
          <AutoCompleteField
            control={control}
            options={licensedAutoCompleteData}
            name="licensed"
            label="Licenciado"
            isDisabled={correlationType?.id === Correlation.PROMOTION}
          />
        )}
        <MultipleValuesAutoCompleteField
          control={control}
          options={coolersAutocomplete}
          name="coolers"
          label="Coolers"
          isLoading={isCoolersLoadingOrFetching}
          isDisabled={correlationType?.id === Correlation.PROMOTION}
        />
        <MultipleValuesAutoCompleteField
          control={control}
          options={categories}
          name="categories"
          label="Categorias"
          isDisabled={
            (Array.isArray(productsValue) && productsValue.length > 0) ||
            correlationType?.id === Correlation.PROMOTION
          }
          isLoading={isCategoriesLoadingOrFetching}
        />
        <MultipleValuesAutoCompleteField
          control={control}
          options={products}
          name="products"
          label="Produtos"
          isDisabled={
            (Array.isArray(categoriesValue) && categoriesValue.length > 0) ||
            correlationType?.id === Correlation.PROMOTION
          }
          isLoading={isProductsLoadingOrFetching}
        />
      </Grid>
    </>
  );
}
