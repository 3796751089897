import {
  IAutocomplete,
  IContestationCalculate,
  IContestationOrderProduct,
  IOrderEdit,
  IOrderProductEdit,
  OrderContestationModals,
} from '@/domain/models';
import makeOrderEditPresenter from '@/main/factories/presenters/pages/order/order-edit-presenter-factory';
import { store } from '@/ui/store/config';
import { sidebarOpenAction } from '@/ui/store/modules';

export default function useOrderEdit(
  orderData: IOrderEdit,
  orderId: string | number | any,
  orderProductQuantity: number | string,
  setOrderData: (value: IOrderEdit) => void,
  setIsEditing: (value: boolean | string | number) => void,
  setOrderProductQuantity: (value: number | string) => void,
  initialOrderData: IOrderEdit,
  setIsLoadingCalculate: (value: boolean) => void,
) {
  const orderEditPresenter = makeOrderEditPresenter();

  const removeRemovedProducts = (products: IOrderProductEdit[]) => {
    return products.filter(
      (product: IOrderProductEdit) => product.removed != true,
    );
  };

  const handleProductsResponse = (
    products: IContestationOrderProduct[] | any,
  ): any => {
    const newProducts: IContestationOrderProduct[] = products;

    orderData.products.map(product => {
      if (product.removed) {
        newProducts.push(product);
      }
    });

    const initialProductsMap = new Map(
      initialOrderData.products.map(product => [product.productId, product]),
    );

    for (const product of newProducts) {
      const initialProduct = initialProductsMap.get(product.productId);

      if (initialProduct) {
        if (product.quantity !== initialProduct.quantity) {
          product.oldQuantity = initialProduct.quantity;
        } else {
          product.oldQuantity = null;
        }

        if (product.totalValue !== initialProduct.totalValue) {
          product.oldTotalValue = initialProduct.totalValue;
        } else {
          product.oldTotalValue = null;
        }
      }
    }

    return newProducts;
  };

  const handleRemoveDuplicatedProducts = (
    products: IOrderProductEdit[],
    productId: string | number,
  ) => {
    return products.filter(
      product => product !== null && product.productId != productId,
    );
  };

  const handleAddProduct = (
    quantity: number,
    unitaryValue: number,
    product: IAutocomplete,
  ) => {
    const newProduct: IOrderProductEdit = {
      name: product.label,
      productId: +product.id,
      totalValue: unitaryValue * quantity,
      quantity: quantity,
      unitaryValue: unitaryValue,
      orderPaymentProductId: orderId,
      oldQuantity: initialOrderData.products.find(
        initialProduct =>
          initialProduct !== null && initialProduct.productId == +product.id,
      )?.quantity,
      oldTotalValue: initialOrderData.products.find(
        initialProduct =>
          initialProduct !== null && initialProduct.productId == +product.id,
      )?.totalValue,
    };

    const removeDuplicated = handleRemoveDuplicatedProducts(
      orderData.products,
      product.id,
    );
    const removeRemoved = removeRemovedProducts(removeDuplicated);

    const newProducts = [...removeDuplicated, newProduct];
    const newProductsWithRemoved = [...removeRemoved, newProduct];

    getCalculate(newProductsWithRemoved, newProducts);
  };

  const handleUpdateProducts = (productId: number) => {
    if (orderProductQuantity == 0) {
      return handleRemoveProduct(productId);
    }

    const newStateProducts: IOrderProductEdit[] | any = orderData.products.map(
      product => {
        if (product.productId === productId) {
          return { ...product, quantity: orderProductQuantity };
        } else {
          return product;
        }
      },
    );

    const removeRemoved = removeRemovedProducts(newStateProducts);

    const newState: IOrderEdit = {
      ...orderData,
      products: newStateProducts!,
    };

    setOrderData(newState);
    getCalculate(removeRemoved);
  };

  const handleRemoveProduct = (productId: number) => {
    const newProducts = orderData.products.filter(product => {
      return product.productId != productId && product.removed != true;
    });

    const newProductsRemoved = orderData.products.map(product => {
      if (product.productId == productId) {
        return { ...product, removed: true };
      } else {
        return product;
      }
    });

    setOrderData({
      ...orderData,
      products: newProductsRemoved,
    });
    getCalculate(newProducts, newProductsRemoved);
  };

  const getUpdatedOrderDataState = (
    res: IContestationCalculate,
    newProductsRemoved: IOrderProductEdit[],
  ) => {
    const newState: IOrderEdit = {
      ...orderData,
      paymentDetails: {
        discount: res.paymentUpdate.newDiscount,
        total: res.paymentUpdate.newTotal,
        valueToReceive: res.paymentUpdate.totalToReceive,
        valueToPay: res.paymentUpdate.totalToPay,
        valuePaidCreditCard:
          res.paymentUpdate.valuesByPaymentMethod[0].valueAfterUpdate,
        valuePaidWallet:
          res.paymentUpdate.valuesByPaymentMethod[1].valueAfterUpdate,
        valuePaidPix:
          res.paymentUpdate.valuesByPaymentMethod[2].valueAfterUpdate,
        oldTotal:
          initialOrderData.paymentDetails.total != res.paymentUpdate.newTotal
            ? initialOrderData.paymentDetails.total!
            : null,
        detailedValuesToPay: res.paymentUpdate.detailedValuesToPay,
      },
      promotion: {
        conditions: res.promotion.conditions,
        name: res.promotion.name,
        promotionType: res.promotion.promotionType,
        id: res.promotion.id,
      },
      valuesByPaymentMethod: res.paymentUpdate.valuesByPaymentMethod,
      products: newProductsRemoved
        ? newProductsRemoved
        : handleProductsResponse(res.products!)!,
    };
    setOrderData(newState);
  };

  const getCalculate = (
    newProducts?: IOrderProductEdit[],
    newProductsRemoved?: IOrderProductEdit[],
  ) => {
    setIsLoadingCalculate(true);
    orderEditPresenter
      .getOrderPaymentContestationCalculateAsync(
        orderId,
        newProducts ? newProducts : orderData.products,
      )
      .then(res => getUpdatedOrderDataState(res, newProductsRemoved!))
      .finally(() => setIsLoadingCalculate(false));
  };

  const handleOpenRemoveModal = (
    orderId: number,
    productName: string,
    productId: number,
  ) => {
    setIsEditing(false);
    store.dispatch(
      sidebarOpenAction({
        sidebarName: OrderContestationModals.ORDER_REMOVE_PRODUCT,
        sidebarState: { right: true },
        elementId: '1',
        elementData: {
          orderId: orderId,
          productName: productName,
          productId: productId,
        },
      }),
    );
  };

  const handleOpenAddProductModal = () => {
    setIsEditing(false);
    store.dispatch(
      sidebarOpenAction({
        sidebarName: OrderContestationModals.ORDER_ADD_PRODUCT,
        sidebarState: { right: true },
        elementId: '1',
        elementData: { orderId: orderId && orderId },
      }),
    );
  };

  const handleConfirmEdit = (productId: number) => {
    handleUpdateProducts(productId);
    setIsEditing(false);
  };

  const handleEnableEdit = (productId: number) => {
    setOrderProductQuantity(
      orderData.products.find(item => item.productId == productId)!.quantity,
    );
    setIsEditing(productId);
  };

  return {
    handleConfirmEdit,
    handleEnableEdit,
    handleOpenAddProductModal,
    handleOpenRemoveModal,
    handleRemoveProduct,
    handleUpdateProducts,
    handleAddProduct,
    handleProductsResponse,
  };
}
