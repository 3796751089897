import {
  ContextType,
  IRefrigeratorStatusIndicators,
  IRefrigeratorStatusListResponse,
  TableIcons,
} from '@/domain/models';
import Default from '../../../default';
import { useContext, useEffect } from 'react';
import { store } from '@/ui/store/config';
import { FilterContext } from '@/ui/context/context-filter';
import selectNavigationAction from '@/ui/store/modules/app/navigation/actions';
import { NavigationAppState, StoreState } from '@/ui/store/models';
import { useSelector } from 'react-redux';
import { IndicatorsCards } from '../components';
import makeRefrigeratorStatusPresenter from '@/main/factories/presenters/pages/refrigerator/refrigerator-status-presenter-factory';
import { StatusTable } from './status-table';
import { useInView } from 'react-intersection-observer';
import { Column } from '@/ui/components/layout';
import { CircularProgress } from '@/ui/components';

export default function RefrigeratorStatusList() {
  const {
    setActivePage,
    shouldReRenderTable,
    setShouldReRenderTable,
    getFiltersObject,
  } = useContext(FilterContext);

  const isDarkTheme = useSelector(
    (state: StoreState) => state.toggleTheme.darkTheme,
  );

  const { getStatusList } = makeRefrigeratorStatusPresenter();

  const {
    data: statusListResponse,
    isSuccess: isStatusListSuccess,
    isLoading: isStatusListLoading,
    hasNextPage: isStatusListHasNextPage,
    fetchNextPage: fetchNextStatusListPage,
    isFetchingNextPage: isStatusListFetchingNextPage,
    isFetching: isStatusListFetching,
    isRefetching: isStatusListRefetching,
    refetch: refetchStatusList,
  } = getStatusList(getFiltersObject, setShouldReRenderTable);
  const statusListPages = isStatusListSuccess ? statusListResponse.pages : [];
  const indicatorsData: IRefrigeratorStatusIndicators =
    Array.isArray(statusListResponse?.pages) &&
    statusListResponse.pages.length > 0 &&
    statusListResponse.pages[0].data.coolerStatusConsolidated
      ? (statusListResponse.pages[0].data as IRefrigeratorStatusListResponse)
          .coolerStatusConsolidated
      : {
          countCameras: null,
          countLock: null,
          countOfflineCoolers: null,
          countCameraProblems: null,
          countCoolers: null,
          countLockProblems: null,
          countOnlineCoolers: null,
        };

  const { ref, inView } = useInView({
    skip:
      !isStatusListHasNextPage ||
      isStatusListFetchingNextPage ||
      isStatusListFetching,
  });

  useEffect(() => {
    if (
      inView &&
      isStatusListHasNextPage &&
      !isStatusListFetching &&
      !isStatusListFetchingNextPage
    ) {
      fetchNextStatusListPage();
    }
  }, [inView]);

  useEffect(() => {
    setActivePage(ContextType.refrigerator_status_list);
    store.dispatch(
      selectNavigationAction(
        new NavigationAppState(
          ContextType.refrigerator_status_list,
          ContextType.refrigerator_status,
          TableIcons.REFRIGERATOR,
        ),
      ),
    );
  }, []);

  useEffect(() => {
    if (shouldReRenderTable) refetchStatusList();
  }, [shouldReRenderTable]);

  return (
    <Default contextType={ContextType.refrigerator_status_list}>
      {(isStatusListLoading || isStatusListRefetching) &&
      !isStatusListFetchingNextPage ? (
        <Column justify="center" align="center" height="100%">
          <CircularProgress variant="indeterminate" />
        </Column>
      ) : (
        <>
          <IndicatorsCards
            isDarkTheme={isDarkTheme}
            indicators={indicatorsData}
            isLoading={
              isStatusListLoading ||
              ((isStatusListLoading || isStatusListRefetching) &&
                !isStatusListFetchingNextPage)
            }
          />
          <StatusTable
            data={statusListPages}
            isLoading={isStatusListLoading}
            isFetching={isStatusListFetching || isStatusListFetchingNextPage}
            isDarkTheme={isDarkTheme}
            reference={ref as any}
          />
        </>
      )}
    </Default>
  );
}
