import ContextUserClassificationTitle from '../crm/user-classification-name';
import UserClassificationType from '../crm/user-classification.type';
import { IInfoModalProps } from './info-modal-type';

const CrmInfoModals = new Map<string, IInfoModalProps>([
  [
    UserClassificationType.inactive_users,
    {
      title: ContextUserClassificationTitle.get(
        UserClassificationType.inactive_users,
      ),
      content: (
        <>
          Total de usuários que consumiram algum produto há 60 dias pela ultima
          vez.
        </>
      ),
    },
  ],
  [
    UserClassificationType.sleeping_users,
    {
      title: ContextUserClassificationTitle.get(
        UserClassificationType.sleeping_users,
      ),
      content: (
        <>Total de usuários que consumiram algum produto entre 31 e 59 dias.</>
      ),
    },
  ],
  [
    UserClassificationType.active_users,
    {
      title: ContextUserClassificationTitle.get(
        UserClassificationType.active_users,
      ),
      content: (
        <>Total de usuários que consumiram algum produto nos últimos 30 dias.</>
      ),
    },
  ],
  [
    UserClassificationType.new_users,
    {
      title: ContextUserClassificationTitle.get(
        UserClassificationType.new_users,
      ),
      content: (
        <>
          Total de usuários que consumiram pela primeira vez algum produto nos
          últimos 30 dias.
        </>
      ),
    },
  ],
]);

export default CrmInfoModals;
