import { useState } from 'react';
import { InfoOutlined } from '@mui/icons-material';
import { DateHelper, NumberHelper } from '@/domain/helpers';
import { IDesktopFinancialContent } from '@/ui/interfaces';
import { store } from '@/ui/store/config';
import { sidebarOpenAction } from '@/ui/store/modules';
import {
  IconButton,
  StyledDivider,
  Table,
  TableHead,
  Modal,
} from '@/ui/components';
import { FinancialInfoModals } from '@/domain/models/app/context/info-modal';
import { FinancialContext } from '@/domain/models';
import { DesktopTr } from './components';
import { FinancialCard, FinancialCardTitle, TableContainer } from './styles';
import { Column } from '@/ui/components/layout';

interface IIsInfoModalOpen {
  isOpen: boolean;
  title: string;
}

export default function DesktopFinancialContent({
  financialReport,
  orderConsolidationTableColumns,
  otherTableColumns,
  financialCloseTableColumns,
  defaultMonthlyPayment,
}: IDesktopFinancialContent): JSX.Element {
  const [isInfoModalOpen, setIsInfoModalOpen] = useState<IIsInfoModalOpen>({
    isOpen: false,
    title: '',
  });

  const { orderConsolidation, financialClose, other, date } = financialReport;
  const { card, pix, promotionForClients, total, wallet } = orderConsolidation;

  const handleRowClick = (row: string) => {
    if ('isMocked' in financialReport) {
      return;
    }
    store.dispatch(
      sidebarOpenAction({
        sidebarName: row,
        sidebarState: { right: true },
        elementId: '0',
        elementData: {},
      }),
    );
  };

  const handleOpenModal = (title: string) => {
    setIsInfoModalOpen({ isOpen: true, title });
  };

  const handleCloseModal = () => {
    setIsInfoModalOpen({ isOpen: false, title: '' });
  };

  const negotiationText = () => {
    if (other.negotiation > 0) {
      return 'Negociações a receber';
    }
    if (other.negotiation < 0) {
      return 'Negociações a pagar';
    } else {
      return 'Negociação';
    }
  };

  const finalClosingText = () => {
    if (financialClose.toReceive > 0) {
      return 'A receber';
    } else {
      return 'A pagar';
    }
  };

  return (
    <Column>
      <FinancialCard>
        <FinancialCardTitle as="h2">
          <span>
            Fechamento mensal {date && ` - ${DateHelper.getMonthAndYear(date)}`}
          </span>
          <IconButton
            onClick={() => handleOpenModal(FinancialContext.FECHAMENTO_MENSAL)}
          >
            <InfoOutlined />
          </IconButton>
        </FinancialCardTitle>
        <StyledDivider />
        <TableContainer className="financial">
          <Table className="mt">
            <col width="20%" />
            <col width="50%" />
            <col width="20%" />
            <col width="20%" />
            <TableHead
              columns={orderConsolidationTableColumns}
              hasCondition={false}
              hasOrdination={false}
            />
            <tbody>
              <DesktopTr
                trTitle="Promoções para os clientes"
                trDescription="Investimento Take - Promoções"
                netValue={promotionForClients.netValue}
                grossValue={promotionForClients.grossValue}
                onClick={() => handleRowClick('promotion-for-clients')}
              />
              <DesktopTr
                trTitle="Wallet"
                trDescription="Pedidos pagos com a carteira digital Take
                                    And Go (clientes fazem recarga via APP)"
                netValue={wallet.netValue}
                grossValue={wallet.grossValue}
                onClick={() => handleRowClick('wallet')}
              />
              <DesktopTr
                trTitle="Cartão de crédito"
                trDescription="Pedidos pagos com cartão de crédito e são
                                    repassados direto pela IUGU"
                netValue={card.netValue}
                grossValue={card.grossValue}
                onClick={() => handleRowClick('credit-card')}
              />
              <DesktopTr
                trTitle="PIX"
                trDescription="Pedido pagos com PIX e são repassados direto
                                    pela IUGU"
                netValue={pix.netValue}
                grossValue={pix.grossValue}
                onClick={() => handleRowClick('pix')}
              />
              <DesktopTr
                trTitle="Total"
                trDescription="Promoções + Wallet + Cartão de crédito"
                netValue={total.netValue}
                grossValue={total.grossValue}
                className="financial-total"
              />
            </tbody>
          </Table>
        </TableContainer>
      </FinancialCard>
      <FinancialCard>
        <FinancialCardTitle>
          Outros {date && ` - ${DateHelper.getMonthAndYear(date)}`}
        </FinancialCardTitle>
        <StyledDivider />
        <TableContainer className="financial">
          <Table className="mt">
            <col width="20%" />
            <col width="70%" />
            <col width="20%" />
            <TableHead
              columns={otherTableColumns}
              hasCondition={false}
              hasOrdination={false}
            />
            <tbody>
              <DesktopTr
                trTitle="Mensalidade"
                trDescription={`Cada cooler tem uma mensalidade de ${NumberHelper.makeMoney(
                  Number(defaultMonthlyPayment?.value) || 0,
                )} referente à taxa de manutenção`}
                value={other.monthlyPayment}
                onClick={() => handleRowClick('monthly-payments')}
              />
              <DesktopTr
                trTitle="Pedidos com contestações bancárias"
                trDescription="Pedidos com contestação bancárias abertos
                                    pelos usuários comprador."
                value={other.ordersWithBankDispute}
                onClick={() => handleRowClick('bank-dispute')}
              />
              {other.condominiumTransfer ? (
                <DesktopTr
                  trTitle="Repasse do condomínio"
                  trDescription="Porcentagem do faturamento (total bruto)
                                    repassada para o condomínio."
                  value={other.condominiumTransfer}
                  className="condominium-transfer"
                  onClick={() => handleRowClick('condominium-transfer')}
                />
              ) : (
                <></>
              )}
              <DesktopTr
                trTitle="Pedidos ressarcidos"
                trDescription="Compras não pagas pelos clientes e
                                    contestações bancárias."
                value={other.refundedOrders}
                onClick={() => handleRowClick('refunded')}
              />
              <DesktopTr
                trTitle={negotiationText()}
                trDescription="Total de negociações no período."
                value={other.negotiation}
                onClick={() => handleRowClick('negotiation')}
                dataTestId="negotiation-row"
              />
            </tbody>
          </Table>
        </TableContainer>
      </FinancialCard>
      <FinancialCard className="last">
        <FinancialCardTitle>
          Fechamento financeiro
          {date && ` - ${DateHelper.getMonthAndYear(date)}`}
        </FinancialCardTitle>
        <StyledDivider />
        <TableContainer className="financial">
          <Table className="fixed mt">
            <col width="20%" />
            <col width="70%" />
            <col width="20%" />
            <TableHead
              columns={financialCloseTableColumns}
              hasCondition={false}
              hasOrdination={false}
            />
            <tbody>
              <DesktopTr
                trTitle="Recebíveis"
                trDescription="Soma dos valores a receber"
                value={financialClose.receivables}
              />
              <DesktopTr
                trTitle="Dedutíveis"
                trDescription="Soma dos valores a pagar"
                value={financialClose.deductible}
              />
              <DesktopTr
                trTitle={finalClosingText()}
                value={financialClose.toReceive}
                className="financial-to-receive"
              />
            </tbody>
          </Table>
        </TableContainer>
      </FinancialCard>
      <Modal
        isOpen={isInfoModalOpen.isOpen}
        onClose={() => handleCloseModal()}
        {...(FinancialInfoModals.get(isInfoModalOpen.title) as any)}
      />
    </Column>
  );
}
