import styled from 'styled-components';

export const StockPercentage = styled.h3`
  margin: 0;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;

  &.red {
    color: #ff5630;
  }

  &.green {
    color: #36b37e;
  }

  &.yellow {
    color: #ffab00;
  }
`;
