import { Tooltip } from 'react-tooltip';
import { InfoColumn } from '../../order-edit-info/styles';
import RefundIcon from '@/ui/components/illustration/icons/refund-icon';
import { NumberHelper } from '@/domain/helpers';
import { ChargeIcon, CircularProgress } from '@/ui/components';
import { Row } from '@/ui/components/layout';

interface IOrderRefundValueProps {
  paymentToReceive?: number;
  isLoadingCalculate?: boolean;
  valueToPay?: number;
  orderDetails?: string;
}

export default function OrderRefundValue(
  props: IOrderRefundValueProps,
): JSX.Element {
  const { paymentToReceive, isLoadingCalculate, valueToPay, orderDetails } =
    props;

  const getRefundColor = (): string => {
    if (paymentToReceive) {
      return '#FF5630';
    } else if (valueToPay) {
      return '#36B37E';
    } else {
      return '';
    }
  };

  const getRefundIcon = (): JSX.Element => {
    if (paymentToReceive) {
      return <RefundIcon />;
    } else {
      return <ChargeIcon />;
    }
  };

  const getRefundText = (): string => {
    if (paymentToReceive) {
      return 'Estorno';
    } else {
      return 'Cobrança';
    }
  };

  return (
    <InfoColumn className="align-start">
      <Tooltip id="refund_details" />
      <p style={{ color: getRefundColor()! }} className="title">
        {getRefundText()}
      </p>
      <p data-testid="payment_refund">
        {isLoadingCalculate ? (
          <CircularProgress size={20} variant="indeterminate" />
        ) : paymentToReceive || valueToPay ? (
          <Row align="center" data-testid="payment_refund">
            {getRefundIcon()}
            <span
              data-tooltip-content={orderDetails?.replaceAll('.', ',') || ''}
              data-tooltip-id="refund_details"
              style={{
                marginLeft: '5px',
                color: getRefundColor(),
              }}
            >
              {NumberHelper.makeMoney(
                paymentToReceive ? paymentToReceive! : valueToPay!,
              )}
            </span>
          </Row>
        ) : (
          '---'
        )}
      </p>
    </InfoColumn>
  );
}
