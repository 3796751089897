import styled from 'styled-components';

const ModalContent = styled.p`
  margin: 10px 0 40px;
  line-height: 24px;
`;

export default {
  ModalContent,
};
