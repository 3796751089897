import {
  AuthenticationHttpEndpoint,
  HttpMethod,
  HttpRequest,
  HttpResponse,
} from '@/domain/models/services';
import { IHttpServicePort, ILoginRepositoryPort } from '@/infra/ports';

export class LoginRepositoryAdapter implements ILoginRepositoryPort {
  constructor(private readonly httpServicePort: IHttpServicePort) {}

  async postUserCredentialsAsync<T>(
    credentials: any,
  ): Promise<HttpResponse<T>> {
    const request = new HttpRequest({
      url: AuthenticationHttpEndpoint.AUTH_BASE,
      method: HttpMethod.post,
      body: credentials,
      auth: true,
    });

    return await this.httpServicePort.request<T>(request);
  }
}
