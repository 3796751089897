import alvoradaDataMock from './alvorada.json';
import changrilaMock from './changrila.json';
import ditribuidoraMock from './distribuidora-luz.json';
import esplanadaMock from './esplanada.json';
import faculdadeEducaMock from './faculdade-educa.json';
import florenciaMock from './florencia.json';
import ipeRoxo from './ipe-roxo.json';
import maragogi from './maragogi.json';
import monaco from './monaco.json';
import transportadoraRodez from './trasnportadora-rodez.json';

export const ContextMock = new Map<string, any>([
  ['alvorada', alvoradaDataMock],
  ['florencia', florenciaMock],
  ['monaco', monaco],
  ['changrila', changrilaMock],
  ['ipe-roxo', ipeRoxo],
  ['distribuidora-luz', ditribuidoraMock],
  ['maragogi', maragogi],
  ['esplanada', esplanadaMock],
  ['faculdade-educa', faculdadeEducaMock],
  ['transportadora-rodez', transportadoraRodez],
]);
