import {
  ProductListHttpEndpoint,
  SearchCriteriaModel,
  SearchRequestModel,
  SearchResponseModel,
} from '@/domain/models';
import { StringHelper } from '@/domain/helpers';
import { ISearchRepositoryPort } from '@/infra';
import { ColumnModel } from '@/presentation/models/table';
import { WindowHelper } from '@/ui/helpers';
import { RowActionProps } from '@/ui/interfaces/props/table';
import { ITableServerSidePresenter } from '@/ui/components/table';
import { EditIcon } from '@/ui/components/illustration/icons';

export default class ProductsTablePresenter
  implements ITableServerSidePresenter
{
  constructor(private readonly searchRepository: ISearchRepositoryPort) {}

  userStatusMap: any;

  makeColumns(): ColumnModel<any>[] {
    return [
      new ColumnModel<any>('Código', 'mix', {}),
      new ColumnModel<any>('Nome', 'name', {}),
      new ColumnModel<any>('Descrição', 'description', {}),
    ];
  }

  makeSearchRequestModel(): SearchRequestModel {
    return new SearchRequestModel(ProductListHttpEndpoint.PRODUCT_MIX, 'name', {
      isOrderByDesc: true,
      criterias: [
        new SearchCriteriaModel('name', {
          value: '',
        }),
      ],
    });
  }

  makeLabelDisplayedResult(response: SearchResponseModel<any>): string {
    if (response === undefined) return '';
    if (response.page === undefined || response.page.total === 0) return '';

    const textPlural = StringHelper.pluralizeE({
      count: response.page.numberOfElements,
    });
    return `Exibindo ${response.page.numberOfElements} mix${textPlural}`;
  }

  async search<T>(
    searchModel: SearchRequestModel,
  ): Promise<SearchResponseModel<T>> {
    return await this.searchRepository.getDataAsync<any>(
      searchModel,
      ProductListHttpEndpoint.PRODUCT_MIX,
    );
  }

  makeRowActions(): RowActionProps<any>[] {
    return [
      {
        icon: () => <EditIcon />,
        tooltip: 'Editar mix',
        enableClickOnRow: false,
        onClick: (event: any, rowData: any) => {
          WindowHelper.redirect(`/product/mix/${rowData?.mix}`);
        },
      },
    ];
  }
}
