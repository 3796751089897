import getInstance from '../instance';

const instance = getInstance();

const deativateCooler = (coolerId: string) => {
  const url = `/coolers/deactivate/${coolerId}`;

  return instance.put(url);
};

export default deativateCooler;
