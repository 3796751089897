export interface IRefrigeratorStatusColumns {
  locality: boolean;
  serial: boolean;
  active: boolean;
  upTimePercentage: boolean;
  cameraProblem: boolean;
  lockProblem: boolean;
  temperature: boolean;
  date: boolean;
  version: boolean;
}

export class RefrigeratorStatusColumnsAppState {
  constructor(public activeColumns: IRefrigeratorStatusColumns) {
    this.activeColumns = activeColumns;
  }
}
