import { useState } from 'react';
import {
  Control,
  UseFormResetField,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form';
import { IPromotionSaveInputs } from '@/domain/models';
import { Button, InputTextField, Modal, Switch } from '@/ui/components';
import { Grid } from '@/ui/components/layout';

export default function PromotionPromocode({
  control,
  watch,
  setValue,
  resetField,
}: {
  control: Control<IPromotionSaveInputs, any>;
  watch: UseFormWatch<IPromotionSaveInputs>;
  setValue: UseFormSetValue<IPromotionSaveInputs>;
  resetField: UseFormResetField<IPromotionSaveInputs>;
}) {
  const [promocodeSwitch, promocode, campaignName, campaignConditions] = watch([
    'promocodeSwitch',
    'promocode',
    'campaignName',
    'campaignConditions',
  ]);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);

  const intercept = !!promocode || !!campaignName || !!campaignConditions;

  const interceptPromocodeSwitch = (value: boolean) => {
    if (promocodeSwitch && !value) {
      return setIsConfirmModalOpen(true);
    }

    return setValue('promocodeSwitch', value);
  };

  return (
    <>
      <h5>
        <Switch
          control={control}
          label="Promocode"
          name="promocodeSwitch"
          interceptOnChange={intercept ? interceptPromocodeSwitch : undefined}
        />
      </h5>
      {promocodeSwitch && (
        <>
          <Grid
            columns={2}
            gap={2}
            responsiveType="input"
            margin={{ bottom: 4 }}
          >
            <InputTextField
              control={control}
              name="promocode"
              label="Cupom"
              placeholder="Cupom promocode"
              rules={{
                required: 'Cupom é obrigatório',
              }}
            />
            <InputTextField
              control={control}
              name="campaignName"
              label="Título do promocode"
              rules={{
                required: 'Título do promocode é obrigatório',
              }}
            />
          </Grid>
          <Grid columns={1}>
            <InputTextField
              control={control}
              name="campaignConditions"
              label="Termos e condições"
              rules={{
                required: 'Termos e condições do promocode é obrigatório',
              }}
              type="textarea"
              maxLength={3000}
              characterCount
            />
          </Grid>
          <Modal
            isOpen={isConfirmModalOpen}
            title="Desativar promocode"
            onClose={() => setIsConfirmModalOpen(false)}
            okButton={
              <Button
                text="Apagar"
                className="danger"
                onClick={() => {
                  setValue('promocodeSwitch', false, {
                    shouldDirty: true,
                    shouldTouch: true,
                  });
                  ['promocode', 'campaignName', 'campaignConditions'].forEach(
                    field => {
                      setValue(field as any, '', {
                        shouldDirty: true,
                        shouldTouch: true,
                      });
                      resetField(field as any, {
                        keepError: false,
                        defaultValue: '',
                      });
                    },
                  );

                  setIsConfirmModalOpen(false);
                }}
                type="button"
              />
            }
            cancelButton={
              <Button
                text="Cancelar"
                className="outlined"
                onClick={() => setIsConfirmModalOpen(false)}
                type="button"
              />
            }
          >
            <p>
              Ao desativar o promocode, os campos serão apagados, e não será
              possível recuperar os dados. <strong>Deseja continuar?</strong>
            </p>
          </Modal>
        </>
      )}
    </>
  );
}
