import { InfoOutlined } from '@mui/icons-material';
import { CouponExpireDataAndType } from './styles';
import { InfoColumn } from '../order-edit-info/styles';
import { IOrderEdit } from '@/domain/models';
import { CircularProgress } from '@/ui/components';
import { Column } from '@/ui/components/layout';

interface IOrderPromotionInfoProps {
  conditions: string | null;
  name: string | null;
  orderInitialData?: IOrderEdit;
  isLoadingCalculate: boolean;
}

export default function OrderPromotionInfo(
  props: IOrderPromotionInfoProps,
): JSX.Element {
  const { conditions, name, orderInitialData, isLoadingCalculate } = props;

  const renderPromotionName = (): JSX.Element | string => {
    if (
      orderInitialData?.promotion &&
      orderInitialData?.promotion.name != name &&
      name == null
    ) {
      return (
        <>
          <span style={{ textDecoration: 'line-through' }}>
            {orderInitialData?.promotion.name}
          </span>
          {' Nenhuma promoção encontrada!'}
        </>
      );
    } else if (
      orderInitialData?.promotion &&
      name === orderInitialData?.promotion.name
    ) {
      return name;
    } else if (
      orderInitialData?.promotion &&
      orderInitialData?.promotion.name != name &&
      name != null
    ) {
      return (
        <>
          <span style={{ textDecoration: 'line-through' }}>
            {orderInitialData?.promotion.name}
          </span>
          {' ' + name}
        </>
      );
    } else if (!orderInitialData?.promotion && name !== null) {
      return (
        <>
          <span style={{ textDecoration: 'line-through' }}>
            {' Nenhuma promoção encontrada!'}
          </span>
          {' ' + name}
        </>
      );
    } else {
      return (
        <>
          <>Nenhuma promoção encontrada!</>
        </>
      );
    }
  };

  return (
    <Column align="start">
      <h5>Promoções</h5>
      {isLoadingCalculate ? (
        <CircularProgress variant="indeterminate" size={20} />
      ) : (
        <>
          <InfoColumn>
            <p className="title">{name && 'Promoção aplicada'}</p>
            <p data-testid="promotion_name">{renderPromotionName()}</p>
          </InfoColumn>
          <CouponExpireDataAndType>
            {conditions && <InfoOutlined sx={{ marginRight: '0.5rem' }} />}
            {conditions && conditions}
          </CouponExpireDataAndType>
        </>
      )}
    </Column>
  );
}
