import { useState } from 'react';
import { VisibilityOutlined } from '@mui/icons-material';

import { PasswordIconContainer } from '@/ui/pages/login/styles';
import { IChangePasswordNewStepProps } from '@/ui/interfaces';
import { Button, InputTextField } from '../input';
import { PasswordInputIcon } from '../illustration';
import {
  PasswordOrientationText,
  PasswordRequirements,
} from '../change-password/styles';
import { Column } from '../layout';

export default function ChangePasswordNewStep(
  props: IChangePasswordNewStepProps,
): JSX.Element {
  const {
    control,
    password,
    passwordLengthValidation,
    passwordEqualValidation,
    isDirty,
  } = props;

  const [showPassword, setShowPassword] = useState<string>('password');

  const handlePasswordClick = () => {
    if (showPassword === 'password') {
      setShowPassword('text');
    } else {
      setShowPassword('password');
    }
  };

  return (
    <>
      <p className="body-extra-large">Crie uma nova senha</p>
      <InputTextField
        type={showPassword}
        control={control}
        name="password"
        label="Senha"
        data-testid="password"
        icon={
          <PasswordIconContainer
            type="button"
            onClick={() => handlePasswordClick()}
          >
            {showPassword === 'password' ? (
              <PasswordInputIcon />
            ) : (
              <VisibilityOutlined
                sx={{
                  color: '#0C0407',
                }}
              />
            )}
          </PasswordIconContainer>
        }
        rules={{
          required: true,
          validate: {
            isNumber: value => {
              return Number.isInteger(+value);
            },
            isLengthEqualsSix: value => {
              return value.length === 6;
            },
          },
        }}
      />
      <InputTextField
        type={showPassword}
        control={control}
        name="passwordConfirmation"
        label="Confirme a senha"
        data-testid="passwordConfirmation"
        rules={{
          deps: ['password'],
          required: true,
          validate: {
            isEqualPassword: value => {
              return password === value;
            },
          },
        }}
        icon={
          <PasswordIconContainer
            type="button"
            onClick={() => handlePasswordClick()}
          >
            {showPassword === 'password' ? (
              <PasswordInputIcon />
            ) : (
              <VisibilityOutlined
                sx={{
                  color: '#0C0407',
                }}
              />
            )}
          </PasswordIconContainer>
        }
      />
      <Column>
        <PasswordOrientationText>A nova senha deve:</PasswordOrientationText>
        <PasswordRequirements>
          <li
            className={
              isDirty ? (passwordLengthValidation ? 'valid' : 'invalid') : ''
            }
          >
            Conter 6 números
          </li>
          <li
            className={
              isDirty ? (passwordEqualValidation ? ' valid' : 'invalid') : ''
            }
          >
            As senhas devem ser iguais
          </li>
        </PasswordRequirements>
      </Column>
      <Button
        disabled={!passwordLengthValidation || !passwordEqualValidation}
        dataTestId="send-button"
        text="Salvar"
        className={`medium-2 mt-2`}
        type="submit"
      />
    </>
  );
}
