import { useContext, useState } from 'react';
import { useMutation } from 'react-query';
import { useForm } from 'react-hook-form';

import {
  HttpResponse,
  IChangePasswordFormData,
  IUserChangePasswordLink,
} from '@/domain/models';
import { NotificationContext } from '@/main';
import makeUserPresenter from '@/main/factories/presenters/pages/user/user-presenter-factory';
import { IChangePasswordState, IUsePasswordRecovery } from '@/ui/interfaces';

export default function usePasswordRecovery(): IUsePasswordRecovery {
  const [changePassword, setChangePassword] = useState<IChangePasswordState>({
    isChangingPassword: false,
    changingPasswordStep: 1,
    data: {
      cpf: '',
      recoveryType: '',
      email: '',
      phone: '',
    },
  });

  const userPresenter = makeUserPresenter();

  const { errorNotification } = useContext(NotificationContext);

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<IChangePasswordFormData>({
    mode: 'onChange',
  });

  const {
    mutate: getRecoveryPasswordOptions,
    isLoading: isRecoveryPasswordOptionsLoading,
  } = useMutation(
    (userCpf: string) => userPresenter.passwordRecoveryOptionsAsync(userCpf),
    {
      onSuccess: res => {
        if (!res.success) {
          return errorNotification(res.error?.message);
        }
        setChangePassword({
          ...changePassword,
          data: { ...changePassword.data, ...res.data },
          changingPasswordStep: 2,
        });
      },
      onError: (res: HttpResponse) => {
        errorNotification(res.data.message);
      },
    },
  );

  const {
    mutate: sendUserChangePasswordLink,
    isLoading: isUserChangePasswordLinkLoading,
  } = useMutation(
    (passwordRecoveryData: IUserChangePasswordLink) =>
      userPresenter.sendUserChangePasswordLinkAsync(passwordRecoveryData),
    {
      onSuccess: () => {
        setChangePassword({
          ...changePassword,
          changingPasswordStep: 3,
        });
      },
      onError: (res: HttpResponse) => {
        errorNotification(res.data.message);
        setChangePassword({
          ...changePassword,
          changingPasswordStep: 3,
        });
      },
    },
  );

  const onSubmit = (data: IChangePasswordFormData) => {
    switch (changePassword.changingPasswordStep) {
      case 1:
        getRecoveryPasswordOptions(data.cpf);
        break;
      case 2:
        setChangePassword({
          ...changePassword,
          data: {
            ...changePassword.data,
            recoveryType: data.recoveryOption,
          },
        });
        sendUserChangePasswordLink({
          cpf: data.cpf,
          recoveryType: data.recoveryOption,
        });
        break;
      case 3:
        sendUserChangePasswordLink({
          cpf: data.cpf,
          recoveryType: data.recoveryOption,
        });
        break;
      default:
        break;
    }
  };

  const isLoading =
    isUserChangePasswordLinkLoading || isRecoveryPasswordOptionsLoading;

  const isSubmitButtonVisible = changePassword.changingPasswordStep === 1;

  return {
    onSubmit,
    isLoading,
    isSubmitButtonVisible,
    changePassword,
    setChangePassword,
    control,
    handleSubmit,
    errors,
    isValid,
  };
}
