import styled from 'styled-components';

const RatingMiniCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  position: relative;

  p {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    margin: 0;
    padding: 0;
    color: ${props => props.theme.secondary};
    text-align: start;
  }

  h1 {
    font-style: normal;
    font-weight: 400;
    font-size: 68px;
    line-height: 120%;
    margin: 0;
    padding: 0;
    color: ${props => props.theme.secondary};
    text-align: start;
  }
`;

export default RatingMiniCard;
