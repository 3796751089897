import { useTheme } from 'styled-components';
import { StyledButton } from './styles';
import { ReactNode } from 'react';

interface IButton {
  text: string | ReactNode;
  onClick?: (e: any) => void;
  type?: any;
  icon?: JSX.Element;
  className?: string;
  disabled?: boolean;
  dataTestId?: string;
}

export default function Button({
  text,
  onClick,
  type,
  icon,
  className,
  disabled,
  dataTestId,
}: IButton): JSX.Element {
  const currentTheme = useTheme();

  return (
    <StyledButton
      className={className}
      type={type && type}
      onClick={onClick}
      disabled={disabled}
      theme={currentTheme}
      data-testid={dataTestId && dataTestId}
    >
      {icon}
      {!icon ? text : null}
    </StyledButton>
  );
}
