import { useTheme } from 'styled-components';

export default function UserPerfilLabelStyles() {
  const theme = useTheme();

  const formStyles = {
    fontSize: '14px',
    m: 0,
    color: theme.tableText,
    textAlign: 'start',
    border: 'none',
    padding: '0px',
    fieldset: {
      border: '0px',
    },
    '& .MuiSvgIcon-root': {
      color: theme.secondary,
      fontSize: '1.5rem',
    },
  };
  const selectStyles = {
    fontSize: '14px',
    color: theme.tableText,
    border: 'none',
    textAlign: 'start',
    padding: 0,
    fieldset: {
      border: '0px',
    },
    '& .MuiSvgIcon-root': {
      color: '#757575',
      fontSize: '1.5rem',
    },
    div: {
      padding: 0,
    },
    '& .MuiOutlinedInput-input': {
      padding: 0,
    },
  };
  const menuItemStyles = {
    fontSize: '14px',
    color: theme.tableText,
    textAlign: 'start',
    border: 'none',
    backgroundColor: theme.primary,
    cursor: 'default',
  };

  return { formStyles, selectStyles, menuItemStyles };
}
