import {
  ResponsiveContainer,
  ComposedChart,
  Line,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from 'recharts';
import { useTheme } from 'styled-components';
import { ILineBarChart } from '@/domain/models';
import { useBreakpoints } from '@/ui/helpers';
import ChartLegend from './legend';
import ChartTooltip from './tooltip';

interface ILineBarChartProps {
  lineBarChartData: ILineBarChart[];
  isFullScreen?: boolean;
}

const LineBarChart = ({
  lineBarChartData,
  isFullScreen,
}: ILineBarChartProps) => {
  const theme = useTheme();

  const { isXs } = useBreakpoints();

  const chartWidth = () => {
    if (isFullScreen) {
      if (lineBarChartData.length <= 14) {
        return '100%';
      }

      return (lineBarChartData.length / 7) * 47 + '%';
    }

    if (isXs) {
      if (lineBarChartData.length <= 7) {
        return '100%';
      }

      return lineBarChartData.length * 47;
    }

    if (lineBarChartData.length <= 7) {
      return '100%';
    }

    return (lineBarChartData.length / 7) * 800;
  };

  return (
    <ResponsiveContainer width={chartWidth()}>
      <ComposedChart
        width={400}
        height={300}
        data={lineBarChartData}
        margin={{
          top: 0,
          right: 0,
          left: 0,
          bottom: 0,
        }}
      >
        <CartesianGrid stroke={theme.chartLine} vertical={false} />
        <XAxis
          dataKey="name"
          tickLine={false}
          axisLine={false}
          style={{
            color: '#f5f5f5',
            fontSize: '10px',
            fill: theme.chartLegend,
          }}
          angle={50}
          tickMargin={25}
          allowDataOverflow={true}
          minTickGap={0}
          interval={0}
          padding="gap"
          dx={20}
        />
        <Tooltip content={<ChartTooltip />} />
        <Legend
          verticalAlign="bottom"
          align="left"
          height={36}
          fontStyle=""
          fontSize={5}
          margin={{ top: 90, left: 50 }}
          chartHeight={100}
          content={<ChartLegend />}
        />
        <Bar
          radius={[5, 5, 0, 0]}
          dataKey="barValue"
          barSize={70}
          fill={lineBarChartData && lineBarChartData[0].barColor}
          yAxisId="left"
          legendType="rect"
          name={lineBarChartData && lineBarChartData[0].barName}
        />
        <Line
          dot={true}
          strokeWidth={3}
          strokeLinecap="round"
          type="monotone"
          dataKey="lineValue"
          stroke={lineBarChartData && lineBarChartData[0].lineColor}
          yAxisId="right"
          legendType="rect"
          name={lineBarChartData && lineBarChartData[0].lineName}
        />
        <YAxis
          tickLine={false}
          yAxisId="left"
          axisLine={false}
          tickCount={5}
          style={{
            color: theme.chartLegend,
            fontSize: '12px',
            fill: theme.chartLegend,
          }}
        />
        <YAxis
          tickLine={false}
          yAxisId="right"
          orientation="right"
          stroke="#3B7AD9"
          axisLine={false}
          tickCount={5}
          style={{
            color: theme.chartLegend,
            fontSize: '12px',
            fill: theme.chartLegend,
          }}
        />
      </ComposedChart>
    </ResponsiveContainer>
  );
};

export default LineBarChart;
