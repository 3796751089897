export enum BarChartColorsName {
  LIGHT_BLUE = 'light-blue',
  YELLOW = 'yellow',
  GREEN = 'green',
  DARK_BLUE = 'dark-blue',
}

export enum BarChartShadowColorsName {
  LIGHT_BLUE = '#00D5FF',
  LIGHT_YELLOW = '#FFCD00',
  LIGHT_GREEN = '#36B37E',
}

export enum PieChartColorsName {
  SHADES_OF_YELLOW = 'shades-of-yellow',
  SHADES_OF_BLUE = 'shades-of-blue',
  BLUE_PINK = 'blue-pink',
  AVERAGE_TICKET_BY_PUBLIC = 'average_ticket_by_public',
}

export enum AverageTicketByPublic {
  UNKNOWN = 'Desconhecido',
  FEMININE = 'Feminino',
  MASCULINE = 'Masculino',
  OTHER = 'Outro',
}

export enum LineChartColorsName {
  YELLOW_BLUE = 'yellow_blue',
}

export enum LineColorsName {
  YELLOW = 'yellow',
  BLUE = 'blue',
}

export const AverageTicketByPublicMap = new Map<string, string[]>([
  [AverageTicketByPublic.UNKNOWN, ['#B9B9B9']],
  [AverageTicketByPublic.FEMININE, ['#6E47AF']],
  [AverageTicketByPublic.MASCULINE, ['#007DB7']],
  [AverageTicketByPublic.OTHER, ['#FFCD00']],
]);

export const BarChartColors = new Map<string, string[]>([
  [BarChartColorsName.LIGHT_BLUE, ['#00D5FF']],
  [BarChartColorsName.YELLOW, ['#FFCD00']],
  [BarChartColorsName.GREEN, ['#36B37E']],
  [BarChartColorsName.DARK_BLUE, ['#00407A']],
]);

export const BarChartLabelColors = new Map<string, string[]>([
  [BarChartColorsName.LIGHT_BLUE, ['#080382']],
  [BarChartColorsName.YELLOW, ['#453804']],
  [BarChartColorsName.GREEN, ['#023620']],
  [BarChartColorsName.DARK_BLUE, ['#00407A']],
]);

export const BarChartShadowColors = new Map<string, string>([
  [BarChartShadowColorsName.LIGHT_BLUE, '#00d5ff21'],
  [BarChartShadowColorsName.LIGHT_YELLOW, '#ffcc001f'],
  [BarChartShadowColorsName.LIGHT_GREEN, '#36b37f1c'],
]);

export const PieChartColors = new Map<string, string[]>([
  [
    PieChartColorsName.SHADES_OF_YELLOW,
    ['#7A5800', '#FFCD00', '#B78C00', '#DBAB00', '#936D00'],
  ],
  [
    PieChartColorsName.SHADES_OF_BLUE,
    ['#00407A', '#007DB7', '#00D5FF', '#659cb5'],
  ],
  [
    PieChartColorsName.AVERAGE_TICKET_BY_PUBLIC,
    ['#FFCD00', '#007DB7', '#6E47AF', '#B9B9B9'],
  ],
  [PieChartColorsName.BLUE_PINK, ['#00D5FF']],
]);

export const LineChartColors = new Map<string, string[]>([
  [LineChartColorsName.YELLOW_BLUE, ['#00D5FF', '#FFCD00', '#00407A']],
]);

export const LineColors = new Map<string, string>([
  [LineColorsName.YELLOW, '#936D00'],
  [LineColorsName.BLUE, '#00407b'],
]);

export const LabelTextColor = new Map<string, string>([
  ['#00D5FF', '#080382'],
  ['#FFCD00', '#574601'],
  ['#36B37E', '#013d23'],
  ['#00407A', '#fff'],
]);
