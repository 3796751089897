export default class DocumentHelper {
  static makeMaskCpf(document: string): string {
    const mask = document.replace(/\D/g, '');
    return mask.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
  }

  static checkCpf(cpfStr: string): boolean {
    const cpf = cpfStr.replace(/[^\d]/g, '');
    if (cpf.length !== 11) return false;
    if (
      [
        '00000000000',
        '11111111111',
        '22222222222',
        '33333333333',
        '44444444444',
        '55555555555',
        '66666666666',
        '77777777777',
        '88888888888',
        '99999999999',
      ].indexOf(cpf) !== -1
    )
      return false;
    let sum = 0;
    let rest = 0;

    for (let i = 1; i <= 9; i++)
      sum += parseInt(cpf.substring(i - 1, i)) * (11 - i);

    rest = (sum * 10) % 11;

    if (rest === 10 || rest === 11) rest = 0;

    if (rest !== parseInt(cpf.substring(9, 10))) return false;

    sum = 0;
    for (let i = 1; i <= 10; i++)
      sum += parseInt(cpf.substring(i - 1, i)) * (12 - i);

    rest = (sum * 10) % 11;

    if (rest === 10 || rest === 11) rest = 0;

    if (rest !== parseInt(cpf.substring(10, 11))) return false;

    return true;
  }

  static makeMaskCnpj(document: string): string {
    let mask = '';
    mask = document.replace(/\D/g, '');
    mask = mask.replace(
      /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
      '$1.$2.$3/$4-$5',
    );
    return mask;
  }

  private static getInvalidDocuments(length: number): string[] {
    const values = [
      '00000000000000',
      '11111111111111',
      '22222222222222',
      '33333333333333',
      '44444444444444',
      '55555555555555',
      '66666666666666',
      '77777777777777',
      '88888888888888',
      '99999999999999',
    ];

    return values.map(x => x.substring(0, length));
  }

  static isCnpjValid(cnpjString: string, allowEmpty = false): boolean {
    if (!cnpjString) return false;
    const cnpj = cnpjString.replace(/[^\d]+/g, '');
    if (cnpj === '') return false;

    if (cnpj.length !== 14) return false;

    if (
      DocumentHelper.getInvalidDocuments(14).find(x => x === cnpj) &&
      !allowEmpty
    )
      return false;

    let cnpjLength = cnpj.length - 2;
    let cnpjNumber = cnpj.substring(0, cnpjLength);
    const cnpjDigits = cnpj.substring(cnpjLength);
    let sum = 0;
    let pos = cnpjLength - 7;
    for (let i = cnpjLength; i >= 1; i--) {
      sum += Number(cnpjNumber.charAt(cnpjLength - i)) * pos--;
      if (pos < 2) pos = 9;
    }
    let result = sum % 11 < 2 ? 0 : 11 - (sum % 11);
    if (result !== Number(cnpjDigits.charAt(0))) return false;

    cnpjLength = cnpjLength + 1;
    cnpjNumber = cnpj.substring(0, cnpjLength);
    sum = 0;
    pos = cnpjLength - 7;
    for (let i = cnpjLength; i >= 1; i--) {
      sum += Number(cnpjNumber.charAt(cnpjLength - i)) * pos--;
      if (pos < 2) pos = 9;
    }
    result = sum % 11 < 2 ? 0 : 11 - (sum % 11);
    if (result !== Number(cnpjDigits.charAt(1))) return false;

    return true;
  }

  static isCpfValid(strCpf: string, allowEmpty = false): boolean {
    try {
      let sum = 0;
      let rest = 0;
      const cpf = strCpf.replace(/\D/g, '');

      if (cpf.length === 0 && allowEmpty) return true;
      if (
        cpf.length > 11 ||
        DocumentHelper.getInvalidDocuments(11).find(x => x === cpf)
      )
        return false;

      for (let i = 1; i <= 9; i += 1)
        sum += parseInt(cpf.substring(i - 1, i)) * (11 - i);
      rest = (sum * 10) % 11;

      if (rest === 10 || rest === 11) rest = 0;
      if (rest !== parseInt(cpf.substring(9, 10))) return false;

      sum = 0;
      for (let i = 1; i <= 10; i += 1)
        sum += parseInt(cpf.substring(i - 1, i)) * (12 - i);
      rest = (sum * 10) % 11;

      if (rest === 10 || rest === 11) rest = 0;
      if (rest !== parseInt(cpf.substring(10, 11))) return false;

      return true;
    } catch {
      return false;
    }
  }

  static formatCpf(cpf: string): string {
    cpf = cpf.replace(/[^\d]/g, '');

    return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
  }

  static isPhoneNumberValid(phoneNumber: string): any {
    phoneNumber = phoneNumber.replace(/\D/g, '');

    if (!(phoneNumber.length >= 10 && phoneNumber.length <= 11)) return false;

    if (phoneNumber.length == 11 && parseInt(phoneNumber.substring(2, 3)) != 9)
      return false;

    for (let n: any = 0; n < 10; n++) {
      if (
        phoneNumber == new Array(11).join(n) ||
        phoneNumber == new Array(12).join(n)
      )
        return false;
    }
    const DDDCodes = [
      11, 12, 13, 14, 15, 16, 17, 18, 19, 21, 22, 24, 27, 28, 31, 32, 33, 34,
      35, 37, 38, 41, 42, 43, 44, 45, 46, 47, 48, 49, 51, 53, 54, 55, 61, 62,
      64, 63, 65, 66, 67, 68, 69, 71, 73, 74, 75, 77, 79, 81, 82, 83, 84, 85,
      86, 87, 88, 89, 91, 92, 93, 94, 95, 96, 97, 98, 99,
    ];
    if (DDDCodes.indexOf(parseInt(phoneNumber.substring(0, 2))) == -1)
      return false;
    if (new Date().getFullYear() < 2017) return true;
    if (
      phoneNumber.length == 10 &&
      [2, 3, 4, 5, 7].indexOf(parseInt(phoneNumber.substring(2, 3))) == -1
    )
      return false;

    return true;
  }

  static isEmailValid(email: string): any {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      );
  }
}
