import { IImage } from '../../common';
import { IAutocomplete } from '../orders';

export default interface IBannerDTO {
  id?: number | null;
  correlationType: string;
  dateBegin: string;
  dateEnd: string;
  image: IImage;
  promotion?: IAutocomplete | null;
  licensed?: IAutocomplete | null;
  coolers?: IAutocomplete[] | null;
  categories?: IAutocomplete[] | null;
  products?: IAutocomplete[] | null;
  link?: string | null;
  title?: string | null;
  description?: string | null;
  status?: 'ACTIVE' | 'INACTIVE';
}

export enum Correlation {
  PROMOTION = 'PROMOTION',
  LINK = 'LINK',
  TEXT = 'TEXT',
}
