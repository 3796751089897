import styled from 'styled-components';

export const StyledButton = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 14px 16px;
  background: #fbcc0a;
  border-radius: 8px;
  border: none;
  font-size: 14px;
  cursor: pointer;
  line-height: 140%;
  box-shadow: ${props => props.theme.cardBoxShadow};
  white-space: nowrap;

  :disabled {
    background: #999796;
  }

  &.icon {
    background: unset;
    border: unset;
    padding: unset;
    box-shadow: unset;

    &:hover {
      background: unset;
    }
  }

  &:hover {
    background: #b78c00;
  }

  &:disabled {
    cursor: not-allowed;
  }

  &.large {
    flex: 1;
    padding: 20px 24px;
    border-radius: 10px;
    font-size: 16px;
  }

  &.medium {
    width: 236px;
    padding: 14px 18px;
    border-radius: 10px;
    font-size: 14px;
  }

  &.medium-2 {
    width: 298px;
    padding: 14px 18px;
    border-radius: 10px;
    font-size: 14px;
  }

  &.mt-2 {
    margin-top: 20px;
  }

  &.mt-3 {
    margin-top: 30px;
  }

  &.base {
    background-color: #bababa;
  }

  &.success {
    background: #388e3c;
    color: #fff;
    &:hover {
      background: #1b5e20;
    }
  }

  &.dark {
    background: #000;
    color: #fbcc0a;
  }

  &.danger {
    background: #d32f2f;
    color: #fff;
    &:hover {
      background: #9a0007;
    }
  }

  &.outlined {
    background-color: transparent;
    border: 1px solid #b78c00;
    color: ${props => props.theme.secondary};
    &:hover {
      background-color: transparent;
      color: ${props => props.theme.secondary};
    }

    &.white {
      border-color: #000;
      color: #000;
      &:disabled {
        border-color: #757575;
        color: #757575;
      }
    }
  }

  .round {
    display: flex;
    background: #fff199;
    border: none;
    border-radius: 100%;
  }

  &.inContDownButton {
    border-radius: 10px;
    border: 1px solid #757575;
    font-size: 16px;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: -0.4px;
    color: #757575;
    background: none;

    &.endContDownButton {
      color: #000;
      border-color: #000;
    }
  }
`;
