import styled from 'styled-components';

export const DeleteButton = styled.button`
  background: none;
  border: none;
`;

export const CustomInput = styled.input`
  background: none;
  padding: 5px;
  max-width: 40px;
  border: 1px solid ${props => props.theme.lines};
  color: ${props => props.theme.secondary};
  border-radius: 5px;
`;
