import StyledSvgIcon from './svg-icon.styles';

export default function RefundIcon(): JSX.Element {
  return (
    <StyledSvgIcon
      width="21"
      height="23"
      viewBox="0 0 21 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.14518 14.1667C7.14518 15.4553 8.14632 16.5 9.38129 16.5H11.4577C12.7809 16.5 13.8535 15.3807 13.8535 14C13.8535 12.6193 12.7809 11.5 11.4577 11.5H9.54102C8.21783 11.5 7.14518 10.3807 7.14518 9C7.14518 7.61929 8.21783 6.5 9.54102 6.5H11.6174C12.8524 6.5 13.8535 7.54467 13.8535 8.83333M10.4993 5V6.5M10.4993 16.5V18M20.0827 11.5C20.0827 17.0228 15.7921 21.5 10.4993 21.5C5.20662 21.5 0.916016 17.0228 0.916016 11.5C0.916016 5.97715 5.20662 1.5 10.4993 1.5C15.7921 1.5 20.0827 5.97715 20.0827 11.5Z"
        stroke="#FF5630"
        strokeWidth="1.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </StyledSvgIcon>
  );
}
