import { useTheme } from 'styled-components';
import StyledSvgIcon from './svg-icon.styles';
import { useSelector } from 'react-redux';
import { StoreState } from '@/ui/store/models';
import { TableIcons } from '@/domain/models';

export default function MonitoringMenuIcon(): JSX.Element {
  const currentTheme = useTheme();
  const activeMenu = useSelector(
    (currentStore: StoreState) => currentStore.navigation.activeMenu,
  );

  return (
    <StyledSvgIcon
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_8_5674)">
        <path
          d="M12.5 2.04816C11.7108 1.80028 10.871 1.66666 9.99996 1.66666C5.39759 1.66666 1.66663 5.39762 1.66663 9.99999C1.66663 14.6024 5.39759 18.3333 9.99996 18.3333C14.6023 18.3333 18.3333 14.6024 18.3333 9.99999C18.3333 8.57055 17.9734 7.22517 17.3393 6.04953M14.1666 4.79166H14.1708M8.75 18.2402L8.75009 16.4041C8.7501 16.3046 8.78568 16.2085 8.8504 16.133L10.9219 13.7162C11.0921 13.5175 11.0394 13.213 10.8123 13.0832L8.43207 11.7231C8.3674 11.6861 8.31382 11.6325 8.27691 11.5678L6.72534 8.84885C6.64459 8.70734 6.48877 8.62588 6.32649 8.64033L1.72011 9.05061M17.5 4.99999C17.5 6.84094 15.8333 8.33332 14.1666 9.99999C12.5 8.33332 10.8333 6.84094 10.8333 4.99999C10.8333 3.15904 12.3257 1.66666 14.1666 1.66666C16.0076 1.66666 17.5 3.15904 17.5 4.99999ZM14.375 4.79166C14.375 4.90672 14.2817 4.99999 14.1666 4.99999C14.0516 4.99999 13.9583 4.90672 13.9583 4.79166C13.9583 4.6766 14.0516 4.58332 14.1666 4.58332C14.2817 4.58332 14.375 4.6766 14.375 4.79166Z"
          stroke={
            activeMenu === TableIcons.MONITORING
              ? currentTheme.secondary
              : currentTheme.quaternary
          }
          strokeWidth="1.75"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_8_5674">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </StyledSvgIcon>
  );
}
