import { ITableServerSidePresenter } from '@/ui/components/table';
import {
  TablePaginationProps,
  TablePaginationPropsDefault,
  TableRowProps,
  TableRowPropsDefault,
} from '@/ui/interfaces/props/table';

export default interface ITableServerSideProps {
  presenter: ITableServerSidePresenter;
  rowProps: TableRowProps;
  paginationProps?: TablePaginationProps;
  reRender?: boolean;
  hasRowSelection?: boolean;
  selectedRowsCallback?: (selectedRows: any[]) => void;
}

export const TableServerSidePropsDefault = (props: ITableServerSideProps) => {
  return {
    presenter: props.presenter,
    paginationProps: TablePaginationPropsDefault(props.paginationProps),
    rowProps: TableRowPropsDefault(props.rowProps, props.hasRowSelection),
  } as ITableServerSideProps;
};
