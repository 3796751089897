import {
  HttpMethod,
  HttpRequest,
  HttpResponse,
  LocationHttpEndpoint,
} from '@/domain/models';
import { IHttpServicePort, ILocationRepositoryPort } from '@/infra/ports';

export default class LocationRepository implements ILocationRepositoryPort {
  constructor(private readonly httpServicePort: IHttpServicePort) {}

  async getStates<T>(): Promise<HttpResponse<T>> {
    const request = new HttpRequest({
      url: LocationHttpEndpoint.LOCATION_STATES,
      method: HttpMethod.get,
      auth: true,
    });

    return this.httpServicePort.request<T>(request);
  }

  async getCities<T>(state: string): Promise<HttpResponse<T>> {
    const request = new HttpRequest({
      url: `${LocationHttpEndpoint.CITIES}/${state}`,
      method: HttpMethod.get,
      auth: true,
    });

    return this.httpServicePort.request<T>(request);
  }
}
