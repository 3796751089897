export enum types {
  ABOVE_AMOUNT = 'ABOVE_AMOUNT',
  ABOVE_VALUE = 'ABOVE_VALUE',
  FIRST_BUY = 'FIRST_BUY',
  PERCENTAGE = 'PERCENTAGE',
  VALUE = 'VALUE',
  CASHBACK = 'CASHBACK',
}

export const PromotionTypeLabelMap = new Map<string, string>([
  [types.ABOVE_AMOUNT, 'Leve e pague'],
  [types.ABOVE_VALUE, 'Acima de (valor)'],
  [types.FIRST_BUY, 'Primeira compra'],
  [types.PERCENTAGE, '% de desconto'],
  [types.VALUE, 'R$ de desconto'],
  [types.CASHBACK, '% de cashback'],
]);
