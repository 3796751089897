export default class NumberHelper {
  static random(min = 1, max = Number.MAX_SAFE_INTEGER): number {
    return Math.floor(Math.random() * (max - min)) + min;
  }

  static makeKey(): number {
    return this.random(1, 10000);
  }

  static toDecimal(value: string): number {
    if (value.isEmpty()) return 0;
    if (value.isNumber()) return Number.parseFloat(value);

    const valueReplace = value
      .replace('R$', '')
      .replace('.', '')
      .replace(',', '.') as string;

    return Number.parseFloat(valueReplace);
  }

  static toMoney(amount: number): string {
    try {
      const decimalCount = 2;
      const decimal = ',';
      const thousands = '.';

      const negativeSign = amount < 0 ? '-' : '';
      const value = Math.abs(Number(amount) || 0);
      const valueText = value.toFixed(decimalCount);

      const i = Number.parseInt(valueText, 10).toString();
      const j = i.length > 3 ? i.length % 3 : 0;

      return (
        negativeSign +
        (j ? i.substr(0, j) + thousands : '') +
        i.substr(j).replace(/(\d{3})(?=\d)/g, `$1${thousands}`) +
        (decimalCount
          ? decimal +
            Math.abs(value - Number(i))
              .toFixed(decimalCount)
              .slice(2)
          : '')
      );
    } catch (e) {
      return '0';
    }
  }

  static sort = (a: number, b: number, { isDesc = false }) => {
    return isDesc ? b - a : a - b;
  };

  static isEven = (num: number) => {
    return num % 2 == 0 ? true : false;
  };

  static makeMoney = (num: number) => {
    return num.toLocaleString('pt-br', {
      style: 'currency',
      currency: 'BRL',
      maximumFractionDigits: 2,
    });
  };
}
