import { Modal } from '@/ui/components';

export default function CantDeleteModal({
  isOpen,
  close,
  productName,
  quantity,
}: {
  isOpen: boolean;
  close: () => void;
  productName: string;
  quantity: number;
}) {
  return (
    <Modal
      title="Erro ao excluir item"
      isOpen={isOpen}
      onClose={close}
      defaultText="Voltar"
    >
      <div style={{ marginBottom: 16 }}>
        Este item ainda está em estoque na sua geladeira!
      </div>
      <div style={{ marginBottom: 16 }}>
        <strong>Produto: </strong>
        {productName}
      </div>
      <div style={{ marginBottom: 16 }}>
        <strong>Estoque atual: </strong>
        {quantity}
      </div>
      <div style={{ marginBottom: 16 }}>
        Remova o estoque da geladeira antes de deletar o item do sistema.
      </div>
    </Modal>
  );
}
