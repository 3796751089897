import {
  HttpResponse,
  IAutocomplete,
  IRefrigerator,
  IRefrigeratorEditInputs,
  IRefrigeratorEditProduct,
  IRefrigeratorUpdate,
  RefrigeratorType,
  State,
} from '@/domain/models';
import { DateHelper } from '@/domain/helpers';
import { RefrigeratorProductUpdate } from '@/presentation/models';
import { IRefrigeratorListPresenter } from '@/ui/pages';
import { IRefrigeratorRepositoryPort } from '@/infra';

export default class RefrigeratorListPresenter
  implements IRefrigeratorListPresenter
{
  constructor(
    private readonly refrigeratorRepository: IRefrigeratorRepositoryPort,
  ) {}

  async getRefrigeratorListAsync(params: string): Promise<any> {
    const response = await this.refrigeratorRepository.getCoolerListAsync<any>(
      params,
    );

    const refrigeratorListData: IRefrigerator[] = response.data.content;
    const totalItems: number = response.data.totalElements;
    const totalPages: number = response.data.totalPages;

    return { refrigeratorListData, totalPages, totalItems };
  }

  async getRefrigeratorAsync(
    refrigeratorId: string,
  ): Promise<IRefrigeratorEditInputs | boolean> {
    return this.refrigeratorRepository
      .getCoolerAsync<IRefrigerator>(refrigeratorId)
      .then(response => {
        if (!response.success) {
          return false;
        }
        return this.formatRefrigeratorToEdit(response.data!);
      })
      .catch(() => {
        return false;
      });
  }

  async getRefrigeratorProductEditAsync(coolerId: string): Promise<any[]> {
    const response = await this.refrigeratorRepository.getProductsByCooler<
      any[]
    >(coolerId);

    const refrigeratorProductEditData: any[] = response.data!;
    return refrigeratorProductEditData;
  }

  async getStockDetailAsync(): Promise<any> {
    const response = await this.refrigeratorRepository.getByTypeAsync<
      IRefrigeratorEditProduct[]
    >(RefrigeratorType.refrigeratorProductEdit);

    const refrigeratorProductEditData: IRefrigeratorEditProduct[] =
      response.data!;
    return refrigeratorProductEditData;
  }

  async getCoolerFilterAutocompleteAsync(
    params?: string,
  ): Promise<IAutocomplete[]> {
    const response = await this.refrigeratorRepository.getCoolerAutoComplete<
      IAutocomplete[]
    >(params);

    if (!response.success) {
      return [];
    }

    const coolerAutocompleteData = response.data!;

    return coolerAutocompleteData;
  }

  async getAllProductsAsync() {
    const response = await this.refrigeratorRepository.getAllProducts();

    const allProducts = response.data!;

    return allProducts;
  }

  async getProductsByRefrigeratorIdAsync(coolerId: string) {
    const response =
      await this.refrigeratorRepository.getProductsByRefrigeratorId(coolerId);

    const productsByRefrigeratorId = response.data!.map((refrigerator: any) => {
      return {
        id: refrigerator.productId,
        label: refrigerator.productName,
        suggestedValue: refrigerator.suggestedValue,
      };
    });

    return productsByRefrigeratorId;
  }

  async updateRefrigerator(
    formData: IRefrigeratorEditInputs,
    refrigeratorId: string,
  ): Promise<HttpResponse<any>> {
    const updateRefrigerator = this.formatRefrigeratorEditToSave(formData);

    return this.refrigeratorRepository
      .updateRefrigerator(updateRefrigerator, refrigeratorId)
      .then(response => response)
      .catch(error => {
        return Promise.reject(
          error.message ||
            'Erro inesperado ao atualizar cooler, por favor contate o suporte.',
        );
      });
  }

  async createRefrigerator(
    formData: IRefrigeratorEditInputs,
  ): Promise<HttpResponse<any>> {
    const createRefrigerator = this.formatRefrigeratorEditToSave(formData);

    return this.refrigeratorRepository
      .createRefrigerator(createRefrigerator)
      .then(response => response)
      .catch(error => {
        return Promise.reject(
          error.message ||
            'Erro inesperado ao criar cooler, por favor contate o suporte.',
        );
      });
  }

  async addRefrigeratorProduct(
    formData: any,
    refrigeratorId: any,
  ): Promise<HttpResponse<any>> {
    const updateRefrigeratorProducts = new RefrigeratorProductUpdate(
      +formData.value,
      +formData.expectedStock,
      +formData.minimalPercentage,
    );

    const response = await this.refrigeratorRepository
      .addRefrigeratorProduct(
        updateRefrigeratorProducts,
        refrigeratorId,
        formData.product.id,
      )
      .then(response => response.data)
      .catch(error => {
        Promise.reject(error?.message);
      });

    if (response.message) {
      return Promise.reject(response.message);
    }

    return response;
  }

  async updateRefrigeratorProduct(
    productId: any,
    refrigeratorId: any,
    data: any,
  ): Promise<HttpResponse<any>> {
    return await this.refrigeratorRepository
      .updateRefrigeratorProduct(data, refrigeratorId, productId)
      .then(response => {
        return response.data;
      })
      .catch(error => {
        Promise.reject(error);
      });
  }

  async deleteRefrigeratorProduct(
    productId: any,
    refrigeratorId: string,
  ): Promise<HttpResponse<any>> {
    return await this.refrigeratorRepository
      .deleteRefrigeratorProduct(+refrigeratorId, +productId)
      .then(response => {
        return response.data;
      })
      .catch(error => {
        Promise.reject(error);
      });
  }

  async updateProductValue(
    productId: string,
    body: { value: number; coolerId: string[] },
  ): Promise<HttpResponse<any>> {
    const response = await this.refrigeratorRepository.updateProductValue(
      productId,
      body,
    );

    if (response.success) {
      return response.data;
    }

    return Promise.reject(response.error?.message);
  }

  async getCoolerMetrics(
    params: string,
    successNotification: (message: string) => void,
    errorNotification: (message: string) => void,
  ): Promise<void> {
    const response =
      await this.refrigeratorRepository.getCoolersMetrics<string>(params);

    if (response.success) {
      return successNotification(response.data);
    }

    return errorNotification(
      response?.data?.message || 'Ocorreu um erro inesperado',
    );
  }

  private formatRefrigeratorToEdit(
    refrigerator: IRefrigerator,
  ): IRefrigeratorEditInputs {
    return {
      ...refrigerator,
      acceptPromotion: this.acceptPromotionsModeOptions.find(
        mode => mode.id === refrigerator.acceptPromotion,
      )!,
      accessType: this.accessTypeOptions.find(
        type => type.id === refrigerator.accessType,
      ),
      coolerMode: this.refrigeratorModeOptions.find(
        mode => mode.id === refrigerator.coolerMode,
      ),
      coolerType: this.refrigeratorTypeOptions.find(
        type => type.id === refrigerator.coolerType,
      )!,
      establishmentType: this.unityTypeOptions.find(
        type => type.id === refrigerator.establishmentType,
      ),
      manualRevisionType: this.manualRevisionTypeOptions.find(
        type => type.id === refrigerator.manualRevisionType,
      ),
      refrigeratorVoltage: this.refrigeratorVoltageOptions.find(
        type => type.id === refrigerator.refrigeratorVoltage,
      ),
      simOperator: this.simOperatorOptions.find(
        type => type.id === refrigerator.simOperator,
      ),
      situation: this.situationOptions.find(
        type => type.id === refrigerator.situation,
      )!,
      state: State.getStates().find(type => type.id === refrigerator.state)!,
      licensedId: {
        id: refrigerator.licensedId.toString(),
        label: refrigerator.licensedId.toString(),
      },
      mix: {
        id: refrigerator.mix,
        label: refrigerator.mix,
      },
      beginDate: DateHelper.formatDateFromRequest(refrigerator.beginDate),
      endDate: DateHelper.formatDateFromRequest(refrigerator.endDate),
      sendDate: refrigerator.sendDate
        ? DateHelper.formatDateFromRequest(refrigerator.sendDate)
        : null,
      status: this.statusOptions.find(
        status => status.id === refrigerator.status,
      )!,
      processing: this.refrigeratorProcessingOptions.find(
        processing => processing.id === refrigerator?.processing?.toString(),
      )!,
      hasCrop: this.refrigeratorProcessingOptions.find(
        hasCrop => hasCrop.id === refrigerator?.hasCrop?.toString(),
      )!,
    };
  }

  private formatRefrigeratorEditToSave(
    data: IRefrigeratorEditInputs,
  ): IRefrigeratorUpdate {
    return {
      ...data,
      acceptPromotion: data.acceptPromotion.id,
      beginDate: DateHelper.formatDateHours2(data.beginDate as any),
      coolerType: data.coolerType.id,
      endDate: DateHelper.formatDateHours2(data.endDate as any),
      licensedId: Number(data.licensedId.id),
      mix: data?.mix?.id,
      situation: data.situation.id,
      state: data.state.id,
      sendDate: data.sendDate
        ? DateHelper.formatDate(data.sendDate as any)
        : null,
      accessType: data.accessType ? data.accessType.id : null,
      coolerMode: data.coolerMode ? data.coolerMode.id : null,
      establishmentType: data.establishmentType
        ? data.establishmentType.id
        : null,
      manualRevisionType: data.manualRevisionType
        ? data.manualRevisionType.id
        : null,
      refrigeratorVoltage: data.refrigeratorVoltage
        ? data.refrigeratorVoltage.id
        : null,
      simOperator: data.simOperator ? data.simOperator.id : null,
      latitude: data.latitude ? data.latitude : '0',
      longitude: data.longitude ? data.longitude : '0',
      status: data.status ? data.status.id : this.statusOptions[1].id,
      processing: data.processing.id === 'true' ? true : false,
      hasCrop: data.hasCrop.id === 'true' ? true : false,
    };
  }

  public refrigeratorSituationOptions: IAutocomplete[] = [
    { label: 'Instalado', id: 'INSTALLED' },
    { label: 'Em produção', id: 'INPRODUCTION' },
    { label: 'Entregue', id: 'DELIVERED' },
    { label: 'Manutenção no PDV', id: 'MAINTENANCEPDV' },
    { label: 'Manutenção na Take', id: 'MAINTENANCETAKE' },
    { label: 'Em estoque', id: 'INSTOCK' },
    { label: 'Fora de operação', id: 'OUTOFOPERATION' },
    { label: 'Em transito', id: 'INTRANSIT' },
  ].sort((a, b) => a.label.localeCompare(b.label));

  public refrigeratorTypeOptions: IAutocomplete[] = [
    { label: 'Alcoólica', id: 'ALCOHOLIC' },
    { label: 'Não Alcoólica', id: 'NOT_ALCOHOLIC' },
  ];

  public unityTypeOptions: IAutocomplete[] = [
    { label: 'Cond. Horizontal', id: 'HORIZONTAL_CONDOMINIUM' },
    { label: 'Cond. Vertical', id: 'VERTICAL_CONDOMINIUM' },
    { label: 'Universidade', id: 'UNIVERSITIES' },
    { label: 'Academia', id: 'ACADEMY' },
    { label: 'Salão de Beleza', id: 'BEAUTY_SALON' },
    { label: 'Centro Esportivo', id: 'SPORT_CENTER' },
    { label: 'Clube', id: 'CLUB' },
    { label: 'Coworking', id: 'COWORKING' },
    { label: 'Estacionamento', id: 'PARKING' },
    { label: 'Empresa', id: 'COMPANY' },
    { label: 'Bar Autônomo', id: 'AUTONOMOUS_BAR' },
    { label: 'Hotel', id: 'HOTEL' },
    { label: 'Escola', id: 'SCHOOL' },
  ];

  public refrigeratorModeOptions: IAutocomplete[] = [
    { label: 'Padrão', id: 'STANDARD' },
    { label: 'G4', id: 'GENERIC_G4' },
    { label: 'G8', id: 'GENERIC_G8' },
  ];

  public acceptPromotionsModeOptions: IAutocomplete[] = [
    { label: 'Aceita promoções', id: 'ALL_PROMOTIONS' },
    { label: 'Não aceita', id: 'NOT_PARTICIPATE_PROMOTIONS' },
  ];

  public situationOptions: IAutocomplete[] = [
    { label: 'Em estoque', id: 'INSTOCK' },
    { label: 'Em produção', id: 'INPRODUCTION' },
    { label: 'Em trânsito', id: 'INTRANSIT' },
    { label: 'Entregue', id: 'DELIVERED' },
    { label: 'Fora de operação', id: 'OUTOFOPERATION' },
    { label: 'Instalado', id: 'INSTALLED' },
    { label: 'Manutenção', id: 'MAINTENANCE' },
  ];

  public statusOptions: IAutocomplete[] = [
    { label: 'Ativo', id: 'ACTIVE' },
    { label: 'Inativo', id: 'INACTIVE' },
  ];

  public simOperatorOptions: IAutocomplete[] = [
    { label: 'Claro', id: 'CLARO' },
    { label: 'Tim', id: 'TIM' },
    { label: 'Vivo', id: 'VIVO' },
  ];

  public accessTypeOptions: IAutocomplete[] = [
    { label: 'Pública', id: 'PUBLIC' },
    { label: 'Privada', id: 'PRIVATE' },
  ];

  public manualRevisionTypeOptions: IAutocomplete[] = [
    { label: 'Fotos', id: 'EIGHT_PICTURES' },
    { label: 'Vídeos', id: 'VIDEOS' },
  ];

  public refrigeratorVoltageOptions: IAutocomplete[] = [
    { label: '127V', id: 'TENSION_127_VOLTS' },
    { label: '220V', id: 'TENSION_220_VOLTS' },
  ];

  public refrigeratorProcessingOptions: IAutocomplete[] = [
    { label: 'Sim', id: 'true' },
    { label: 'Não', id: 'false' },
  ];

  getStockPercentageColor = (productPercentage: number): string => {
    if (productPercentage <= 49) {
      return '#ff5630';
    } else if (productPercentage >= 50 && productPercentage <= 69) {
      return '#ffab00';
    } else if (productPercentage >= 69) {
      return '#36b37e';
    } else {
      return '#ffff';
    }
  };

  getStatus(): any {
    enum coolerStatus {
      ACTIVE = 'ACTIVE',
      INACTIVE = 'INACTIVE',
    }

    enum coolerSituation {
      INSTALLED = 'INSTALLED',
      INPRODUCTION = 'INPRODUCTION',
      DELIVERED = 'DELIVERED',
      MAINTENANCEPDV = 'MAINTENANCEPDV',
      MAINTENANCETAKE = 'MAINTENANCETAKE',
      INSTOCK = 'INSTOCK',
      OUTOFOPERATION = 'OUTOFOPERATION',
      INTRANSIT = 'INTRANSIT',
    }

    const coolerStatusEnum = new Map<string, string>([
      [coolerStatus.ACTIVE, 'Ativo'],
      [coolerStatus.INACTIVE, 'Inativo'],
    ]);

    const coolerSituationEnum = new Map<string, string>([
      [coolerSituation.INSTALLED, 'Instalado'],
      [coolerSituation.INPRODUCTION, 'Em produção'],
      [coolerSituation.DELIVERED, 'Entregue'],
      [coolerSituation.MAINTENANCEPDV, 'Manutenção no PDV'],
      [coolerSituation.MAINTENANCETAKE, 'Manutenção na Take'],
      [coolerSituation.INSTOCK, 'Em estoque'],
      [coolerSituation.OUTOFOPERATION, 'Fora de operação'],
      [coolerSituation.INTRANSIT, 'Em transito'],
    ]);

    return { coolerStatusEnum, coolerSituationEnum };
  }
}
