export default class FinancialHttpEndpoint {
  static API_VERSION = 'api/v1';

  static FINANCIAL_DETAILS_BASE = `${FinancialHttpEndpoint.API_VERSION}/financials/details`;
  static FINANCIAL_REPORT = `${FinancialHttpEndpoint.API_VERSION}/financials/consolidated-reports`;
  static FINANCIAL_BANK_DISPUTE_DETAILS = `${FinancialHttpEndpoint.FINANCIAL_DETAILS_BASE}/bank-dispute-details`;
  static FINANCIAL_CONDOMINIUM_TRANSFER_DETAILS = `${FinancialHttpEndpoint.FINANCIAL_DETAILS_BASE}/condominium-transfer-details`;
  static FINANCIAL_CREDIT_CARD_DETAILS = `${FinancialHttpEndpoint.FINANCIAL_DETAILS_BASE}/credit-card-details`;
  static FINANCIAL_MONTHLY_PAYMENTS = `${FinancialHttpEndpoint.FINANCIAL_DETAILS_BASE}/monthly-payments`;
  static FINANCIAL_NEGOTIATION_DETAILS = `${FinancialHttpEndpoint.FINANCIAL_DETAILS_BASE}/negotiation-details`;
  static FINANCIAL_NEGOTIATION_BY_ID = `${FinancialHttpEndpoint.FINANCIAL_DETAILS_BASE}/negotiation`;
  static FINANCIAL_NEGOTIATION = `${FinancialHttpEndpoint.FINANCIAL_DETAILS_BASE}/negotiation`;

  static FINANCIAL_PIX_DETAILS = `${FinancialHttpEndpoint.FINANCIAL_DETAILS_BASE}/pix-details`;
  static FINANCIAL_PROMOTIONS_DETAILS = `${FinancialHttpEndpoint.FINANCIAL_DETAILS_BASE}/promotions-details`;
  static FINANCIAL_REFUND_DETAILS = `${FinancialHttpEndpoint.FINANCIAL_DETAILS_BASE}/refund-details`;
  static FINANCIAL_WALLET_DETAILS = `${FinancialHttpEndpoint.FINANCIAL_DETAILS_BASE}/wallet-details`;
  static FINANCIAL_REPORT_EXPORT = `${FinancialHttpEndpoint.API_VERSION}/financials/consolidated-reports/xls`;
  static FINANCIAL_REPORT_EXPORT_IN_PROGRESS = `${this.API_VERSION}/report/FINANCIAL_CONSOLIDATED_XLS/in-progress`;
  static FINANCIAL_CONDOMINIUM_TRANSFER_REPORT = `${this.API_VERSION}/financials/condominium-transfer/xls`;
  static LICENSED_DEFAULT_MONTHLY_PAYMENT = `${FinancialHttpEndpoint.FINANCIAL_DETAILS_BASE}/default-monthly-payment`;
}
