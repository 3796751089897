import { Column } from '@/ui/components/layout';
import styled from 'styled-components';

const ModalWrapper = styled(Column)``;

const ModalFooter = styled.footer`
  width: 100%;
  display: flex;
  z-index: 2;
  gap: 20px;
  padding: 20px 40px;
  justify-content: right;
  position: absolute;
  bottom: 0;
  left: 0;
`;

const FieldsFormWrapper = styled(Column)`
  padding-right: 20px;
  flex: 1;
  max-height: 500px;
`;

const FieldsContainer = styled.div`
  width: 100%;
  max-height: 500px;
  overflow-y: auto;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  padding-top: 20px;

  @media (max-width: 1440px) {
    width: 200px;
  }
`;

const InputWrapper = styled.div`
  padding: 5px;
  display: flex;
  flex: 1;
  min-width: 40%;
  gap: 5px;

  img {
    width: 62px;
    height: 62px;
  }

  @media (max-width: 1440px) {
    flex: auto;
  }
`;

const CarouselWrapper = styled(Column)`
  text-align: center;
  justify-content: center;
  flex: 1;
  padding-bottom: 30px;

  @media (max-width: 1440px) {
    flex-basis: 60%;
  }
`;

const CarouselToolbar = styled.div`
  display: flex;
`;

const ModalTitle = styled.a`
  font-weight: bold;
  text-decoration: underscore;
  color: #111;
`;

export default {
  CarouselWrapper,
  CarouselToolbar,
  ModalWrapper,
  ModalFooter,
  FieldsFormWrapper,
  FieldsContainer,
  InputWrapper,
  ModalTitle,
};
