import { Row } from '@/ui/components/layout';
import styled from 'styled-components';

export const OrderDetailTitle = styled(Row)`
  position: relative;

  h2 {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    color: ${props => props.theme.secondary};
    margin-bottom: 2px;
  }

  p {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    color: #757575;
    margin-top: 2px;
  }
`;

export const NoProductsFound = styled.p`
  min-width: 300px;
  text-align: center;
`;
