import { ProductTableHeadDetail } from '../../styles/refrigerator-stock-product-detail-table-head';

export default function StockProductDetailTableHead(): JSX.Element {
  return (
    <ProductTableHeadDetail>
      <th className="first-item">Geladeira</th>
      <th>Ult. Reposição</th>
      <th>Consumo</th>
      <th>Estoque atual</th>
      <th>Estoque mínimo</th>
      <th>Estoque esperado</th>
      <th className="align-center">% Estoque</th>
      <th>Valor unitário</th>
      <th>{''}</th>
    </ProductTableHeadDetail>
  );
}
