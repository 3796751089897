import { IFinishOrderReview } from '@/domain/models';
import {
  OrderHttpEndpoint,
  HttpMethod,
  HttpRequest,
  HttpResponse,
} from '@/domain/models/services';
import { IHttpServicePort, IOrderRepositoryPort } from '@/infra/ports';

export class OrderRepositoryAdapter implements IOrderRepositoryPort {
  constructor(private readonly httpServicePort: IHttpServicePort) {}

  async getOrders<T>(params: string): Promise<HttpResponse<T>> {
    const request = new HttpRequest({
      url: OrderHttpEndpoint.ORDER_BASE_API + params,
      method: HttpMethod.get,
      auth: true,
    });

    return await this.httpServicePort.request<T>(request);
  }

  async getOrderDetail<T>(orderId: string): Promise<HttpResponse<T>> {
    const request = new HttpRequest({
      url: OrderHttpEndpoint.ORDER_DETAIL + orderId + '/products',
      method: HttpMethod.get,
      auth: true,
    });

    return await this.httpServicePort.request<T>(request);
  }

  async exportSimpleXls<T>(params: string): Promise<HttpResponse<T>> {
    const request = new HttpRequest({
      url: OrderHttpEndpoint.ORDER_EXPORT_SIMPLE_XLS + params,
      method: HttpMethod.get,
      auth: true,
    });

    return await this.httpServicePort.request<T>(request);
  }

  async exportDetailedXls<T>(params: string): Promise<HttpResponse<T>> {
    const request = new HttpRequest({
      url: OrderHttpEndpoint.ORDER_EXPORT_DETAILED_XLS + params,
      method: HttpMethod.get,
      auth: true,
    });

    return await this.httpServicePort.request<T>(request);
  }

  async exportSimplePdf<T>(params: string): Promise<HttpResponse<T>> {
    const request = new HttpRequest({
      url: OrderHttpEndpoint.ORDER_EXPORT_SIMPLE_PDF + params,
      method: HttpMethod.get,
      auth: true,
    });

    return await this.httpServicePort.request<T>(request);
  }

  async exportNfceXls<T>(): Promise<HttpResponse<T>> {
    const request = new HttpRequest({
      url: OrderHttpEndpoint.ORDER_NFCE_XLS,
      method: HttpMethod.get,
      auth: true,
    });

    return await this.httpServicePort.request<T>(request);
  }

  async visualizeNfc<T>(orderId: string): Promise<HttpResponse<T>> {
    const request = new HttpRequest({
      url: OrderHttpEndpoint.ORDER_VISUALIZE_NFC + orderId,
      method: HttpMethod.get,
      auth: true,
    });

    return await this.httpServicePort.request<T>(request);
  }

  async visualizeInvoice<T>(orderId: string): Promise<HttpResponse<T>> {
    const request = new HttpRequest({
      url: OrderHttpEndpoint.ORDER_VISUALIZE_INVOICE + orderId,
      method: HttpMethod.get,
      auth: true,
    });

    return await this.httpServicePort.request<T>(request);
  }

  async getOrderMedias<T>(orderId: string): Promise<HttpResponse<T>> {
    const request = new HttpRequest({
      url: OrderHttpEndpoint.ORDER_BASE + `/${orderId}/medias `,
      method: HttpMethod.get,
      auth: true,
    });

    return await this.httpServicePort.request<T>(request);
  }

  async getOrderReview<T>(orderId: string): Promise<HttpResponse<T>> {
    const request = new HttpRequest({
      url: OrderHttpEndpoint.ORDER_BASE + `/${orderId}/review`,
      method: HttpMethod.get,
      auth: true,
    });

    return this.httpServicePort.request<T>(request);
  }

  async finishOrderReview<T>(
    orderId: string,
    data: IFinishOrderReview,
  ): Promise<HttpResponse<T>> {
    const request = new HttpRequest({
      url: OrderHttpEndpoint.ORDER_BASE + `/${orderId}/review`,
      body: data,
      method: HttpMethod.post,
      auth: true,
    });

    return this.httpServicePort.request<T>(request);
  }

  async getDeadbeatReport<T>(params: string): Promise<HttpResponse<T>> {
    const request = new HttpRequest({
      url: OrderHttpEndpoint.ORDER_DEADBEAT_REPORT + '?' + params,
      method: HttpMethod.post,
      auth: true,
    });

    return this.httpServicePort.request<T>(request);
  }

  async postRefundCalculate<T>(orderIds: number[]): Promise<HttpResponse<T>> {
    const request = new HttpRequest({
      url: OrderHttpEndpoint.ORDER_REFUND_CALCULATE,
      body: orderIds,
      method: HttpMethod.post,
      auth: true,
    });

    return this.httpServicePort.request<T>(request);
  }

  async postRefundApply<T>(orderIds: number[]): Promise<HttpResponse<T>> {
    const request = new HttpRequest({
      url: OrderHttpEndpoint.ORDER_REFUND_APPLY,
      body: orderIds,
      method: HttpMethod.post,
      auth: true,
    });

    return this.httpServicePort.request<T>(request);
  }

  async postReprocessOrder<T>(orderId: number): Promise<HttpResponse<T>> {
    const request = new HttpRequest({
      url: OrderHttpEndpoint.ORDER_REPROCESS + orderId,
      method: HttpMethod.post,
      auth: true,
    });

    return this.httpServicePort.request<T>(request);
  }
}
