import { DownloadIcon } from '@/ui/components';
import { DownloadNfceButton, StyledNfceTable } from './styles';
import { useContext } from 'react';
import { NotificationContext } from '@/main';

interface INfceProps {
  nfceData: {
    mes: string;
    xmls: string;
  }[];
}

export default function NfceTable({ nfceData }: INfceProps) {
  const { successNotification } = useContext(NotificationContext);

  return (
    <StyledNfceTable>
      <thead>
        <tr>
          <th className="align-start">Mês</th>
          <th className="align-end">Download</th>
        </tr>
      </thead>
      <tbody>
        {nfceData.map((nfceDownload, index) => (
          <tr key={nfceDownload.mes + index}>
            <td className="align-start">{nfceDownload.mes}</td>
            <td className="align-end">
              <DownloadNfceButton
                onClick={(e: any) => {
                  e.preventDefault();
                  window.location.href = nfceDownload.xmls;
                  successNotification('Download feito com sucesso!');
                }}
              >
                <DownloadIcon />
              </DownloadNfceButton>
            </td>
          </tr>
        ))}
      </tbody>
    </StyledNfceTable>
  );
}
