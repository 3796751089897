import { Correlation } from '@/domain/models';
import { IBannerManagementForm } from '@/presentation';
import { DateTimePickerField } from '@/ui/components';
import { Grid } from '@/ui/components/layout';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Control, UseFormWatch } from 'react-hook-form';

export default function ValidityPeriod({
  control,
  watch,
}: {
  control: Control<IBannerManagementForm>;
  watch: UseFormWatch<IBannerManagementForm>;
}) {
  const [dateBegin, dateEnd, correlationType] = watch([
    'dateBegin',
    'dateEnd',
    'correlationType',
  ]);

  return (
    <>
      <h5>Período de vigência</h5>
      <Grid columns={3} gap={6}>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br">
          <DateTimePickerField
            control={control}
            name="dateBegin"
            label="Data de início"
            rules={{
              required: 'Data de início é obrigatória',
              deps: ['dateEnd'],
              validate: {
                isLessThan: (value: string): boolean | string =>
                  new Date(dateEnd) > new Date(value) ||
                  'Data de início deve ser menor que a data de término',
              },
            }}
            readOnly={correlationType?.id === Correlation.PROMOTION}
          />
          <DateTimePickerField
            control={control}
            name="dateEnd"
            label="Data de término"
            rules={{
              required: 'Data de término é obrigatória',
              deps: ['dateBegin'],
              validate: {
                isGreaterThan: (value: string): boolean | string =>
                  new Date(dateBegin) < new Date(value) ||
                  'Data de término deve ser maior que a data de início',
              },
            }}
            readOnly={correlationType?.id === Correlation.PROMOTION}
          />
        </LocalizationProvider>
      </Grid>
    </>
  );
}
