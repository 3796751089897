import styled from 'styled-components';
import { MenuItem } from '@mui/material';

export const ExportOption = styled(MenuItem).attrs(props => ({
  sx: {
    fontSize: 14,
    padding: '15px 32px',
    '&:hover': {
      backgroundColor: props.theme.exportBox.optionBgHover,
      color: props.theme.exportBox.fontColorHover,
    },
  },
}))``;
