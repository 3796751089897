import { DateHelper } from '@/domain/helpers';

export default function getDefaultRequiredValues() {
  const lastDayOfTheMonth = DateHelper.getLastDayOfTheMonth();
  const firstDayOfTheMonth = DateHelper.getFirstDayOfTheMonth();

  const defaultRequiredValues: any = {
    date: {
      financial: {
        date: {
          id: lastDayOfTheMonth,
          name: 'date',
          label: lastDayOfTheMonth,
        },
      },
    },
    initialDate: {
      home_overview: {
        initialDate: {
          id: firstDayOfTheMonth,
          name: 'initialDate',
          label: firstDayOfTheMonth,
        },
      },
      home_rating: {
        initialDate: {
          id: firstDayOfTheMonth,
          name: 'initialDate',
          label: firstDayOfTheMonth,
        },
      },
    },
    endDate: {
      home_overview: {
        endDate: {
          id: lastDayOfTheMonth,
          name: 'endDate',
          label: lastDayOfTheMonth,
        },
      },
      home_rating: {
        endDate: {
          id: lastDayOfTheMonth,
          name: 'endDate',
          label: lastDayOfTheMonth,
        },
      },
    },
    licensedId: {
      home_overview: {
        licensedId: {
          id: window.localStorage.getItem('licensedId'),
          name: 'licensedId',
          label: window.localStorage.getItem('licensedName'),
        },
      },
      home_rating: {
        licensedId: {
          id: window.localStorage.getItem('licensedId'),
          name: 'licensedId',
          label: window.localStorage.getItem('licensedName'),
        },
      },
    },
  };

  return defaultRequiredValues;
}
