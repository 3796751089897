import { ToggleThemeAppState } from '@/ui/store/models';
import { Types, ThemeToggleAppAction } from './types';

export default function toggleTheme(
  state = new ToggleThemeAppState({
    isActive: true,
  }),
  action: ThemeToggleAppAction,
): ToggleThemeAppState {
  switch (action.type) {
    case Types.TOGGLE_THEME:
      return new ToggleThemeAppState({ isActive: !state.darkTheme });
    default:
      return state;
  }
}
