import { useSelector } from 'react-redux';
import { Backdrop, Box, Modal as BaseModal, Fade } from '@mui/material';
import { Close } from '@mui/icons-material';
import { StoreState } from '@/ui/store/models';
import { CloseButton, Title, ModalContent } from './styles';
import { Button } from '../../input';
import { ReactNode } from 'react';
import { Row } from '../../layout';
import { useBreakpoints } from '@/ui/helpers';

interface IModalProps {
  isOpen: boolean;
  onClose: () => void;
  title: string | ReactNode;
  canClose?: boolean;
  children?: ReactNode;
  content?: string | ReactNode;
  okButton?: ReactNode;
  cancelButton?: ReactNode;
  defaultText?: string;
  maxHeight?: string;
}

export default function Modal({
  isOpen,
  onClose,
  title,
  canClose = true,
  children,
  content,
  okButton,
  cancelButton,
  defaultText,
  maxHeight,
}: IModalProps) {
  const isDarkTheme = useSelector((store: StoreState) => {
    return store.toggleTheme.darkTheme;
  });

  const { isXs } = useBreakpoints();

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    width: 'max-content',
    maxHeight: maxHeight ? maxHeight : 'unset',
    maxWidth: '80vw',
    minWidth: isXs ? '300px' : '360px',
    transform: 'translate(-50%, -50%)',
    bgcolor: isDarkTheme ? '#333333' : '#F7F7F7',
    color: isDarkTheme ? '#f9f9f9' : '#120D0E',
    fill: isDarkTheme ? '#f9f9f9' : '#120D0E',
    boxShadow: 24,
    p: 5,
    borderRadius: 2,
  };

  const handleOnClose = (_: any, reason: 'backdropClick' | 'escapeKeyDown') => {
    if (reason === 'backdropClick') {
      return;
    }
    return onClose;
  };

  return (
    <BaseModal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={isOpen}
      onClose={handleOnClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
      disableEscapeKeyDown={true}
    >
      <Fade in={isOpen}>
        <Box data-testid="modal-box" sx={style}>
          <Row
            as="header"
            align="center"
            justify="space-between"
            width="100%"
            gap={4}
            margin={{
              bottom: 8,
            }}
          >
            <Title data-testid="modal-title">{title}</Title>
            {canClose && (
              <CloseButton data-testid="modal-close-button" onClick={onClose}>
                <Close />
              </CloseButton>
            )}
          </Row>
          <ModalContent isDarkTheme={isDarkTheme} gap={4} margin={{ top: 4 }}>
            {children || content}
          </ModalContent>
          <Row
            as="footer"
            align="center"
            justify="flex-end"
            margin={{
              top: 0,
            }}
            gap={2}
          >
            {okButton} {cancelButton}{' '}
            {!okButton && !cancelButton && (
              <Button
                onClick={onClose}
                text={defaultText ? defaultText : 'Fechar'}
                type="button"
              />
            )}
          </Row>
        </Box>
      </Fade>
    </BaseModal>
  );
}
