import {
  ContextType,
  Correlation,
  IAutocomplete,
  IBannerDTO,
  IImage,
  TableIcons,
} from '@/domain/models';
import { useContext, useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { BannerDTO, useCreateBanner, useUpdateBanner } from '@/presentation';
import { useParams } from 'react-router-dom';
import { DateHelper } from '@/domain/helpers';
import { store } from '@/ui/store/config';
import { NavigationAppState } from '@/ui/store/models';
import { useQuery } from 'react-query';
import { NotificationContext } from '@/main/notification-provider';
import { makeBannerRepository } from '@/main/factories/repositories/banner-repository-factory';
import selectNavigationAction from '@/ui/store/modules/app/navigation/actions';

export interface IBannerManagementForm {
  id?: number | null;
  dateBegin: any;
  dateEnd: any;
  correlationType: IAutocomplete | null;
  status: 'ACTIVE' | 'INACTIVE';
  image: File | null | any;
  promotion?: IAutocomplete | null;
  licensed?: IAutocomplete | null;
  coolers: IAutocomplete[];
  categories: IAutocomplete[];
  products: IAutocomplete[];
  title?: string | null;
  description?: string | null;
  link?: string | null;
}

export default function useBannerManagementPresentation() {
  const { errorNotification } = useContext(NotificationContext);

  const [previewSource, setPreviewSource] = useState<{
    image: string;
    updated: boolean;
  }>({
    image: '',
    updated: false,
  });

  const { bannerId } = useParams();

  const {
    control,
    setValue,
    watch,
    handleSubmit,
    reset,
    resetField,
    formState: { isValid, isDirty, isSubmitting },
  } = useForm<IBannerManagementForm>({
    mode: 'all',
    defaultValues: {
      dateBegin: null,
      dateEnd: null,
      coolers: [],
      categories: [],
      products: [],
      licensed: null,
      status: 'ACTIVE',
    },
  });

  const correlationsTypeOptions = [
    {
      id: 'PROMOTION',
      label: 'Promoção',
    },
    {
      id: 'LINK',
      label: 'Link',
    },
    {
      id: 'TEXT',
      label: 'Texto',
    },
  ];

  const [image] = watch(['image']);

  const { mutateAsync: createBannerMutation, isLoading: isCreatingBanner } =
    useCreateBanner();
  const { mutateAsync: updateBannerAsync, isLoading: isUpdatingBanner } =
    useUpdateBanner();

  const onSubmit: SubmitHandler<IBannerManagementForm> = data => {
    const image: IImage = {
      imageBase64: previewSource.updated
        ? previewSource.image.split(',')[1]
        : previewSource.image,
      imageName: data.image?.name,
      type: 'jpg',
    };

    const correlationType = data?.correlationType?.id as string;
    const dateBegin = DateHelper.formatDateHours(data.dateBegin);
    const dateEnd = DateHelper.formatDateHours(data.dateEnd);
    const promotion =
      data.promotion && correlationType === Correlation.PROMOTION
        ? {
            id: data.promotion?.id,
            label: data.promotion?.label,
          }
        : null;
    const link = correlationType === Correlation.LINK ? data.link : null;
    const description =
      correlationType !== Correlation.LINK ? data.description : null;
    const title = correlationType !== Correlation.LINK ? data.title : null;

    const bannerDTO: IBannerDTO = {
      ...data,
      correlationType,
      dateBegin,
      dateEnd,
      image,
      promotion,
      link,
      title,
      description,
    };

    bannerId ? updateBannerAsync(bannerDTO) : createBannerMutation(bannerDTO);
  };

  const previewFile = (file: File) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      const imageSrc = reader.result as string;
      const img = new Image();
      img.src = imageSrc;

      img.onload = () => {
        const width = img.width;
        const height = img.height;

        if (width !== 350 || height !== 130) {
          errorNotification(
            'Imagem não atende as dimensões de 350x130px. Por favor, selecione uma imagem com as dimensões corretas.',
          );
          setValue('image', null, {
            shouldValidate: true,
          });
          setPreviewSource({
            image: '',
            updated: true,
          });
          return;
        }
      };

      setPreviewSource({
        image: reader.result as string,
        updated: true,
      });
    };
  };

  const handleRemoveImage = () => {
    setValue('image', null, {
      shouldValidate: true,
    });
    setPreviewSource({
      image: '',
      updated: true,
    });
  };

  useEffect(() => {
    if (image instanceof File) {
      previewFile(image);
    }
  }, [image]);

  const bannerRepository = makeBannerRepository();

  async function validateImage(imageBase64: string): Promise<boolean> {
    return new Promise(resolve => {
      const image = new Image();
      image.src = imageBase64;

      image.onerror = () => {
        errorNotification(
          'Imagem está faltando, por favor selecione uma imagem válida.',
        );
        resolve(true);
      };

      image.onload = () => {
        const width = image.width;
        const height = image.height;

        if (width !== 350 || height !== 130) {
          errorNotification(
            'Imagem não atende as dimensões de 350x130px. Por favor, selecione uma imagem com as dimensões corretas.',
          );
          resolve(true);
        } else {
          resolve(false);
        }
      };
    });
  }

  useQuery(
    ['bannerById', bannerId],
    () => bannerRepository.getBannerById<BannerDTO>(Number(bannerId)),
    {
      enabled: Boolean(bannerId),
      onError: (error: string) => {
        errorNotification(error);
      },
      onSuccess: async data => {
        const { data: bannerData } = data;

        const correlationType: IAutocomplete = correlationsTypeOptions.find(
          (correlation: IAutocomplete) =>
            correlation.id === bannerData?.correlationType,
        )!;
        const dateBegin = DateHelper.formatDateFromRequest(
          bannerData?.dateBegin,
        );
        const dateEnd = DateHelper.formatDateFromRequest(bannerData?.dateEnd);

        let hasImageError = false;

        if (bannerData?.image?.imageBase64) {
          hasImageError = await validateImage(bannerData.image.imageBase64);
        }

        if (!hasImageError) {
          setPreviewSource({
            image: bannerData?.image?.imageBase64,
            updated: false,
          });
        }

        reset({
          ...bannerData,
          correlationType,
          dateBegin,
          dateEnd,
          image: hasImageError ? null : bannerData?.image,
        });

        const name = bannerData?.title ? bannerData?.title : bannerData?.id;

        store.dispatch(
          selectNavigationAction(
            new NavigationAppState(
              ContextType.banner_edit,
              ContextType.banner_list,
              TableIcons.BANNER,
              name,
            ),
          ),
        );
      },
    },
  );

  const isSubmitDisabled =
    !isValid ||
    !isDirty ||
    isSubmitting ||
    isCreatingBanner ||
    isUpdatingBanner;

  return {
    control,
    previewSource: previewSource.image,
    handleRemoveImage,
    handleSubmit,
    onSubmit,
    watch,
    resetField,
    setValue,
    isSubmitDisabled,
    correlationsTypeOptions,
  };
}
