import { useTheme } from 'styled-components';
import StyledSvgIcon from './svg-icon.styles';

export default function LightModeIcon() {
  const currentTheme = useTheme();

  return (
    <StyledSvgIcon
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_582_182845)">
        <path
          d="M18.3332 13.2035C17.2387 13.6985 16.0237 13.974 14.7444 13.974C9.92925 13.974 6.0258 10.0706 6.0258 5.25541C6.0258 3.97609 6.30134 2.76112 6.79632 1.66663C3.77131 3.03465 1.6665 6.07888 1.6665 9.6147C1.6665 14.4298 5.56995 18.3333 10.3851 18.3333C13.9209 18.3333 16.9651 16.2285 18.3332 13.2035Z"
          stroke={currentTheme.secondary}
          strokeWidth="1.75"
          strokeLinecap="round"
          strokeLinejoin="round"
          fill="none"
        />
      </g>
      <defs>
        <clipPath id="clip0_582_182845">
          <rect width="20" height="20" fill="none" />
        </clipPath>
      </defs>
    </StyledSvgIcon>
  );
}
