import { HttpError } from './index';

export default class HttpResponse<T = any> {
  success: boolean;
  data?: T | undefined | any;
  error: HttpError | undefined;
  statusCode: number | undefined;

  constructor(
    success = false,
    data: T | undefined = undefined,
    error: HttpError | undefined = undefined,
    statusCode = 0,
  ) {
    this.success = success;
    this.data = data;
    this.error = error;
    this.statusCode = statusCode;
  }
}
