enum OrderType {
  REPOSITION = 'REPOSITION',
  CONSUME = 'CONSUME',
  REPOSITION_SETUP = 'REPOSITION_SETUP',
  PROMOTER = 'PROMOTER',
}

const ContextOrderType = new Map<string, string>([
  [OrderType.REPOSITION, 'Reposição'],
  [OrderType.CONSUME, 'Consumo'],
  [OrderType.REPOSITION_SETUP, 'Reposição de SETUP'],
  [OrderType.PROMOTER, 'Promoter'],
]);

export default ContextOrderType;
