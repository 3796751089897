import { IFilterOption } from '@/ui/context/context-filter/type';

export interface IOpeningAttemptsFilterParams {
  beginDate: IFilterOption | null;
  endDate: IFilterOption | null;
  coolerId: IFilterOption | null;
  licensedId: IFilterOption | null;
  userName: IFilterOption | null;
  userCPF: IFilterOption | null;
  coolerLabel?: IFilterOption | null;
  licensedLabel?: IFilterOption | null;
  page?: IFilterOption | null;
  limit?: IFilterOption | null;
  sort?: IFilterOption | null;
  column?: IFilterOption | null;
}

export default class OpeningAttemptsFilter
  implements IOpeningAttemptsFilterParams
{
  beginDate: IFilterOption;
  endDate: IFilterOption;
  coolerId: IFilterOption;
  licensedId: IFilterOption;
  userName: IFilterOption;
  userCPF: IFilterOption;

  constructor(
    beginDate: any,
    endDate: any,
    coolerId: string,
    licensedId: string,
    userName: string,
    userCPF: string,
    licensedLabel: string,
    coolerLabel: string,
  ) {
    this.beginDate = {
      id: beginDate,
      name: 'beginDate',
      label: beginDate,
    };
    this.endDate = {
      id: endDate,
      name: 'endDate',
      label: endDate,
    };
    this.coolerId = {
      id: coolerId,
      name: 'coolerId',
      label: coolerLabel,
    };
    this.licensedId = {
      id: licensedId,
      name: 'licensed',
      label: licensedLabel,
    };
    this.userName = {
      id: userName,
      name: 'userName',
      label: userName,
    };
    this.userCPF = {
      id: userCPF,
      name: 'userCPF',
      label: userCPF,
    };
  }
}
