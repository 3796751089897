import { StringHelper } from '@/domain/helpers';
import { IUserResponse } from '@/domain/models';
import {
  UserHttpEndpoint,
  HttpMethod,
  HttpRequest,
  HttpResponse,
} from '@/domain/models/services';
import { IHttpServicePort, IUserRepositoryPort } from '@/infra/ports';

export class UserRepositoryAdapter implements IUserRepositoryPort {
  constructor(private readonly httpServicePort: IHttpServicePort) {}

  async getByTypeAsync<T>(type: string): Promise<HttpResponse<T>> {
    const request = new HttpRequest({
      url: StringHelper.format(UserHttpEndpoint.USER_GET_BY_TYPE, type),
      method: HttpMethod.get,
    });

    return await this.httpServicePort.request<T>(request);
  }

  async getUserById<T>(userId: string): Promise<HttpResponse<T>> {
    const request = new HttpRequest({
      url: UserHttpEndpoint.USER_BASE + '/' + userId,
      method: HttpMethod.get,
      auth: true,
    });

    return await this.httpServicePort.request<T>(request);
  }

  async updateUserById<T>(
    userId: string,
    userData: IUserResponse | any,
  ): Promise<HttpResponse<T>> {
    const request = new HttpRequest({
      url: UserHttpEndpoint.USER_BASE + '/' + userId,
      method: HttpMethod.put,
      auth: true,
      body: userData,
    });

    return await this.httpServicePort.request<T>(request);
  }

  async createUser<T>(userData: IUserResponse): Promise<HttpResponse<T>> {
    const request = new HttpRequest({
      url: UserHttpEndpoint.USER_MANUAL_REGISTER,
      method: HttpMethod.post,
      auth: true,
      body: userData,
    });

    return await this.httpServicePort.request<T>(request);
  }

  async toggleActivateUser<T>(
    userId: string,
    isActive: boolean,
    reason?: any,
  ): Promise<HttpResponse<T>> {
    const request = new HttpRequest({
      url: UserHttpEndpoint.USER_BASE + '/' + userId + '/actives/' + isActive,
      method: HttpMethod.put,
      auth: true,
      body: reason ? reason.reason : {},
    });

    return await this.httpServicePort.request<T>(request);
  }

  async resetPasswordAsync<T>(userId: any): Promise<HttpResponse<T>> {
    const request = new HttpRequest({
      url: UserHttpEndpoint.USER_BASE + `/${userId}/sac-reset-password`,
      method: HttpMethod.patch,
      auth: true,
    });
    return await this.httpServicePort.request<T>(request);
  }

  async getUserPasswordRecoveryOptionsAsync<T>(
    userCpf: string,
  ): Promise<HttpResponse<T>> {
    const request = new HttpRequest({
      url:
        UserHttpEndpoint.USER_PASSWORD_RECOVERY +
        `/${userCpf}/recovery-options`,
      method: HttpMethod.get,
      auth: false,
    });

    return await this.httpServicePort.request<T>(request);
  }

  async sendUserChangePasswordLinkAsync<T>(
    cpf: string,
    recoveryType: string,
  ): Promise<HttpResponse<T>> {
    const request = new HttpRequest({
      url: UserHttpEndpoint.USER_CHANGE_PASSWORD_LINK,
      method: HttpMethod.post,
      auth: false,
      body: {
        cpf,
        recoveryType,
      },
    });

    return await this.httpServicePort.request<T>(request);
  }

  async userChangePasswordAsync<T>(
    userToken: string,
    password: string,
  ): Promise<HttpResponse<T>> {
    const request = new HttpRequest({
      url: UserHttpEndpoint.USER_CHANGE_PASSWORD_RECOVERY,
      method: HttpMethod.post,
      auth: false,
      body: {
        token: userToken,
        password,
      },
    });

    return await this.httpServicePort.request<T>(request);
  }

  async userValidateToken<T>(token: string): Promise<HttpResponse<T>> {
    const request = new HttpRequest({
      url: UserHttpEndpoint.USER_CHANGE_PASSWORD_VALIDATE_TOKEN,
      method: HttpMethod.post,
      auth: false,
      body: token,
    });

    return await this.httpServicePort.request<T>(request);
  }
}
