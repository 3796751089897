import React from 'react';
import { NavLink } from './styles';

function isActiveClass(
  { isActive }: { isActive: boolean },
  props: any,
): string {
  const isActivated = isActive ? props.activeClassName : '';

  return isActivated + props.className;
}

const RefNavLink = React.forwardRef<any, any>((props, ref) => (
  <NavLink
    ref={ref}
    to={props.to}
    target={props.target}
    onClick={props.onClick}
    className={navProps => isActiveClass(navProps, props)}
    data-testid={props.dataTestid}
  >
    {props.children}
  </NavLink>
));

RefNavLink.displayName = 'RefNavLink';

export default RefNavLink;
