import moment from 'moment';
import { StringHelper } from '@/domain/helpers';
import ContextGender from '@/domain/models/app/context/crm/gender';
import {
  IPieChartData,
  IBarChartData,
  IPieChartProps,
  IRatingClientData,
  ILineBarChart,
  ITopCustomers,
  IAverageTicketByPublic,
  IUserCard,
} from '@/domain/models/pages/home';
import IDashboardRatingRepositoryPort from '@/infra/ports/repositories/dashboard-rating-repository';
import { LineBarChart } from '@/presentation/models';
import { makePieChartProps } from '@/main/factories';
import { IHomeRatingPresenter } from '@/ui/pages/home/';
import { PieChartColors, PieChartColorsName } from '@/ui/styles/';
import { RatingClientData } from './rating';
import { IExportReport } from '@/domain/models';

export default class HomeRatingPresenter implements IHomeRatingPresenter {
  constructor(
    private readonly dashboardRatingRepository: IDashboardRatingRepositoryPort,
  ) {}

  getParams(filterParams: any) {
    const date = new Date();
    const lastDay = moment(
      new Date(date.getFullYear(), date.getMonth() + 1, 0),
    ).format('DD/MM/YYYY');
    const firstDay = moment(
      new Date(date.getFullYear(), date.getMonth(), 1),
    ).format('DD/MM/YYYY');
    return {
      initialDate: filterParams.initialDate
        ? filterParams.initialDate
        : firstDay,
      endDate: filterParams.endDate ? filterParams.endDate : lastDay,
      coolerId: filterParams.coolerId ? filterParams.coolerId : '',
      LicensedId: filterParams.LicensedId ? filterParams.LicensedId : '',
    };
  }

  async getRatingAsync(params: string): Promise<IRatingClientData> {
    const response =
      await this.dashboardRatingRepository.getAverageAndTotalRatingsAsync<IRatingClientData>(
        params,
      );

    const responseData: IRatingClientData = response.data!;

    const ratingClientData: IRatingClientData = new RatingClientData(
      responseData.averageRating,
      responseData.ratingQuantity,
    );

    return ratingClientData;
  }

  async getRatingWhatCanImproveAsync(params: string): Promise<IPieChartProps> {
    const response =
      await this.dashboardRatingRepository.getImprovementsPointsSuggestionsAsync<
        IPieChartData[]
      >(params);

    const whatCanImproveData: IPieChartData[] = response.data!;

    const whatCanImproveChartData: any = whatCanImproveData.map(item => {
      return {
        id: item.key,
        name: item.key,
        value: item.value,
        percentage: item.percentage,
      };
    });

    const removeNothingToImprove = whatCanImproveChartData.filter(
      (item: any) => item.id != 'Nenhuma.',
    );

    const whatCanImproveProps = makePieChartProps(
      1,
      PieChartColors.get(PieChartColorsName.SHADES_OF_YELLOW)!,
      removeNothingToImprove,
    );

    return whatCanImproveProps;
  }

  async getRatingAverageRatingByReviewQuantityAsync(
    params: string,
  ): Promise<ILineBarChart[]> {
    const response =
      await this.dashboardRatingRepository.getRatingsAverageQuantityByDateAsync<
        IBarChartData[]
      >(params);

    const ratingByReviewQuantityData: IBarChartData[] = response.data!;

    const rechartRatingByReviewQuantity: ILineBarChart[] =
      ratingByReviewQuantityData.map(ratingRefrigerator => {
        return new LineBarChart(
          StringHelper.substring(ratingRefrigerator.key),
          ratingRefrigerator.value,
          ratingRefrigerator.lineData![0].value,
          'Quantidade de avaliações',
          'Nota média',
          '#00D5FF',
          '#936D00',
        );
      });

    return rechartRatingByReviewQuantity;
  }

  async getRatingAverageRatingByRefrigeratorAsync(
    params: string,
  ): Promise<ILineBarChart[]> {
    const response =
      await this.dashboardRatingRepository.getRatingsAverageQuantityByCoolerAsync<
        IBarChartData[]
      >(params);

    const ratingByRefrigeratorData: IBarChartData[] = response.data!;

    const rechartRatingByRefrigerator: ILineBarChart[] =
      ratingByRefrigeratorData.map(ratingRefrigerator => {
        return new LineBarChart(
          StringHelper.substring(ratingRefrigerator.key),
          ratingRefrigerator.value,
          ratingRefrigerator.lineData![0].value,
          'Quantidade de avaliações',
          'Nota média',
          '#36B37E',
          '#00407A',
        );
      });

    return rechartRatingByRefrigerator;
  }

  async getRatingTopUsersAsync(params: string): Promise<ITopCustomers[]> {
    const response =
      await this.dashboardRatingRepository.getRatingTopUsersAsync<
        ITopCustomers[]
      >(params);

    const responseData: ITopCustomers[] = response.data!;

    return responseData;
  }

  async getRatingAverageTicketByPublicAsync(params: string): Promise<any> {
    const response =
      await this.dashboardRatingRepository.getRatingAverageTickerByPublicAsync<
        IAverageTicketByPublic[]
      >(params);

    const responseData: IAverageTicketByPublic[] = response.data!;

    const averageTicketByUser = {
      id: 1,
      color: PieChartColors.get(PieChartColorsName.AVERAGE_TICKET_BY_PUBLIC)!,
      data: responseData.map(item => {
        return {
          id: ContextGender.get(item.key),
          value: item.value,
          percentage: item.percentage,
        };
      }),
    };

    return averageTicketByUser;
  }

  async getUserStatisticsAsync(params: string): Promise<IUserCard[]> {
    const response =
      await this.dashboardRatingRepository.getUserStatisticsAsync<IUserCard[]>(
        params,
      );

    const responseData: IUserCard[] = response.data!;

    return responseData;
  }

  async getExportDetailedRatingAsync(params: string): Promise<IExportReport> {
    const response =
      await this.dashboardRatingRepository.getExportDetailedRatingAsync<IExportReport>(
        params,
      );

    if (response.data.status === 400) {
      Promise.reject(
        response.data.message || 'Erro inesperado ao exportar relatório',
      );
    }

    if (!response.success) {
      Promise.reject(
        response.data.message || 'Erro inesperado ao exportar relatório',
      );
    }

    return response.data! as IExportReport;
  }

  async hasExportDetailedRatingInProgressAsync(): Promise<boolean> {
    const response =
      await this.dashboardRatingRepository.hasExportDetailedRatingInProgress<number>();

    if (response.data > 0) {
      return true;
    }

    return false;
  }
}
