import styled from 'styled-components';

export const LoginSection = styled.main`
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  width: 100%;
  min-height: 100vh;
`;

export const LoginButton = styled.button`
  padding: 16px 24px;
  width: 300px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: #000000;
  background: #fbcc0a;
  border-radius: 16px;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;

  &.margin-top {
    margin-top: 24px;
  }

  &:hover {
    background: #b78c00;
  }

  &.large {
    padding: 22px 24px;
    color: #000;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 14px;
    line-height: 140%;
  }
`;

export const TakeText = styled.div`
  height: 85%;
  color: #ffff;
  font-style: normal;
  font-weight: 700;
  font-size: 44px;
  line-height: 120%;
  display: flex;
  align-items: flex-end;
  padding-bottom: 57px;
`;

export const PasswordIconContainer = styled.button`
  background: none;
  border: none;
  cursor: pointer;
`;

export const ImgContainer = styled.div`
  background-image: url('./login-bg.png');
  background-position: left;
  background-color: #000;
  min-width: 480px;
  position: relative;
  padding: 66px 52px;
`;

export const ForgotPasswordText = styled.p`
  color: var(--primary-light-3, #b78c00);
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 16px;
  cursor: pointer;
  text-align: center;
  font-weight: 400;
`;

export const LoadingContainer = styled.div`
  position: absolute;
  bottom: -70px;
`;
