import { ContextType, SubMenu } from '@/domain/models/app';

export default class MenuModel {
  id: number;
  isEnable: boolean;
  takeAndGoAccess: boolean;
  contextType: ContextType;
  submenus: SubMenu[];
  onlyTake?: boolean;
  target?: string;
  isPartner: boolean;

  constructor({
    id = 0,
    isEnable = true,
    takeAndGoAccess = false,
    contextType = ContextType.none,
    submenus = [] as SubMenu[],
    onlyTake = false,
    target = '_self',
    isPartner = false,
  }) {
    this.id = id;
    this.isEnable = isEnable;
    this.takeAndGoAccess = takeAndGoAccess;
    this.contextType = contextType;
    this.submenus = submenus;
    this.onlyTake = onlyTake;
    this.target = target;
    this.isPartner = isPartner;
  }

  static makeSystemMenus(): MenuModel[] {
    return [
      new MenuModel({
        id: 0,
        contextType: ContextType.home,
        takeAndGoAccess: true,
        submenus: [
          new SubMenu({
            id: 0,
            contextType: ContextType.home_overview,
          }),
          new SubMenu({
            id: 1,
            contextType: ContextType.home_rating,
          }),
        ],
      }),
      new MenuModel({
        id: 1,
        contextType: ContextType.mural_take,
        takeAndGoAccess: true,
        target: '_blank',
      }),
      new MenuModel({
        id: 2,
        contextType: ContextType.refrigerator,
        takeAndGoAccess: true,
        submenus: [
          new SubMenu({
            id: 0,
            contextType: ContextType.refrigerator_stock,
          }),
          new SubMenu({
            id: 1,
            contextType: ContextType.refrigerator_list,
          }),
          new SubMenu({
            id: 2,
            contextType: ContextType.refrigerator_status_list,
            isPartner: true,
          }),
        ],
      }),
      new MenuModel({
        id: 7,
        contextType: ContextType.products,
        takeAndGoAccess: true,
        onlyTake: true,
        isPartner: true,
        submenus: [
          new SubMenu({
            id: 0,
            contextType: ContextType.product_list,
            isPartner: true,
          }),
          new SubMenu({
            id: 1,
            contextType: ContextType.product_mix,
            onlyTake: true,
          }),
          new SubMenu({
            id: 2,
            contextType: ContextType.product_category,
            onlyTake: true,
          }),
        ],
      }),
      new MenuModel({
        id: 3,
        contextType: ContextType.financial,
        takeAndGoAccess: true,
      }),
      new MenuModel({
        id: 4,
        contextType: ContextType.order,
        takeAndGoAccess: true,
      }),
      new MenuModel({
        id: 5,
        takeAndGoAccess: true,
        contextType: ContextType.promotion_list,
      }),
      new MenuModel({
        id: 6,
        contextType: ContextType.banner_list,
        takeAndGoAccess: true,
        onlyTake: true,
      }),
      new MenuModel({
        id: 7,
        contextType: ContextType.user,
        takeAndGoAccess: true,
      }),
      new MenuModel({
        id: 8,
        contextType: ContextType.monitoring,
        takeAndGoAccess: true,
        onlyTake: true,
        submenus: [
          new SubMenu({
            id: 0,
            contextType: ContextType.monitoring_opening_attempts,
          }),
          new SubMenu({
            id: 1,
            contextType: ContextType.uptime_downtime_apis,
            target: '_blank',
          }),
        ],
      }),
      new MenuModel({
        id: 9,
        contextType: ContextType.licensed_list,
        takeAndGoAccess: true,
        onlyTake: true,
      }),
    ];
  }
}
