import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { ContextType, TableIcons } from '@/domain/models';
import { useBreakpoints } from '@/ui/helpers';
import { NavigationAppState, StoreState } from '@/ui/store/models';
import { store } from '@/ui/store/config';
import selectNavigationAction from '@/ui/store/modules/app/navigation/actions';
import {
  SubHeader,
  Card,
  OverviewCardContainer,
  ChartValueText,
  CardTitle,
  CardInfo,
} from '@/ui/components';
import {
  LineBarChart,
  ReBarChart,
  RelineChart,
} from '@/ui/components/recharts';
import RePieChart from '@/ui/components/recharts/pie-chart';

import Default from '../default';
import { Container } from '../home/styles';
import { ContextMock } from './context-mock';
import { Column } from '@/ui/components/layout';

export default function DashboardMock(): JSX.Element {
  const { isXs } = useBreakpoints();

  const activeMenu = useSelector(
    (store: StoreState) => store.navigation.activePage,
  );

  const isDarkTheme = useSelector((store: StoreState) => {
    return store.toggleTheme.darkTheme;
  });

  useEffect(() => {
    store.dispatch(
      selectNavigationAction(
        new NavigationAppState(
          ContextType.home_overview,
          ContextType.home,
          TableIcons.HOME,
        ),
      ),
    );
  }, []);

  return (
    <Default contextType={ContextType.home_overview}>
      {ContextMock.get('florencia') && (
        <Container>
          {activeMenu === 'home_overview' && <SubHeader />}
          {Boolean(isXs) && (
            <Column gap="8px">
              {ContextMock.get('florencia').topCards.map(
                (data: any, index: any) => (
                  <CardInfo
                    key={index + data.title + data.value}
                    position={(index + 1).toString()}
                    title={data.title}
                    value={data.value}
                    text1={data.text1}
                    text2={data.text2}
                    value2={data.value2}
                    value2Color={data.value2Color}
                    isDarkTheme={isDarkTheme}
                  />
                ),
              )}
            </Column>
          )}
          <OverviewCardContainer>
            {!isXs &&
              ContextMock.get('florencia').topCards.map(
                (data: any, index: any) => (
                  <CardInfo
                    key={index + data.title + data.value}
                    position={(index + 1).toString()}
                    title={data.title}
                    value={data.value}
                    text1={data.text1}
                    text2={data.text2}
                    value2={data.value2}
                    value2Color={data.value2Color}
                    isDarkTheme={isDarkTheme}
                  />
                ),
              )}
            <Card className="card-position-2-1">
              <>
                <CardTitle className="mb">
                  Produtos consumidos vs faturamento bruto
                </CardTitle>
                <LineBarChart
                  lineBarChartData={
                    ContextMock.get('florencia').productsVsGrossAmount
                  }
                />
              </>
            </Card>
            <Card
              className={` ${
                isXs
                  ? 'card-position-2-mobile'
                  : 'card-position-2-2 justify-start'
              }`}
            >
              <>
                <CardTitle>Detalhe faturamento bruto</CardTitle>
                <ChartValueText>
                  {ContextMock.get('florencia').totalGross}
                </ChartValueText>
                <RePieChart
                  pieChartProps={ContextMock.get('florencia').grossPieChart}
                />
              </>
            </Card>
            <Card className={` card-position-3-1`}>
              <>
                <CardTitle className="mb">
                  Total de usuários ativos e novos
                </CardTitle>
                <RelineChart
                  LineChartProps={
                    ContextMock.get('florencia').totalUsersVsNewUsers
                  }
                />
              </>
            </Card>
            <Card className={` card-position-3-2`}>
              <>
                <CardTitle>Detalhe faturamento líquido</CardTitle>
                <ChartValueText>
                  {ContextMock.get('florencia').totalNet}
                </ChartValueText>
                <RePieChart pieChartProps={ContextMock.get('florencia').net} />
              </>
            </Card>
            <Card className={`card-position-4-1`}>
              <>
                <CardTitle className="mb">Transações por dia</CardTitle>
                <ReBarChart
                  barChartData={ContextMock.get('florencia').dailyTransactions}
                />
              </>
            </Card>
            <Card className={`card-position-4-2`}>
              <>
                <CardTitle className="mb">Produtos mais vendidos</CardTitle>
                <ReBarChart
                  barChartData={
                    ContextMock.get('florencia').bestSellingProducts
                  }
                />
              </>
            </Card>
            <Card className={`card-position-5`}>
              <>
                <CardTitle className="mb">
                  Faturamento bruto por geladeira <span> </span>
                  <span
                    style={{
                      fontSize: 12,
                      paddingLeft: 10,
                    }}
                  >
                    <strong>(R$)</strong>
                  </span>
                </CardTitle>
                <ReBarChart
                  barChartData={ContextMock.get('florencia').posBilling}
                />
              </>
            </Card>
          </OverviewCardContainer>
        </Container>
      )}
    </Default>
  );
}
