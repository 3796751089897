import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useContext } from 'react';
import { UseFormReset, useForm } from 'react-hook-form';

import { DateHelper } from '@/domain/helpers';
import makeFinancialPresenter from '@/main/factories/presenters/pages/financial/finantial-presenter-factory';
import { NotificationContext } from '@/main';
import { INegotiationForm } from '@/domain/models';
import { FilterContext } from '@/ui/context/context-filter';
import { FilterType } from '@/ui/context/context-filter/type';

export function useGetNegotiationById(negotiationId?: number) {
  const financialPresenter = makeFinancialPresenter();

  const {
    data: negotiationData,
    isLoading: isNegotiationLoading,
    isFetching: isNegotiationFetching,
  } = useQuery(
    ['negotiationById', negotiationId],
    () => financialPresenter.getNegotiationByIdAsync(negotiationId!),
    {
      keepPreviousData: false,
      enabled: !!negotiationId,
    },
  );

  const isLoading = isNegotiationLoading || isNegotiationFetching;

  return {
    negotiationData,
    isLoading,
  };
}

export function useDeleteNegotiation(handleClose: () => void) {
  const financialPresenter = makeFinancialPresenter();

  const { errorNotification, successNotification } =
    useContext(NotificationContext);

  const { getFiltersObject } = useContext(FilterContext);
  const pageFilters: FilterType<'financial'> = getFiltersObject();

  const queryClient = useQueryClient();

  const { mutate: deleteNegotiation, isLoading: isDeleteNegotiationLoading } =
    useMutation(
      (negotiationId: number) =>
        financialPresenter.deleteNegotiationAsync(negotiationId),
      {
        onSuccess: res => {
          queryClient.invalidateQueries(['financialData', pageFilters]);
          if (!res.success) {
            return errorNotification('Erro ao deletar negociação!');
          }

          successNotification('Negociação excluida com sucesso!');
          return handleClose();
        },
      },
    );

  return {
    deleteNegotiation,
    isDeleteNegotiationLoading,
  };
}

export function useCreateNegotiation(
  licensedId: string,
  handleClose: () => void,
) {
  const negotiationForm = useForm<INegotiationForm>({
    mode: 'all',
    defaultValues: {
      type: 'DEBIT',
      date: null,
    },
  });

  const { successNotification, errorNotification } =
    useContext(NotificationContext);

  const { getFiltersObject } = useContext(FilterContext);
  const pageFilters: FilterType<'financial'> = getFiltersObject();
  const queryClient = useQueryClient();

  const financialPresenter = makeFinancialPresenter();

  const { mutateAsync: createNegotiation } = useMutation(
    (data: INegotiationForm) =>
      financialPresenter.postNegotiation(data, licensedId as string),
    {
      onSuccess: res => {
        if (res.success) {
          successNotification('Negociação criada com sucesso!');
          queryClient.invalidateQueries(['financialData', pageFilters]);
          negotiationForm.reset({
            type: 'DEBIT',
            date: null,
            description: '',
            value: null,
          });
          return handleClose();
        }

        if (res.data && res.data.message) {
          errorNotification(res.data.message);
          return handleClose();
        }

        errorNotification('Erro ao criar negociação!');
        handleClose();
      },
    },
  );

  const submitNegotiation = (data: INegotiationForm) => {
    createNegotiation({
      ...data,
      date: DateHelper.formatDateToFinancialFilter(
        DateHelper.formatDate(data.date),
      ),
    });
  };

  return {
    negotiationForm,
    submitNegotiation,
  };
}

export function useUpdateNegotiation(
  negotiationId: string,
  handleClose: () => void,
  reset: UseFormReset<INegotiationForm>,
) {
  const queryClient = useQueryClient();

  const financialPresenter = makeFinancialPresenter();

  const { getFiltersObject } = useContext(FilterContext);
  const pageFilters: FilterType<'financial'> = getFiltersObject();

  const { errorNotification, successNotification } =
    useContext(NotificationContext);

  const { mutate: updateNegotiation, isLoading: isUpdateNegotiationLoading } =
    useMutation(
      (data: INegotiationForm) =>
        financialPresenter.updateNegotiation(data, negotiationId),
      {
        onSuccess: res => {
          if (!res.success) {
            return errorNotification('Erro ao atualizar negociação!');
          }
          queryClient.invalidateQueries(['financialData', pageFilters]);
          reset({
            type: 'DEBIT',
            date: null,
            description: '',
            value: null,
          });
          successNotification('Negociação atualizada com sucesso!');
          handleClose();
        },
      },
    );

  const submitUpdateNegotiation = (data: INegotiationForm) => {
    updateNegotiation({
      ...data,
      date: DateHelper.formatDateToFinancialFilter(
        DateHelper.formatDate(data.date),
      ),
    });
  };

  return {
    submitUpdateNegotiation,
    isUpdateNegotiationLoading,
  };
}
