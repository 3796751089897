export enum SeverityLevel {
  info = 1,
  success = 2,
  warning = 3,
  error = 99,
}

export const SeverityLevelDescription = new Map<number, string>([
  [SeverityLevel.error, 'error'],
  [SeverityLevel.info, 'info'],
  [SeverityLevel.success, 'success'],
  [SeverityLevel.warning, 'warning'],
]);
