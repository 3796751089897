export default function RefrigeratorStockTableHead(): JSX.Element {
  return (
    <tr>
      <th className="start">Produtos</th>
      <th className="end">Ult. Repo</th>
      <th className="end">Consumo</th>
      <th className="end">Esperado</th>
      <th className="end">Estoque</th>
      <th className="end first">% Estoque</th>
    </tr>
  );
}
