import { Controller } from 'react-hook-form';
import { DatePicker } from '@mui/x-date-pickers';
import { IDatePickerField } from '@/ui/interfaces';
import defaultInputStyles from '../input-styles';
import StyledTextField from '../styled-text-field';

export default function DatePickerField({
  control,
  name,
  label,
  views,
  mask,
  rules,
  dataTestId,
  placeholder,
  defaultValue,
  readOnly = false,
  min,
  max,
  size = 'normal',
}: IDatePickerField) {
  const { inputStyles } = defaultInputStyles(readOnly);

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      defaultValue={defaultValue}
      render={({
        field: { onChange, value, ...field },
        fieldState: { error },
      }) => {
        return (
          <DatePicker
            minDate={min ? min : '01/01/1905'}
            maxDate={max ? max : '31/12/2100'}
            inputFormat={mask ? mask : 'DD/MM/YYYY'}
            disableMaskedInput
            label={label}
            views={views ? views : ['day']}
            readOnly={readOnly}
            {...field}
            value={value}
            InputProps={{
              placeholder,
            }}
            onChange={(event: any) => {
              if (!event) {
                return onChange(null);
              }
              return onChange(event.$d);
            }}
            renderInput={(params: any) => {
              return (
                <StyledTextField
                  data-testid={dataTestId}
                  inputProps={{
                    readOnly: readOnly,
                    placeholder,
                  }}
                  className={size}
                  sx={inputStyles}
                  placeholder={placeholder}
                  {...params}
                  error={!!error}
                  helperText={error ? error.message : null}
                  required={!!rules?.required}
                />
              );
            }}
          />
        );
      }}
    />
  );
}
