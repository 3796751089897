import StyledSvgIcon from './svg-icon.styles';

export default function PeopleIcon() {
  return (
    <StyledSvgIcon
      width="20"
      height="18"
      viewBox="0 0 20 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.3332 16.5V14.8333C18.3332 13.2801 17.2709 11.9751 15.8332 11.605M12.9165 1.7423C14.1381 2.23679 14.9998 3.43443 14.9998 4.83333C14.9998 6.23224 14.1381 7.42988 12.9165 7.92437M14.1665 16.5C14.1665 14.9469 14.1665 14.1703 13.9128 13.5577C13.5745 12.741 12.9255 12.092 12.1088 11.7537C11.4962 11.5 10.7196 11.5 9.1665 11.5H6.6665C5.11337 11.5 4.3368 11.5 3.72423 11.7537C2.90747 12.092 2.25855 12.741 1.92024 13.5577C1.6665 14.1703 1.6665 14.9469 1.6665 16.5M11.2498 4.83333C11.2498 6.67428 9.75745 8.16667 7.9165 8.16667C6.07555 8.16667 4.58317 6.67428 4.58317 4.83333C4.58317 2.99238 6.07555 1.5 7.9165 1.5C9.75745 1.5 11.2498 2.99238 11.2498 4.83333Z"
        stroke="#00A6DB"
        fill="transparent"
        strokeWidth="1.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </StyledSvgIcon>
  );
}
