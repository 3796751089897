import { Row } from '../layout';

export default function NoDataFoundMessage({
  children,
}: {
  children?: React.ReactNode | string;
}): JSX.Element {
  return (
    <Row grow={1} align="center" justify="center" width="100%">
      {children || 'Nenhum dado encontrado'}
    </Row>
  );
}
