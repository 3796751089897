import { SeverityLevel } from '@/domain/models';

export default class HttpError {
  message: string;
  statusCode: number;
  severity: SeverityLevel;
  code?: string;

  constructor(
    message: string,
    statusCode: number,
    severity: SeverityLevel = SeverityLevel.warning,
    code = undefined,
  ) {
    this.message = message;
    this.statusCode = statusCode;
    this.severity = severity;
    this.code = code;
  }

  setMessage(message: string, severity = SeverityLevel.warning): void {
    if (message !== '' && message !== undefined && message !== null) return;
    this.message = message;
    this.severity = severity;
  }
}
