import { DefaultTheme } from 'styled-components';

export default class DarkStyleGuide {
  static darkTheme(): DefaultTheme {
    return {
      fontFamily: 'Roboto',
      primary: '#120D0E',
      secondary: '#FFFFFF',
      tertiary: '#FFCD00',
      quaternary: '#7f7f7f',
      pageTitleHover: '#1a1a1a',
      lines: '#4d4a4a',
      cardBg: '#1a1a1a',
      bodyBg: '#120d0e',
      yellowIconBg: 'rgba(122, 88, 0, 0.4);',
      blueIconBg: 'rgba(0, 64, 122, 0.4)',
      chartLegend: '#BABABA',
      yellowIconColor: '#FFDD3F',
      blueIconColor: '#3FEEFF',
      tableText: '#BABABA',
      searchBorder: '#4d4a4a',
      columnFilter: '#7a5800',
      tableIconColors: '#4d4a4a',
      tableBorder: '#3e3a3b',
      formTextColor: '#FFCD00',
      tableNthChild: '#1e191a',
      cardBoxShadow: '5px 5px 5px rgba(195, 195, 195, 0)',
      fileBg: '#757575',
      dragDrop: '#1A1A1A',
      chartLine: '#493336',
      removedProduct: '#1f1f1f',
      productDetailHead: '#1a1416',
      filterButtonHover: {
        font: '#fff',
        background: '#313131',
      },
      exportBox: {
        fontColor: '#989898',
        exportBoxBg: '#313131',
        fontColorHover: '#ffffff',
        optionBgHover: '#3c3c3c',
      },
      financialReport: {
        financialTotal: '#7a5800',
        financialToReceive: '#00407a',
        condominiumTransfer: '#757575',
      },
      orderStatusLabel: {
        success: {
          textColor: '#36b37e',
          borderColor: '#1a3729',
        },
        problems: {
          textColor: '#ff5630',
          borderColor: '#4d1f16',
        },
        processing: {
          textColor: '#adab8c',
          borderColor: '#adab8c',
        },
        canceled: {
          textColor: '#ff5630',
          borderColor: '#4d1f16',
        },
        reposition: {
          textColor: '#00a6db',
          borderColor: '#0d3341',
        },
        contestation: {
          textColor: '#ffab00',
          borderColor: '#4d350a',
        },
        contestationApproved: {
          textColor: '#36b37e',
          borderColor: '#1a3729',
        },
        contestationDenied: {
          textColor: '#ff5630',
          borderColor: '#4d1f16',
        },
        manualBilling: {
          textColor: '#a64ffd',
          borderColor: '#361e49',
        },
        bankDispute: {
          textColor: '#9ab336',
          borderColor: '#333717',
        },
        refunded: {
          textColor: '#36b37e',
          borderColor: '#1a3729',
        },
      },
      loading: {
        primaryColor: '#1a1a1a',
        secondaryColor: '#120d0e',
      },
      notification: {
        dark: '#424242',
        error: '#F77066',
        warning: '#FFA622',
        info: '#41A5F5',
        success: '#62BB66',
      },
      table: {
        text: '#BABABA',
      },
    };
  }
}
