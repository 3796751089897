import { SortingRule, TableInstance } from 'react-table';
import {
  CssBaseline,
  TableBody,
  TableContainer,
  TableHead,
} from '@mui/material';
import { TableRowProps } from '@/ui/interfaces/props/table';
import BodyMap from './body-map';
import { TableMui } from './styles';
import TheadMapRow from './thead-map';
import { useCallback, useContext, useEffect } from 'react';
import { SelectedTableRowsContext } from '@/ui/context/selected-table-rows';

interface ITableBuilderProps {
  builder: TableInstance<any>;
  rowProps: TableRowProps;
  enableCellSorting?: boolean;
  TableBodyCustom?: JSX.Element;
  className?: string;
  sortBy: SortingRule<any>[];
}

export default function TableBuilder(props: ITableBuilderProps): JSX.Element {
  const { builder, rowProps, enableCellSorting, className, sortBy } = props;
  const { getTableProps } = builder;

  const {
    selectedRows,
    handleAddAllRows,
    handleRemoveAllRows,
    handleAddRow,
    handleRemoveRow,
    isAllSelected,
  } = useContext(SelectedTableRowsContext);

  const TableBodyCustom = props.TableBodyCustom as JSX.Element;

  return (
    <>
      <CssBaseline />
      <TableContainer sx={{ overflowX: 'initial' }}>
        <TableMui stickyHeader {...getTableProps()} className={className}>
          <TableHead
            sx={{ zIndex: 3, position: 'sticky', top: '0px' }}
            className="material-table-head"
          >
            <TheadMapRow
              sortBy={sortBy}
              builder={builder}
              enableRowActions={rowProps?.enableRowActions}
              enableCellSorting={enableCellSorting}
              hasRowSelection={rowProps?.hasRowSelection}
              handleAddAllRows={handleAddAllRows}
              handleRemoveAllRows={handleRemoveAllRows}
              isAllSelected={isAllSelected}
            />
          </TableHead>
          {TableBodyCustom}

          <TableBody className="material-table-body">
            <BodyMap
              builder={builder}
              rowProps={rowProps}
              handleAddRow={handleAddRow}
              handleRemoveRow={handleRemoveRow}
              isAllSelected={isAllSelected}
              selectedRows={selectedRows}
            />
          </TableBody>
        </TableMui>
      </TableContainer>
    </>
  );
}

TableBuilder.defaultProps = {
  enableCellSorting: false,
  TableBodyCustom: <></>,
};
