import { IBarChart } from '@/domain/models';

export default class BarChart implements IBarChart {
  name: string;
  value: number;
  color: string;
  barName: string;
  tooltipName?: string;

  constructor(
    name: string,
    value: number,
    color: string,
    barName: string,
    tooltipName?: string,
  ) {
    this.name = name;
    this.color = color;
    this.value = value;
    this.barName = barName;
    this.tooltipName = tooltipName;
  }
}
