import React, { ReactNode, useState } from 'react';

import { Button } from '@/components/atoms';
import { ConfirmationModal } from '@/components/molecules';

interface ButtonWithConfirmationProps {
  label: string | ReactNode;
  message: string;
  variant?: 'text' | 'outlined' | 'contained';
  onClick: () => Promise<boolean>;
  onSuccess?: () => void;
  onError?: (error: Error) => void;
}

const ButtonWithConfirmation: React.FC<ButtonWithConfirmationProps> = ({
  label,
  message,
  variant,
  onClick,
  onSuccess,
  onError,
  ...props
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleButtonClick = () => {
    setIsModalOpen(true);
  };

  const handleConfirm = async () => {
    try {
      await onClick();
      if (onSuccess) onSuccess();
    } catch (error) {
      if (onError && error instanceof Error) onError(error);
    }
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <div>
      <Button variant={variant} onClick={handleButtonClick} {...props}>
        {label}
      </Button>
      {isModalOpen && (
        <ConfirmationModal
          title={label}
          isOpen={isModalOpen}
          message={message}
          confirmText="Confirmar"
          cancelText="Cancelar"
          onClose={() => setIsModalOpen(false)}
          onConfirm={handleConfirm}
          onCancel={handleCancel}
        />
      )}
    </div>
  );
};

export default ButtonWithConfirmation;
