import {
  SearchCriteriaModel,
  SearchRequestModel,
  SearchResponseModel,
  UserFormat,
  UserHttpEndpoint,
  UserRoles,
} from '@/domain/models';
import {
  AuthenticationHelper,
  PermissionHelper,
  StringHelper,
} from '@/domain/helpers';
import { ColumnModel } from '@/presentation/models/table';
import { sidebarOpenAction } from '@/ui/store/modules';
import { store } from '@/ui/store/config';
import { WindowHelper } from '@/ui/helpers';
import { RowActionProps } from '@/ui/interfaces/props/table';
import { ITableServerSidePresenter } from '@/ui/components/table';
import { TableColoredLabel, UserPerfilLabel } from '@/ui/components';
import { ISearchRepositoryPort, IUserRepositoryPort } from '@/infra';

export default class UserTablePresenter implements ITableServerSidePresenter {
  constructor(
    private readonly searchRepository: ISearchRepositoryPort,
    private readonly userRepository: IUserRepositoryPort,
    private userStatusMap = UserFormat.userStatusMap,
  ) {}

  makeColumns(): ColumnModel<any>[] {
    return [
      new ColumnModel<any>('Código', 'id', {}),
      new ColumnModel<any>('Nome', 'name', {}),
      new ColumnModel<any>('E-mail', 'email', {}),
      new ColumnModel<any>('Telefone', 'telephone', {}).withRender(
        (value: string) => {
          const phoneMask = (value: any) => {
            if (!value) return '';
            value = value.replace(/\D/g, '');
            value = value.replace(/(\d{2})(\d)/, '($1) $2');
            value = value.replace(/(\d)(\d{4})$/, '$1-$2');
            return value;
          };
          return <>{phoneMask(value)}</>;
        },
      ),
      new ColumnModel<any>('CPF', 'cpf', {}),
      new ColumnModel<any>('Perfil', 'roles', {})
        .withRender((value: string, rowData: any) => {
          return <UserPerfilLabel roles={rowData.roles} />;
        })
        .withRenderTitleCustom((rowData: any) => {
          return rowData.roles.map((role: string) => UserRoles.get(role));
        }),
      new ColumnModel<any>('Status', 'status', {})
        .withRender((value: string) => {
          return (
            <TableColoredLabel isActive={value}>
              {this.userStatusMap.get(value)}
            </TableColoredLabel>
          );
        })
        .withRenderTitleCustom(
          ({ status }: any) => this.userStatusMap.get(status)!,
        ),
      new ColumnModel<any>('Data de criação', 'dateRegister', {}),
    ];
  }

  makeSearchRequestModel(): SearchRequestModel {
    return new SearchRequestModel(UserHttpEndpoint.USER_BASE, 'id', {
      isOrderByDesc: true,
      criterias: [
        new SearchCriteriaModel('name', {
          value: '',
        }),
      ],
    });
  }

  makeLabelDisplayedResult(response: SearchResponseModel<any>): string {
    if (response === undefined) return '';
    if (response.page === undefined || response.page.total === 0) return '';

    const textPlural = StringHelper.pluralize({
      count: response.page.numberOfElements,
    });
    return `Exibindo ${response.page.numberOfElements} usuário${textPlural}`;
  }

  async search<T>(
    searchModel: SearchRequestModel,
  ): Promise<SearchResponseModel<T>> {
    return await this.searchRepository.getDataAsync<any>(
      searchModel,
      UserHttpEndpoint.USER_BASE,
    );
  }

  makeRowActions(): RowActionProps<any>[] {
    return [];
  }

  token = AuthenticationHelper.getToken();

  permissionHelper = new PermissionHelper(this.token.auth);

  moreActionsMenu = [
    {
      enabled: ({ status }: any) =>
        status === 'ACTIVE' || status === 'INACTIVE',
      label: 'Ativar / Desativar',
      onClick: (
        rowData: any,
        handleClose: any,
        successNotification: any,
        refetchTable: any,
      ) => {
        rowData.status === 'ACTIVE'
          ? store.dispatch(
              sidebarOpenAction({
                sidebarName: 'change_activate_user',
                sidebarState: { right: true },
                elementId: rowData.id,
                elementData: {
                  status: rowData.status,
                  orderId: rowData.id,
                },
              }),
            )
          : this.userRepository
              .toggleActivateUser(rowData.id, true)
              .then(() => refetchTable())
              .then(
                () => successNotification('Usuário ativado com sucesso!'),
                handleClose(),
              );
      },
    },
    {
      enabled: true,
      label: 'Editar usuário',
      onClick: (rowData: any) => {
        WindowHelper.redirect(`/user/${rowData?.id}`);
      },
    },
    {
      enabled: (rowData: any) => {
        return (
          (this.permissionHelper.isTakeAndGo() &&
            rowData.status == 'CHANGING_PASSWORD') ||
          rowData.status == 'ACTIVE'
        );
      },
      label: 'Enviar senha temporária',
      onClick: (rowData: any, handleClose: any) => {
        handleClose();
        store.dispatch(
          sidebarOpenAction({
            sidebarName: 'reset_user_password',
            sidebarState: { right: true },
            elementId: rowData.id,
            elementData: {
              username: rowData.name,
              email: rowData.email,
              userId: rowData.id,
            },
          }),
        );
      },
    },
  ];
}
