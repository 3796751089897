import { IRatingClientData } from '@/domain/models';

export default class RatingClientData implements IRatingClientData {
  averageRating: number;
  ratingQuantity: number;

  constructor(averageRating: number, ratingQuantity: number) {
    this.averageRating = averageRating;
    this.ratingQuantity = ratingQuantity;
  }
}
