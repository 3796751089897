import {
  ProductListHttpEndpoint,
  SearchCriteriaModel,
  SearchRequestModel,
  SearchResponseModel,
} from '@/domain/models';
import { StringHelper } from '@/domain/helpers';
import { ISearchRepositoryPort } from '@/infra';
import { ColumnModel } from '@/presentation/models/table';
import { RowActionProps } from '@/ui/interfaces/props/table';
import { ITableServerSidePresenter } from '@/ui/components/table';
import { EditIcon } from '@/ui/components';
import { WindowHelper } from '@/ui/helpers';

export default class ProductsCategoryTablePresenter
  implements ITableServerSidePresenter
{
  constructor(private readonly searchRepository: ISearchRepositoryPort) {}

  userStatusMap: any;

  makeColumns(): ColumnModel<any>[] {
    return [
      new ColumnModel<any>('Código', 'id', {}),
      new ColumnModel<any>('Descrição', 'description', {}),
    ];
  }

  makeSearchRequestModel(): SearchRequestModel {
    return new SearchRequestModel(
      ProductListHttpEndpoint.PRODUCT_CATEGORY,
      'description',
      {
        isOrderByDesc: false,
        criterias: [
          new SearchCriteriaModel('description', {
            value: '',
          }),
        ],
      },
    );
  }

  makeLabelDisplayedResult(response: SearchResponseModel<any>): string {
    if (response === undefined) return '';
    if (response.page === undefined || response.page.total === 0) return '';

    const textPlural = StringHelper.pluralize({
      count: response.page.numberOfElements,
    });
    return `Exibindo ${response.page.numberOfElements} categoria${textPlural}`;
  }

  async search<T>(
    searchModel: SearchRequestModel,
  ): Promise<SearchResponseModel<T>> {
    return await this.searchRepository.getDataAsync<any>(
      searchModel,
      ProductListHttpEndpoint.PRODUCT_CATEGORY,
    );
  }

  makeRowActions(): RowActionProps<any>[] {
    return [
      {
        icon: () => <EditIcon />,
        tooltip: 'Editar categoria',
        enableClickOnRow: false,
        redirectTo: (rowData: any) => {
          return `/product/category/${rowData?.id}`;
        },
        onClick: (event: any, rowData: any) => {
          WindowHelper.redirect(`/product/category/${rowData?.id}`);
        },
      },
    ];
  }
}
