import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import Modal from '@mui/material/Modal';
import { OrderContestationModals } from '@/domain/models';
import { TitleText } from '@/ui/pages/user/components/styles';
import { Button, CloseIcon, ModalContainer } from '@/ui/components';
import { TitleContainer } from '@/ui/layouts/header/sub-header/styles';
import { Row } from '@/ui/components/layout';

interface IOrderRemoveProductModalProps {
  handleRemoveProduct: (productId: number) => void;
}

export default function OrderRemoveProductModal(
  props: IOrderRemoveProductModalProps,
) {
  const { handleRemoveProduct } = props;

  const [open, setOpen] = useState(false);

  const sidebar = useSelector((store: any) => store.sidebar);

  const handleClose = () => setOpen(false);

  const { orderId, productName, productId } = sidebar.elementData;

  useEffect(() => {
    if (sidebar.sidebarName === OrderContestationModals.ORDER_REMOVE_PRODUCT) {
      setOpen(sidebar.sidebarState.right);
    }
  }, [sidebar]);

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="order_remove_product"
        aria-describedby="order_remove_product"
      >
        <ModalContainer className="order_remove_product">
          <TitleContainer>
            <TitleText style={{ marginTop: 0 }}>Excluir item?</TitleText>
            <Row justify="space-around" onClick={() => handleClose()}>
              <CloseIcon />
            </Row>
          </TitleContainer>
          <div style={{ textAlign: 'start' }}>
            Deseja excluir o item <strong>{productName}</strong> do pedido
            {' ' + orderId}?
          </div>
          <Row justify="end" margin={{ top: '2rem' }} gap={2}>
            <Button
              onClick={handleClose}
              className="dark"
              text="Cancelar"
              dataTestId="cancel_remove_product"
            />
            <Button
              dataTestId="confirm_remove_product"
              onClick={() => {
                handleRemoveProduct(productId);
                handleClose();
              }}
              text="Excluir"
            />
          </Row>
        </ModalContainer>
      </Modal>
    </div>
  );
}
