import { useTheme } from 'styled-components';
import StyledSvgIcon from './svg-icon.styles';

export default function OrdinationArrowUp() {
  const theme = useTheme();

  return (
    <StyledSvgIcon
      width="15"
      height="15"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      transform="translate(2, 5)"
    >
      <path
        d="M5 8.5V1.5M5 1.5L1.5 5M5 1.5L8.5 5"
        stroke={theme.lines}
        strokeWidth="1.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </StyledSvgIcon>
  );
}
