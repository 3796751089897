import { Row } from '@/ui/components/layout';
import styled from 'styled-components';

export const ConnectionContainer = styled(Row)`
  gap: 4px;
  color: ${props => props.theme.quaternary};

  &.online {
    color: ${props => props.theme.orderStatusLabel.success.textColor};

    svg {
      stroke: ${props => props.theme.orderStatusLabel.success.textColor};
    }
  }

  &.warning {
    color: ${props => props.theme.orderStatusLabel.contestation.textColor};

    svg {
      stroke: ${props => props.theme.orderStatusLabel.contestation.textColor};
    }
  }

  &.offline {
    color: ${props => props.theme.orderStatusLabel.problems.textColor};

    svg {
      stroke: ${props => props.theme.orderStatusLabel.problems.textColor};
    }
  }

  &.camera {
    cursor: pointer;
  }
`;

export const StatusRows = styled(Row)`
  gap: 8px;
  align-items: center;

  span {
    color: ${props => props.theme.quaternary};
  }

  &.stroke {
    svg {
      stroke: ${props => props.theme.secondary};
      cursor: default;
    }
  }
  &.cameraProblem {
    cursor: pointer;
    svg {
      cursor: pointer;
    }
  }
`;

export const CoolerLocality = styled.div`
  width: 30ch;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-bottom: 4px;
`;

export const Capitalized = styled.span`
  text-transform: capitalize;
`;
