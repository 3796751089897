import { SubmitHandler, useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Modal from '@mui/material/Modal';
import {
  Button,
  CloseIcon,
  InputTextField,
  ModalContainer,
} from '@/ui/components';
import { TitleContainer } from '@/ui/layouts/header/sub-header/styles';
import { TitleText } from '@/ui/pages/user/components/styles';
import { OrderContestationModals } from '@/domain/models';
import IOrderContestationDeny from '@/domain/models/pages/orders/edit/order-contestation-deny';
import { Row } from '@/ui/components/layout';

export default function OrderDeclineContestationModal() {
  const [open, setOpen] = useState(false);

  const sidebar = useSelector((store: any) => store.sidebar);

  const { declineOrderContestation } = sidebar.elementData;

  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = useForm<IOrderContestationDeny>({
    mode: 'onChange',
  });

  const handleClose = () => setOpen(false);

  const handleOrderContestationDecline = (reason: string) => {
    declineOrderContestation(reason);
  };

  const onSubmit: SubmitHandler<IOrderContestationDeny> = data =>
    handleOrderContestationDecline(data.reason);

  useEffect(() => {
    if (
      sidebar.sidebarName === OrderContestationModals.ORDER_DECLINE_CONTESTATION
    ) {
      setOpen(sidebar.sidebarState.right);
    }
  }, [sidebar]);

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="order_remove_product"
        aria-describedby="order_remove_product"
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalContainer className="order_remove_product">
            <TitleContainer>
              <TitleText
                data-testid="deny_contestation_title"
                style={{ marginTop: 0 }}
              >
                Motivo da reprovação:
              </TitleText>
              <Row justify="space-around" onClick={() => handleClose()}>
                <CloseIcon />
              </Row>
            </TitleContainer>

            <InputTextField
              dataTestId="deny_contestation_reason_input"
              name="reason"
              label="Motivo da reprovação"
              type="textarea"
              control={control}
              rules={{
                required: 'O campo motivo da reprovação é obrigatório',
              }}
            />
            <Row justify="end" margin={{ top: '2rem' }} gap={2}>
              <Button
                dataTestId="deny_contestation_cancel_button"
                onClick={handleClose}
                className="dark"
                text="Cancelar"
                type="button"
              />
              <Button
                disabled={!isValid}
                dataTestId="deny_contestation_confirm_button"
                text="Reprovar contestação"
                type="submit"
              />
            </Row>
          </ModalContainer>
        </form>
      </Modal>
    </div>
  );
}
