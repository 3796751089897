import { ContextRoutePath } from './context-route-path';

enum TakeUserRoles {
  dashboard = ContextRoutePath.home_overview,
  rating = ContextRoutePath.home_rating,
  refrigerator_list = ContextRoutePath.refrigerator_list,
  refrigerator_stock = ContextRoutePath.refrigerator_stock,
  promotion_list = ContextRoutePath.promotion_list,
  promotion_save = ContextRoutePath.promotion_save,
  promotion_edit = ContextRoutePath.promotion_edit,
  product_list = ContextRoutePath.product_list,
  product_save = ContextRoutePath.product_save,
  product_edit = ContextRoutePath.product_edit,
  product_mix = ContextRoutePath.product_mix,
  product_mix_save = ContextRoutePath.product_mix_save,
  product_mix_edit = ContextRoutePath.product_mix_edit,
  product_category = ContextRoutePath.product_category,
  product_category_save = ContextRoutePath.product_category_save,
  product_category_edit = ContextRoutePath.product_category_edit,
  order = ContextRoutePath.order,
  banner_list = ContextRoutePath.banner_list,
  banner_save = ContextRoutePath.banner_save,
  banner_edit = ContextRoutePath.banner_edit,
}

const ContextTakeUserRoles = new Map<string, string>([
  [TakeUserRoles.dashboard, TakeUserRoles.dashboard],
  [TakeUserRoles.rating, TakeUserRoles.rating],
  [TakeUserRoles.refrigerator_list, TakeUserRoles.refrigerator_list],
  [TakeUserRoles.refrigerator_stock, TakeUserRoles.refrigerator_stock],
  [TakeUserRoles.promotion_list, TakeUserRoles.promotion_list],
  [TakeUserRoles.promotion_edit, TakeUserRoles.promotion_edit],
  [TakeUserRoles.promotion_save, TakeUserRoles.promotion_save],
  [TakeUserRoles.product_mix, TakeUserRoles.product_mix],
  [TakeUserRoles.product_mix_edit, TakeUserRoles.product_mix_edit],
  [TakeUserRoles.product_mix_save, TakeUserRoles.product_mix_save],
  [TakeUserRoles.product_category, TakeUserRoles.product_category],
  [TakeUserRoles.product_category_edit, TakeUserRoles.product_category_edit],
  [TakeUserRoles.product_category_save, TakeUserRoles.product_category_save],
  [TakeUserRoles.product_save, TakeUserRoles.product_save],
  [TakeUserRoles.product_edit, TakeUserRoles.product_edit],
  [TakeUserRoles.product_list, TakeUserRoles.product_list],
  [TakeUserRoles.order, TakeUserRoles.order],
  [TakeUserRoles.banner_list, TakeUserRoles.banner_list],
  [TakeUserRoles.banner_save, TakeUserRoles.banner_save],
  [TakeUserRoles.banner_edit, TakeUserRoles.banner_edit],
]);

export default ContextTakeUserRoles;
