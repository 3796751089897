import 'dayjs/locale/pt-br';
import { Control, UseFormWatch } from 'react-hook-form';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { IPromotionSaveInputs } from '@/domain/models';
import { DateTimePickerField } from '@/ui/components';
import { Grid } from '@/ui/components/layout';

export default function PromotionDate({
  control,
  watch,
}: {
  control: Control<IPromotionSaveInputs, any>;
  watch: UseFormWatch<IPromotionSaveInputs>;
}) {
  const [dateBegin, dateEnd] = watch(['dateBegin', 'dateEnd']);

  return (
    <>
      <h5>Período de vigência</h5>
      <Grid columns={8} gap={2} margin={{ bottom: 6 }} responsiveType="input">
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br">
          <DateTimePickerField
            control={control}
            name="dateBegin"
            label="Data de início"
            rules={{
              required: 'Data de início é obrigatória',
              deps: ['dateEnd'],
              validate: {
                isLessThan: (value: string): boolean | string =>
                  new Date(dateEnd) > new Date(value) ||
                  'Data de início deve ser menor que a data de término',
              },
            }}
          />
          <DateTimePickerField
            control={control}
            name="dateEnd"
            label="Data de término"
            rules={{
              required: 'Data de término é obrigatória',
              deps: ['dateBegin'],
              validate: {
                isGreaterThan: (value: string): boolean | string =>
                  new Date(dateBegin) < new Date(value) ||
                  'Data de término deve ser maior que a data de início',
              },
            }}
          />
        </LocalizationProvider>
      </Grid>
    </>
  );
}
