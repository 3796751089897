import {
  AuthenticationHelper,
  PermissionHelper,
  StringHelper,
} from '@/domain/helpers';
import {
  MonitoringHttpEndpoint,
  SearchCriteriaModel,
  SearchRequestModel,
  SearchResponseModel,
} from '@/domain/models';
import { ISearchRepositoryPort } from '@/infra';
import { ColumnModel } from '@/presentation/models/table';
import { RowActionProps } from '@/ui/interfaces/props/table';
import { ITableServerSidePresenter } from '@/ui/components/table';

export default class MonitoringTablePresenter
  implements ITableServerSidePresenter
{
  constructor(private readonly searchRepository: ISearchRepositoryPort) {}

  statusText: any;
  statusClassName: any;

  makeColumns(): ColumnModel<any>[] {
    return [
      new ColumnModel<any>('Data', 'date', {}),
      new ColumnModel<any>('Cooler', 'coolerLocality', {}),
      new ColumnModel<any>('Licenciado', 'licensed', {}),
      new ColumnModel<any>('Usuário', 'userName', {}),
      new ColumnModel<any>('CPF', 'userCPF', {}),
      new ColumnModel<any>('Descrição', 'description', {}),
    ];
  }

  makeSearchRequestModel(): SearchRequestModel {
    return new SearchRequestModel(
      MonitoringHttpEndpoint.MONITORING_ATTEMPTS_TO_OPENING,
      'beginDate',
      {
        isOrderByDesc: true,
        criterias: [
          new SearchCriteriaModel('date', {
            value: '',
          }),
        ],
      },
    );
  }

  makeLabelDisplayedResult(response: SearchResponseModel<any>): string {
    if (response === undefined) return '';
    if (response.page === undefined || response.page.total === 0) return '';

    const textPlural = StringHelper.pluralize({
      count: response.page.numberOfElements,
    });
    return ` Exibindo ${response.page.numberOfElements} tentativa${textPlural} de abertura`;
  }

  async search<T>(
    searchModel: SearchRequestModel,
  ): Promise<SearchResponseModel<T>> {
    return await this.searchRepository.getDataAsync<any>(
      searchModel,
      MonitoringHttpEndpoint.MONITORING_ATTEMPTS_TO_OPENING,
    );
  }

  token = AuthenticationHelper.getToken();

  permissionHelper = new PermissionHelper(this.token.auth);

  makeRowActions(): RowActionProps<any>[] {
    return [];
  }
}
