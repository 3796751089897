import {
  parse,
  isDate,
  addDays,
  addMonths,
  addMinutes,
  isAfter,
  parseISO,
  addHours,
  addYears,
  subDays,
  differenceInCalendarDays,
} from 'date-fns';
import moment from 'moment';
import 'moment/locale/pt-br';

export default class DateHelper {
  static maskRequest = 'yyyy-MM-dd';
  static maskRequestWithHour = "yyyy-MM-dd'T'HH:mm:ss";

  static getDefaultDate(date: string) {
    if (!date) return null;

    const [day, month, year] = date.split('/');
    return `${year}-${month}-${day}`;
  }

  static parsePromotionDefaultDate(value: string): string {
    const [day, month, year] = value.split('/');
    return `${year}-${month}-${day}`;
  }

  static parseYup(value: string, originalValue: string): Date | string {
    if (originalValue.isEmpty()) return '';

    const parsedDate = isDate(originalValue)
      ? originalValue
      : parse(originalValue, 'yyyy-MM-dd', new Date());

    return parsedDate;
  }

  static parse(value: string | Date): Date {
    switch (typeof value) {
      case 'string':
        return parse(value, 'yyyy-MM-dd', new Date());

      case 'object':
      default:
        return value;
    }
  }

  static getUtcNowInMillis(): number {
    return Date.parse(new Date().toUTCString());
  }

  static addDaysToMillis(date: number | Date, daysToAdd: number): number {
    return addDays(date, daysToAdd).getTime();
  }

  static addYearsToDate(date: number | Date, yearsToAdd: number): Date {
    return addYears(date, yearsToAdd);
  }

  static addDaysToDate(date: number | Date, daysToAdd: number): Date {
    return addDays(date, daysToAdd);
  }

  static subtractDaysToDate(date: number | Date, daysToAdd: number): Date {
    return subDays(date, daysToAdd);
  }

  static addMonthsToDate(date: number | Date, monthToAdd: number): Date {
    return addMonths(date, monthToAdd);
  }

  static addMinutesToMillis(date: number | Date, minutesToAdd: number): number {
    return addMinutes(date, minutesToAdd).getTime();
  }

  static isAfter(date: number | Date, dateToCompare: number | Date): boolean {
    return isAfter(date, dateToCompare);
  }

  static dateFormat(date: any, mask = 'dd/MM/yyyy', utc = -3): string {
    if (date === null || date === undefined) return date;

    const typeDate = typeof date;
    const isTypeString = typeDate === 'string';

    if (isTypeString && `${date}`.isEmpty()) return '';

    const value = isTypeString ? addHours(parseISO(date as string), utc) : date;

    return this.format(value, mask);
  }

  static format(date: string | Date, mask = 'dd/MM/yyyy'): string {
    return DateHelper.dateFormat(date, mask);
  }

  static formatNotUtc(date: string | Date, mask = 'dd/MM/yyyy'): string {
    return DateHelper.dateFormat(date, mask, 0);
  }

  static formatWithHour(date: string, mask = 'dd/MM/yyyy HH:mm'): string {
    return this.format(date, mask);
  }

  static toUTC(date = new Date()): Date {
    return new Date(
      date.getUTCFullYear(),
      date.getUTCMonth(),
      date.getUTCDate(),
      date.getUTCHours(),
      date.getUTCMinutes(),
      date.getUTCSeconds(),
      date.getUTCMilliseconds(),
    );
  }

  static getIntervalDays(startDate: Date, endDate: Date): number {
    return differenceInCalendarDays(endDate, startDate);
  }

  static formatDateHours(date: string | undefined) {
    if ((date as any)['$d']) {
      return moment((date as any)['$d']).format('DD/MM/YYYY HH:mm:ss');
    }

    if (new Date(date as any).toString() === 'Invalid Date') {
      return moment((date as any)['$d']).format('DD/MM/YYYY HH:mm:ss');
    }

    return moment(date).format('DD/MM/YYYY HH:mm:ss');
  }

  static formatDateHours2(date: string | undefined) {
    return moment(date).format('DD/MM/YYYY HH:mm');
  }

  static formatDate(date: any) {
    return moment(date).format('DD/MM/YYYY');
  }

  static formatDateHoursToRequest(date: string | undefined) {
    return moment(date).format('DD-MM-YYYY-HH:mm:ss');
  }

  static formatDateHoursFromRequest(date: string | undefined): Date | null {
    if (!date) return null;
    return moment(this.formatDateHoursToMui(date)).toDate();
  }

  static formatDateFromRequest(date: string | undefined): Date {
    return moment(this.formatDateToMui(date)).toDate();
  }

  static getDayOfWeek(date: string): string {
    const days = [
      'Domingo',
      'Segunda',
      'Terça-feira',
      'Quarta-feira',
      'Quinta-feira',
      'Sexta-feira',
      'Sabado-feira',
    ];

    const newDate = new Date(date);
    return days[newDate.getDay()];
  }

  static formatDateToMui = (defaultValue: string | undefined) => {
    if (defaultValue) {
      const defaultDay = defaultValue.substring(0, 2);
      const defaultMonth = defaultValue.substring(3, 5);
      const defaultYear = defaultValue.substring(6, 10);
      return `${defaultYear}-${defaultMonth}-${defaultDay}`;
    } else {
      return;
    }
  };

  static formatDateToFinancialFilter = (date: any) => {
    if (date == 'Data inválida') {
      return;
    }
    if (date) {
      const defaultMonth = date.substr(3, 3);
      const defaultYear = date.substr(6, 9);

      const formatDate = `01/${defaultMonth}${defaultYear}`;

      return formatDate;
    } else {
      return;
    }
  };

  static initialFinancialDate = (lastDay: string) => {
    const defaultMonth = lastDay.substr(3, 3);
    const defaultYear = lastDay.substr(6, 9);

    const initialFinancialDate = `${defaultMonth}/${defaultYear}`;

    return initialFinancialDate;
  };

  static formatDateHoursToMui = (defaultValue: string | undefined) => {
    if (!defaultValue) return;

    const defaultDay = defaultValue.substring(0, 2);
    const defaultMonth = defaultValue.substring(3, 5);
    const defaultYear = defaultValue.substring(6, 10);

    const defaultHour = defaultValue.substring(11, 13);
    const defaultMinute = defaultValue.substring(14, 16);
    const defaultSecond = defaultValue.substring(17, 19);

    const formatDate = `${defaultYear}-${defaultMonth}-${defaultDay}T${defaultHour}:${defaultMinute}:${defaultSecond}`;

    return formatDate;
  };

  static formatPromotionEndDate = (promotionEndDate: any) => {
    if (promotionEndDate) {
      const defaultDay = promotionEndDate.substr(0, 2);
      const defaultMonth = promotionEndDate.substr(2, 3);
      const defaultYear = promotionEndDate.substr(6, 4);

      const defaultHour = promotionEndDate.substr(10, 10);

      const formatDate = `${defaultDay}${defaultMonth}/${defaultYear}`;
      const formatHours = `${defaultHour}`;

      const formatDayWithHours = `${formatDate}${
        formatHours == ' 00:00:00' ? ' 23:59:59' : formatHours
      }`;

      return formatDayWithHours;
    } else {
      return;
    }
  };

  static getCurrentDate = () => {
    const today = new Date();
    const yyyy = today.getFullYear();
    let mm: any = today.getMonth() + 1; // Months start at 0!
    let dd: any = today.getDate();

    if (dd < 10) dd = '0' + dd;
    if (mm < 10) mm = '0' + mm;

    const formattedToday = dd + '/' + mm + '/' + yyyy;
    return formattedToday;
  };

  static getMonthAndYear(date: string): string {
    const monthNumber = date.slice(0, 2);
    const year = date.slice(3, 7);

    enum monthEnum {
      JANUARY = '01',
      FEBRUARY = '02',
      MARCH = '03',
      APRIL = '04',
      MAY = '05',
      JUNE = '06',
      JULY = '07',
      AUGUST = '08',
      SEPTEMBER = '09',
      OCTOBER = '10',
      NOVEMBER = '11',
      DECEMBER = '12',
    }

    const MonthLabelMap = new Map<string, string>([
      [monthEnum.JANUARY, 'Janeiro'],
      [monthEnum.FEBRUARY, 'Fevereiro'],
      [monthEnum.MARCH, 'Março'],
      [monthEnum.APRIL, 'Abril'],
      [monthEnum.MAY, 'Maio'],
      [monthEnum.JUNE, 'Junho'],
      [monthEnum.JULY, 'Julho'],
      [monthEnum.AUGUST, 'Agosto'],
      [monthEnum.SEPTEMBER, 'Setembro'],
      [monthEnum.OCTOBER, 'Outubro'],
      [monthEnum.NOVEMBER, 'Novembro'],
      [monthEnum.DECEMBER, 'Dezembro'],
    ]);

    const currentMonth = MonthLabelMap.get(monthNumber);

    const financialReportLabelDate = `${currentMonth} ${year}`;

    if (
      financialReportLabelDate &&
      financialReportLabelDate != 'undefined ' &&
      financialReportLabelDate != undefined
    ) {
      return financialReportLabelDate;
    }

    return 'Nenhum mês selecionado';
  }

  static createADateFromSting(date: string) {
    const day = date.slice(0, 2);
    const month = date.slice(3, 5);
    const year = date.slice(6, 10);

    const newDate = new Date(`${year}-${month}-${day}`);

    return newDate;
  }

  static getFirstDayOfTheMonth() {
    const date = new Date();
    const firstDayOfTheMonth = moment(
      new Date(date.getFullYear(), date.getMonth(), 1),
    ).format('DD/MM/YYYY');

    return firstDayOfTheMonth;
  }

  static getLastDayOfTheMonth() {
    const date = new Date();
    const lastDayOfTheMonth = moment(
      new Date(date.getFullYear(), date.getMonth() + 1, 0),
    ).format('DD/MM/YYYY');

    return lastDayOfTheMonth;
  }
}
