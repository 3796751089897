import { useTheme } from 'styled-components';
import StyledSvgIcon from './svg-icon.styles';
import { useSelector } from 'react-redux';
import { StoreState } from '@/ui/store/models';
import { TableIcons } from '@/domain/models';

export default function LicensedIcon(): JSX.Element {
  const currentTheme = useTheme();
  const activeMenu = useSelector(
    (currentStore: StoreState) => currentStore.navigation.activeMenu,
  );

  return (
    <StyledSvgIcon
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.5 17.5H16.5M4 15V8.33331M7.33333 15V8.33331M10.6667 15V8.33331M14 15V8.33331M15.6667 5.83331L9.35333 1.88748C9.22515 1.80737 9.16106 1.76731 9.09233 1.75169C9.03155 1.73788 8.96845 1.73788 8.90767 1.75169C8.83894 1.76731 8.77485 1.80737 8.64667 1.88748L2.33333 5.83331H15.6667Z"
        strokeWidth="1.75"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke={
          activeMenu === TableIcons.LICENSED
            ? currentTheme.secondary
            : currentTheme.quaternary
        }
      />
    </StyledSvgIcon>
  );
}
