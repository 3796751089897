import {
  ContextType,
  IAutocomplete,
  IUserSaveInputs,
  TableIcons,
} from '@/domain/models';
import { NotificationContext } from '@/main';
import { makeLicensedPresenter, makeLocationPresenter } from '@/main/factories';
import makeUserPresenter from '@/main/factories/presenters/pages/user/user-presenter-factory';
import { store } from '@/ui/store/config';
import { NavigationAppState } from '@/ui/store/models';
import selectNavigationAction from '@/ui/store/modules/app/navigation/actions';
import { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';

export default function useUserSaveHook(userId?: string) {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { errorNotification, successNotification } =
    useContext(NotificationContext);

  const isUpdate = !!userId;

  const userForm = useForm<IUserSaveInputs>({
    mode: 'all',
    defaultValues: {
      birthDate: null,
      telephone: '',
      cpf: '',
      address: {
        zipCode: '',
      },
      profiles: [
        {
          id: 'USER',
          label: 'Usuário',
        },
      ],
      walletBalance: 0,
    },
  });

  const [state] = userForm.watch(['address.state']);

  const licensedPresenter = makeLicensedPresenter();
  const userPresenter = makeUserPresenter();
  const locationPresenter = makeLocationPresenter();

  const rolesOptions = userPresenter.rolesOptions;
  const genderOptions = userPresenter.genderOptions;

  const licensedAutoCompleteData = queryClient.getQueryData<
    Array<IAutocomplete>
  >('licensedAutoComplete');
  const licensedsQuery = useQuery(
    'licensedAutoComplete',
    () => licensedPresenter.getLicensedAutoComplete(),
    {
      initialData: licensedAutoCompleteData,
      enabled: !licensedAutoCompleteData,
      onSuccess: data => {
        queryClient.setQueryData('licensedAutoComplete', data);
      },
    },
  );

  useQuery(
    ['user', userId],
    () => userPresenter.getUserById(userId as string),
    {
      enabled: isUpdate && !licensedsQuery.isLoading,
      onSuccess: data => {
        const nameAndSurname = data.name.split(' ').slice(0, 2).join(' ');

        const franchiseeId = licensedsQuery.data?.find(
          licensed => Number(licensed.id) === Number(data.franchiseeId?.id),
        );

        userForm.reset({ ...data, franchiseeId });
        userForm.trigger('telephone');
        userForm.trigger('cpf');
        userForm.trigger('address.zipCode');
        store.dispatch(
          selectNavigationAction(
            new NavigationAppState(
              ContextType.user_edit,
              ContextType.user,
              TableIcons.USER,
              nameAndSurname,
            ),
          ),
        );
      },
    },
  );

  const statesQuery = useQuery('states', () => locationPresenter.getStates());

  const citiesQuery = useQuery(
    ['cities', state],
    () => locationPresenter.getCities((state as IAutocomplete).id),
    {
      enabled: !!state,
    },
  );

  const { mutateAsync: updateUser } = useMutation(
    (userFormData: IUserSaveInputs) =>
      userPresenter.updateUserById(userId as string, userFormData),
    {
      onSuccess: res => {
        if (!res) {
          return errorNotification('Erro ao atualizar usuário');
        }

        successNotification('Usuário atualizado com sucesso');
        navigate(0);
      },
      onError: () => {
        errorNotification('Erro ao atualizar usuário');
      },
    },
  );

  const { mutateAsync: createUser } = useMutation(
    (userFormData: IUserSaveInputs) => userPresenter.createUser(userFormData),
    {
      onSuccess: res => {
        if (!res) {
          return errorNotification('Erro ao criar usuário');
        }

        successNotification('Usuário criado com sucesso');
        navigate('/user/' + res.id);
      },
      onError: () => {
        errorNotification('Erro ao criar usuário');
      },
    },
  );

  return {
    userForm,
    licensedsQuery,
    statesQuery,
    citiesQuery,
    rolesOptions,
    genderOptions,
    updateUser,
    createUser,
  };
}
