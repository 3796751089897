import { useContext } from 'react';
import { UseFormReturn, useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { NotificationContext } from '@/main';
import { makePromotionListPresenter } from '@/main/factories';
import {
  ContextType,
  IPromotion,
  IPromotionSaveInputs,
  TableIcons,
} from '@/domain/models';
import { FilterContext } from '@/ui/context/context-filter';
import { IUsePromotionQueries } from '@/ui/interfaces';
import { store } from '@/ui/store/config';
import selectNavigationAction from '@/ui/store/modules/app/navigation/actions';
import { NavigationAppState } from '@/ui/store/models';

export default function usePromotionQueries(
  promotionId?: string,
): IUsePromotionQueries {
  const isUpdate = !!promotionId;

  const promotionForm: UseFormReturn<IPromotionSaveInputs> =
    useForm<IPromotionSaveInputs>({
      mode: 'all',
      reValidateMode: 'onChange',
      criteriaMode: 'all',
      defaultValues: {
        licensed: null,
        cooler: [],
        categories: [],
        products: [],
        status: 'ACTIVE',
        dateBegin: null,
        dateEnd: null,
      },
    });

  const { isDirty, isValid } = promotionForm.formState;

  const { successNotification, errorNotification, warningNotification } =
    useContext(NotificationContext);
  const { setShouldReRenderTable } = useContext(FilterContext);
  const navigate = useNavigate();

  const promotionPresenter = makePromotionListPresenter();

  const { isLoading } = useQuery(
    'updatePromotion',
    () => promotionPresenter.getPromotionAsync(promotionId),
    {
      enabled: isUpdate,
      onSuccess: (promotion: IPromotionSaveInputs) => {
        promotionForm.reset(promotion);
        store.dispatch(
          selectNavigationAction(
            new NavigationAppState(
              ContextType.promotion_edit,
              ContextType.promotion_list,
              TableIcons.PROMOTION,
              promotion.name,
            ),
          ),
        );
      },
      onError: (err: string) => {
        warningNotification(err);
        navigate('/promotion');
      },
    },
  );

  const { mutate: createPromotion, isLoading: isCreatingPromotion } =
    useMutation(
      (data: IPromotionSaveInputs) => promotionPresenter.createPromotion(data),
      {
        onSuccess: (res: IPromotion) => {
          successNotification('Promoção criada com sucesso!');
          setShouldReRenderTable(true);
          navigate(`/promotion/${res.id}`, {
            state: {
              from: 'save',
            },
          });
          setTimeout(() => {
            store.dispatch(
              selectNavigationAction(
                new NavigationAppState(
                  ContextType.promotion_edit,
                  ContextType.promotion_list,
                  TableIcons.PROMOTION,
                  res.name,
                ),
              ),
            );
          }, 1000);
        },
        onError: (err: string) => {
          errorNotification(err);
        },
      },
    );

  const { mutate: updatePromotion, isLoading: isUpdatingPromotion } =
    useMutation(
      (data: IPromotionSaveInputs) =>
        promotionPresenter.updatePromotion(data, Number(promotionId)),
      {
        onSuccess: () => {
          successNotification('Promoção atualizada com sucesso!');
          setShouldReRenderTable(true);
          navigate(`/promotion/${promotionId}`);
        },
        onError: (err: string) => {
          errorNotification(err);
        },
      },
    );
  const isSubmitDisabled =
    !isDirty || !isValid || isCreatingPromotion || isUpdatingPromotion;

  return {
    promotionForm,
    isSubmitDisabled,
    isLoading,
    createPromotion,
    updatePromotion,
  };
}
