import { getUnixTime } from 'date-fns';
import { TokenData } from '@/domain/models';
import jwt from 'jwt-decode';

export default class AuthenticationHelper {
  static getToken(): TokenData {
    const tokenString: any = localStorage.getItem('token');
    if (tokenString) {
      return jwt<TokenData>(tokenString);
    } else {
      return {} as TokenData;
    }
  }

  static getTokenToString(): string {
    return localStorage.getItem('token') as string;
  }

  static hasToken(): boolean {
    return localStorage.getItem('token') !== null;
  }

  static saveToken = (token: any): void => {
    localStorage.setItem('token', token);
  };

  static removeToken = (): void => {
    localStorage.clear();
  };

  static isAuthenticated(): boolean {
    if (!this.hasToken()) return false;

    const tokenData = this.getToken();
    const currentDate = new Date();

    return getUnixTime(currentDate) <= tokenData.exp;
  }
}
