import { Box, Tab, Tabs, Typography, styled } from '@mui/material';

interface StyledTabsProps {
  children?: React.ReactNode;
  value: number;
  onChange: (event: React.SyntheticEvent, newValue: number) => void;
  isDarkTheme: boolean;
}

const StyledTabs = styled((props: StyledTabsProps) => {
  return (
    <Tabs
      sx={{
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
      }}
      {...props}
      TabIndicatorProps={{
        children: <span className="MuiTabs-indicatorSpan" />,
      }}
    />
  );
})(({ isDarkTheme }) => ({
  '& .MuiTabs-indicator': {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: isDarkTheme ? 'transparent' : '#936D00',
    left: '-10px',
  },
  '& .MuiTabs-indicatorSpan': {
    maxWidth: 180,
    width: '100%',
    backgroundColor: isDarkTheme ? '#fff' : '#000',
    left: '-10px',
  },
}));

interface StyledTabProps {
  label: string;
  isDarkTheme: boolean;
}

const StyledTab = styled((props: StyledTabProps) => (
  <Tab disableRipple {...props} />
))(({ isDarkTheme }) => ({
  textTransform: 'none',
  fontWeight: 400,
  fontSize: '0.875rem',
  marginRight: 10,
  color: isDarkTheme ? 'rgba(255, 255, 255, 0.7)' : 'fbcc08',
  '&.Mui-selected': {
    color: isDarkTheme ? '#fff' : '#000',
  },
  '&.Mui-focusVisible': {
    backgroundColor: 'rgba(100, 95, 228, 0.32)',
  },
}));

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
  isDarkTheme: boolean;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {Boolean(value === index) && (
        <Box sx={{ p: 1 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export { StyledTabs, StyledTab, TabPanel };
