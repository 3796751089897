import { useTheme } from 'styled-components';
import StyledSvgIcon from './svg-icon.styles';

export default function EditIcon() {
  const currentTheme = useTheme();

  return (
    <StyledSvgIcon
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="edit_icon"
    >
      <path
        d="M14.0007 8.33326L10.6673 4.99993M1.08398 17.9166L3.90429 17.6032C4.24887 17.5649 4.42115 17.5458 4.58219 17.4937C4.72506 17.4474 4.86102 17.3821 4.98639 17.2994C5.1277 17.2062 5.25027 17.0836 5.49542 16.8385L16.5007 5.83326C17.4211 4.91279 17.4211 3.4204 16.5007 2.49993C15.5802 1.57945 14.0878 1.57945 13.1673 2.49992L2.16209 13.5052C1.91694 13.7503 1.79436 13.8729 1.70118 14.0142C1.61851 14.1396 1.55316 14.2755 1.50691 14.4184C1.45478 14.5794 1.43564 14.7517 1.39735 15.0963L1.08398 17.9166Z"
        stroke={currentTheme.tableIconColors}
        strokeWidth="1.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </StyledSvgIcon>
  );
}
