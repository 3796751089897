import styled from 'styled-components';
import { Row } from '@/ui/components/layout';

export const CardHeader = styled(Row)`
  font-weight: 700;
  font-size: 18px;
  gap: 8px;

  &.blue {
    svg {
      stroke: ${({ theme }) => theme.blueIconColor};
    }
  }
  &.yellow {
    svg {
      stroke: ${({ theme }) => theme.yellowIconColor};
    }
  }

  .yellow {
    stroke: ${({ theme }) => theme.yellowIconColor};
  }
`;

export const CardLabel = styled.div`
  font-size: 14px;
  color: ${({ theme }) => theme.table.text};
`;

export const CardValue = styled.div`
  font-size: 28px;

  &.success {
    color: ${({ theme }) => theme.orderStatusLabel.success.textColor};
  }

  &.danger {
    color: ${({ theme }) => theme.orderStatusLabel.canceled.textColor};
  }
`;
