import { StringHelper } from '@/domain/helpers';
import { ContextType } from './context-type';

export const ContextLabelMap = new Map<string, string>([
  [ContextType.none, ''],
  [ContextType.mural_take, 'Mural'],
  [ContextType.home, 'Dashboard'],
  [ContextType.order, 'Pedidos'],
  [ContextType.home_overview, 'Visão Geral'],
  [ContextType.home_rating, 'Avaliações'],
  [ContextType.refrigerator, 'Coolers'],
  // [ContextType.refrigerator_status, 'Status'],
  [ContextType.refrigerator_stock, 'Estoque'],
  [ContextType.refrigerator_list, 'Lista'],
  [ContextType.refrigerator_status_list, 'Status'],
  [ContextType.promotion_list, 'Promoções'],
  [ContextType.user, 'Usuários'],
  [ContextType.financial, 'Financeiro'],
  [ContextType.products, 'Produtos'],
  [ContextType.product_list, 'Produtos'],
  [ContextType.product_mix, 'Mix'],
  [ContextType.product_category, 'Categoria'],
  [ContextType.monitoring, 'Monitoramento'],
  [ContextType.monitoring_opening_attempts, 'Tentaivas de Abertura'],
  [ContextType.uptime_downtime_apis, "Uptime Api's"],
  [ContextType.licensed_list, 'Licenciados'],
  [ContextType.banner_list, 'Banner'],
]);

const pluralize = (contextType: string, suffix = 's') =>
  StringHelper.pluralize({ noun: ContextLabelMap.get(contextType), suffix });

export const ContextLabelPluralizeMap = new Map<string, string>([
  [ContextType.none, pluralize(ContextType.none)],
  [ContextType.home, pluralize(ContextType.home)],
  [ContextType.order, pluralize(ContextType.order)],
  [ContextType.home_overview, pluralize(ContextType.home_overview)],
  [ContextType.home_rating, pluralize(ContextType.home_rating)],
  [ContextType.refrigerator, pluralize(ContextType.refrigerator)],
  // [
  //     ContextType.refrigerator_status,
  //     pluralize(ContextType.refrigerator_status),
  // ],
  [ContextType.refrigerator_stock, pluralize(ContextType.refrigerator_stock)],
  [ContextType.refrigerator_list, pluralize(ContextType.refrigerator_list)],
  [ContextType.promotion_list, pluralize(ContextType.promotion_list)],
  [ContextType.financial, pluralize(ContextType.financial)],
  [ContextType.licensed_list, pluralize(ContextType.licensed_list)],
]);
