import { TableRowProps } from '@/ui/interfaces/props/table';
import { Cell, Row, TableInstance } from 'react-table';
import { BodyCellAction } from '@/ui/components/table/common';
import { TableRowMui, TableCellMui } from './styles';
import { ChangeEvent, useContext } from 'react';
import { FilterContext } from '@/ui/context/context-filter';
import { Checkbox } from '@mui/material';

interface IBodyMapProps {
  builder: TableInstance<any>;
  rowProps: TableRowProps;
  handleAddRow: (row: any) => void;
  handleRemoveRow: (row: any) => void;
  isAllSelected: boolean;
  selectedRows: any[];
}

export default function BodyMap(props: IBodyMapProps): JSX.Element {
  const { builder, rowProps } = props;
  const { rows, prepareRow } = builder;

  const { identityPropertyName } = rowProps;

  const enableRowAction = () => {
    if (rowProps == null || rowProps == undefined) return false;
    if (rowProps.rowActions === null || rowProps.rowActions === undefined)
      return false;

    return true;
  };

  const { setShouldReRenderTable } = useContext(FilterContext);

  const refetchTable = () => {
    setShouldReRenderTable(true);
  };

  const handleOnClick = (event: any, row: Row<any>) => {
    const { rowProps } = props;
    event.stopPropagation();

    if (!enableRowAction()) return;

    const action = rowProps?.rowActions?.find(x => x.enableClickOnRow);
    action?.onClick(event, row.original, refetchTable);
  };

  const enableClickOnRow = () => {
    const { rowProps } = props;

    if (!enableRowAction()) return false;

    return rowProps?.rowActions?.find(x => x.enableClickOnRow) !== undefined;
  };

  const makeRowTitle = (cell: Cell<any, any>) => {
    const cellColumn = cell.column as any;

    if (cellColumn.renderTitleCustom !== undefined) {
      return cellColumn.renderTitleCustom(cell.row?.original);
    }

    return cell?.value;
  };

  const handleCheckboxChange = (
    event: ChangeEvent<HTMLInputElement>,
    row: any,
  ) => {
    if (event.target.checked) {
      props.handleAddRow(row);
    } else {
      props.handleRemoveRow(row);
    }
  };

  return (
    <>
      {rows.map((row, index) => {
        prepareRow(row);
        return (
          <TableRowMui
            {...row.getRowProps()}
            key={`body-rows-${index} ${row.id}`}
            onClick={e => handleOnClick(e, row)}
            data-enable-row-action={enableClickOnRow()}
          >
            {rowProps?.hasRowSelection && (
              <TableCellMui
                padding="checkbox"
                key="body-selection"
                sx={{
                  textOverflow: 'unset !important',
                }}
              >
                <Checkbox
                  sx={{
                    color: !(
                      props.isAllSelected ||
                      props.selectedRows.find(
                        selectedRow =>
                          selectedRow[identityPropertyName as string] ===
                          row.original[identityPropertyName as string],
                      ) !== undefined
                    )
                      ? '#7f7f7f'
                      : '#fbcc0a' + ' !important',
                  }}
                  onChange={e => handleCheckboxChange(e, row.original)}
                  checked={
                    props.isAllSelected ||
                    props.selectedRows.find(
                      selectedRow =>
                        selectedRow[identityPropertyName as string] ===
                        row.original[identityPropertyName as string],
                    ) !== undefined
                  }
                />
              </TableCellMui>
            )}
            {row.cells.map((cell, cellIndex) => {
              return (
                <>
                  <TableCellMui
                    align={(cell.column as any)['align'] ?? 'left'}
                    title={makeRowTitle(cell)}
                    {...cell.getCellProps({
                      key: `table-cell${cellIndex} cell-${makeRowTitle(cell)}`,
                    })}
                    style={{
                      width: cell.column.width ?? '1%',
                      maxWidth: cell.column.maxWidth ?? cell.column.width,
                    }}
                  >
                    {cell.render('Cell')}
                  </TableCellMui>
                  {Boolean(enableRowAction()) && (
                    <BodyCellAction
                      key={`body-cell-action-${cellIndex}`}
                      isLastCell={row.cells.length === cellIndex + 1}
                      actions={rowProps?.rowActions}
                      moreActionsMenu={rowProps?.moreActionsMenu}
                      rowData={cell.row?.original}
                      refetchTable={refetchTable}
                    />
                  )}
                </>
              );
            })}
          </TableRowMui>
        );
      })}
    </>
  );
}
