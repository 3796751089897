import { IRefrigeratorStatusList } from '@/domain/models';
import { ColumnProps } from '@/presentation/presenters/pages/refrigerator/refrigerator-status-presenter';
import { TableCell } from '@mui/material';

export default function StatusTableHead({
  columns,
}: {
  columns: ColumnProps<IRefrigeratorStatusList>[];
}) {
  return columns.map((column, index) => (
    <TableCell
      align="left"
      key={column.key + index}
      style={{
        width: column.width,
      }}
      role="columnheader"
      colSpan={1}
    >
      {column.title}
    </TableCell>
  ));
}
