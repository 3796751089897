import { useEffect } from 'react';
import { SubmitHandler } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { DevTool } from '@hookform/devtools';
import { store } from '@/ui/store/config';
import { ContextType, IPromotionSaveInputs, TableIcons } from '@/domain/models';
import { AuthenticationHelper, PermissionHelper } from '@/domain/helpers';
import { Button, CircularProgress } from '@/ui/components';
import { useBreakpoints } from '@/ui/helpers';
import selectNavigationAction from '@/ui/store/modules/app/navigation/actions';
import { UsePromotionQueries } from '@/ui/hooks';
import {
  PromotionData,
  PromotionDate,
  PromotionDevices,
  PromotionPromocode,
} from './forms';
import { Column, Row } from '@/ui/components/layout';
import PromotionCPF from './forms/promotion-cpf';
import Default from '../default';
import { NavigationAppState } from '@/ui/store/models';

export default function PromotionSave(): JSX.Element {
  const { promotionId } = useParams();
  const isUpdate = !!promotionId;

  const roles = AuthenticationHelper.getToken().auth;
  const permissionHelper = new PermissionHelper(roles);
  const isTakeAndGo =
    permissionHelper.isTakeAndGo() || permissionHelper.isDeveloper();

  const { isXs } = useBreakpoints();

  const {
    promotionForm,
    isSubmitDisabled,
    isLoading,
    createPromotion,
    updatePromotion,
  } = UsePromotionQueries(promotionId);

  const { control, handleSubmit, watch, resetField, setValue } = promotionForm;

  const onSubmit: SubmitHandler<IPromotionSaveInputs> = data => {
    if (isUpdate) return updatePromotion(data);

    return createPromotion(data);
  };

  useEffect(() => {
    !isUpdate &&
      store.dispatch(
        selectNavigationAction(
          new NavigationAppState(
            ContextType.promotion_save,
            ContextType.promotion_list,
            TableIcons.PROMOTION,
          ),
        ),
      );
  }, []);

  const components = [
    PromotionData,
    PromotionDevices,
    PromotionPromocode,
    PromotionCPF,
    PromotionDate,
  ];

  return (
    <Default
      contextType={ContextType.promotion_save}
      isSubmitDisabled={isSubmitDisabled}
    >
      <Column
        gap={2}
        onSubmit={handleSubmit(onSubmit)}
        as="form"
        id="promotion-form"
      >
        {isLoading ? (
          <Row
            margin={{
              top: '50px',
            }}
          >
            <CircularProgress variant="indeterminate" />
          </Row>
        ) : (
          components.map((Component, index) => (
            <Component
              key={index + Component.name}
              control={control}
              watch={watch}
              resetField={resetField}
              setValue={setValue}
              isTakeAndGo={isTakeAndGo}
            />
          ))
        )}
        {isXs && (
          <Button type="submit" text="Atualizar" disabled={isSubmitDisabled} />
        )}
      </Column>
      <DevTool control={control} placement="top-right" />
    </Default>
  );
}
