import { StoreState } from '@/ui/store/models';
import { createContext } from 'react';
import { useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';

interface INotificationContext {
  successNotification: (message: string, time?: number) => void;
  errorNotification: (message: string, time?: number) => void;
  warningNotification: (message: string, time?: number) => void;
  infoNotification: (message: string, time?: number) => void;
}

export const NotificationContext = createContext<INotificationContext>(
  {} as INotificationContext,
);

interface Props {
  children: React.ReactNode | JSX.Element | string;
}

export const NotificationProvider: React.FC<Props> = ({ children }: Props) => {
  const darkTheme = useSelector((state: StoreState) => {
    return state.toggleTheme.darkTheme;
  });

  const successNotification = (message: string, time = 5000) => {
    toast.success(message, {
      position: 'bottom-right',
      hideProgressBar: false,
      draggable: true,
      progress: undefined,
      theme: darkTheme ? 'light' : 'dark',
      autoClose: time,
    });
  };

  const errorNotification = (message: string, time = 5000) => {
    toast.error(message, {
      position: 'bottom-right',
      autoClose: time,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: darkTheme ? 'light' : 'dark',
    });
  };

  const warningNotification = (message: string, time = 5000) => {
    toast.warn(message, {
      position: 'bottom-right',
      autoClose: time,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: darkTheme ? 'light' : 'dark',
    });
  };

  const infoNotification = (message: string, time = 5000) => {
    toast.info(message, {
      position: 'bottom-right',
      autoClose: time,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: darkTheme ? 'light' : 'dark',
    });
  };

  return (
    <NotificationContext.Provider
      value={{
        successNotification,
        errorNotification,
        warningNotification,
        infoNotification,
      }}
    >
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      {children}
    </NotificationContext.Provider>
  );
};
