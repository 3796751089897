import { Column, Row } from '@/ui/components/layout';
import {
  MobileCardSection,
  MobileCardSubtitle,
  MobileCardText,
  MobileValueTitle,
} from '../../styles';

interface IMobileCardSection {
  cardSubtitle: string;
  value?: number;
  grossValue?: number;
  netValue?: number;
  textDescription?: string;
  className?: string;
  onClick?: () => void;
}

export default function MobileSection({
  cardSubtitle,
  value,
  grossValue,
  netValue,
  textDescription,
  className,
  onClick,
}: IMobileCardSection): JSX.Element {
  return (
    <MobileCardSection className={className && className} onClick={onClick}>
      {Boolean(grossValue) && (
        <MobileCardSubtitle>{cardSubtitle}</MobileCardSubtitle>
      )}
      {grossValue ? (
        <Row justify="space-between">
          <Column>
            <MobileValueTitle>Valor bruto</MobileValueTitle>
            <MobileCardSubtitle>
              {Intl.NumberFormat('pt-br', {
                style: 'currency',
                currency: 'BRL',
              }).format(+grossValue!)}
            </MobileCardSubtitle>
          </Column>
          <Column>
            <MobileValueTitle>Valor líquido</MobileValueTitle>
            <MobileCardSubtitle>
              {Intl.NumberFormat('pt-br', {
                style: 'currency',
                currency: 'BRL',
              }).format(+netValue!)}
            </MobileCardSubtitle>
          </Column>
        </Row>
      ) : (
        <Row justify="space-between">
          <MobileCardSubtitle>{cardSubtitle}</MobileCardSubtitle>
          <MobileCardSubtitle>
            {Intl.NumberFormat('pt-br', {
              style: 'currency',
              currency: 'BRL',
            }).format(+value!)}
          </MobileCardSubtitle>
        </Row>
      )}
      {Boolean(textDescription) && (
        <MobileCardText>{textDescription}</MobileCardText>
      )}
    </MobileCardSection>
  );
}
