import { useContext, useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { parsePhoneNumber } from 'react-phone-number-input';
import { DocumentHelper } from '@/domain/helpers';
import { ILicensedFilter, LicensedFilterParams } from '@/presentation';
import { IFilterDrawer } from '@/ui/interfaces';
import {
  Filter,
  InputPhoneNumber,
  InputTextField,
  MaskedInputTextField,
} from '@/ui/components';
import { FilterContext } from '@/ui/context/context-filter';

export default function LicensedFilter({ filter, setFilter }: IFilterDrawer) {
  const { getFilters, resetFilter, setResetFilter, updateFilters } =
    useContext(FilterContext);
  const filtersPage = getFilters();

  const formatPhone = () => {
    if (!filtersPage?.licensedPhone?.id) return '';

    if (
      filtersPage?.licensedPhone &&
      !parsePhoneNumber(filtersPage?.licensedPhone.id, 'BR')?.isValid()
    ) {
      return filtersPage?.licensedPhone.id;
    }

    if (
      filtersPage?.licensedPhone?.id &&
      parsePhoneNumber(filtersPage?.licensedPhone.id, 'BR')?.isValid()
    ) {
      return parsePhoneNumber(
        filtersPage?.licensedPhone.id,
        'BR',
      )?.formatNational();
    }

    return null;
  };

  const formatCnpj = () => {
    if (!filtersPage?.licensedCnpj?.id) return '';
    if (filtersPage?.licensedCnpj?.id) {
      return DocumentHelper.makeMaskCnpj(filtersPage?.licensedCnpj.id);
    }
    return '';
  };

  const setDefaultLicensedName = () => {
    if (!filtersPage?.licensedName?.id) return '';
    return filtersPage?.licensedName?.id;
  };

  const resetValues = {
    licensedName: setDefaultLicensedName(),
    licensedCnpj: formatCnpj(),
    licensedPhone: formatPhone(),
  };

  const { handleSubmit, control, reset, watch } = useForm<ILicensedFilter>({
    defaultValues: resetValues,
  });

  const [licensedCnpj] = watch(['licensedCnpj']);

  const onSubmit = (data: ILicensedFilter) => {
    const licensedPhone =
      data.licensedPhone &&
      String(parsePhoneNumber(data.licensedPhone, 'BR')?.nationalNumber);
    const licensedCnpj = data.licensedCnpj;

    const licensedFilterParams = new LicensedFilterParams(
      data.licensedName ? data.licensedName : null,
      licensedCnpj ? licensedCnpj.replace(/[^\d]+/g, '') : null,
      data.licensedCnpj ? DocumentHelper.makeMaskCnpj(data.licensedCnpj) : null,
      licensedPhone ? licensedPhone : null,
      data.licensedPhone,
    );

    updateFilters({ ...filtersPage, ...licensedFilterParams }, true);
    setFilter({ right: false });
    reset();
  };

  const submitHandler: SubmitHandler<ILicensedFilter> = data => onSubmit(data);

  useEffect(() => {
    reset(resetValues);
  }, [filtersPage]);

  useEffect(() => {
    if (resetFilter) {
      reset(resetValues);
      setResetFilter(false);
    }
  }, [resetFilter]);

  return (
    <Filter
      filter={filter}
      setFilter={setFilter}
      handleSubmit={handleSubmit as any}
      onSubmit={submitHandler}
    >
      <MaskedInputTextField
        control={control}
        name="licensedCnpj"
        label="CNPJ"
        mask="99.999.999/9999-99"
        shrink={!!licensedCnpj}
      />
      <InputPhoneNumber
        control={control}
        name="licensedPhone"
        label="Telefone"
      />
      <InputTextField
        control={control}
        name="licensedName"
        label="Licenciado"
      />
    </Filter>
  );
}
