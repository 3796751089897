import styled from 'styled-components';
import { NavLink as NavLinkBase } from 'react-router-dom';

export const NavLink = styled(NavLinkBase)`
  display: flex;
  align-items: center;
  border-radius: 10px;
  transition: background-color 500ms;
  transition-timing-function: ease-in-out;
  padding: 10px 0 10px 3px;
  text-decoration: none;
  margin: 0 16px;
  background-color: ${props => `${props.theme.primary}`};

  &.active {
    background-color: ${props => `${props.theme.pageTitleHover}`};
    stroke: ${props => `${props.theme.secondary}!important`};

    .MuiSvgIcon-colorPrimary {
      transition: background-color 500ms;
      background-color: ${props => `${props.theme.pageTitleHover}`};
      color: ${props => `${props.theme.pageTitleHover}`};
      stroke: ${props => `${props.theme.secondary}`};
    }

    .MuiSvgIcon-colorSecondary {
      background-color: ${props => `${props.theme.pageTitleHover}`};
      color: ${props => `${props.theme.pageTitleHover}`};
    }

    && {
      .MuyTypography-root {
        font-size: 14px !important;
      }
    }

    span {
      color: ${props => `${props.theme.secondary}`};
      transition: 400ms;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &:hover {
    background-color: ${props => `${props.theme.pageTitleHover}`};
    stroke: ${props => `${props.theme.secondary}!important`};

    .MuiSvgIcon-colorPrimary {
      transition: background-color 500ms;
      background-color: ${props => `${props.theme.pageTitleHover}`};
      color: ${props => `${props.theme.pageTitleHover}`};
      stroke: ${props => `${props.theme.secondary}`};
    }

    .MuiSvgIcon-colorSecondary {
      background-color: ${props => `${props.theme.pageTitleHover}`};
      color: ${props => `${props.theme.pageTitleHover}`};
    }

    && {
      .MuyTypography-root {
        font-size: 14px !important;
      }
    }

    span {
      color: ${props => `${props.theme.secondary}`};
      transition: 400ms;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  @media (max-width: 650px) {
    border-top: 1px solid;
    border-color: ${props => `${props.theme.lines}`};
    border-radius: 0px;
    align-items: start;
    justify-content: center;
    padding: 16px 20px;
    margin: 0px;
  }
`;

export const ExpandedArrowIcon = styled.button`
  background: none;
  border: none;
`;
