import { maxwidth } from './../types/sizes';
import styled from 'styled-components';
import {
  align,
  basis,
  direction,
  grow,
  justify,
  margin,
  padding,
  shrink,
  wrap,
  width,
  maxHeight,
  overflow,
  height,
} from '../types';
import getBaseValue from '../base-values';

interface IFlexProps {
  justify?: justify;
  align?: align;
  basis?: basis;
  grow?: grow;
  shrink?: shrink;
  wrap?: wrap;
  direction?: direction;
  gap?: string | number;
  margin?:
    | {
        top?: margin;
        right?: margin;
        bottom?: margin;
        left?: margin;
      }
    | margin;
  padding?:
    | {
        top?: padding;
        right?: padding;
        bottom?: padding;
        left?: padding;
      }
    | padding;
  color?: string;
  width?: width;
  height?: height;
  maxHeight?: maxHeight;
  maxwidth?: maxwidth;
  overflow?:
    | {
        x?: overflow;
        y?: overflow;
      }
    | overflow;
  fontSize?: number | string;
}

const Flex = styled.div<IFlexProps>`
  display: flex;
  justify-content: ${props => props.justify};
  align-items: ${props => props.align};
  flex-basis: ${props => props.basis};
  flex-grow: ${props => props.grow};
  flex-shrink: ${props => props.shrink};
  flex-wrap: ${props => props.wrap};
  flex-direction: ${props => props.direction};
  gap: ${props => getBaseValue(props.gap)};
  ${({ margin }) =>
    typeof margin === 'object'
      ? Object.entries(margin)
          .map(([key, value]) => `margin-${key}: ${getBaseValue(value)};`)
          .join('\n')
      : `margin: ${getBaseValue(margin)};`}
  ${({ padding }) =>
    typeof padding === 'object'
      ? Object.entries(padding)
          .map(([key, value]) => `padding-${key}: ${getBaseValue(value)};`)
          .join('\n')
      : `padding: ${getBaseValue(padding)};`}
    color: ${props => props.color};
  width: ${props => (props.width ? getBaseValue(props.width) : 'auto')};
  height: ${props => (props.height ? getBaseValue(props.height) : 'auto')};
  max-height: ${props =>
    props.maxHeight ? getBaseValue(props.maxHeight) : 'auto'};
  max-width: ${props =>
    props.maxwidth ? getBaseValue(props.maxwidth) : 'auto'};
  ${({ overflow }) =>
    typeof overflow === 'object'
      ? Object.entries(overflow)
          .map(([key, value]) => `overflow-${key}: ${value};`)
          .join('\n')
      : `overflow: ${overflow};`}
  font-size: ${props =>
    props.fontSize ? getBaseValue(props.fontSize) : '16px'};
`;

const Row = styled(Flex)`
  flex-direction: row;
`;

const Column = styled(Flex)`
  flex-direction: column;
`;

export { Row, Column };
