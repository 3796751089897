import StyledSvgIcon from './svg-icon.styles';

export default function WifiIcon(): JSX.Element {
  return (
    <StyledSvgIcon
      width="800px"
      height="800px"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.99992 16.75H10.0083M19.0053 7.75063C16.6329 5.57666 13.4713 4.25 9.99982 4.25C6.52837 4.25 3.36678 5.57666 0.994385 7.75063M3.9432 10.7025C5.5583 9.27971 7.67831 8.41667 9.9999 8.41667C12.3215 8.41667 14.4415 9.27971 16.0566 10.7025M13.0819 13.6459C12.2326 12.9802 11.1626 12.5833 9.99982 12.5833C8.81949 12.5833 7.73469 12.9923 6.87939 13.6763"
        strokeWidth="1.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </StyledSvgIcon>
  );
}
