import StyledSvgIcon from './svg-icon.styles';

export default function DownloadIcon() {
  return (
    <StyledSvgIcon
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13 13H1M11 6.33333L7 10.3333M7 10.3333L3 6.33333M7 10.3333V1"
        stroke="#0C0407"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </StyledSvgIcon>
  );
}
