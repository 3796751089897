import { IImage, IProductCreate } from '@/domain/models';

export default class ProductCreate implements IProductCreate {
  categoryId: number;
  code: string;
  costPrice: number;
  description: string;
  general: boolean;
  image: IImage;
  information: string;
  name: string;
  ncm: string;
  providerId: number;
  suggestedValue: number;
  productMixId: string[];

  constructor(
    categoryId: number,
    code: string,
    costPrice: number,
    description: string,
    general: boolean,
    image: IImage,
    information: string,
    name: string,
    ncm: string,
    providerId: number,
    suggestedValue: number,
    productMixId: string[],
  ) {
    this.categoryId = categoryId;
    this.code = code;
    this.costPrice = costPrice;
    this.description = description;
    this.general = general;
    this.image = image;
    this.information = information;
    this.name = name;
    this.ncm = ncm;
    this.providerId = providerId;
    this.suggestedValue = suggestedValue;
    this.productMixId = productMixId;
  }
}
