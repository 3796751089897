import {
  HttpResponse,
  IContestationCalculate,
  IContestationOrderProduct,
  IOrderEdit,
} from '@/domain/models';
import IContestationEvaluation from '@/domain/models/pages/orders/edit/contestation-calculate/contestation-evaluation';
import { IOrderEditRepository } from '@/infra';
import { IOrderEditPresenter } from '@/ui/pages';

export default class OrderEditPresenter implements IOrderEditPresenter {
  constructor(private readonly orderEditRepository: IOrderEditRepository) {}
  initialOrderDataState: IOrderEdit = {
    card: {
      number: '',
      holderName: '',
    },
    contestation: {
      reason: '',
      medias: [''],
    },
    paymentSuccessDate: '',
    contestationEvaluation: '',
    medias: ['', ''],
    openDoorTime: '',
    orderDate: '',
    paymentDetails: {
      discount: 0,
      total: 0,
      valuePaidCreditCard: 0,
      valuePaidPix: 0,
      valuePaidWallet: 0,
    },
    products: [
      {
        name: '',
        orderPaymentProductId: 0,
        productId: 0,
        quantity: 0,
        totalValue: 0,
        unitaryValue: 0,
      },
    ],
    promotion: {
      conditions: '',
      name: '',
    },
    licensed: '',
    cooler: '',
  };

  async getOrderPaymentContestationAsync(
    orderPaymentId: number,
  ): Promise<IOrderEdit> {
    const response =
      await this.orderEditRepository.getOrderPaymentContestationAsync<
        HttpResponse<IOrderEdit>
      >(orderPaymentId);

    return response.data;
  }

  async declineOrderPaymentContestationAsync(
    body: any,
    orderId: any,
  ): Promise<IContestationCalculate> {
    const response =
      await this.orderEditRepository.declineOrderPaymentContestationAsync(
        body,
        orderId,
      );

    return response.data;
  }

  async updateOrderPaymentContestationAsync(
    orderPaymentId: string | number,
    body: IOrderEdit,
    evaluationData: IContestationEvaluation,
  ): Promise<IContestationCalculate> {
    const removeRemovedProducts = body.products.filter(
      product => !product.removed,
    );

    const orderUpdated = {
      contestationEvaluation: {
        additionalInfos: evaluationData.contestationObservation,
        rootCause: evaluationData.contestationRootCause.id,
      },
      orderId: +orderPaymentId,
      paymentUpdate: {
        newDiscount: body.paymentDetails.discount,
        newTotal: body.paymentDetails.total,
        totalToPay: body.paymentDetails.valueToPay,
        totalToReceive: body.paymentDetails.valueToReceive,
        valuesByPaymentMethod: body.valuesByPaymentMethod!,
      },
      products: removeRemovedProducts,
      promotion: body.promotion,
    };

    const response =
      await this.orderEditRepository.updateOrderPaymentContestationAsync<
        HttpResponse<IContestationCalculate>
      >(orderUpdated);

    return response.data;
  }

  async getOrderPaymentContestationCalculateAsync(
    orderPaymentId: string | number,
    body: IContestationOrderProduct[],
  ): Promise<IContestationCalculate> {
    const response =
      await this.orderEditRepository.getOrderPaymentContestationCalculateAsync({
        orderId: orderPaymentId,
        products: body,
      });

    return response.data;
  }

  async getProductUnitaryValueAtOrderDateAsync(
    orderPaymentId: string | number,
    productId: any,
  ): Promise<any> {
    const response =
      await this.orderEditRepository.getProductUnitaryValueAtOrderDate(
        orderPaymentId,
        productId,
      );

    return response;
  }
}
