import { Button, Modal } from '@/ui/components';

export default function ConfirmTabChangeModal({
  isOpen,
  onClose,
  confirmChange,
}: {
  isOpen: boolean;
  onClose: () => void;
  confirmChange: () => void;
}) {
  return (
    <Modal
      title="Mudanças não salvas"
      isOpen={isOpen}
      onClose={onClose}
      cancelButton={
        <Button className="base" text="Retornar" onClick={onClose} />
      }
      okButton={<Button text="Continuar sem salvar" onClick={confirmChange} />}
      content={<div>Tem certeza que deseja sair sem salvar as mudanças?</div>}
    />
  );
}
