import { IBannerFilters } from '@/domain/models';

const bannerDefaultValues: IBannerFilters = {
  dateBegin: null,
  dateEnd: null,
  correlationType: null,
  page: {
    id: '0',
    name: 'page',
    label: '0',
  },
  limit: {
    id: 25,
    name: 'limit',
    label: '25',
  },
  column: {
    id: 'id',
    name: 'column',
    label: 'id',
  },
  sort: {
    id: 'DESC',
    name: 'sort',
    label: 'DESC',
  },
};

export default bannerDefaultValues;
