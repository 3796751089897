/* eslint-disable react/prop-types */
import { Controller } from 'react-hook-form';
import { Checkbox, FormControlLabel } from '@mui/material';
import { checkboxStyles } from './styles';

interface ICheckboxInput {
  name: string;
  label: string;
  control: any;
  defaultValue?: any;
  readOnly?: boolean;
}

export default function CheckboxInput({
  name,
  label,
  control,
  defaultValue,
  readOnly = false,
}: ICheckboxInput) {
  const styles = checkboxStyles(readOnly);

  return (
    <FormControlLabel
      sx={styles}
      label={label}
      control={
        <Controller
          control={control}
          defaultValue={defaultValue}
          name={name}
          render={({ field: props }) => (
            <Checkbox
              readOnly={readOnly}
              inputProps={{
                readOnly: true,
              }}
              checked={props.value}
              {...props}
              sx={styles}
              onChange={e =>
                !readOnly ? props.onChange(e.target.checked) : null
              }
            />
          )}
        />
      }
    />
  );
}
