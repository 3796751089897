import styled from 'styled-components';

export const MobileSubtitle = styled.h2`
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 140%;
  color: ${props => props.theme.secondary};
`;

export const MobileValue = styled.h2`
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  line-height: 140%;
  color: ${props => props.theme.secondary};
  margin: 1px;
  text-align: start;
`;
