import { AuthenticationHelper, PermissionHelper } from '@/domain/helpers';
import {
  CloseButton,
  CloseIcon,
  FilterCheckbox,
  FilterContent,
  FilterSidebarHeader,
  StyledDivider,
} from '@/ui/components';
import { Grid, Row } from '@/ui/components/layout';
import { IFilterDrawer } from '@/ui/interfaces';
import { store } from '@/ui/store/config';
import { RefrigeratorStatusColumnsAppState } from '@/ui/store/models';
import { refrigeratorStatusColumnsAction } from '@/ui/store/modules';
import { Box, SwipeableDrawer } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTheme } from 'styled-components';

type Anchor = 'right';

export default function ColumnFilter({ filter, setFilter }: IFilterDrawer) {
  const currentTheme = useTheme();
  const userRoles = AuthenticationHelper.hasToken()
    ? AuthenticationHelper.getToken().auth
    : '';
  const permissionHelper = new PermissionHelper(userRoles);

  const isAllowed =
    permissionHelper.isTakeAndGo() || permissionHelper.isDeveloper();

  const [activeColumns, setActiveColumns] = useState<any>({
    locality: true,
    serial: true,
    active: true,
    upTimePercentage: isAllowed,
    cameraProblem: true,
    lockProblem: true,
    temperature: true,
    date: true,
    version: true,
  });

  const saveColumns = () => {
    const newAppState = new RefrigeratorStatusColumnsAppState(activeColumns);
    store.dispatch(refrigeratorStatusColumnsAction(newAppState));
  };

  useEffect(() => {
    saveColumns();
  }, []);

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event &&
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return;
      }

      saveColumns();
      setFilter({ ...filter, [anchor]: open });
    };

  const columnsList = [
    {
      dataIndex: 'locality',
      filterText: 'Cooler',
    },
    {
      dataIndex: 'serial',
      filterText: 'Código',
    },
    {
      dataIndex: 'active',
      filterText: 'Status',
    },
    {
      dataIndex: 'upTimePercentage',
      filterText: 'Uptime',
    },
    {
      dataIndex: 'cameraProblem',
      filterText: 'Câmera',
    },
    {
      dataIndex: 'lockProblem',
      filterText: 'Porta',
    },
    {
      dataIndex: 'temperature',
      filterText: 'Temperatura',
    },
    {
      dataIndex: 'date',
      filterText: 'Última vez',
      secondText: 'disponível',
    },
    {
      dataIndex: 'version',
      filterText: 'Versão',
    },
  ];

  if (!isAllowed) {
    columnsList.splice(3, 1);
  }

  const list = (anchor: Anchor) => (
    <Box
      sx={{
        width: 360,
        backgroundColor: currentTheme.bodyBg,
        color: currentTheme.secondary,
        flex: 1,
      }}
      role="filter"
    >
      <FilterSidebarHeader>
        Colunas
        <Row align="center" justify="center">
          <CloseButton onClick={toggleDrawer(anchor, false)}>
            <CloseIcon />
          </CloseButton>
        </Row>
      </FilterSidebarHeader>
      <StyledDivider />
      <FilterContent>
        Exibir
        <Grid
          columns={2}
          gap={{
            column: 1,
          }}
          margin={{ top: 4 }}
        >
          {columnsList.map((column, index) => {
            return (
              <FilterCheckbox
                key={column.dataIndex + index}
                setStateMethod={setActiveColumns}
                toggleState={{
                  ...activeColumns,
                  [column.dataIndex]: !activeColumns[column.dataIndex],
                }}
                currentFilter={activeColumns[column.dataIndex]}
                filterText={column.filterText}
                secondText={column.secondText}
              />
            );
          })}
        </Grid>
      </FilterContent>
    </Box>
  );

  return (
    <div>
      {(['right'] as Anchor[]).map((anchor, index) => (
        <>
          <SwipeableDrawer
            key={anchor + index}
            anchor={anchor}
            open={filter[anchor]}
            onClose={toggleDrawer(anchor, false)}
            onOpen={toggleDrawer(anchor, true)}
          >
            {list(anchor)}
          </SwipeableDrawer>
        </>
      ))}
    </div>
  );
}
