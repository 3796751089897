import { IconButton } from '@mui/material';
import styled from 'styled-components';

export const Container = styled(IconButton)`
  &.MuiIconButton-colorPrimary {
    color: ${props =>
      props.color === 'primary' ? props.theme.tertiary : props.theme.secondary};
    fill: ${props =>
      props.color === 'primary' ? props.theme.tertiary : props.theme.secondary};
  }

  &.MuiIconButton-colorSecondary {
    color: white;
    fill: white;
  }

  .MuiSvgIcon-root {
    transition: 400ms;
  }

  &.table {
    .MuiSvgIcon-root {
      fill: ${props => `${props.theme.tableIconColors} !important`};
    }
  }

  &.info-yellow {
    svg {
      stroke: none !important;
      fill: ${props => props.theme.yellowIconColor};
    }
  }
`;
