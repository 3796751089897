import { action } from 'typesafe-actions';
import { Types } from './types';
import { RefrigeratorStatusColumnsAppState } from '@/ui/store/models';

export default function refrigeratorStatusColumnsAction(
  data: RefrigeratorStatusColumnsAppState,
) {
  return action(Types.REFRIGERATOR_STATUS_COLUMNS, {
    data,
  });
}
