import { DefaultTheme } from 'styled-components';

export default class LightStyleGuide {
  static lightTheme(): DefaultTheme {
    return {
      fontFamily: 'Roboto',
      primary: '#FFFFFF',
      secondary: '#000000',
      tertiary: '#FFCD00',
      quaternary: '#757575',
      pageTitleHover: '#E8E8E8',
      lines: 'rgba(0, 0, 0, 0.1)',
      cardBg: '#FFFFFF',
      bodyBg: '#f9f9f9',
      yellowIconBg: '#FFF9CC',
      blueIconBg: '#99FFF8',
      chartLegend: '#757575',
      yellowIconColor: '#936D00',
      blueIconColor: '#005993',
      tableText: '#000',
      searchBorder: '#d4d4d4',
      columnFilter: '#FFCD00',
      tableIconColors: '#BABABA',
      tableBorder: '#d4d4d4',
      formTextColor: '#d1a902',
      tableNthChild: '#ebebeb',
      cardBoxShadow: '0px 4px 4px rgba(0, 0, 0, 0.1)',
      fileBg: '#FFF',
      dragDrop: '#F3F3F3',
      chartLine: '#EFEFEF',
      removedProduct: '#F9F9F9',
      productDetailHead: '#F3F3F3',
      filterButtonHover: {
        font: '#fff',
        background: '#f2f2f2',
      },
      exportBox: {
        fontColor: '#7f7f7f',
        exportBoxBg: '#FFFFFF',
        fontColorHover: '#000000',
        optionBgHover: '#f2f2f2',
      },
      financialReport: {
        financialTotal: '#FFCD00',
        financialToReceive: '#00D5FF',
        condominiumTransfer: '#d4d4d4',
      },
      orderStatusLabel: {
        success: {
          textColor: '#36b37e',
          borderColor: '#36b37e',
        },
        problems: {
          textColor: '#ff5630',
          borderColor: '#ff5630',
        },
        processing: {
          textColor: '#A3A3A3',
          borderColor: '#A3A3A3',
        },
        canceled: {
          textColor: '#ff5630',
          borderColor: '#ff5630',
        },
        reposition: {
          textColor: '#00a6db',
          borderColor: '#00a6db',
        },
        contestation: {
          textColor: '#ffab00',
          borderColor: '#ffab00',
        },
        contestationApproved: {
          textColor: '#36b37e',
          borderColor: '#36b37e',
        },
        contestationDenied: {
          textColor: '#ff5630',
          borderColor: '#ff5630',
        },
        manualBilling: {
          textColor: '#a64ffd',
          borderColor: '#a64ffd',
        },
        bankDispute: {
          textColor: '#9ab336',
          borderColor: '#9ab336',
        },
        refunded: {
          textColor: '#36b37e',
          borderColor: '#36b37e',
        },
      },
      loading: {
        primaryColor: '#fff',
        secondaryColor: '#e8e8e8',
      },
      notification: {
        dark: '#424242',
        error: '#F77066',
        warning: '#FFA622',
        info: '#41A5F5',
        success: '#62BB66',
      },
      table: {
        text: '#757575',
      },
    };
  }
}
