import { DateHelper, RequestQueryMaker } from '@/domain/helpers';
import { NotificationContext } from '@/main';
import makeOrderListPresenter from '@/main/factories/presenters/pages/order/order-list-presenter-factory';
import { Button, DateTimePickerField, Modal } from '@/ui/components';
import { Grid } from '@/ui/components/layout';
import { store } from '@/ui/store/config';
import { StoreState } from '@/ui/store/models';
import { sidebarOpenAction } from '@/ui/store/modules';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useSelector } from 'react-redux';

interface IDeadbeatReportForm {
  dateBegin: string;
  dateEnd: string;
}

export default function DeadbeatReportModal() {
  const [isOpen, setIsOpen] = useState(false);

  const handleClose = () => {
    store.dispatch(
      sidebarOpenAction({
        sidebarName: '',
        sidebarState: { right: false },
        elementId: '',
        elementData: {},
      }),
    );
    setIsOpen(false);
  };

  const { successNotification, errorNotification } =
    useContext(NotificationContext);

  const modal = useSelector((store: StoreState) => store.sidebar);

  useEffect(() => {
    if (modal.sidebarName === 'order_deadbeat_report' && modal.elementData) {
      setIsOpen(modal.sidebarState.right);
    }
  }, [modal]);

  const {
    control,
    watch,
    handleSubmit,
    formState: { isValid },
  } = useForm<IDeadbeatReportForm>({
    mode: 'all',
    defaultValues: {
      dateBegin: null as any,
      dateEnd: null as any,
    },
  });

  const orderPresenter = makeOrderListPresenter();

  const { mutateAsync } = useMutation(
    (params: string) => orderPresenter.getDeadbeatReport(params),
    {
      onSuccess: data => {
        if (data.status) {
          errorNotification(data.message);
        }

        successNotification(data);
      },
    },
  );

  const onSubmit = (data: IDeadbeatReportForm) => {
    const params =
      'dateBegin=' +
      DateHelper.formatDateHours(data.dateBegin) +
      '&' +
      'dateEnd=' +
      DateHelper.formatDateHours(data.dateEnd);

    mutateAsync(params);
  };

  const [dateBegin, dateEnd] = watch(['dateBegin', 'dateEnd']);

  return (
    <Modal
      title="Relatório de inadimplência"
      isOpen={isOpen}
      onClose={handleClose}
      okButton={
        <Button
          text="Gerar relatório"
          onClick={handleSubmit(onSubmit)}
          disabled={!isValid}
        />
      }
      cancelButton={<></>}
      content={
        <Grid
          columns={2}
          gap={4}
          padding={{
            top: 4,
            bottom: 4,
          }}
        >
          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            adapterLocale="pt-br"
          >
            <DateTimePickerField
              control={control}
              name="dateBegin"
              label="Data de início"
              rules={{
                required: 'Data de início é obrigatória',
                deps: ['dateEnd'],
                validate: {
                  isLessThan: (value: string): boolean | string =>
                    new Date(dateEnd) > new Date(value) ||
                    `Data de início deve ser menor que a data de término`,
                },
              }}
            />
            <DateTimePickerField
              control={control}
              name="dateEnd"
              label="Data de término"
              rules={{
                required: 'Data de término é obrigatória',
                deps: ['dateBegin'],
                validate: {
                  isGreaterThan: (value: string): boolean | string =>
                    new Date(dateBegin) < new Date(value) ||
                    'Data de término deve ser maior que a data de início',
                },
              }}
            />
          </LocalizationProvider>
        </Grid>
      }
    />
  );
}
