export default class UserState {
  id: string;
  name: string;
  email: string;
  sub: string;
  auth: string;
  activated: string;
  exp: number;

  constructor(
    id: string,
    name: string,
    email: string,
    sub: string,
    auth: string,
    activated: string,
    exp: number,
  ) {
    this.name = name;
    this.id = id;
    this.email = email;
    this.sub = sub;
    this.auth = auth;
    this.activated = activated;
    this.exp = exp;
  }
}
