import { useNavigate, useSearchParams } from 'react-router-dom';
import { StringHelper } from '@/domain/helpers';
import {
  CoolerFormat,
  RefrigeratorHttpEndpoint,
  SearchCriteriaModel,
  SearchRequestModel,
  SearchResponseModel,
} from '@/domain/models';
import { ColumnModel } from '@/presentation/models/table';
import { ISearchRepositoryPort } from '@/infra';
import { RowActionProps } from '@/ui/interfaces/props/table';
import { store } from '@/ui/store/config';
import { sidebarOpenAction } from '@/ui/store/modules';
import { EditIcon, StockIcon } from '@/ui/components/illustration/icons';
import { ITableServerSidePresenter } from '@/ui/components/table';
import { StockPercentage, TableColoredLabel } from '@/ui/components';

export default class RefrigeratorTablePresenter
  implements ITableServerSidePresenter
{
  constructor(
    private readonly searchRepository: ISearchRepositoryPort,
    private coolerSituation = CoolerFormat.coolerSituationMap,
    private coolerStatus = CoolerFormat.coolerStatusMap,
  ) {}

  navigate = useNavigate();
  params = useSearchParams();

  getClassColor = (productPercentage: number) => {
    if (productPercentage <= 49) {
      return 'red';
    } else if (productPercentage >= 50 && productPercentage <= 69) {
      return 'yellow';
    } else if (productPercentage >= 69) {
      return 'green';
    } else {
      return '';
    }
  };

  makeColumns(): ColumnModel<any>[] {
    return [
      new ColumnModel<any>('Código', 'code', {}),
      new ColumnModel<any>('Dispositivo', 'device', {}),
      new ColumnModel<any>('Localização', 'location', {}),
      new ColumnModel<any>('Licença', 'licensed', {}),
      new ColumnModel<any>('Endereço', 'address', {}),
      new ColumnModel<any>('Número', 'addressNumber', {}),
      new ColumnModel<any>('Cidade', 'city', {}),
      new ColumnModel<any>('Estoque', 'stockPercentage', {}).withRender(
        (value: number) => {
          return (
            <StockPercentage className={this.getClassColor(value)}>
              {Math.trunc(value)}%
            </StockPercentage>
          );
        },
      ),
      new ColumnModel<any>('Situação', 'situation', {}).withRender(
        (value: string) => {
          return <>{this.coolerSituation.get(value)}</>;
        },
      ),
      new ColumnModel<any>('Status', 'status', {}).withRender(
        (value: string) => {
          return (
            <TableColoredLabel isActive={value}>
              {this.coolerStatus.get(value)}
            </TableColoredLabel>
          );
        },
      ),
    ];
  }

  makeSearchRequestModel(): SearchRequestModel {
    return new SearchRequestModel(
      RefrigeratorHttpEndpoint.REFRIGERATOR_LIST,
      'id',
      {
        isOrderByDesc: true,
        criterias: [
          new SearchCriteriaModel('location', {
            value: '',
          }),
        ],
      },
    );
  }

  makeLabelDisplayedResult(response: SearchResponseModel<any>): string {
    if (response === undefined) return '';
    if (response.page === undefined || response.page.total === 0) return '';

    const textPlural = StringHelper.pluralize({
      count: response.page.numberOfElements,
    });
    return `Exibindo ${response.page.numberOfElements} cooler${textPlural}`;
  }

  async search<T>(searchModel: any): Promise<SearchResponseModel<T>> {
    return await this.searchRepository.getDataAsync<any>(
      searchModel,
      RefrigeratorHttpEndpoint.REFRIGERATOR_LIST,
    );
  }

  makeRowActions(): RowActionProps<any>[] {
    return [
      {
        icon: () => <EditIcon />,
        tooltip: 'Editar',
        enableClickOnRow: false,
        onClick: (_, rowData: any) => {
          this.navigate(`/refrigerator/list/${rowData?.id}`);
        },
      },
      {
        icon: () => <StockIcon />,
        tooltip: 'Estoque',
        enableClickOnRow: false,
        onClick: (_, rowData: any) => {
          store.dispatch(
            sidebarOpenAction({
              sidebarName: 'refrigerator_stock_detail',
              sidebarState: { right: true },
              elementId: rowData?.id,
            }),
          );
        },
      },
    ];
  }
}
