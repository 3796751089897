import { IBannerDTO } from '@/domain/models';
import {
  BannerHttpEndpoint,
  HttpMethod,
  HttpRequest,
  HttpResponse,
} from '@/domain/models/services';
import { IBannerRepositoryPort, IHttpServicePort } from '@/infra/ports';

export class BannerRepositoryAdapter implements IBannerRepositoryPort {
  constructor(private readonly httpServicePort: IHttpServicePort) {}

  async postBanner<T>(bannerDTO: IBannerDTO): Promise<HttpResponse<T>> {
    const request = new HttpRequest({
      url: BannerHttpEndpoint.BANNERS,
      method: HttpMethod.post,
      auth: true,
      body: bannerDTO,
    });

    return await this.httpServicePort.request<T>(request);
  }

  async updateBanner<T>(bannerDTO: IBannerDTO): Promise<HttpResponse<T>> {
    const request = new HttpRequest({
      url: `${BannerHttpEndpoint.BANNERS}/${bannerDTO?.id}`,
      method: HttpMethod.put,
      auth: true,
      body: bannerDTO,
    });

    return await this.httpServicePort.request<T>(request);
  }

  async getBannerById<T>(bannerId: number): Promise<HttpResponse<T>> {
    const request = new HttpRequest({
      url: BannerHttpEndpoint.BANNERS + `/${bannerId}`,
      method: HttpMethod.get,
      auth: true,
    });

    return await this.httpServicePort.request<T>(request);
  }

  async toggleActivateBanner<T>(
    bannerId: number,
    isActive: boolean,
  ): Promise<HttpResponse<T>> {
    const request = new HttpRequest({
      url: `${BannerHttpEndpoint.BANNERS}/${bannerId}/actives/${isActive}`,
      method: HttpMethod.put,
      auth: true,
    });

    return await this.httpServicePort.request<T>(request);
  }
}
