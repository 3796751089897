/* eslint-disable */
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import {
  IBankDisputeDetails,
  ICondominiumTransferDetails,
  ICreditCardDetails,
  IMonthlyPayments,
  INegotiationDetails,
  IPixDetails,
  IPromotionDetails,
  IRefundDetails,
  IWalletDetails,
  RolesType,
} from '@/domain/models';
import contextFinancialDetails from './context-financial-details';
import { StoreState } from '@/ui/store/models';
import { Button, EditIcon, TrashIcon } from '@/ui/components';
import { Row } from '@/ui/components/layout';
import { sidebarOpenAction } from '@/ui/store/modules';
import { store } from '@/ui/store/config';
import { AuthenticationHelper, PermissionHelper } from '@/domain/helpers';

export default function DetailTableBody(): Map<string, JSX.Element> {
  const [sidebarData, setSidebarData] = useState([]);
  const sidebar = useSelector((store: StoreState) => store.sidebar);

  const roles = AuthenticationHelper.getToken().auth;
  const permissionHelper = new PermissionHelper(roles || '');

  useEffect(() => {
    if (sidebar.sidebarName === 'negotiation') {
      setSidebarData(sidebar.elementData);
    }
  }, [sidebar]);

  const contextDetailTableBody = new Map<string, JSX.Element>([
    [
      contextFinancialDetails.bank_dispute,
      <tbody>
        {sidebar.elementData!.length > 0 &&
          sidebar.elementData!.map(
            (financialData: IBankDisputeDetails, index: number) => (
              <tr key={financialData.orderId + index} className="content-row">
                <td className="product-code">{financialData.orderId}</td>
                <td>{financialData.coolerLocation}</td>
                <td>
                  {Intl.NumberFormat('pt-br', {
                    style: 'currency',
                    currency: 'BRL',
                  }).format(+financialData.total)}
                </td>
                <td>{financialData.orderDate}</td>
              </tr>
            ),
          )}
      </tbody>,
    ],
    [
      contextFinancialDetails.condominium_transfer,
      <tbody>
        {sidebar.elementData!.length > 0 &&
          sidebar.elementData!.map(
            (financialData: ICondominiumTransferDetails, index: number) => (
              <tr
                key={financialData.coolerCode + index}
                className="content-row"
              >
                <td className="product-code">{financialData.coolerCode}</td>
                <td>{financialData.coolerLocation}</td>
                <td>
                  {Intl.NumberFormat('pt-br', {
                    style: 'currency',
                    currency: 'BRL',
                  }).format(+financialData.grossRevenue)}
                </td>
                <td>{financialData.referenceMonth}</td>
                <td>
                  {Intl.NumberFormat('pt-br', {
                    style: 'currency',
                    currency: 'BRL',
                  }).format(+financialData.transferValue)}
                </td>
              </tr>
            ),
          )}
      </tbody>,
    ],
    [
      contextFinancialDetails.credit_card,
      <tbody>
        {sidebar.elementData!.length > 0 &&
          sidebar.elementData!.map(
            (financialData: ICreditCardDetails, index: number) => (
              <tr
                key={
                  financialData.coolerCode +
                  financialData.coolerLocation +
                  index
                }
                className="content-row"
              >
                <td className="product-code">{financialData.coolerCode}</td>
                <td>{financialData.coolerLocation}</td>
                <td>
                  {Intl.NumberFormat('pt-br', {
                    style: 'currency',
                    currency: 'BRL',
                  }).format(+financialData.grossAmount)}
                </td>
                <td>
                  {Intl.NumberFormat('pt-br', {
                    style: 'currency',
                    currency: 'BRL',
                  }).format(+financialData.netAmount)}
                </td>
              </tr>
            ),
          )}
      </tbody>,
    ],
    [
      contextFinancialDetails.monthly_payments,
      <tbody>
        {sidebar.elementData!.length > 0 &&
          sidebar.elementData!.map(
            (financialData: IMonthlyPayments, index: number) => {
              return (
                <tr key={financialData.code + index} className="content-row">
                  <td className="product-code">{financialData.code}</td>
                  <td>{financialData.device}</td>

                  <td>{financialData.location}</td>
                </tr>
              );
            },
          )}
      </tbody>,
    ],
    [
      contextFinancialDetails.negotiation,
      <tbody>
        {sidebar.elementData!.length > 0 &&
          sidebar.elementData!.map(
            (financialData: INegotiationDetails, index: number) => (
              <tr
                key={financialData.negotiationDate + index}
                className="content-row"
              >
                <td className="product-code">
                  {financialData.negotiationDate}
                </td>
                <td>{financialData.negotiationDescription}</td>
                <td>
                  {Intl.NumberFormat('pt-br', {
                    style: 'currency',
                    currency: 'BRL',
                  }).format(+financialData.negotiationValue!)}
                </td>
                {permissionHelper.hasAnyRole([RolesType.ADM_FINANCIAL]) && (
                  <td>
                    <Row gap="9.81px">
                      <Button
                        dataTestId="edit-negotiation"
                        type="icon"
                        text=""
                        className="icon"
                        icon={<EditIcon />}
                        onClick={() =>
                          store.dispatch(
                            sidebarOpenAction({
                              sidebarName: 'financial_negotiation_modal_edit',
                              sidebarState: {
                                right: true,
                              },
                              elementId: financialData.id,
                              elementData: {
                                negotiationId: financialData.id,
                              },
                            }),
                          )
                        }
                      />
                      <Button
                        dataTestId="delete-negotiation"
                        type="icon"
                        text=""
                        className="icon"
                        icon={<TrashIcon />}
                        onClick={() =>
                          store.dispatch(
                            sidebarOpenAction({
                              sidebarName: 'financial_delete_negotiation_modal',
                              sidebarState: {
                                right: true,
                              },
                              elementId: financialData.id,
                              elementData: {
                                negotiationId: financialData.id,
                              },
                            }),
                          )
                        }
                      />
                    </Row>
                  </td>
                )}
              </tr>
            ),
          )}
      </tbody>,
    ],
    [
      contextFinancialDetails.negotiation_delete,
      <tbody>
        {sidebar.elementData!.length > 0 &&
          sidebar.elementData!.map(
            (financialData: INegotiationDetails, index: number) => (
              <tr
                key={financialData.negotiationDate + index}
                className="content-row"
              >
                <td className="product-code">
                  {financialData.negotiationDate}
                </td>
                <td>{financialData.negotiationDescription}</td>
                <td>
                  {Intl.NumberFormat('pt-br', {
                    style: 'currency',
                    currency: 'BRL',
                  }).format(+financialData.negotiationValue!)}
                </td>
                {permissionHelper.hasAnyRole([RolesType.ADM_FINANCIAL]) && (
                  <td>
                    <Row gap="9.81px">
                      <Button
                        dataTestId="edit-negotiation"
                        type="icon"
                        text=""
                        className="icon"
                        icon={<EditIcon />}
                        onClick={() =>
                          store.dispatch(
                            sidebarOpenAction({
                              sidebarName: 'financial_negotiation_modal_edit',
                              sidebarState: {
                                right: true,
                              },
                              elementId: financialData.id,
                              elementData: {
                                negotiationId: financialData.id,
                              },
                            }),
                          )
                        }
                      />
                      <Button
                        dataTestId="delete-negotiation"
                        type="icon"
                        text=""
                        className="icon"
                        icon={<TrashIcon />}
                        onClick={() =>
                          store.dispatch(
                            sidebarOpenAction({
                              sidebarName: 'financial_delete_negotiation_modal',
                              sidebarState: {
                                right: true,
                              },
                              elementId: financialData.id,
                              elementData: {
                                negotiationId: financialData.id,
                              },
                            }),
                          )
                        }
                      />
                    </Row>
                  </td>
                )}
              </tr>
            ),
          )}
      </tbody>,
    ],
    [
      contextFinancialDetails.negotiation_edit,
      <tbody>
        {sidebar.elementData!.length > 0 &&
          sidebar.elementData!.map(
            (financialData: INegotiationDetails, index: number) => (
              <tr
                key={financialData.negotiationDate + index}
                className="content-row"
              >
                <td className="product-code">
                  {financialData.negotiationDate}
                </td>
                <td>{financialData.negotiationDescription}</td>
                <td>
                  {Intl.NumberFormat('pt-br', {
                    style: 'currency',
                    currency: 'BRL',
                  }).format(+financialData.negotiationValue!)}
                </td>
                {permissionHelper.hasAnyRole([RolesType.ADM_FINANCIAL]) && (
                  <td>
                    <Row gap="9.81px">
                      <Button
                        dataTestId="edit-negotiation"
                        type="icon"
                        text=""
                        className="icon"
                        icon={<EditIcon />}
                        onClick={() =>
                          store.dispatch(
                            sidebarOpenAction({
                              sidebarName: 'financial_negotiation_modal_edit',
                              sidebarState: {
                                right: true,
                              },
                              elementId: financialData.id,
                              elementData: {
                                negotiationId: financialData.id,
                              },
                            }),
                          )
                        }
                      />
                      <Button
                        dataTestId="delete-negotiation"
                        type="icon"
                        text=""
                        className="icon"
                        icon={<TrashIcon />}
                        onClick={() =>
                          store.dispatch(
                            sidebarOpenAction({
                              sidebarName: 'financial_delete_negotiation_modal',
                              sidebarState: {
                                right: true,
                              },
                              elementId: financialData.id,
                              elementData: {
                                negotiationId: financialData.id,
                              },
                            }),
                          )
                        }
                      />
                    </Row>
                  </td>
                )}
              </tr>
            ),
          )}
      </tbody>,
    ],
    [
      contextFinancialDetails.pix,
      <tbody>
        {sidebar.elementData!.length > 0 &&
          sidebar.elementData!.map(
            (financialData: IPixDetails, index: number) => (
              <tr
                key={
                  financialData.coolerCode +
                  financialData.coolerLocation +
                  index
                }
                className="content-row"
              >
                <td className="product-code">{financialData.coolerCode}</td>
                <td>{financialData.coolerLocation}</td>
                <td>
                  {Intl.NumberFormat('pt-br', {
                    style: 'currency',
                    currency: 'BRL',
                  }).format(+financialData.grossAmount)}
                </td>
                <td>
                  {Intl.NumberFormat('pt-br', {
                    style: 'currency',
                    currency: 'BRL',
                  }).format(+financialData.netAmount)}
                </td>
              </tr>
            ),
          )}
      </tbody>,
    ],
    [
      contextFinancialDetails.promotion_for_clients,
      <tbody>
        {sidebar.elementData!.length > 0 &&
          sidebar.elementData!.map(
            (financialData: IPromotionDetails, index: number) => (
              <tr
                key={financialData.promotionName + index}
                className="content-row"
              >
                <td className="product-code">{financialData.promotionName}</td>
                <td>
                  {Intl.NumberFormat('pt-br', {
                    style: 'currency',
                    currency: 'BRL',
                  }).format(+financialData.grossAmount)}
                </td>
                <td>
                  {Intl.NumberFormat('pt-br', {
                    style: 'currency',
                    currency: 'BRL',
                  }).format(+financialData.netAmount)}
                </td>
              </tr>
            ),
          )}
      </tbody>,
    ],
    [
      contextFinancialDetails.refunded,
      <tbody>
        {sidebar.elementData!.length > 0 &&
          sidebar.elementData!.map(
            (financialData: IRefundDetails, index: number) => (
              <tr
                key={financialData.orderId + financialData.refundDate + index}
                className="content-row"
              >
                <td className="product-code">{financialData.orderId}</td>
                <td>
                  {Intl.NumberFormat('pt-br', {
                    style: 'currency',
                    currency: 'BRL',
                  }).format(+financialData.orderValue)}
                </td>
                <td>{financialData.refundDate}</td>
                <td>
                  {Intl.NumberFormat('pt-br', {
                    style: 'currency',
                    currency: 'BRL',
                  }).format(+financialData.refundedValue)}
                </td>
              </tr>
            ),
          )}
      </tbody>,
    ],
    [
      contextFinancialDetails.wallet,
      <tbody>
        {sidebar.elementData!.length > 0 &&
          sidebar.elementData!.map(
            (financialData: IWalletDetails, index: number) => (
              <tr
                key={
                  financialData.coolerCode +
                  financialData.coolerLocation +
                  financialData.grossAmount +
                  index
                }
                className="content-row"
              >
                <td className="product-code">{financialData.coolerCode}</td>
                <td>{financialData.coolerLocation}</td>
                <td>
                  {Intl.NumberFormat('pt-br', {
                    style: 'currency',
                    currency: 'BRL',
                  }).format(+financialData.grossAmount)}
                </td>
                <td>
                  {Intl.NumberFormat('pt-br', {
                    style: 'currency',
                    currency: 'BRL',
                  }).format(+financialData.netAmount)}
                </td>
              </tr>
            ),
          )}
      </tbody>,
    ],
  ]);

  return contextDetailTableBody;
}
