enum userStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  CHANGING_PASSWORD = 'CHANGING_PASSWORD',
  VALIDATING_DATA = 'VALIDATING_DATA',
  EXCLUDED = 'EXCLUDED',
  PENDING_DELETION = 'PENDING_DELETION',
}

export const userStatusMap = new Map<string, string>([
  [userStatus.ACTIVE, 'Ativo'],
  [userStatus.INACTIVE, 'Inativo'],
  [userStatus.CHANGING_PASSWORD, 'Trocando senha'],
  [userStatus.VALIDATING_DATA, 'Validando dados'],
  [userStatus.EXCLUDED, 'Excluído'],
  [userStatus.PENDING_DELETION, 'Pendente de exclusão'],
]);
