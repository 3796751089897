export default function ChangePasswordDrawing(): JSX.Element {
  return (
    <svg
      width="90%"
      height="90%"
      viewBox="0 0 275 407"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M90.6667 256.2C90.6667 266.829 99.3563 275.5 110 275.5H165C175.644 275.5 184.333 266.829 184.333 256.2V109.8C184.333 99.1713 175.644 90.5 165 90.5H110C99.3563 90.5 90.6667 99.1713 90.6667 109.8V256.2ZM110 1H256.667C266.209 1 274 8.77927 274 18.3V256.2C274 265.721 266.209 273.5 256.667 273.5H201.667C191.023 273.5 182.333 282.171 182.333 292.8V347.7C182.333 357.221 174.543 365 165 365H18.3333C8.79081 365 1 357.221 1 347.7V109.8C1 100.279 8.79082 92.5 18.3333 92.5H73.3333C83.977 92.5 92.6667 83.8287 92.6667 73.2V18.3C92.6667 8.77927 100.457 1 110 1Z"
        stroke="#FFCD00"
        strokeWidth="2"
      />
      <ellipse
        opacity="0.1"
        cx="91.5"
        cy="14.5"
        rx="91.5"
        ry="14.5"
        transform="matrix(-1 0 0 1 257 378)"
        fill="#1A1A1A"
      />
      <g clipPath="url(#clip0_152_1131)">
        <path
          d="M108.574 385.929H102.34L101.619 269.739H109.31L108.574 385.929Z"
          fill="white"
          stroke="black"
          strokeWidth="1.41368"
          strokeLinejoin="round"
        />
        <path
          d="M101.647 274.658H109.281L109.31 269.739H101.619L101.647 274.658Z"
          fill="black"
          stroke="black"
          strokeWidth="1.41368"
          strokeMiterlimit="10"
        />
        <path
          d="M117.89 385.929H111.656L110.921 269.739H118.611L117.89 385.929Z"
          fill="black"
          stroke="black"
          strokeWidth="1.41368"
          strokeMiterlimit="10"
        />
        <path
          d="M227.182 385.929H220.962L220.227 269.739H227.917L227.182 385.929Z"
          fill="white"
          stroke="black"
          strokeWidth="1.41368"
          strokeLinejoin="round"
        />
        <path
          d="M220.255 274.658H227.889L227.917 269.739H220.227L220.255 274.658Z"
          fill="black"
          stroke="black"
          strokeWidth="1.41368"
          strokeMiterlimit="10"
        />
        <path
          d="M217.06 385.929H210.84L210.104 269.739H217.795L217.06 385.929Z"
          fill="black"
          stroke="black"
          strokeWidth="1.41368"
          strokeMiterlimit="10"
        />
        <path
          d="M230.956 261.638H100.403L116.986 236.135H214.374L230.956 261.638Z"
          fill="white"
          stroke="black"
          strokeWidth="1.41368"
          strokeLinejoin="round"
        />
        <path
          d="M231.126 261.636H100.403V270.26H231.126V261.636Z"
          fill="white"
          stroke="black"
          strokeWidth="1.41368"
          strokeLinejoin="round"
        />
        <path
          d="M125.821 141.419H205.129C210.006 141.419 213.964 145.377 213.964 150.255V236.15H116.986V150.255C116.986 145.377 120.944 141.419 125.821 141.419Z"
          fill="white"
          stroke="black"
          strokeWidth="1.41368"
          strokeLinejoin="round"
        />
        <path
          d="M133.243 363.338C133.243 363.338 128.295 371.156 127.065 374.252C125.835 377.334 117.184 385.363 115.12 389.279C113.056 393.195 115.742 396.489 125.411 397.521C135.095 398.553 138.587 391.965 140.848 389.081C143.11 386.197 143.11 382.084 145.796 380.232C148.468 378.38 153.614 373.432 154.038 368.498C154.448 363.55 153.416 360.059 153.416 360.059L133.243 363.352V363.338Z"
          fill="white"
          stroke="black"
          strokeWidth="1.41368"
          strokeMiterlimit="10"
        />
        <path
          d="M154.039 368.482C154.265 365.712 154.039 363.379 153.812 361.881C153.515 363.195 153.233 364.765 153.021 366.631C152.201 373.84 143.549 376.512 141.697 380.626C139.845 384.74 137.173 392.981 129.554 394.621C123.927 395.837 117.537 392.685 114.555 390.946C114.031 394.098 117.17 396.629 125.426 397.505C135.109 398.537 138.601 391.95 140.863 389.066C143.125 386.182 143.125 382.068 145.811 380.216C148.483 378.364 153.629 373.416 154.053 368.482H154.039Z"
          fill="white"
          stroke="black"
          strokeWidth="1.41368"
          strokeMiterlimit="10"
        />
        <path
          d="M201.877 363.338C201.877 363.338 206.825 371.156 208.055 374.252C209.284 377.334 217.936 385.363 220 389.279C222.064 393.195 219.378 396.489 209.709 397.521C200.025 398.553 196.533 391.965 194.271 389.081C192.009 386.197 192.009 382.084 189.323 380.232C186.652 378.38 181.506 373.432 181.082 368.498C180.672 363.55 181.704 360.059 181.704 360.059L201.877 363.352V363.338Z"
          fill="white"
          stroke="black"
          strokeWidth="1.41368"
          strokeMiterlimit="10"
        />
        <path
          d="M181.081 368.482C180.855 365.712 181.081 363.379 181.308 361.881C181.605 363.195 181.887 364.765 182.099 366.631C182.919 373.84 191.571 376.512 193.423 380.626C195.275 384.74 197.947 392.981 205.566 394.621C211.193 395.837 217.583 392.685 220.565 390.946C221.089 394.098 217.95 396.629 209.694 397.505C200.011 398.537 196.519 391.95 194.257 389.066C191.995 386.182 191.995 382.068 189.309 380.216C186.637 378.364 181.491 373.416 181.067 368.482H181.081Z"
          fill="white"
          stroke="black"
          strokeWidth="1.41368"
          strokeMiterlimit="10"
        />
        <path
          d="M233.246 260.179C233.246 253.182 213.95 226.534 213.95 226.534L206.952 210.036L171.327 213.387H163.156L127.532 210.036L120.534 226.534C120.534 226.534 101.237 253.182 101.237 260.179C101.237 267.177 102.071 269.411 102.071 269.411L133.116 354.161L130.599 360.041L131.716 364.523C131.716 364.523 135.632 367.873 143.181 367.873C150.73 367.873 155.211 363.392 155.211 363.392C155.211 363.392 156.046 354.161 156.046 350.81C156.046 347.46 149.896 331.231 149.896 327.88C149.896 324.53 144.029 263.261 144.029 263.261L165.885 247.81H168.613L190.469 263.261C190.469 263.261 184.602 324.516 184.602 327.88C184.602 331.245 178.452 347.46 178.452 350.81C178.452 354.161 179.286 363.392 179.286 363.392C179.286 363.392 183.768 367.873 191.317 367.873C198.866 367.873 202.782 364.523 202.782 364.523L203.898 360.041L201.382 354.161L232.426 269.411C232.426 269.411 233.26 267.177 233.26 260.179H233.246Z"
          fill="white"
          stroke="black"
          strokeWidth="1.4878"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M170.875 244.162C170.875 244.162 182.057 227.382 195.77 222.632"
          stroke="#7D7D7D"
          strokeWidth="1.41368"
          strokeMiterlimit="10"
        />
        <path
          d="M182.905 233.53C182.905 233.53 193.253 225.415 203.319 224.299"
          stroke="#7D7D7D"
          strokeWidth="1.41368"
          strokeMiterlimit="10"
        />
        <path
          d="M147.663 228.214C147.663 228.214 156.894 234.929 161.093 241.927"
          stroke="#7D7D7D"
          strokeWidth="1.41368"
          strokeMiterlimit="10"
        />
        <path
          d="M146.814 104.025L112.688 116.89L119.954 179.261C119.954 179.261 122.471 210.588 124.987 211.705C127.503 212.822 160.23 218.137 166.379 217.855C172.529 217.572 205.255 216.738 208.889 210.857C212.522 204.976 222.587 119.392 222.319 117.159C222.036 114.925 179.527 100.095 179.527 100.095L146.8 104.011L146.814 104.025Z"
          fill="#FFE766"
          stroke="black"
          strokeWidth="1.4878"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M154.095 127.238L164.443 139.552L175.908 127.521C175.908 127.521 183.457 107.376 183.174 105.143C182.891 102.909 177.576 98.9932 177.576 98.9932L158.279 98.4277L144.849 104.577L154.08 127.238H154.095Z"
          fill="white"
          stroke="black"
          strokeWidth="1.41368"
          strokeLinejoin="round"
        />
        <path
          d="M151.296 95.0775L144.029 103.192L154.095 127.253L163.877 117.456L175.908 127.521C175.908 127.521 181.774 113.809 183.174 110.176C184.574 106.542 184.574 101.51 184.574 101.51L177.024 93.678L151.296 95.0775Z"
          fill="white"
          stroke="black"
          strokeWidth="1.41368"
          strokeLinejoin="round"
        />
        <path
          d="M148.497 91.4425L149.896 100.674L163.877 117.454L179.541 100.108L180.375 89.7603L163.043 98.1575L148.497 91.4425Z"
          fill="white"
          stroke="black"
          strokeWidth="1.41368"
          strokeLinejoin="round"
        />
        <path
          d="M140.382 63.7489C140.382 63.7489 137.583 64.3144 137.865 69.0643C138.148 73.8143 145.132 79.4124 145.132 79.4124L142.898 62.3494L140.382 63.7489Z"
          fill="white"
          stroke="black"
          strokeWidth="1.41368"
          strokeLinejoin="round"
        />
        <path
          d="M142.346 60.1137C142.346 60.1137 144.863 88.0904 146.545 90.8753C148.227 93.6744 158.293 104.588 162.774 105.139C165.149 105.436 180.671 94.7912 181.788 90.3098C182.905 85.8285 184.304 77.1626 184.304 77.1626C184.304 77.1626 187.655 77.4454 189.62 69.6136C191.585 61.7819 188.503 60.3823 187.103 60.3823H184.87C184.87 60.3823 184.304 48.9174 183.47 46.6838C182.636 44.4502 145.428 49.7656 142.078 52.5506C138.727 55.3355 142.361 60.0996 142.361 60.0996L142.346 60.1137Z"
          fill="white"
          stroke="black"
          strokeWidth="1.41368"
          strokeLinejoin="round"
        />
        <path
          d="M173.532 76.6101C173.532 77.9248 172.966 78.9851 172.274 78.9851C171.581 78.9851 171.016 77.9248 171.016 76.6101C171.016 75.2954 171.581 74.2351 172.274 74.2351C172.966 74.2351 173.532 75.2954 173.532 76.6101Z"
          fill="black"
        />
        <path
          d="M154.504 75.9206C154.504 77.2353 153.939 78.2956 153.246 78.2956C152.553 78.2956 151.988 77.2353 151.988 75.9206C151.988 74.6059 152.553 73.5457 153.246 73.5457C153.939 73.5457 154.504 74.6059 154.504 75.9206Z"
          fill="black"
        />
        <path
          d="M159.961 84.1616C159.961 84.1616 159.678 86.678 161.36 87.512C163.043 88.3461 166.393 87.512 166.676 84.1616"
          stroke="black"
          strokeWidth="1.41368"
          strokeLinejoin="round"
        />
        <path
          d="M160.795 92.8396H164.711"
          stroke="black"
          strokeWidth="1.41368"
          strokeLinejoin="round"
        />
        <path
          d="M147.379 70.1787H156.879"
          stroke="black"
          strokeWidth="2.82735"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M166.676 69.344C166.676 69.344 172.825 65.7108 176.19 69.344"
          stroke="black"
          strokeWidth="2.82735"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M149.047 78.5752L153.811 79.692"
          stroke="black"
          strokeWidth="1.41368"
          strokeLinejoin="round"
        />
        <path
          d="M176.741 78.0098L171.426 80.5261"
          stroke="black"
          strokeWidth="1.41368"
          strokeLinejoin="round"
        />
        <path
          d="M185.139 36.0544C183.259 32.5202 164.994 24.8722 154.095 31.0217C154.095 31.0217 139.831 36.0544 135.081 47.5193C131.363 56.4961 132.805 60.8361 139.095 61.8964L143.746 75.2132L143.181 71.2973L142.502 62.165C143.421 62.165 144.383 62.1508 145.429 62.066C156.894 61.2319 166.973 58.7156 172.006 56.4679C177.039 54.2342 179.555 51.4352 179.555 51.4352C179.555 51.4352 179.272 57.3161 179.555 59.5497C179.838 61.7833 182.905 64.2996 182.905 64.2996L183.188 72.9654L184.87 72.6827L185.987 62.8859L187.387 58.9701C188.376 60.3555 190.85 46.7418 185.153 36.0402L185.139 36.0544Z"
          fill="black"
          stroke="black"
          strokeWidth="1.41368"
          strokeLinejoin="round"
        />
        <path
          d="M197.169 141.234L208.634 155.498"
          stroke="black"
          strokeWidth="1.41368"
          strokeLinejoin="round"
        />
        <path
          d="M205.553 137.319L210.034 170.328L166.959 176.761L167.242 194.107L174.24 194.672C174.24 194.672 179.273 198.588 185.422 198.588C191.572 198.588 223.181 194.672 228.78 193.272C234.378 191.873 237.728 190.473 238.294 185.158C238.859 179.843 226.829 123.903 225.98 120.27C225.146 116.637 211.434 112.438 211.434 112.438"
          fill="#FFE766"
        />
        <path
          d="M205.553 137.319L210.034 170.328L166.959 176.761L167.242 194.107L174.24 194.672C174.24 194.672 179.273 198.588 185.422 198.588C191.572 198.588 223.181 194.672 228.78 193.272C234.378 191.873 237.728 190.473 238.294 185.158C238.859 179.843 226.829 123.903 225.98 120.27C225.146 116.637 211.434 112.438 211.434 112.438"
          stroke="black"
          strokeWidth="1.4878"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M222.899 173.397C218.149 170.047 210.034 170.315 210.034 170.315C210.034 170.315 217.032 167.234 222.616 169.199"
          stroke="black"
          strokeWidth="1.41368"
          strokeLinejoin="round"
        />
        <path
          d="M174.226 177.029L174.791 193.258"
          stroke="black"
          strokeWidth="1.41368"
          strokeLinejoin="round"
        />
        <path
          d="M166.959 176.743L152.978 175.909L135.915 167.512L132.833 169.194C132.833 169.194 126.401 181.225 127.8 182.059C129.2 182.893 131.151 185.409 132.282 185.409C133.413 185.409 135.915 183.444 135.915 183.444C135.915 183.444 142.63 190.159 146.828 190.159C151.027 190.159 154.377 188.477 154.377 188.477L167.525 190.159L166.959 176.729V176.743Z"
          fill="white"
          stroke="black"
          strokeWidth="1.41368"
          strokeLinejoin="round"
        />
        <path
          d="M135.915 183.457L132.268 180.389L131.434 181.775"
          stroke="black"
          strokeWidth="1.41368"
          strokeLinejoin="round"
        />
        <path
          d="M159.961 175.343H156.045V189.607H159.961V175.343Z"
          fill="black"
          stroke="black"
          strokeWidth="1.41368"
          strokeMiterlimit="10"
        />
        <path
          d="M158.011 178.709C160.173 178.709 161.926 177.709 161.926 176.475C161.926 175.241 160.173 174.241 158.011 174.241C155.848 174.241 154.095 175.241 154.095 176.475C154.095 177.709 155.848 178.709 158.011 178.709Z"
          fill="white"
          stroke="black"
          strokeWidth="1.41368"
          strokeLinejoin="round"
        />
        <path
          d="M112.688 116.894C112.688 116.894 98.707 195.211 98.707 199.975C98.707 204.739 119.121 225.153 119.121 225.153L128.352 216.204L122.202 189.359L112.688 116.908V116.894Z"
          fill="#FFE766"
          stroke="black"
          strokeWidth="1.4878"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M107.104 192.413C107.104 192.413 115.219 187.932 121.085 188.78"
          stroke="black"
          strokeWidth="1.41368"
          strokeLinejoin="round"
        />
        <path
          d="M103.188 191.861C103.188 191.861 110.186 186.263 115.502 186.263"
          stroke="black"
          strokeWidth="1.41368"
          strokeLinejoin="round"
        />
        <path
          d="M114.936 220.954C114.936 220.954 114.653 212.557 127.249 212.289"
          stroke="black"
          strokeWidth="1.41368"
          strokeLinejoin="round"
        />
        <path
          d="M128.352 216.189L140.58 220.232C140.58 220.232 142.347 223.512 143.351 226.537C144.355 229.562 145.881 232.602 145.881 233.351C145.881 234.1 145.627 235.118 144.623 235.118C143.62 235.118 139.322 231.329 139.322 231.329L136.792 230.071C136.792 230.071 140.071 234.609 140.835 234.864C141.598 235.118 144.623 237.14 144.623 237.14C144.623 237.14 151.183 234.114 151.183 236.631C151.183 239.147 141.584 244.717 141.584 244.717C141.584 244.717 133.498 243.204 131.985 242.186C130.472 241.183 119.106 225.138 119.106 225.138L128.338 216.189H128.352Z"
          fill="white"
          stroke="black"
          strokeWidth="1.41368"
          strokeLinejoin="round"
        />
      </g>
      <g clipPath="url(#clip1_152_1131)">
        <path
          d="M105.058 106.054C118.453 92.6588 118.453 70.9413 105.058 57.5463C91.6632 44.1513 69.9456 44.1513 56.5506 57.5463C43.1557 70.9413 43.1557 92.6588 56.5506 106.054C69.9456 119.449 91.6632 119.449 105.058 106.054Z"
          fill="black"
          stroke="black"
          strokeLinejoin="round"
        />
        <path
          d="M104.879 101.918C115.991 88.6188 114.218 68.8294 100.918 57.7173C87.619 46.6051 67.8296 48.3783 56.7175 61.6776C45.6053 74.977 47.3784 94.7665 60.6778 105.879C73.9772 116.991 93.7667 115.218 104.879 101.918Z"
          fill="white"
          stroke="black"
          strokeLinejoin="round"
        />
        <path
          d="M83.7199 81.7999C83.7199 80.1899 82.4099 78.8799 80.7999 78.8799C79.1899 78.8799 77.8799 80.1899 77.8799 81.7999C77.8799 83.4099 79.1899 84.7199 80.7999 84.7199C82.4099 84.7199 83.7199 83.4099 83.7199 81.7999Z"
          fill="black"
          stroke="black"
          strokeLinejoin="round"
        />
        <path
          d="M83.1802 83.97L87.7502 89.74"
          stroke="black"
          strokeLinejoin="round"
        />
        <path
          d="M83.1802 79.6499L96.1702 61.1299"
          stroke="black"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_152_1131">
          <rect
            width="141"
            height="370.326"
            fill="white"
            transform="translate(98 28)"
          />
        </clipPath>
        <clipPath id="clip1_152_1131">
          <rect
            width="69.6"
            height="69.6"
            fill="white"
            transform="translate(46 47)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
