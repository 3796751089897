import { ContextType } from './context-type';

const ContextFormRoutes = new Map<string, string>([
  [ContextType.refrigerator_edit, 'refrigerator_edit'],
  [ContextType.refrigerator_save, 'refrigerator_save'],
  [ContextType.promotion_edit, 'promotion_edit'],
  [ContextType.promotion_save, 'promotion_save'],
  [ContextType.user_save, 'user_save'],
  [ContextType.user_edit, 'user_edit'],
  [ContextType.product_save, 'product_save'],
  [ContextType.product_mix_save, 'product_mix_save'],
  [ContextType.product_category_save, 'product_category_save'],
  [ContextType.product_mix_edit, 'product_mix_edit'],
  [ContextType.product_category_edit, 'product_category_edit'],
  [ContextType.product_edit, 'product_edit'],
  [ContextType.order_edit, 'order_edit'],
  [ContextType.licensed_save, 'licensed_save'],
  [ContextType.licensed_edit, 'licensed_edit'],
  [ContextType.banner_save, 'banner_save'],
  [ContextType.banner_edit, 'banner_edit'],
]);

export default ContextFormRoutes;
