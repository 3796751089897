import { minHeight, maxwidth, minWidth } from './../types/sizes';
import styled from 'styled-components';
import {
  align,
  justify,
  padding,
  margin,
  width,
  height,
  overflow,
  maxHeight,
} from '../types';
import getBaseValue from '../base-values';

interface IGridProps {
  columns?: string | number;
  rows?: string | number;
  responsiveType?: 'input';
  gap?:
    | {
        column?: string | number;
        row?: string | number;
      }
    | string
    | number;
  justify?: justify;
  align?: align;
  padding?:
    | {
        top?: padding;
        right?: padding;
        bottom?: padding;
        left?: padding;
      }
    | padding;
  margin?:
    | {
        top?: margin;
        right?: margin;
        bottom?: margin;
        left?: margin;
      }
    | margin;
  color?: string;
  width?: width;
  maxwidth?: maxwidth;
  minWidth?: minWidth;
  height?: height;
  maxHeight?: maxHeight;
  minHeight?: minHeight;
  overflow?:
    | {
        x?: overflow;
        y?: overflow;
      }
    | overflow;
  sm?: IGridProps;
}

const Grid = styled.div<IGridProps>`
  display: grid;
  grid-template-columns: ${({ columns }) =>
    typeof columns === 'number' ? `repeat(${columns}, 1fr)` : columns};
  grid-template-rows: ${({ rows }) =>
    typeof rows === 'number' ? `repeat(${rows}, 1fr)` : rows};
  justify-content: ${props => props.justify};
  align-items: ${props => props.align};
  ${({ margin }) =>
    typeof margin === 'object'
      ? Object.entries(margin)
          .map(([key, value]) => `margin-${key}: ${getBaseValue(value)};`)
          .join('\n')
      : `margin: ${getBaseValue(margin)};`}
  ${({ padding }) =>
    typeof padding === 'object'
      ? Object.entries(padding)
          .map(([key, value]) => `padding-${key}: ${getBaseValue(value)};`)
          .join('\n')
      : `padding: ${getBaseValue(padding)};`}
    color: ${props => props.color};
  width: ${props => (props.width ? getBaseValue(props.width) : 'auto')};
  max-width: ${props =>
    props.maxwidth ? getBaseValue(props.maxwidth) : 'auto'};
  min-width: ${props =>
    props.minWidth ? getBaseValue(props.minWidth) : 'auto'};
  height: ${props => (props.height ? getBaseValue(props.height) : 'auto')};
  min-height: ${props =>
    props.minHeight ? getBaseValue(props.minHeight) : 'auto'};
  max-height: ${props =>
    props.maxHeight ? getBaseValue(props.maxHeight) : 'auto'};
  ${({ gap }) =>
    typeof gap === 'object'
      ? Object.entries(gap)
          .map(([key, value]) => `grid-${key}-gap: ${getBaseValue(value)};`)
          .join('\n')
      : `grid-gap: ${getBaseValue(gap)};`}
  ${({ overflow }) =>
    typeof overflow === 'object'
      ? Object.entries(overflow)
          .map(([key, value]) => `overflow-${key}: ${value};`)
          .join('\n')
      : `overflow: ${overflow};`}

    @media screen and (max-width: 1023px) and (min-width: 767px) {
    ${({ responsiveType }) =>
      responsiveType === 'input'
        ? 'grid-template-columns: repeat(2, 1fr);'
        : 'grid-template-columns: repeat(1, 1fr);'}
  }

  @media screen and (max-width: 767px) {
    grid-template-columns: repeat(1, 1fr);
  }

  ${({ sm }) =>
    sm &&
    `
        @media screen and (max-width: 767px) {
            grid-template-columns: ${
              typeof sm.columns === 'number'
                ? `repeat(${sm.columns}, 1fr)`
                : sm.columns
            };
            grid-template-rows: ${
              typeof sm.rows === 'number' ? `repeat(${sm.rows}, 1fr)` : sm.rows
            };
            
        }
    `}
`;

export { Grid };
