import { Button as MuiButton, ButtonProps } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme';

interface IButton extends ButtonProps {
  startIcon?: JSX.Element;
  endIcon?: JSX.Element;
  color?: 'primary' | 'secondary' | 'success';
  variant?: 'text' | 'outlined' | 'contained';
  disabled?: boolean;
}

export default function Button({
  startIcon,
  endIcon,
  color = 'primary',
  variant = 'contained',
  disabled,
  ...props
}: IButton): JSX.Element {
  return (
    <ThemeProvider theme={theme}>
      <MuiButton
        color={color}
        variant={variant}
        startIcon={startIcon}
        disabled={disabled}
        endIcon={endIcon}
        {...props}
      />
    </ThemeProvider>
  );
}
