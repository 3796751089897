import { AuthenticationHelper } from '@/domain/helpers';
import axios from 'axios';
export default class TelemetryAxiosHelper {
  static getInstance() {
    const token = AuthenticationHelper.getTokenToString();
    return axios.create({
      baseURL: process.env.REACT_APP_API_TELEMETRY,
      responseType: 'json',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
  }
}
