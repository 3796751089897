import {
  HttpRequest,
  HttpResponse,
  HttpServerError,
} from '@/domain/models/services';
import { AxiosError } from 'axios';
import { AxiosHelper, HttpErrorHelper } from './helpers';
import { IHttpServicePort } from '@/infra/ports';

export default class AxiosHttpServiceAdapter implements IHttpServicePort {
  async request<T>(data: HttpRequest): Promise<HttpResponse<T>> {
    return AxiosHelper.getInstance(data.auth)
      .request({
        url: data.url,
        method: data.method,
        data: data.body,
      })
      .then(response => new HttpResponse(true, response.data))
      .catch((error: AxiosError<HttpServerError | T>) => {
        return new HttpResponse<T>(
          false,
          error?.response?.data as T,
          HttpErrorHelper.normalizeError(
            error as AxiosError<HttpServerError>,
            data.notify,
          ),
          error?.response?.status,
        );
      });
  }
}
