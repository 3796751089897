import { IPieChartData, IPieChartProps } from '@/domain/models/pages/home';

export default class PieChartProps implements IPieChartProps {
  id: number;
  color: string[];
  data: IPieChartData[];

  constructor(id: number, color: string[], data: IPieChartData[]) {
    this.id = id;
    this.color = color;
    this.data = data;
  }
}
