import { IInfoModalProps } from './info-modal-type';

const RefrigeratorStatusIndicators = new Map<string, IInfoModalProps>([
  [
    'Geladeiras',
    {
      title: 'Geladeiras',
      content: (
        <>
          Total de geladeiras com a situação instaladas e com o status de
          ativas.
        </>
      ),
    },
  ],
  [
    'Conexão',
    {
      title: 'Conexão',
      content: (
        <>
          É definido como offline os coolers que não reportaram há 5 minutos ou
          mais.
        </>
      ),
    },
  ],
]);

export default RefrigeratorStatusIndicators;
