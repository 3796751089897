import { useContext } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { IBannerDTO } from '@/domain/models';
import { NotificationContext } from '@/main/notification-provider';
import { makeBannerRepository } from '@/main/factories/repositories/banner-repository-factory';
import { BannerDTO } from '@/presentation/models';

export function useCreateBanner() {
  const bannerRepository = makeBannerRepository();

  const { successNotification, errorNotification } =
    useContext(NotificationContext);

  const navigate = useNavigate();

  return useMutation(
    (bannerDTO: IBannerDTO) => bannerRepository.postBanner(bannerDTO),
    {
      onSuccess: res => {
        if (res.success) {
          successNotification('Banner created successfully!');

          return navigate(`/banner/${res.data.id}`, {
            state: {
              from: 'save',
            },
          });
        }

        return errorNotification('Failed to create banner');
      },
    },
  );
}

export function useUpdateBanner() {
  const { successNotification, errorNotification } =
    useContext(NotificationContext);
  const bannerRepository = makeBannerRepository();
  const queryClient = useQueryClient();

  const { bannerId } = useParams();

  return useMutation(
    (bannerDTO: IBannerDTO) => bannerRepository.updateBanner(bannerDTO),
    {
      onSuccess: res => {
        if (res.success) {
          successNotification('Banner updated successfully!');
          return queryClient.invalidateQueries({
            queryKey: ['bannerById', bannerId],
          });
        }

        return errorNotification('Failed to update banner');
      },
    },
  );
}

export function useGetBannerById() {
  const bannerRepository = makeBannerRepository();

  const { errorNotification } = useContext(NotificationContext);

  const { bannerId } = useParams();

  return useQuery(
    ['bannerById', bannerId],
    () => bannerRepository.getBannerById<BannerDTO>(Number(bannerId)),
    {
      enabled: Boolean(bannerId),
      onError: (error: string) => {
        errorNotification(error);
      },
    },
  );
}
