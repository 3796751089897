import { IImage } from '@/domain/models';

export default class ProductPartnerCreate {
  categoryId: number;
  name: string;
  description: string;
  suggestedValue: number;
  information: string;
  image: IImage;

  constructor(
    categoryId: number,
    name: string,
    description: string,
    suggestedValue: number,
    information: string,
    image: IImage,
  ) {
    this.categoryId = categoryId;
    this.name = name;
    this.description = description;
    this.suggestedValue = suggestedValue;
    this.information = information;
    this.image = image;
  }
}
