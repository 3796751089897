import styled from 'styled-components';

export const ProductStockTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  overflow-x: scroll;
`;

export const ProductStockTableHead = styled.thead`
  margin-bottom: 0.81rem;
  position: sticky;
  top: 0;
  z-index: 2;
  background-color: ${props => props.theme.bodyBg};

  th {
    color: ${props => props.theme.table.text};
    font-size: 0.8rem;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    text-align: start;
    padding: 0.81rem 0;

    &.center {
      text-align: center;
    }
  }
`;

export const TableContainerStockProduct = styled.div`
  overflow: auto;
  max-width: 98vw;
  max-height: 63.5vh;
  min-height: 250px;

  @media (max-width: 1440px) and (max-height: 789px) {
    max-height: 62vh;
  }

  @media (max-width: 412px) and (max-height: 915px) {
    max-height: 59vh;
  }

  @media (max-width: 390px) and (max-height: 844px) {
    max-height: 55vh;
  }

  @media (max-width: 414px) and (max-height: 896px) {
    max-height: 57vh;
  }

  ::-webkit-scrollbar {
    border-radius: 10px;
    width: 6px;
    border: 1px;

    @media (max-width: 650px) {
      width: 5px;
    }
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #3e3a3b;
    border-radius: 10px;

    @media (max-width: 650px) {
      width: 5px;
    }

    @media (max-width: 650px) {
      width: 5px;
    }
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
    width: 10px;

    @media (max-width: 650px) {
      width: 5px;
    }
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

export const ProductStockTableBody = styled.tbody`
  tr {
    border-bottom: 0.5px solid rgba(255, 255, 255, 0.25);
    &:nth-child(odd) {
      background-color: ${props => props.theme.tableNthChild};
    }
    max-height: 50px !important;
    &.detail {
      &:nth-child(odd) {
        background: ${props => props.theme.productDetailHead};
      }
      background: ${props => props.theme.productDetailHead};
    }

    &.danger {
      background: rgba(255, 171, 0, 0.1);
    }

    td {
      color: ${props => props.theme.table.text};
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 400;
      text-align: start;
      padding: 0.7rem 0;
      position: relative;
      max-height: 50px !important;

      &.first-item {
        padding-left: 40px;
      }

      &.first-item-inside {
        padding-left: 80px;
      }

      &.refrigerator {
        padding-left: 3.2rem;
      }

      &.center {
        display: flex;
        text-align: center;
        align-items: center;
        justify-content: center;
        margin: 0 50%;
      }

      &.loading {
        height: '50px';
        text-align: 'center';
        width: '100%';
      }

      &.no-result-found {
        font-size: 14px;
        text-align: center;
        width: 100%;
        padding: 5px;
        height: 50px;
      }
    }
  }
`;

export const CollapseButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  padding-top: 5px;
  position: absolute;
  left: 0px;
  top: 15px;
`;

export const NoResultFountTd = styled.td`
  font-size: 14px;
  text-align: center;
  width: 100%;
  padding: 5px;
  height: 50px;
  justify-content: center;
  padding: 0 50%;
  text-align: center !important;
  vertical-align: middle;
`;
