import { AmexCardIcon, EloCardIcon, VisaIcon } from '@/ui/components';
import MasterCardIcon2 from 'ui/components/illustration/icons/mc_vrt_opt_pos_46_1x.png';

enum CreditCardType {
  visa = 'cc-visa',
  amex = 'cc-amex',
  discover = 'cc-discover',
  mastercard = 'cc-mastercard',
  elo = 'cc-elo',
  mir = 'cc-mir',
  hiper = 'cc-hiper',
  hipercard = 'cc-hipercard',
  unknown = 'unknown',
}

export const ContextCreditCardIcon = new Map<any, JSX.Element>([
  [
    CreditCardType.visa,
    <>
      <VisaIcon />
    </>,
  ],
  [
    CreditCardType.mastercard,
    <>
      <img src={MasterCardIcon2} alt="mastercard icon" />
    </>,
  ],
  [
    CreditCardType.elo,
    <>
      <EloCardIcon />
    </>,
  ],
  [
    CreditCardType.amex,
    <>
      <AmexCardIcon />
    </>,
  ],
  [
    CreditCardType.amex,
    <>
      <AmexCardIcon />
    </>,
  ],
]);
