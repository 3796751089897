import HttpEndpoint from '../http-endpoint';

export default class UserHttpEndpoint {
  static USER_BASE = `${HttpEndpoint.API_VERSION}/users`;
  static USER_MANUAL_REGISTER = `${HttpEndpoint.API_VERSION}/users/manual-register`;
  static USER_GET_BY_TYPE = `${UserHttpEndpoint.USER_BASE}/type/{0}`;
  static USER_PASSWORD_RECOVERY = `${UserHttpEndpoint.USER_BASE}/web/password-recovery`;
  static USER_CHANGE_PASSWORD_LINK = `${UserHttpEndpoint.USER_BASE}/web/password-recovery/send-link`;
  static USER_CHANGE_PASSWORD_RECOVERY = `${UserHttpEndpoint.USER_BASE}/web/password-recovery/change-password`;
  static USER_CHANGE_PASSWORD_VALIDATE_TOKEN = `${UserHttpEndpoint.USER_BASE}/web/password-recovery/validate-token`;
}
