import { ContextType, IRefrigeratorStock, TableIcons } from '@/domain/models';
import IRefrigeratorProductStock from '@/domain/models/pages/refrigerator/stock/refrigerator-product-stock';
import {
  CircularProgress,
  MasonryContainer,
  NoDataFoundMessage,
  Pagination,
} from '@/ui/components';
import RefrigeratorStockTableHead from './refrigerator-stock-table-head';
import RefrigeratorStockTableBody from './refrigerator-stock-table-body';
import RefrigeratorCardHead from './refrigerator-card-head';
import { RefrigeratorCard, StockTable } from '../styles';
import { Row } from '@/ui/components/layout';
import { store } from '@/ui/store/config';
import selectNavigationAction from '@/ui/store/modules/app/navigation/actions';
import { NavigationAppState } from '@/ui/store/models';
import { useEffect } from 'react';

interface IRefrigeratorStockProps {
  refrigeratorStock: any;
  isRefrigeratorStockLoading: boolean;
  pageFilters: any;
  handleChangePage: any;
  handleChangeRowsPerPage: any;
  setActivePage: any;
}

export default function RefrigeratorStockTable(
  props: IRefrigeratorStockProps,
): JSX.Element {
  const {
    refrigeratorStock,
    isRefrigeratorStockLoading,
    pageFilters,
    handleChangePage,
    handleChangeRowsPerPage,
    setActivePage,
  } = props;

  useEffect(() => {
    setActivePage(ContextType.refrigerator_stock);
    store.dispatch(
      selectNavigationAction(
        new NavigationAppState(
          ContextType.refrigerator_stock_by_cooler,
          ContextType.refrigerator_stock,
          TableIcons.REFRIGERATOR,
        ),
      ),
    );
  }, []);

  const renderPage = () => {
    if (isRefrigeratorStockLoading)
      return <CircularProgress variant="indeterminate" />;

    if (!isRefrigeratorStockLoading) {
      if (
        refrigeratorStock &&
        Array.isArray(refrigeratorStock.stockData) &&
        refrigeratorStock.stockData.length > 0
      ) {
        return (
          <>
            <Row align="end" justify="end">
              <Pagination
                count={refrigeratorStock.totalElements}
                page={+pageFilters.page}
                onPageChange={handleChangePage}
                rowsPerPage={pageFilters.limit as number}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Row>
            <MasonryContainer>
              {refrigeratorStock.stockData.map(
                (refrigerator: IRefrigeratorStock, index: number) => {
                  return (
                    <RefrigeratorCard key={refrigerator.coolerId + index}>
                      <RefrigeratorCardHead refrigerator={refrigerator} />
                      <StockTable>
                        {Boolean(refrigerator.products.length > 0) && (
                          <RefrigeratorStockTableHead />
                        )}
                        {refrigerator.products.length > 0 &&
                          refrigerator.products.map(
                            (
                              product: IRefrigeratorProductStock,
                              index: number,
                            ) => (
                              <RefrigeratorStockTableBody
                                key={product.productName + index}
                                product={product}
                              />
                            ),
                          )}
                      </StockTable>
                    </RefrigeratorCard>
                  );
                },
              )}
            </MasonryContainer>
          </>
        );
      }
    }

    return <NoDataFoundMessage />;
  };

  return renderPage();
}
