import styled from 'styled-components';
import { TableCell as Cell } from '@mui/material';

export const TableCell = styled(Cell)`
  &.sort-by-false {
    cursor: initial;
  }

  &.sort-by-true {
    cursor: pointer;
  }

  .MuiTableSortLabel-root.sort-label {
    position: absolute;
  }

  .MuiTableSortLabel-root.sort-label.Mui-active .MuiTableSortLabel-icon,
  .MuiTableSortLabel-root.sort-label .MuiTableSortLabel-icon:hover {
    color: ${props => props.theme.table?.text ?? props.theme.primary};
  }
`;
