import { RefrigeratorStatusColumnsAppState } from '@/ui/store/models';
import { RefrigeratorStatusColumnsAppAction, Types } from './types';

export default function refrigeratorStatusColumns(
  state = new RefrigeratorStatusColumnsAppState({
    locality: true,
    serial: true,
    active: true,
    upTimePercentage: true,
    cameraProblem: true,
    lockProblem: true,
    temperature: true,
    date: true,
    version: true,
  }),
  action: RefrigeratorStatusColumnsAppAction,
): RefrigeratorStatusColumnsAppState {
  switch (action.type) {
    case Types.REFRIGERATOR_STATUS_COLUMNS:
      return action.payload.data;
    default:
      return state;
  }
}
