import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import styled from 'styled-components';

export const ContainerRoot = styled.div`
  position: relative;
  display: inline-table;
  width: 100%;
  background-color: transparent;
  overflow: auto;

  &.d-block {
    display: block !important;
  }

  && table.MuiTable-root td {
    padding-right: 10px;
  }

  && table.MuiTable-root td,
  && table.MuiTable-root thead.MuiTableHead-root th {
    color: ${props => props.theme.table?.text ?? props.theme.primary};
    width: unset;
    white-space: nowrap;

    &.table-cell-action-thead {
      @media (min-width: 650px) {
        width: 100px !important;
      }
    }

    .MuiTableSortLabel-root.sort-label {
      position: static;
    }
  }

  && table.MuiTable-root thead tr th,
  && table.MuiTable-root tr td {
    border: 0.5px solid ${props => props.theme.lines};
    border-style: groove none;
  }

  thead {
    th.MuiTableCell-root {
      font-style: normal;
      font-weight: 400;
      color: ${props => props.theme.tableText};
    }
  }

  && [data-enable-row-action='true'] {
    cursor: pointer;
  }
`;

export const TableMui = styled(Table)``;

export const TableCellMui = styled(TableCell)``;

export const TableRowMui = styled(TableRow)``;

export const TableHeadContainer = styled.div`
  position: fixed;
  max-width: 100vw;
`;
