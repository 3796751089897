import { CardInfo } from '@/ui/components';
import { Fragment } from 'react';

interface MainCardsProps {
  data: any[] | undefined;
  isLoading: boolean;
  isDarkTheme: boolean;
}

export default function MainCards({
  data,
  isLoading,
  isDarkTheme,
}: MainCardsProps) {
  const cardData = isLoading ? Array.from({ length: 4 }, (v, i) => i) : data;

  return (
    <Fragment>
      {cardData &&
        cardData.map((data: any, index) => (
          <CardInfo
            key={index + data.title + data.value}
            position={(index + 1).toString()}
            title={data.title}
            value={data.value}
            text1={data.text1}
            text2={data.text2}
            value2={data.value2}
            value2Color={data.value2Color}
            isLoading={isLoading}
            isDarkTheme={isDarkTheme}
          />
        ))}
    </Fragment>
  );
}
