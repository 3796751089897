import {
  PromotionHttpEndpoint,
  HttpMethod,
  HttpRequest,
  HttpResponse,
  ProductHttpEndpoint,
} from '@/domain/models/services';
import { IHttpServicePort, IPromotionRepositoryPort } from '@/infra/ports';

export class PromotionRepositoryAdapter implements IPromotionRepositoryPort {
  constructor(private readonly httpServicePort: IHttpServicePort) {}

  async getPromotionListAsync<T>(params: string): Promise<HttpResponse<T>> {
    const request = new HttpRequest({
      url: PromotionHttpEndpoint.PROMOTION_BASE + params,
      method: HttpMethod.get,
      auth: true,
    });

    return await this.httpServicePort.request<T>(request);
  }

  async getPromotionAutocompleteAsync<T>(): Promise<HttpResponse<T>> {
    const request = new HttpRequest({
      url: PromotionHttpEndpoint.PROMOTION_AUTOCOMPLETE,
      method: HttpMethod.get,
      auth: true,
    });

    return await this.httpServicePort.request<T>(request);
  }

  async getPromotionAsync<T>(promotionId: string): Promise<HttpResponse<T>> {
    const request = new HttpRequest({
      url: `${PromotionHttpEndpoint.PROMOTION_BASE}/${promotionId}`,
      method: HttpMethod.get,
      auth: true,
    });

    return await this.httpServicePort.request<T>(request);
  }

  async toggleActivatePromotion<T>(
    promotionId: string,
    isActive: boolean,
  ): Promise<HttpResponse<T>> {
    const request = new HttpRequest({
      url: `${PromotionHttpEndpoint.PROMOTION_BASE}/${promotionId}/actives/${isActive}`,
      method: HttpMethod.put,
      auth: true,
    });

    return await this.httpServicePort.request<T>(request);
  }

  async createPromotion<T>(data: any): Promise<HttpResponse<T>> {
    const request = new HttpRequest({
      url: PromotionHttpEndpoint.PROMOTION_BASE,
      method: HttpMethod.post,
      auth: true,
      body: data,
    });

    return await this.httpServicePort.request<T>(request);
  }

  async updatePromotion<T>(
    data: any,
    promotionId: number | string,
  ): Promise<HttpResponse<T>> {
    const request = new HttpRequest({
      url: `${PromotionHttpEndpoint.PROMOTION_BASE}/${promotionId}`,
      method: HttpMethod.put,
      auth: true,
      body: data,
    });

    return await this.httpServicePort.request<T>(request);
  }

  async getAllLicensedProducts<T>(params = ''): Promise<HttpResponse<T>> {
    const request = new HttpRequest({
      url: ProductHttpEndpoint.PRODUCT_BASE + params,
      method: HttpMethod.get,
      auth: true,
    });

    return await this.httpServicePort.request<T>(request);
  }

  async getAllLicensedCategories<T>(params = ''): Promise<HttpResponse<T>> {
    const request = new HttpRequest({
      url: ProductHttpEndpoint.PRODUCT_BASE_CATEGORIES + params,
      method: HttpMethod.get,
      auth: true,
    });

    return await this.httpServicePort.request<T>(request);
  }

  async getCategoriesByCoolerIdAsync<T>(
    coolerId: string,
    params = '',
  ): Promise<HttpResponse<T>> {
    const request = new HttpRequest({
      url:
        ProductHttpEndpoint.PRODUCT_BASE_CATEGORIES_BY_COOLERID(coolerId) +
        params,
      method: HttpMethod.get,
      auth: true,
    });

    return await this.httpServicePort.request<T>(request);
  }

  async getProductsByCoolerIdAsync<T>(
    coolerId: string,
    params = '',
  ): Promise<HttpResponse<T>> {
    const request = new HttpRequest({
      url:
        ProductHttpEndpoint.PRODUCT_BASE_PRODUCTS_BY_COOLERID(coolerId) +
        params,
      method: HttpMethod.get,
      auth: true,
    });

    return await this.httpServicePort.request<T>(request);
  }
}
