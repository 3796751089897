import styled, { keyframes } from 'styled-components';
import { Column, Grid } from '../../layout';

const fadeIn = keyframes`
    0%{background-position:0% 100%}
   100%{background-position:100% 0%}
`;

export const CardContainer = styled(Column)`
  align-items: start;
  justify-content: space-between;
  border-radius: 16px;
  background: ${props => props.theme.cardBg};
  box-shadow: ${props => props.theme.cardBoxShadow};
  position: relative;
  overflow-y: hidden;

  &.overflow-x {
    overflow-x: scroll;
  }

  ::-webkit-scrollbar {
    height: 6px;
    width: 6px;
    border: 1px solid #d5d5d5;
  }

  &.card-content {
    min-height: 235px;
  }

  &.no_min-height {
    min-height: unset;
  }

  ::-webkit-scrollbar {
    border-radius: 10px;
    width: 6px;
    border: 1px;

    @media (max-width: 650px) {
      width: 5px;
    }
  }

  ::-webkit-scrollbar-track {
    background: #3e3a3b;
    border-radius: 10px;
    max-height: 50%;
  }

  ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
    width: 10px;
    max-height: 50%;

    @media (max-width: 650px) {
      width: 5px;
    }
  }

  &.default-loading {
    min-height: 50px;
    background: linear-gradient(90deg, #d4d4d4 2%, #757575 48%, #d4d4d4 100%);
    background-size: 300% 300%;
    -webkit-animation: ${fadeIn} 0.8s ease infinite;
    -moz-animation: ${fadeIn} 0.8s ease infinite;
    -o-animation: ${fadeIn} 0.8s ease infinite;
    animation: ${fadeIn} 0.8s ease infinite;
  }

  &.dark-default-loading {
    min-height: 50px;
    background: linear-gradient(
      270deg,
      rgba(32, 28, 28, 1) 20%,
      rgba(0, 0, 0, 1) 48%,
      rgba(32, 28, 28, 1) 76%
    );
    background-size: 300% 300%;
    -webkit-animation: ${fadeIn} 0.8s ease infinite;
    -moz-animation: ${fadeIn} 0.8s ease infinite;
    -o-animation: ${fadeIn} 0.8s ease infinite;
    animation: ${fadeIn} 0.8s ease infinite;
  }

  &.danger {
    border: 1px solid rgba(255, 86, 48, 0.4);

    background: rgba(255, 86, 48, 0.1);
  }
`;

export const Card = styled(CardContainer)`
  padding: 20px 10px 40px 20px;
  z-index: 1;
  min-height: 450px;
  gap: 18px;

  &.card-content {
    min-height: 235px;
  }

  &.default-loading {
    min-height: 266px;
  }

  &.dark-default-loading {
    min-height: 266px;
  }

  &.justify-start {
    justify-content: start;
  }

  &.card-position-1 {
    grid-area: 1 / 1 / 2 / 2;
  }

  &.card-position-2 {
    grid-area: 1 / 2 / 2 / 3;
  }

  &.card-position-3 {
    grid-area: 1 / 3 / 2 / 4;
  }

  &.card-position-4 {
    grid-area: 1 / 4 / 2 / 5;
  }

  &.card-position-2-1 {
    grid-area: 2 / 1 / 3 / 3;
  }

  &.card-position-2-2 {
    grid-area: 2 / 3 / 3 / 5;
  }

  &.card-position-3-1 {
    grid-area: 3 / 1 / 4 / 3;
  }

  &.card-position-3-2 {
    grid-area: 3 / 3 / 4 / 5;
  }

  &.card-position-4-1 {
    grid-area: 4 / 1 / 5 / 3;
  }

  &.card-position-4-2 {
    grid-area: 4 / 3 / 5 / 5;
  }

  &.card-position-5 {
    height: 500px;
    grid-area: 5 / 1 / 6 / 5;
    overflow-y: hidden;
    padding: 27px 10px 0px 27px;
  }

  &.card-position-2-mobile {
    grid-area: 2 / 1 / 3 / 2;
    height: 480px;
  }

  &.responsive-table {
    overflow: auto;
  }

  &.increase-height {
    height: 400px;
  }

  @media (max-width: 650px) {
    padding: 20px;
    padding-bottom: 36px;

    &.card-position-2-1 {
      padding: 16px 0px 75px 16px;
      grid-area: 1 / 1 / 2 / 2;
      height: 480px;
    }

    &.card-position-2-2 {
      /* padding: 16px 0px 75px 16px;*/
      grid-area: 1 / 1 / 2 / 2;
      height: 480px;
    }

    &.card-position-3-1 {
      padding: 16px 0px 16px 16px;
      grid-area: 3 / 1 / 4 / 2;
      height: 480px;

      @media (max-width: 650px) {
        padding: 30px 0px 55px 16px;
      }
    }

    &.card-position-3-2 {
      grid-area: 4 / 1 / 5 / 2;
      height: 480px;
    }

    &.card-position-4-1 {
      grid-area: 5 / 1 / 6 / 2;
      height: 520px;
    }

    &.card-position-4-2 {
      grid-area: 6 / 1 / 7 / 2;
      height: 520px;
    }

    &.card-position-5 {
      grid-area: 7 / 1 / 8 / 2;
      height: 520px;
    }
  }
`;

export const OverviewCardContainer = styled(Grid)`
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  margin-bottom: 8px;

  @media (max-width: 650px) {
    grid-template-columns: 1fr;
    ${Card} {
      grid-area: unset;
    }
  }
`;
