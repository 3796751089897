import { useState } from 'react';
import { InfoOutlined } from '@mui/icons-material';
import { DateHelper, NumberHelper } from '@/domain/helpers';
import { FinancialContext } from '@/domain/models';
import { FinancialInfoModals } from '@/domain/models/app/context/info-modal';
import { store } from '@/ui/store/config';
import { IconButton, Modal, StyledDivider } from '@/ui/components';
import { IMobileFinancial } from '@/ui/interfaces';
import { sidebarOpenAction } from '@/ui/store/modules';
import { MobileSection } from './components';
import { MobileCard, MobileCardTitle } from './styles';
import { Column } from '@/ui/components/layout';

interface IIsInfoModalOpen {
  isOpen: boolean;
  title: string;
}

export default function MobileFinancialContent({
  financialReport,
  defaultMonthlyPayment,
}: IMobileFinancial): JSX.Element {
  const [isInfoModalOpen, setIsInfoModalOpen] = useState<IIsInfoModalOpen>({
    isOpen: false,
    title: '',
  });
  const { orderConsolidation, financialClose, other, date } = financialReport;
  const { card, pix, promotionForClients, total, wallet } = orderConsolidation;

  const handleRowClick = (section: string) => {
    store.dispatch(
      sidebarOpenAction({
        sidebarName: section,
        sidebarState: { right: true },
        elementId: '0',
        elementData: {},
      }),
    );
  };

  const handleOpenModal = (title: string) => {
    setIsInfoModalOpen({ isOpen: true, title });
  };

  const handleCloseModal = () => {
    setIsInfoModalOpen({ isOpen: false, title: '' });
  };

  const negotiationText = () => {
    if (other.negotiation > 0) {
      return 'Negociações a receber';
    }
    if (other.negotiation < 0) {
      return 'Negociações a pagar';
    } else {
      return 'Negociação';
    }
  };

  const finalClosingText = () => {
    if (financialClose.toReceive > 0) {
      return 'A receber';
    } else {
      return 'A pagar';
    }
  };

  return (
    <Column>
      <MobileCard>
        <MobileCardTitle as="h2">
          <span>Fechamento mensal - {DateHelper.getMonthAndYear(date)}</span>
          <IconButton
            onClick={() => handleOpenModal(FinancialContext.FECHAMENTO_MENSAL)}
          >
            <InfoOutlined />
          </IconButton>
        </MobileCardTitle>
        <StyledDivider />
        <MobileSection
          cardSubtitle="Promoções para os clientes"
          grossValue={promotionForClients.grossValue}
          netValue={promotionForClients.netValue}
          textDescription="Investimento Take - Promoções"
          onClick={() => handleRowClick('promotion-for-clients')}
          value={promotionForClients.grossValue}
        />
        <StyledDivider />
        <MobileSection
          cardSubtitle="Wallet"
          grossValue={wallet.grossValue}
          netValue={wallet.netValue}
          textDescription="Pedidos pagos com a carteira digital Take And Go
                        (clientes fazem recarga via APP)"
          onClick={() => handleRowClick('wallet')}
          value={wallet.grossValue}
        />
        <StyledDivider />
        <MobileSection
          cardSubtitle="Cartão de crédito"
          grossValue={card.grossValue}
          netValue={card.netValue}
          textDescription="Pedidos pagos com cartão de crédito e são repassados
                        direto pela IUGU"
          onClick={() => handleRowClick('credit-card')}
          value={card.grossValue}
        />
        <StyledDivider />
        <MobileSection
          cardSubtitle="PIX"
          grossValue={pix.grossValue}
          netValue={pix.netValue}
          textDescription="Pedido pagos com PIX e são repassados direto pela IUGU"
          onClick={() => handleRowClick('pix')}
          value={pix.grossValue}
        />
        <StyledDivider />
        <MobileSection
          cardSubtitle="Total"
          grossValue={total.grossValue}
          netValue={total.netValue}
          textDescription="Promoções + Wallet + Cartão de crédito"
          className="financial-total"
          value={total.grossValue}
        />
      </MobileCard>
      <MobileCard>
        <MobileCardTitle>
          Outros - {DateHelper.getMonthAndYear(date)}
        </MobileCardTitle>
        <StyledDivider />
        <MobileSection
          cardSubtitle="Mensalidade"
          value={other.monthlyPayment}
          textDescription={`Cada cooler tem uma mensalidade de ${NumberHelper.makeMoney(
            Number(defaultMonthlyPayment?.value) || 0,
          )} referente à taxa de manutenção`}
          onClick={() => handleRowClick('monthly-payments')}
        />
        <StyledDivider />
        <MobileSection
          cardSubtitle="Pedidos com contestações bancárias"
          value={other.ordersWithBankDispute}
          textDescription="Pedidos com contestação bancárias abertos pelos usuários
                        comprador."
          onClick={() => handleRowClick('bank-dispute')}
        />
        <StyledDivider />
        {other.condominiumTransfer ? (
          <MobileSection
            className="condominium-transfer"
            cardSubtitle="Repasse do condomínio"
            value={other.condominiumTransfer}
            textDescription="Porcentagem do faturamento (total bruto) repassada para
                        o condomínio"
            onClick={() => handleRowClick('condominium-transfer')}
          />
        ) : (
          <></>
        )}
        <StyledDivider />
        <MobileSection
          cardSubtitle="Pedidos ressarcidos"
          value={other.refundedOrders}
          textDescription="Compras não pagas pelos clientes e contestações
                        bancárias"
          onClick={() => handleRowClick('refunded')}
        />
        <StyledDivider />
        <MobileSection
          cardSubtitle={negotiationText()}
          value={other.negotiation}
          textDescription="Total de negociações no período."
          onClick={() => handleRowClick('negotiation')}
        />
      </MobileCard>
      <MobileCard>
        <MobileCardTitle>
          Fechamento financeiro - {DateHelper.getMonthAndYear(date)}
        </MobileCardTitle>
        <StyledDivider />
        <MobileSection
          cardSubtitle="Recebíveis"
          value={financialClose.receivables}
          textDescription="Soma dos valores a receber"
        />
        <StyledDivider />
        <MobileSection
          cardSubtitle="Dedutíveis"
          value={financialClose.deductible}
          textDescription="Soma dos valores a pagar"
        />
        <StyledDivider />
        <MobileSection
          className="financial-to-receive"
          cardSubtitle={finalClosingText()}
          value={financialClose.toReceive}
          textDescription="Porcentagem do faturamento (total bruto) repassada para
                        o condomínio"
        />
      </MobileCard>
      <Modal
        isOpen={isInfoModalOpen.isOpen}
        onClose={() => handleCloseModal()}
        {...(FinancialInfoModals.get(isInfoModalOpen.title) as any)}
      />
    </Column>
  );
}
