import { SubmitInput } from './styles';

interface ISubmitInput {
  text: string;
  form: string;
  disabled?: boolean;
}

export default function SubmitButton({ text, form, disabled }: ISubmitInput) {
  return (
    <SubmitInput
      type="submit"
      name=""
      value={text}
      disabled={disabled}
      form={form}
    />
  );
}
