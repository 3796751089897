import { Cities, IAutocomplete, States } from '@/domain/models';
import { ILocationRepositoryPort } from '@/infra';

export default class LocationPresenter {
  constructor(private readonly locationRepository: ILocationRepositoryPort) {}

  async getStates(): Promise<Array<IAutocomplete>> {
    const request = await this.locationRepository.getStates<States>();

    if (request.data) {
      return request.data.map((state: string) => ({
        id: state,
        label: state,
      }));
    }

    return [];
  }

  async getCities(state: string): Promise<Array<IAutocomplete>> {
    const request = await this.locationRepository.getCities<Cities[]>(state);

    if (request.data) {
      return request.data.map(({ id, name }: Cities) => ({
        id: id.toString(),
        label: name,
      }));
    }

    return [];
  }
}
