import { useSelector } from 'react-redux';
import { useContext, useEffect } from 'react';

import { ContextType, TableIcons } from '@/domain/models';
import makeFinancialPresenter from '@/main/factories/presenters/pages/financial/finantial-presenter-factory';
import selectNavigationAction from '@/ui/store/modules/app/navigation/actions';
import { useBreakpoints } from '@/ui/helpers';
import { NavigationAppState, StoreState } from '@/ui/store/models';
import { store } from '@/ui/store/config';
import { Column } from '@/ui/components/layout';
import { FilterContext } from '@/ui/context/context-filter';
import { useFinancialQueries } from '@/ui/hooks';
import DesktopFinancialContent from './desktop-financial-content';
import MobileFinancialContent from './mobile-financial-content';
import Default from '../default';
import { FinancialDetail } from './detail';
import { FinancialCard } from './styles';
import NegotiationModal from './components/negotiation-modal';
import { sidebarOpenAction } from '@/ui/store/modules';
import { DeleteNegotiationModal } from './components';

export default function Financial(): JSX.Element {
  const financialPresenter = makeFinancialPresenter();

  const { setActivePage } = useContext(FilterContext);

  const { isXs } = useBreakpoints();

  const isDarkTheme = useSelector((store: StoreState) => {
    return store.toggleTheme.darkTheme;
  });

  const {
    isExportButtonDisabled,
    isLoading,
    isFetching,
    handleRenderFinancialReport,
    defaultMonthlyPayment,
  } = useFinancialQueries();

  const orderConsolidationTableColumns =
    financialPresenter.orderConsolidationTableColumns();
  const financialCloseTableColumns =
    financialPresenter.financialCloseTableColumns();
  const otherTableColumns = financialPresenter.otherTableColumns();

  useEffect(() => {
    setActivePage(ContextType.financial);
    store.dispatch(
      selectNavigationAction(
        new NavigationAppState(
          ContextType.financial,
          ContextType.financial,
          TableIcons.FINANCIAL,
        ),
      ),
    );

    store.dispatch(
      sidebarOpenAction({
        elementId: '',
        sidebarName: '',
        sidebarState: {
          right: false,
        },
      }),
    );
  }, []);

  return (
    <Default
      contextType={ContextType.financial}
      isSubmitDisabled={[isExportButtonDisabled, false]}
    >
      <FinancialDetail />
      <NegotiationModal />
      <DeleteNegotiationModal />
      {(!isLoading || !isFetching) && (
        <>
          {isXs ? (
            <MobileFinancialContent
              financialReport={handleRenderFinancialReport()}
              defaultMonthlyPayment={defaultMonthlyPayment}
            />
          ) : (
            <>
              <DesktopFinancialContent
                isLoading={isLoading}
                financialReport={handleRenderFinancialReport()}
                orderConsolidationTableColumns={orderConsolidationTableColumns}
                financialCloseTableColumns={financialCloseTableColumns}
                otherTableColumns={otherTableColumns}
                defaultMonthlyPayment={defaultMonthlyPayment}
              />
            </>
          )}
        </>
      )}
      {Boolean(isLoading) && (
        <Column>
          <FinancialCard
            className={isDarkTheme ? 'loading' : 'loading-white'}
          ></FinancialCard>
          <FinancialCard
            className={isDarkTheme ? 'loading' : 'loading-white'}
          ></FinancialCard>
          <FinancialCard
            className={isDarkTheme ? 'loading' : 'loading-white'}
          ></FinancialCard>
        </Column>
      )}
    </Default>
  );
}
