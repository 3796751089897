import styled from 'styled-components';
import { Column } from '../../layout';

export const DrawerStyled = styled(Column)`
  flex: 1;
  max-height: 100%;
  min-width: 640px;
  background-color: ${props => props.theme.bodyBg};
  color: ${props => props.theme.secondary};

  &.filter {
    min-width: unset;
    width: 392px;
  }

  @media (max-width: 650px) {
    width: 100vw;
    min-width: 0px;

    &.filter {
      min-width: unset;
      width: 100vw;
    }
  }
`;
