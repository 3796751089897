import { IAutocomplete, IRefrigeratorAddProduct } from '@/domain/models';
import { AutoCompleteField, Button, InputTextField } from '@/ui/components';
import MonetaryTextField from '@/ui/components/input/monetary-text-field';
import { Grid, Row } from '@/ui/components/layout';
import { useBreakpoints } from '@/ui/helpers';
import { SubmitHandler, useForm } from 'react-hook-form';

interface IAddProductFormProps {
  productOptions: Array<IAutocomplete>;
  addRefrigeratorProduct: any;
}

export default function AddProductForm({
  productOptions,
  addRefrigeratorProduct,
}: IAddProductFormProps) {
  const { handleSubmit, control, reset, watch, setValue } =
    useForm<IRefrigeratorAddProduct>({
      mode: 'all',
    });

  const [value] = watch(['value']);
  const handleSetValueField = (value: number) => {
    setValue('value', value);
  };

  const { isXs } = useBreakpoints();

  const changeProduct = (product: {
    id: number;
    label: string;
    suggestedValue: number;
  }) => {
    handleSetValueField(product === null ? 0 : product.suggestedValue);
    setValue('product', product);
  };

  const handleAddRefrigeratorProduct: SubmitHandler<IRefrigeratorAddProduct> = (
    data: IRefrigeratorAddProduct,
  ) => {
    addRefrigeratorProduct(data);
    reset();
  };

  return (
    <Grid
      columns="2fr repeat(5, 1fr)"
      gap={4}
      align="center"
      responsiveType="input"
      as="form"
      onSubmit={handleSubmit(handleAddRefrigeratorProduct)}
    >
      <AutoCompleteField
        control={control}
        options={productOptions}
        interceptOnChange={changeProduct}
        name="product"
        label="Produto"
      />
      <MonetaryTextField
        defaultValue={value}
        setValue={handleSetValueField}
        label="Valor do produto"
        name="value"
      />
      <InputTextField
        control={control}
        name="expectedStock"
        label="Estoque esperado"
        type="number"
        rules={{
          min: {
            value: 0,
            message: 'O valor deve ser positivo!',
          },
        }}
      />
      <InputTextField
        control={control}
        name="minimalPercentage"
        label="% Estoque mínimo"
        type="number"
        rules={{
          min: {
            value: 0,
            message: 'O valor deve ser positivo!',
          },
        }}
      />
      <Row align="center" justify="center" margin={{ bottom: isXs ? 4 : 0 }}>
        <Button
          text="Adicionar"
          type="submit"
          className={isXs ? 'medium' : ''}
        />
      </Row>
    </Grid>
  );
}
