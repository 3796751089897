import { NumberHelper } from '@/domain/helpers';
import { IProductDetailData } from '@/domain/models';
import { makeRefrigeratorStockPresenter } from '@/main/factories';
import { IconButton, ProgressBar, VerifyIcon } from '@/ui/components';
import { Edit } from '@mui/icons-material';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTheme } from 'styled-components';
import { DeleteButton } from '../../../list/forms/styles';
import MonetaryTextField from '@/ui/components/input/monetary-text-field';
import { Link } from './style';

interface IStockProductDetailRowProps {
  productDetail: IProductDetailData;
  productId: string;
  productName: string;
  confirmModal: (payload: any) => void;
}

export default function StockProductDetailRow({
  productDetail,
  productId,
  productName,
  confirmModal,
}: IStockProductDetailRowProps): JSX.Element {
  const [isEditRow, setIsEditRow] = useState(false);
  const theme = useTheme();
  const refrigeratorStockPresenter = makeRefrigeratorStockPresenter();

  const { control, handleSubmit, watch } = useForm({
    mode: 'all',
  });

  const value = watch('productValue');

  const getClassName = (stockPercentage: number) => {
    if (stockPercentage == 0) {
      return 'danger';
    } else {
      return 'detail';
    }
  };

  const handleValueChange = (data: any) => {
    confirmModal({
      productName,
      productId,
      value: Number(data.productValue),
      coolers: [
        {
          id: productDetail.refrigeratorId,
          label: `${productDetail.refrigeratorCode} - ${productDetail.refrigeratorLocality}`,
        },
      ],
    });
    setIsEditRow(false);
  };

  return (
    <>
      <tr
        className={
          'first-item-inside ' + getClassName(productDetail.stockPercentage)
        }
        key={productDetail.refrigeratorCode}
      >
        <td className="refrigerator">
          <Link
            to={`../refrigerator/list/${productDetail.refrigeratorId}`}
            target="_blank"
            rel="noreferrer"
          >
            {productDetail.refrigeratorLocality +
              ' ' +
              productDetail.refrigeratorCode}
          </Link>
        </td>
        <td>{productDetail.lastReposition}</td>
        <td>{productDetail.consumedQuantity.toLocaleString('pt-BR')}</td>
        <td>{productDetail.currentQuantity.toLocaleString('pt-BR')}</td>
        <td>{productDetail.minimalPercentage.toLocaleString('pt-BR')}</td>
        <td>{productDetail.expectedStock.toLocaleString('pt-BR')}</td>
        <td className="center">
          <ProgressBar
            trackColor={theme.lines}
            indicatorColor={refrigeratorStockPresenter.getIndicatorColor(
              productDetail.stockPercentage,
            )}
            progress={+productDetail.stockPercentage}
          />
        </td>
        <td>
          {isEditRow ? (
            <Controller
              control={control}
              name="productValue"
              defaultValue={value}
              render={({ field: { onChange, value } }) => (
                <MonetaryTextField
                  label=""
                  name="productValue"
                  setValue={(value: number) => onChange(value)}
                  defaultValue={value}
                  size="small"
                />
              )}
            />
          ) : (
            NumberHelper.makeMoney(productDetail.productValue)
          )}
        </td>
        <td>
          {isEditRow ? (
            <DeleteButton
              className="success"
              onClick={handleSubmit(handleValueChange)}
              disabled={!value}
            >
              <VerifyIcon />
            </DeleteButton>
          ) : (
            <IconButton className="table" onClick={() => setIsEditRow(true)}>
              <Edit />
            </IconButton>
          )}
        </td>
      </tr>
    </>
  );
}
