import styled from 'styled-components';

const WarningCard = styled.div`
  background-color: #ffab0066;
  border-radius: 20px;
  padding: 20px 30px 20px 0;
  margin-top: 30px;
  display: flex;
  gap: 5px;
`;

const WarningIcon = styled.div`
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 48px;
    height: 48px;
  }
`;

const WarningContent = styled.div`
  flex: 1;
`;

export default {
  WarningCard,
  WarningIcon,
  WarningContent,
};
