import { Control, Controller, RegisterOptions } from 'react-hook-form';
import { MonetaryTextField } from '../..';

interface ICurrencyTextFieldProps {
  control: Control<any>;
  name: string;
  label: string;
  rules?: RegisterOptions;
  dataTestId?: string;
}

export default function ControlledMonetaryTextField({
  control,
  name,
  label,
  rules,
  dataTestId,
}: ICurrencyTextFieldProps) {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({
        field: { onChange, value, ...field },
        fieldState: { error },
      }) => (
        <MonetaryTextField
          {...field}
          label={label}
          name={name}
          error={error}
          defaultValue={value}
          setValue={value => {
            onChange(value);
          }}
          dataTestId={dataTestId}
        />
      )}
    />
  );
}
