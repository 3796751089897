import { useSnackbar, VariantType, WithSnackbarProps } from 'notistack';

interface IProps {
  setUseSnackbarRef: (showSnackbar: WithSnackbarProps) => void;
}

const InnerSnackbarUtilsConfigurator: React.FC<IProps> = (props: IProps) => {
  const { setUseSnackbarRef } = props;
  setUseSnackbarRef(useSnackbar());
  return null;
};

let useSnackbarRef: WithSnackbarProps;
const setUseSnackbarRef = (useSnackbarRefProp: WithSnackbarProps) => {
  useSnackbarRef = useSnackbarRefProp;
};

export const SnackbarUtilsConfigurator = (): JSX.Element => {
  return (
    <InnerSnackbarUtilsConfigurator setUseSnackbarRef={setUseSnackbarRef} />
  );
};

function enqueue(msg: string, variant: VariantType = 'default'): void {
  useSnackbarRef.enqueueSnackbar(msg, {
    variant,
    autoHideDuration: 6000,
    anchorOrigin: {
      vertical: 'top',
      horizontal: 'right',
    },
  });
}

export default class NotificationHelper {
  static success(msg: string): void {
    enqueue(msg, 'success');
  }
  static warning(msg: string): void {
    enqueue(msg, 'warning');
  }
  static info(msg: string): void {
    enqueue(msg, 'info');
  }
  static error(msg: string): void {
    enqueue(msg, 'error');
  }
  static notify(msg: string, severity = 'default'): void {
    enqueue(msg, severity as VariantType);
  }
}
