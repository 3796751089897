import { useContext } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { NotificationContext } from '@/main';
import { makeRefrigeratorListPresenter } from '@/main/factories';
import { NumberHelper } from '@/domain/helpers';
import { IAutocomplete } from '@/domain/models';
import { Button, Modal } from '@/ui/components';
import { Column } from '@/ui/components/layout';

interface IConfirmValueModalProps {
  payload: {
    productName: string;
    productId: string;
    value: number;
    coolers: IAutocomplete[];
    isAllCoolers: boolean;
  };
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  refetchData: () => void;
}

export default function ConfirmValueModal({
  payload: { productName, productId, value, coolers, isAllCoolers },
  isOpen,
  setIsOpen,
  refetchData,
}: IConfirmValueModalProps) {
  const { successNotification, errorNotification } =
    useContext(NotificationContext);
  const queryClient = useQueryClient();

  const refrigeratorPresenter = makeRefrigeratorListPresenter();

  const { mutate: editProductValue } = useMutation(
    'editProductValue',
    (body: any) => refrigeratorPresenter.updateProductValue(productId, body),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('refrigeratorStockProduct');
        refetchData();
        successNotification('Valor alterado com sucesso!', 3000);
      },
      onError: error => {
        errorNotification(error, 3000);
      },
    },
  );

  const handleSubmit = () => {
    editProductValue({
      coolerId: coolers.map(cooler => cooler.id),
      value,
    });
    setIsOpen(false);
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      title="Edição de valor"
      okButton={
        <Button
          className="base"
          text="Cancelar"
          onClick={() => setIsOpen(false)}
        />
      }
      cancelButton={<Button text="Salvar" onClick={handleSubmit} />}
    >
      <Column align="start" gap={4}>
        <div>Revise os dados da edição antes de salvá-la:</div>
        <div>
          <strong>Produto:</strong> {productName}
        </div>
        <div>
          <strong>Novo valor unitário:</strong> {NumberHelper.makeMoney(value)}
        </div>
        <div>
          <strong>Coolers: </strong>
          {isAllCoolers
            ? 'Todos os coolers'
            : coolers.map(cooler => cooler.label).join('; ')}
          .
        </div>
      </Column>
    </Modal>
  );
}
