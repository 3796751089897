import { LoadingAppState } from '@/ui/store/models';
import { Types, LoadingToggleAppAction } from './types';

export default function toggleLoading(
  state = new LoadingAppState({
    isLoading: 0,
  }),
  action: LoadingToggleAppAction,
): LoadingAppState {
  switch (action.type) {
    case Types.LOADING_START:
      return new LoadingAppState({ isLoading: state.isLoading + 1 });
    case Types.LOADING_FINISH:
      return new LoadingAppState({ isLoading: state.isLoading - 1 });
    default:
      return new LoadingAppState({ isLoading: state.isLoading });
  }
}
