import { StyledModalContainer } from './styles';

interface IModalContainer {
  children: React.ReactNode;
  className?: string;
}

export default function ModalContainer({
  children,
  className,
}: IModalContainer): JSX.Element {
  return (
    <StyledModalContainer className={className && className}>
      {children}
    </StyledModalContainer>
  );
}
