import { useState } from 'react';
import { InfoOutlined } from '@mui/icons-material';
import { IUserCard } from '@/domain/models';
import {
  CardTitle,
  RatingUserIcon,
  ValueText,
  IconContainer,
  IconButton,
  Modal,
} from '@/ui/components';
import { CrmCard } from '../styles';
import { Column, Row } from '@/ui/components/layout';
import { CrmInfoModals } from '@/domain/models/app/context/info-modal';

interface IUserInfoCardProps {
  userInfo: IUserCard;
  className: string;
  index: number;
  isLoading: boolean;
  isDarkTheme: boolean;
  position?: string;
}

export default function UserInfoCard({
  userInfo,
  className,
  index,
  isLoading,
  isDarkTheme,
  position,
}: IUserInfoCardProps): JSX.Element {
  const [isOpenModal, setIsOpenModal] = useState(false);

  const loading = () => {
    if (isLoading && !isDarkTheme) {
      return 'default-loading';
    } else if (isLoading && isDarkTheme) {
      return 'dark-default-loading';
    }
  };

  return (
    <CrmCard className={`${className} ${loading()} card-position-${position}`}>
      {!isLoading && (
        <>
          <CardTitle className="card-info no-sticky">
            <Row align="center" justify="center">
              <IconContainer className="mr">
                <RatingUserIcon index={index} />
              </IconContainer>
              {userInfo.key}
            </Row>
            <IconButton onClick={() => setIsOpenModal(true)}>
              <InfoOutlined />
            </IconButton>
          </CardTitle>
          <Column align="start" justify="end">
            <CardTitle className="gray no-sticky">Nº de clientes</CardTitle>
            <ValueText>{userInfo.value}</ValueText>
          </Column>
          <Modal
            isOpen={isOpenModal}
            onClose={() => setIsOpenModal(false)}
            {...(CrmInfoModals.get(className) as any)}
          />
        </>
      )}
    </CrmCard>
  );
}
