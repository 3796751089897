import { css } from 'styled-components';

const headingSizes = [48, 40, 32, 24, 20, 16];
const headings = headingSizes.map((size, i) => {
  return css`
    h${i + 1}, .heading-${i + 1} {
      font-size: ${size}px;
      font-weight: 700;
      text-align: start;
    }
  `;
});

const textSizes = new Map([
  ['extra-large', 20],
  ['large', 18],
  ['medium', 16],
  ['small', 14],
  ['extra-small', 12],
  ['tiny', 10],
]);

const paragraphs = Array.from(textSizes).map(([name, size]) => {
  return css`
    .body-${name} {
      font-size: ${size}px;
    }
  `;
});

const labels = Array.from(textSizes).map(([name, size]) => {
  return css`
    .label-${name} {
      font-size: ${size}px;
    }
  `;
});

const weigths = new Map([
  ['thin', 100],
  ['extraLight', 200],
  ['light', 300],
  ['regular', 400],
  ['medium', 500],
  ['semi-bold', 600],
  ['bold', 700],
  ['extra-bold', 800],
  ['black', 900],
]);

const weights = Array.from(weigths).map(([name, weight]) => {
  return css`
    .font-weight-${name} {
      font-weight: ${weight};
    }
  `;
});

export default css`
  ${headings}
  ${paragraphs}
    ${labels}
    ${weights}
`;
