import { useSelector } from 'react-redux';

import { ContextType } from '@/domain/models';
import {
  MonitoringFilter,
  OrderFilter,
  PromotionFilter,
  RefrigeratorStatusFilterList,
  UserFilter,
  RefrigeratorStatusFilterDashboard,
} from '@/ui/pages';
import { StoreState } from '@/ui/store/models';
import OverviewFilter from '@/ui/pages/home/filter';
import FinancialFilter from '@/ui/pages/financial/filter';
import RefrigeratorFilter from '@/ui/pages/refrigerator/filter';
import LicensedFilter from '@/ui/pages/licenseds/filter';
import { BannerFilter } from '@/ui/pages/banner';

interface IGetFilters {
  showFilterColumn: ISidebarState;
  setFiltersColumn: React.Dispatch<React.SetStateAction<ISidebarState>>;
}

type ISidebarState = {
  right: boolean;
};

const contextFilters = ({
  showFilterColumn,
  setFiltersColumn,
}: IGetFilters) => {
  const activeMenu = useSelector(
    (store: StoreState) => store.navigation.activePage,
  );

  switch (activeMenu) {
    case ContextType.home_overview:
      return (
        <OverviewFilter
          filter={showFilterColumn}
          setFilter={setFiltersColumn}
        />
      );
    case ContextType.home_rating:
      return (
        <OverviewFilter
          filter={showFilterColumn}
          setFilter={setFiltersColumn}
        />
      );
    case ContextType.order:
      return (
        <OrderFilter filter={showFilterColumn} setFilter={setFiltersColumn} />
      );
    case ContextType.refrigerator_list:
      return (
        <RefrigeratorFilter
          filter={showFilterColumn}
          setFilter={setFiltersColumn}
        />
      );
    case ContextType.refrigerator_status_dashboard:
      return (
        <RefrigeratorStatusFilterDashboard
          filter={showFilterColumn}
          setFilter={setFiltersColumn}
        />
      );
    case ContextType.refrigerator_status_list:
      return (
        <RefrigeratorStatusFilterList
          filter={showFilterColumn}
          setFilter={setFiltersColumn}
        />
      );
    case ContextType.refrigerator_stock_by_cooler:
      return (
        <RefrigeratorFilter
          filter={showFilterColumn}
          setFilter={setFiltersColumn}
        />
      );
    case ContextType.promotion_list:
      return (
        <PromotionFilter
          filter={showFilterColumn}
          setFilter={setFiltersColumn}
        />
      );
    case ContextType.financial:
      return (
        <FinancialFilter
          filter={showFilterColumn}
          setFilter={setFiltersColumn}
        />
      );

    case ContextType.user:
      return (
        <UserFilter filter={showFilterColumn} setFilter={setFiltersColumn} />
      );
    case ContextType.monitoring_opening_attempts:
      return (
        <MonitoringFilter
          filter={showFilterColumn}
          setFilter={setFiltersColumn}
        />
      );
    case ContextType.licensed_list:
      return (
        <LicensedFilter
          filter={showFilterColumn}
          setFilter={setFiltersColumn}
        />
      );
    case ContextType.banner_list:
      return (
        <BannerFilter filter={showFilterColumn} setFilter={setFiltersColumn} />
      );
    default:
      break;
  }
};

export default contextFilters;
