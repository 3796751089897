/* eslint-disable react/prop-types */

import {
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  LineChart,
} from 'recharts';
import { useTheme } from 'styled-components';
import ChartLegend from './legend';
import ChartTooltip from './tooltip';

export default function RelineChart({ LineChartProps }) {
  const theme = useTheme();

  const maxSecondLine = Math.max(
    ...LineChartProps.map(obj => parseInt(obj.secondLineValue)),
  );
  const maxFirstLine = Math.max(
    ...LineChartProps.map(obj => parseInt(obj.firstLineValue)),
  );

  const max = Math.max(maxFirstLine, maxSecondLine) + 1;

  return (
    <ResponsiveContainer width="100%" height="100%">
      <LineChart
        width={500}
        height={300}
        data={LineChartProps}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid stroke={theme.chartLine} vertical={false} />
        <XAxis
          tickLine={false}
          axisLine={false}
          dataKey="name"
          style={{
            color: '#f5f5f5',
            fontSize: '10px',
            fill: theme.chartLegend,
          }}
          allowDataOverflow={true}
          minTickGap={0}
          interval={0}
          angle={50}
          tickMargin={20}
          padding="gap"
        />
        <Tooltip content={<ChartTooltip />} />
        <YAxis
          domain={[0, max]}
          dataKey="firstLineValue"
          allowDataOverflow
          includeHidden
          tickLine={false}
          axisLine={false}
          tickCount={5}
          style={{
            color: theme.chartLegend,
            fontSize: '12px',
            fill: theme.chartLegend,
          }}
        />
        <Legend
          verticalAlign="bottom"
          align="left"
          height={36}
          fontSize={5}
          margin={{ top: 90, left: 50 }}
          chartHeight={100}
          content={<ChartLegend />}
        />
        <Line
          name={LineChartProps[0].firstLineName}
          type="monotone"
          dataKey="firstLineValue"
          stroke="#00D5FF"
          activeDot={{ r: 8 }}
          strokeWidth={3}
        />
        <Line
          name={LineChartProps[0].secondLineName}
          strokeWidth={3}
          type="monotone"
          dataKey="secondLineValue"
          stroke="#FFCD00"
        />
      </LineChart>
    </ResponsiveContainer>
  );
}
