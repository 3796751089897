import { useContext } from 'react';
import { ContextPagesWithoutBaseLayout } from '@/domain/models';
import useBreakpoints from '@/ui/helpers/breakpoint-helper';
import { ReactChildrenProps } from '@/ui/interfaces';
import { DefaultSidebar, MobileSidebar, HeaderDefault } from '@/ui/layouts';
import { FilterContext } from '@/ui/context/context-filter';
import { Column, Row } from '../layout';

export default function BaseLayout(props: ReactChildrenProps) {
  const { children } = props;
  const { isXs } = useBreakpoints();

  const { activePage } = useContext(FilterContext);

  return (
    <Row overflow="hidden" height="100vh">
      {!ContextPagesWithoutBaseLayout.get(activePage) &&
        (isXs ? <MobileSidebar /> : <DefaultSidebar />)}
      <Column
        padding={!isXs ? { left: 24 } : {}}
        overflow="hidden"
        grow={1}
        maxHeight="100vh"
      >
        {!ContextPagesWithoutBaseLayout.get(activePage) && !isXs && (
          <HeaderDefault />
        )}
        {children}
      </Column>
    </Row>
  );
}
