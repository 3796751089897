import contextFinancialDetails from './context-financial-details';

const contextFinancialDetailTitle = new Map<string, string>([
  [contextFinancialDetails.bank_dispute, 'Contestações bancárias'],
  [contextFinancialDetails.condominium_transfer, 'Repasse de condomínio'],
  [contextFinancialDetails.credit_card, 'Cartão de crédito'],
  [contextFinancialDetails.monthly_payments, 'Mensalidade'],
  [contextFinancialDetails.negotiation, 'Negociação'],
  [contextFinancialDetails.pix, 'Pix'],
  [contextFinancialDetails.promotion_for_clients, 'Promoções para os clientes'],
  [contextFinancialDetails.refunded, 'Pedidos ressarcidos'],
  [contextFinancialDetails.wallet, 'Wallet'],
]);

export default contextFinancialDetailTitle;
