import { makePromotionRepository } from './../../../repositories/promotion-repository-factory';
import { PromotionTablePresenter } from '@/presentation/presenters';
import { makeSearchRepository } from '@/main/factories/repositories';
import { ITableServerSidePresenter } from '@/ui/components/table';

const makePromotionTablePresenter = (): ITableServerSidePresenter =>
  new PromotionTablePresenter(
    makeSearchRepository(),
    makePromotionRepository(),
  );

export default makePromotionTablePresenter;
