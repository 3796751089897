import { StoreState } from '@/ui/store/models';
import { useSelector } from 'react-redux';
import { useTheme } from 'styled-components';
import StyledSvgIcon from './svg-icon.styles';
import { Newspaper } from '@mui/icons-material';
import { TableIcons } from '@/domain/models';

export default function MuralTakeIcon() {
  const currentTheme = useTheme();
  const activeMenu = useSelector(
    (currentStore: StoreState) => currentStore.navigation.activeMenu,
  );

  return (
    <StyledSvgIcon
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <Newspaper
        style={{
          stroke:
            activeMenu === TableIcons.MURAL
              ? currentTheme.secondary
              : currentTheme.quaternary,
          strokeWidth: '1.25',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
        }}
      />
    </StyledSvgIcon>
  );
}
