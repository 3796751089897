import { useState } from 'react';
import { InfoOutlined } from '@mui/icons-material';
import {
  OverviewIconsMaterial,
  OverviewLittleITestId,
} from '@/domain/models/app/context/context-icons';
import {
  Card,
  CardTitle,
  IconContainer,
  IconButton,
  ValueText,
  Modal,
  ArrowUp,
  ArrowDown,
} from '@/ui/components';
import { OverviewInfoModals } from '@/domain/models/app/context/info-modal';
import { SecondValueText, Title } from './styles';
import { Column, Row } from '../../layout';

interface CardInfo {
  position: string;
  title: string;
  value: string;
  text1: string;
  text2: string;
  value2: string | number | null;
  value2Color: string;
  isLoading?: boolean;
  isDarkTheme: boolean;
}

export default function CardInfo({
  position,
  title,
  text1,
  text2,
  value,
  value2,
  value2Color,
  isLoading,
  isDarkTheme,
}: CardInfo): JSX.Element {
  const [isOpenModal, setIsOpenModal] = useState(false);

  const secondTextColor = () => {
    if (value2 === null) {
      return '';
    }
    if (value2Color === 'secondary') {
      return value2Color;
    } else if (+value2 < 0) {
      return 'red';
    } else {
      return 'green';
    }
  };

  const variationIsNull = () => {
    return value2 === null;
  };

  const loading = () => {
    if (isLoading && !isDarkTheme) {
      return 'default-loading';
    } else if (isLoading && isDarkTheme) {
      return 'dark-default-loading';
    }

    return '';
  };

  return (
    <Card className={`card-position-${position} ${loading()} no_min-height`}>
      {!isLoading && (
        <>
          <CardTitle className="card-info">
            <Row align="center" justify="center">
              <IconContainer>{OverviewIconsMaterial.get(title)}</IconContainer>
              <Title>{title}</Title>
            </Row>
            <IconButton onClick={() => setIsOpenModal(true)}>
              <InfoOutlined data-testid={OverviewLittleITestId.get(title)} />
            </IconButton>
          </CardTitle>
          <Column justify="end" align="start" data-testid="card-info">
            <div data-testid="card-info"></div>
            <CardTitle className="gray">{text1}</CardTitle>
            <ValueText>{value}</ValueText>
            <CardTitle className="gray">
              {text2}
              <SecondValueText className={secondTextColor()}>
                {variationIsNull() ? '-' : value2}
                {!variationIsNull() && secondTextColor() != 'secondary' && '%'}
              </SecondValueText>
              {!variationIsNull() && Boolean(secondTextColor() === 'red') && (
                <div data-testid="arrow-down">
                  <ArrowDown />
                </div>
              )}
              {!variationIsNull() && Boolean(secondTextColor() === 'green') && (
                <div data-testid="arrow-up">
                  <ArrowUp />
                </div>
              )}
            </CardTitle>
          </Column>
          <Modal
            isOpen={isOpenModal}
            onClose={() => setIsOpenModal(false)}
            {...(OverviewInfoModals.get(title) as any)}
          />
        </>
      )}
    </Card>
  );
}
