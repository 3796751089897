import { ButtonWithConfirmation } from '@/components/molecules/';
import { useMutation } from 'react-query';

import { deactivateCooler } from '@/api';
import { ReactNode, useContext } from 'react';
import { NotificationContext } from '@/main';

type ButtonResetCoolerProps = {
  coolerId: string;
  endIcon: ReactNode;
};

const ButtonResetCooler = ({ coolerId, ...props }: ButtonResetCoolerProps) => {
  const { successNotification, errorNotification } =
    useContext(NotificationContext);

  const { isLoading, mutateAsync } = useMutation(deactivateCooler, {
    onSuccess: () => {
      successNotification('O cooler foi baixado com sucesso!');
    },
    onError: () => {
      errorNotification(
        'Aconteceu um erro ao baixar o cooler, tente novamente mais tarde.',
      );
    },
  });

  const handleOnClick = async () => {
    const response = await mutateAsync(coolerId);

    console.log('response on click', response);
    return true;
  };

  return (
    <ButtonWithConfirmation
      onClick={handleOnClick}
      message="Todos os dados do seu cooler serão restaurados, você tem certeza disso?"
      variant="text"
      label={isLoading ? 'Loading...' : <strong>Baixar Cooler</strong>}
      {...props}
    />
  );
};

export default ButtonResetCooler;
