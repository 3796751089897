import { DateHelper } from '@/domain/helpers';
import {
  HttpResponse,
  IAutocomplete,
  IPromotionSaveInputs,
  PromotionSaveData,
} from '@/domain/models';
import IPromotion from '@/domain/models/pages/promotion/promotion';
import { IPromotionRepositoryPort } from '@/infra';
import IPromotionPresenter from '@/ui/pages/promotion/promotion-presenter';

export default class PromotionPresenter implements IPromotionPresenter {
  constructor(private readonly promotionRepository: IPromotionRepositoryPort) {}

  async getPromotionAsync(params: string): Promise<IPromotionSaveInputs> {
    return this.promotionRepository
      .getPromotionAsync<any>(params)
      .then((res: HttpResponse<IPromotion>) => {
        if (res.success) {
          return this.formatFromSaveDataToForm(res.data);
        }
        if (res.error) {
          Promise.reject(
            res.error?.message || 'Erro inesperado ao buscar promoção',
          );
        }

        return this.formatFromSaveDataToForm(res.data);
      });
  }

  async createPromotion(data: IPromotionSaveInputs): Promise<IPromotion> {
    const formattedData = this.formatFromFormToSaveData(data);

    return this.promotionRepository
      .createPromotion(formattedData)
      .then((res: HttpResponse<IPromotion>) => {
        if (res.success) {
          return res.data;
        }
        if (res.error) {
          Promise.reject(
            res.error?.message || 'Erro inesperado ao criar promoção',
          );
        }

        return res.data;
      });
  }

  async updatePromotion(
    data: IPromotionSaveInputs,
    promotionId: number,
  ): Promise<IPromotion> {
    const formattedData = this.formatFromFormToSaveData(data);

    return this.promotionRepository
      .updatePromotion(formattedData, promotionId)
      .then((res: HttpResponse<IPromotion>) => {
        if (res.success) {
          return res.data;
        }
        if (res.error) {
          Promise.reject(
            res.error?.message || 'Erro inesperado ao atualizar promoção',
          );
        }

        return res;
      });
  }

  async getProductsAsync(params?: string): Promise<IAutocomplete[]> {
    const response = await this.promotionRepository.getAllLicensedProducts<any>(
      params,
    );

    return response.data!;
  }

  async getCategoriesAsync(params?: string): Promise<IAutocomplete[]> {
    const response =
      await this.promotionRepository.getAllLicensedCategories<any>(params);

    return response.data!;
  }

  async getCategoriesByCoolerIdAsync(
    coolerId: string,
    params?: string,
  ): Promise<IAutocomplete[]> {
    const response =
      await this.promotionRepository.getCategoriesByCoolerIdAsync<any>(
        coolerId,
        params,
      );

    return response.data!;
  }

  async getProductsByCoolerIdAsync(
    coolerId: string,
    params?: string,
  ): Promise<IAutocomplete[]> {
    const response =
      await this.promotionRepository.getProductsByCoolerIdAsync<any>(
        coolerId,
        params,
      );

    return response.data!;
  }

  formatFromFormToSaveData(data: IPromotionSaveInputs): PromotionSaveData {
    return {
      ...data,
      minimum: Number(data.minimum) || 0,
      type: data.type.id,
      discount:
        data.type.id === 'ABOVE_AMOUNT'
          ? Number(data.minimum) - Number(data.discount)
          : Number(data.discount),
      useCase: data.useCase.id,
      licensed: data.licensed ? data.licensed : null,
      cooler: data.cooler ? data.cooler : [],
      categories: data.categories ? data.categories : [],
      products: data.products ? data.products : [],
      dateBegin: DateHelper.formatDateHours(data.dateBegin),
      dateEnd: DateHelper.formatDateHours(data.dateEnd),
      takeParticipationPercent: data.takeParticipationPercent
        ? Number(data.takeParticipationPercent)
        : null,
      promocode: data.promocode ? data.promocode : null,
      campaignName: data.campaignName ? data.campaignName : null,
      campaignConditions: data.campaignConditions
        ? data.campaignConditions
        : null,
      users: data.users ? data.users : [],
      cashbackExpirationDays: data.cashbackExpirationDays
        ? Number(data.cashbackExpirationDays)
        : null,
      maximumDiscount: data.maximumDiscount
        ? Number(data.maximumDiscount)
        : null,
    };
  }

  formatFromSaveDataToForm(data: IPromotion): IPromotionSaveInputs {
    const type: IAutocomplete = this.promotionTypeOptions.find(
      (item: IAutocomplete) => item.id === data.type,
    )!;
    const useCase: IAutocomplete = this.useCaseOptions.find(
      (item: IAutocomplete) => item.id === data.useCase,
    )!;

    const dataWithNulls: IPromotionSaveInputs = Object.keys(data).reduce(
      (acc: any, key: string) => {
        if (!data[key as keyof IPromotion]) {
          acc[key] = null;
        } else {
          acc[key] = data[key as keyof IPromotion];
        }

        return acc;
      },
      {},
    );

    return {
      ...dataWithNulls,
      type,
      useCase,
      discount:
        data.type === 'ABOVE_AMOUNT'
          ? Number(data.minimum) - Number(data.discount)
          : Number(data.discount),
      promocodeSwitch: !!data.promocode,
      cpfSwitch: Array.isArray(data.users) && data.users.length > 0,
      users: Array.isArray(data.users) ? data.users : [],
      dateBegin: DateHelper.formatDateHoursFromRequest(data.dateBegin),
      dateEnd: DateHelper.formatDateHoursFromRequest(data.dateEnd),
    };
  }

  async getAutocomplete(): Promise<Array<IAutocomplete>> {
    const response =
      await this.promotionRepository.getPromotionAutocompleteAsync<
        Array<IAutocomplete>
      >();

    return response.data;
  }

  public useCaseOptions = [
    {
      label: 'Primeira Compra',
      id: 'FIRST_BUY',
    },
    {
      label: 'Ilimitado',
      id: 'UNLIMITED',
    },
    {
      label: 'Uso único',
      id: 'ONE_TIME',
    },
    {
      label: 'Por dia',
      id: 'PER_DAY',
    },
  ];

  public statusOptions = [
    { label: 'Ativa', id: 'ACTIVE' },
    { label: 'Inativa', id: 'INACTIVE' },
  ];

  public promotionTypeOptions = [
    { label: 'Leve e Pague', id: 'ABOVE_AMOUNT' },
    { label: '% de Cashback', id: 'CASHBACK' },
    { label: '% de Desconto', id: 'PERCENTAGE' },
    { label: 'R$ de Desconto', id: 'VALUE' },
  ];
}
