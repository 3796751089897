import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { Controller } from 'react-hook-form';
import SelectFieldStyles from './styles';

const SelectField = ({
  control,
  name,
  label,
  options,
  marginLeft,
  marginRight,
  dataTestId,
}: any): JSX.Element => {
  const { selectLabelStyles, selectStyles } = SelectFieldStyles(
    marginLeft,
    marginRight,
  );

  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <FormControl fullWidth>
          <InputLabel sx={selectLabelStyles} id={label}>
            {label}
          </InputLabel>
          <Select
            data-testid={dataTestId}
            labelId={label}
            variant="outlined"
            sx={selectStyles}
            label={label}
            {...field}
          >
            {options.map((option: any) => (
              <MenuItem key={option.id} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    />
  );
};

export default SelectField;
