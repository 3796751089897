import { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTheme } from 'styled-components';

import { ContextType, MenuModel } from '@/domain/models';
import { AuthenticationHelper, PermissionHelper } from '@/domain/helpers';
import makeSidebarPresenter from '@/main/factories/presenters/components/sidebar-presenter-factory';
import { ICollapseState } from '@/ui/interfaces';
import { Menu } from '@/ui/components/navigation';
import { TakeLogo } from '@/ui/components';
import { Row } from '@/ui/components/layout';
import { DefaultDrawer, DefaultList } from './styles';

export default function Sidebar() {
  const appVersion = useMemo(() => {
    return process.env.REACT_APP_VERSION;
  }, []);

  const [open, setOpen] = useState(false);
  const [collapseState, setCollapseState] = useState<ICollapseState>({
    activePage: '',
    isCollapseOpen: false,
    activeMenu: '',
  });

  const sidebarPresenter = makeSidebarPresenter();

  const userRoles = AuthenticationHelper.getToken().auth || '';
  const permissionHelper = new PermissionHelper(userRoles);
  const isTakeAndGo = permissionHelper.isTakeAndGo();
  const isPartner = permissionHelper.isPartner();
  const isReviewer = permissionHelper.isReviewer();

  const renderMenus = () => {
    const menus = MenuModel.makeSystemMenus();

    const isLicensedMenu = (menuItem: MenuModel): boolean => {
      return (
        (!isTakeAndGo && menuItem.isEnable && !menuItem.onlyTake) ||
        (menuItem.isPartner && isPartner)
      );
    };

    const isTakeAndGoMenu = (menuItem: MenuModel): boolean | undefined => {
      return isTakeAndGo && (menuItem.takeAndGoAccess || menuItem.onlyTake);
    };

    const getOrderMenu = () =>
      menus.find(menu => menu.contextType === ContextType.order);

    if (isReviewer) {
      const orderMenu = getOrderMenu();
      return (
        <Menu
          menu={orderMenu!}
          open={open}
          collapseState={collapseState}
          setCollapseState={setCollapseState}
        />
      );
    }

    return menus.map((menuItem, index) => {
      if (isTakeAndGoMenu(menuItem)) {
        return (
          <Menu
            key={menuItem.id + menuItem.contextType + index}
            menu={menuItem}
            open={open}
            collapseState={collapseState}
            setCollapseState={setCollapseState}
          />
        );
      } else if (isLicensedMenu(menuItem)) {
        return (
          <Menu
            key={menuItem.id + menuItem.contextType + index}
            menu={menuItem}
            open={open}
            collapseState={collapseState}
            setCollapseState={setCollapseState}
          />
        );
      }
    });
  };

  const styledTheme = useTheme();

  return (
    <>
      <DefaultDrawer
        sx={{
          '.MuiPaper-root': {
            backgroundColor: styledTheme.primary,
          },
          '.MuiList-root': {
            borderRadius: '0 !important',
          },
          '.MuiDrawer-paper': {
            borderRight: `0.5px solid ${styledTheme.lines}`,
          },
        }}
        variant="permanent"
        open={open}
        onMouseOver={() => sidebarPresenter.handleDrawerOpen(setOpen)}
        onMouseLeave={() => sidebarPresenter.handleDrawerClose(setOpen)}
      >
        <Row
          padding={{
            top: 6,
            left: 10,
          }}
          align="center"
        >
          <Link to="/">
            <TakeLogo />
          </Link>
        </Row>
        <DefaultList open={open}>{renderMenus()}</DefaultList>
        <Row
          padding={{
            top: 6,
            left: 10,
            bottom: 2,
          }}
          align="center"
          className="body-tiny"
          color={styledTheme.secondary}
        >
          v {appVersion}
        </Row>
      </DefaultDrawer>
    </>
  );
}
