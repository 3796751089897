import { Collapse, List, ListItem } from '@mui/material';
import styled from 'styled-components';

interface ISubmenuListProps {
  isActive: boolean;
}

export const SubmenuList = styled(List)<ISubmenuListProps>`
  border-left: 1px solid ${props => props.theme.lines};
  border-radius: 0 !important;
  padding-left: 16px;
  font-size: 14px;
  cursor: pointer;
  background-color: ${props => props.theme.primary} !important;
  min-height: unset;
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media (max-width: 650px) {
    padding-top: 6px;
    padding-bottom: 6px;
  }
`;

export const CollapseSubmenu = styled(Collapse)`
  padding-left: 35px;
  background-color: ${props => props.theme.primary} !important;
  border-radius: 0 !important;

  @media (max-width: 650px) {
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 48px;
  }
`;

export const SubMenuListItem = styled(ListItem)<ISubmenuListProps | any>`
  border-radius: 0 !important;
  color: ${props => {
    return props.isActive ? props.theme.secondary : props.theme.quaternary;
  }};
  padding: 0 0 0 16px !important;
`;
