import { AutoCompleteField, Filter, InputTextField } from '@/ui/components';
import { IFilterDrawer } from '@/ui/interfaces';
import useRefrigeratorFilter from '../../components/filter';
import {
  CameraOrLockProblemOptions,
  RefrigeratorActiveOptions,
} from '@/domain/models';
import { AuthenticationHelper, PermissionHelper } from '@/domain/helpers';

export default function RefrigeratorStatusFilterDashboard({
  filter,
  setFilter,
}: IFilterDrawer) {
  const {
    statusForm: { control, handleSubmit },
    onSubmit,
    licensedAutoCompleteData,
  } = useRefrigeratorFilter(setFilter);

  const token = AuthenticationHelper.getToken();
  const permissionHelper = new PermissionHelper(token.auth);
  const isAllowed =
    permissionHelper.isTakeAndGo() || permissionHelper.isDeveloper();

  return (
    <Filter
      filter={filter}
      setFilter={setFilter}
      handleSubmit={handleSubmit as any}
      onSubmit={onSubmit}
    >
      {isAllowed && (
        <AutoCompleteField
          control={control}
          options={licensedAutoCompleteData}
          name="licensedName"
          label="Licenciado"
          dataTestId="licensed"
        />
      )}
      <AutoCompleteField
        control={control}
        options={CameraOrLockProblemOptions}
        name="cameraProblem"
        label="Status da câmera"
        dataTestId="cameraProblem"
      />
      <AutoCompleteField
        control={control}
        options={CameraOrLockProblemOptions}
        name="lockProblem"
        label="Status da porta"
        dataTestId="lockProblem"
      />
      <AutoCompleteField
        control={control}
        options={RefrigeratorActiveOptions}
        name="active"
        label="Status de conexão"
        dataTestId="active"
      />
      <InputTextField
        control={control}
        name="version"
        label="Versão da geladeira"
        dataTestId="version"
      />
    </Filter>
  );
}
