import { DateHelper } from '@/domain/helpers';
import { IOverviewFilterParams } from '@/domain/models';

const lastDayOfTheMonth = DateHelper.getLastDayOfTheMonth();
const firstDayOfTheMonth = DateHelper.getFirstDayOfTheMonth();

const homeOverviewDefaultValues: IOverviewFilterParams = {
  licensedId: window.localStorage.getItem('licensedId')
    ? {
        id: window.localStorage.getItem('licensedId') as string,
        name: 'licensedId',
        label: window.localStorage.getItem('licensedName') as string,
      }
    : null,
  coolerId: null,
  initialDate: {
    id: firstDayOfTheMonth,
    name: 'initialDate',
    label: firstDayOfTheMonth,
  },
  endDate: {
    id: lastDayOfTheMonth,
    name: 'endDate',
    label: lastDayOfTheMonth,
  },
};

export default homeOverviewDefaultValues;
