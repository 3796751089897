import styled from 'styled-components';
import { TableRow } from '@mui/material';
import { Container as ContainerBase } from '@mui/material';

export const Container = styled(TableRow)`
  p {
    font-size: 12px !important;
    font-weight: 700;
    color: ${props => props.theme.table?.text ?? props.theme.primary};
  }

  &.pagination {
    width: 100%;
    margin-left: auto;
    box-sizing: border-box;
    margin-right: auto;
    display: contents;

    td.MuiTableCell-root {
      border: none;
    }
  }

  .MuiTablePagination-selectLabel,
  .MuiTablePagination-displayedRows {
    font-style: normal;
    font-weight: 700;
    color: ${props => props.theme.table?.text ?? props.theme.primary};
    padding-left: 0px;
  }

  .MuiSelect-select.MuiTablePagination-select,
  .MuiSelect-icon.MuiSvgIcon-root {
    color: ${props => props.theme.table?.text ?? props.theme.primary};
  }
`;

export const ContainerActions = styled.div`
  flexshrink: 0;
  display: contents;

  && button {
    color: ${props => props.theme.table?.text ?? props.theme.primary};
  }
`;

export const ContainerPaginationHeader = styled(ContainerBase)`
  &.MuiContainer-root {
    height: 52px;
    margin: 0;
    padding: 0;
    display: flex;
    max-width: inherit;
    align-items: center;
    justify-content: space-between;
  }
`;
