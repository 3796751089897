import styled, { keyframes } from 'styled-components';

export const CustomerRelationshipManagementContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 8px;
`;

const fadeIn = keyframes`
    0%{background-position:0% 100%}
   100%{background-position:100% 0%}
`;

export const CrmCard = styled.div`
  background-color: ${props => props.theme.cardBg};
  padding: 32px;
  border-radius: 16px;
  display: flex;
  align-items: start;
  flex-direction: column;
  box-shadow: ${props => props.theme.cardBoxShadow};
  flex: 1;

  &.default-loading {
    min-height: 266px;
    background: linear-gradient(90deg, #d4d4d4 2%, #757575 48%, #d4d4d4 100%);
    background-size: 300% 300%;
    -webkit-animation: ${fadeIn} 0.8s ease infinite;
    -moz-animation: ${fadeIn} 0.8s ease infinite;
    -o-animation: ${fadeIn} 0.8s ease infinite;
    animation: ${fadeIn} 0.8s ease infinite;
  }

  &.dark-default-loading {
    min-height: 266px;
    background: linear-gradient(
      270deg,
      rgba(32, 28, 28, 1) 20%,
      rgba(0, 0, 0, 1) 48%,
      rgba(32, 28, 28, 1) 76%
    );
    background-size: 300% 300%;
    -webkit-animation: ${fadeIn} 0.8s ease infinite;
    -moz-animation: ${fadeIn} 0.8s ease infinite;
    -o-animation: ${fadeIn} 0.8s ease infinite;
    animation: ${fadeIn} 0.8s ease infinite;
  }

  &.card-position-3-1 {
    grid-area: 3 / 1;
  }

  &.card-position-3-2 {
    grid-area: 3 / 2;
  }

  &.card-position-3-3 {
    grid-area: 3 / 3;
  }

  &.card-position-3-4 {
    grid-area: 3 / 4;
  }
`;
