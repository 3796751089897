export enum ContextRoutePath {
  mural_take = 'https://muraltake.carrd.co/',
  uptime_downtime_apis = 'https://stats.uptimerobot.com/wqXQkI0ZkP',
  home_overview = '/',
  home_rating = '/rating',
  order = '/order',
  order_edit = '/order/:orderId',
  refrigerator_stock = '/refrigerator/stock',
  refrigerator_list = '/refrigerator/list',
  refrigerator_save = '/refrigerator/list/save',
  refrigerator_edit = '/refrigerator/list/:refrigeratorId',
  refrigerator_status_list = '/refrigerator/status/list',
  refrigerator_status_dashboard = '/refrigerator/status/dashboard',
  promotion_list = '/promotion',
  promotion_save = '/promotion/save',
  promotion_edit = '/promotion/:promotionId',
  user = '/user',
  user_save = '/user/save',
  user_edit = '/user/:userId',
  financial = '/financial',
  login = '/login',
  login_with_token = '/login/:token',
  password_recovery = '/password-recovery',
  password_recovery_token = '/password-recovery/:token',
  product_list = '/products/list',
  product_save = '/product/save',
  product_edit = '/product/:productId',
  product_mix = '/product/mix',
  product_mix_save = '/product/mix/save',
  product_mix_edit = '/product/mix/:mixCode',
  product_category = '/product/category',
  product_category_save = '/product/category/save',
  product_category_edit = '/product/category/:categoryId',
  monitoring_opening_attempts = '/monitoring/opening-attempts',
  licensed_list = '/licensed',
  licensed_save = '/licensed/save',
  licensed_edit = '/licensed/:licensedId',
  banner_save = '/banner/save',
  banner_list = '/banner',
  banner_edit = '/banner/:bannerId',
}
