export default class StringHelper {
  static format(...text: string[]): string {
    let result = '';
    const list = text[0].split(/{[\d]}/);
    const args = Array.prototype.slice.call(text, 1);

    for (let i = 0; i < list.length; i += 1)
      result += list[i] + (args[i] !== undefined ? args[i] : '');

    return result;
  }

  static pluralize = ({ noun = '', count = 1, suffix = 's' }) =>
    `${noun}${count > 0 ? suffix : ''}`;

  static pluralizeE = ({ noun = '', count = 1, suffix = 'es' }) =>
    `${noun}${count > 0 ? suffix : ''}`;

  static substring = (text: string) => {
    return text.length > 15 ? text.substring(0, 15) + '...' : text;
  };
}
