import { NotificationContext } from '@/main';
import { Button, CircularProgress, Modal, Table } from '@/ui/components';
import { store } from '@/ui/store/config';
import { StoreState } from '@/ui/store/models';
import { sidebarOpenAction } from '@/ui/store/modules';
import { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import THeadRefundTable from './thead-refund-table';
import { IOrderList, IRefundTable } from '@/domain/models';
import makeOrderListPresenter from '@/main/factories/presenters/pages/order/order-list-presenter-factory';
import { useMutation } from 'react-query';
import TBodyRefundTable from './tbody-refund-table';
import { Column } from '@/ui/components/layout';
import { TableContainer } from '@/ui/pages/financial/styles';
import { NumberHelper } from '@/domain/helpers';

export default function RefundModal({
  rerenderTable,
}: {
  rerenderTable: () => void;
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [rows, setRows] = useState<IRefundTable[]>([]);

  const handleClose = () => {
    store.dispatch(
      sidebarOpenAction({
        sidebarName: '',
        sidebarState: { right: false },
        elementId: '',
        elementData: {},
      }),
    );
    setIsConfirmModalOpen(false);
    setIsOpen(false);
    setRows([]);
  };

  const { successNotification, errorNotification } =
    useContext(NotificationContext);

  const orderPresenter = makeOrderListPresenter();

  const modal = useSelector((store: StoreState) => store.sidebar);

  const selectedRows: IOrderList[] = modal.elementData;

  let totalRefundValue = 0;

  const { mutateAsync: refundCalculate, isLoading } = useMutation(
    (orderIds: number[]) => orderPresenter.postRefundCalculate(orderIds),
    {
      onSuccess: data => {
        if (Boolean((data as any).status) && (data as any).status !== 200) {
          errorNotification(
            (data as any).message || 'Erro ao calcular reembolso!',
          );

          return handleClose();
        }

        const newRows: IRefundTable[] = selectedRows.map(row => {
          const refund = data.find(refund => refund.orderId === row.order)!;

          return {
            ...row,
            refundValue: refund.refundValue,
          };
        });

        totalRefundValue = data.reduce((acc, row) => acc + row.refundValue, 0);

        setRows(newRows);
      },
    },
  );

  const { mutateAsync: refundApply, isLoading: isRefundApplyLoading } =
    useMutation(
      (orderIds: number[]) => orderPresenter.postRefundApply(orderIds),
      {
        onSuccess: data => {
          if (Boolean(data.status) && data.status !== 200) {
            return errorNotification('Erro ao aplicar reembolso!');
          }

          handleClose();
          rerenderTable();
          successNotification(data || 'Reembolso aplicado com sucesso!');
        },
      },
    );

  useEffect(() => {
    if (modal.sidebarName === 'order_refund' && modal.elementData) {
      const orderIds: number[] = modal.elementData.map(
        (order: IOrderList) => order.order,
      );
      refundCalculate(orderIds);
      setIsOpen(modal.sidebarState.right);
    }
  }, [modal]);

  return (
    <>
      <Modal
        title={`Reembolsar pedidos selecionados - Total de reembolso: ${NumberHelper.makeMoney(
          totalRefundValue,
        )}`}
        isOpen={isOpen}
        onClose={handleClose}
        cancelButton={
          <Button
            text="Gerar reembolso"
            onClick={() => setIsConfirmModalOpen(true)}
            disabled={isRefundApplyLoading}
          />
        }
        okButton={
          <Button
            disabled={isRefundApplyLoading}
            className="outlined"
            text="Cancelar"
            onClick={handleClose}
          />
        }
        content={
          <TableContainer className="modal">
            {!isLoading ? (
              <Table style={{ marginTop: 25 }}>
                <THeadRefundTable />
                <tbody>
                  {rows.map(row => (
                    <TBodyRefundTable key={row.order} row={row} />
                  ))}
                </tbody>
              </Table>
            ) : (
              <Column align="center" justify="center">
                <CircularProgress variant="indeterminate" />
              </Column>
            )}
          </TableContainer>
        }
      />
      <Modal
        title="Confirmação de reembolso"
        isOpen={isConfirmModalOpen}
        onClose={() => setIsConfirmModalOpen(false)}
        cancelButton={
          <Button
            text="Cancelar"
            onClick={() => setIsConfirmModalOpen(false)}
          />
        }
        okButton={
          <Button
            className="outlined"
            text="Confirmar"
            onClick={() => refundApply(rows.map(row => row.order))}
            disabled={isRefundApplyLoading}
          />
        }
        content={
          <Column align="center" justify="center">
            <p>
              Deseja realmente aplicar o reembolso de{' '}
              {NumberHelper.makeMoney(totalRefundValue)} nos pedidos
              selecionados?
            </p>
          </Column>
        }
      />
    </>
  );
}
