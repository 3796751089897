import { ContextCreditCardIcon, IOrderCard, IOrderEdit } from '@/domain/models';

import { NumberHelper } from '@/domain/helpers';
import { InfoColumn } from '../order-edit-info/styles';
import { CircularProgress } from '@/ui/components';
import OrderRefundValue from './components/order-refund-value';
import { Column, Grid, Row } from '@/ui/components/layout';

interface IOrderProductPaymentProps {
  discount: number;
  creditCard: number;
  wallet: number;
  pix: number;
  total: number;
  paymentToReceive?: number | null;
  card: IOrderCard;
  isLoadingCalculate: boolean;
  valueToPay?: number | null;
  initialOrderData: IOrderEdit;
  orderDetails?: string;
}

export default function OrderProductPayment(
  props: IOrderProductPaymentProps,
): JSX.Element {
  const {
    discount,
    creditCard,
    wallet,
    pix,
    total,
    paymentToReceive,
    card,
    isLoadingCalculate,
    valueToPay,
    initialOrderData,
    orderDetails,
  } = props;
  const { number, holderName } = card;

  const getCreditCardIconName = (lastDigits: string) => {
    switch (lastDigits) {
      case '4':
        return 'cc-visa';
      case '5':
        return 'cc-mastercard';
      case '3':
        return 'cc-amex';
      case '6':
        return 'cc-discover';
      default:
        return null;
    }
  };

  const handleValues = (value: number, initialValue: number) => {
    if (value == initialValue) {
      return NumberHelper.makeMoney(value);
    } else {
      return (
        <>
          <span style={{ textDecoration: 'line-through' }}>
            {NumberHelper.makeMoney(initialValue)}
          </span>{' '}
          {NumberHelper.makeMoney(value)}
        </>
      );
    }
  };

  return (
    <Column>
      <h5>Dados do pagamento</h5>
      <Grid columns={4} responsiveType="input" gap={1}>
        <InfoColumn className="align-start">
          <p className="title">Desconto</p>
          <p data-testid="payment_discount">
            {handleValues(discount, initialOrderData.paymentDetails.discount)}
          </p>
        </InfoColumn>
        <InfoColumn className="align-start">
          <p className="title">Cartão</p>
          <p data-testid="payment_card">
            {handleValues(
              creditCard,
              initialOrderData.paymentDetails.valuePaidCreditCard,
            )}
          </p>
        </InfoColumn>
        <InfoColumn className="align-start">
          <p className="title">Wallet</p>
          <p data-testid="payment_wallet">
            {handleValues(
              wallet,
              initialOrderData.paymentDetails.valuePaidWallet,
            )}
          </p>
        </InfoColumn>
        <InfoColumn className="align-start">
          <p className="title">Pix</p>
          <p data-testid="payment_pix">
            {handleValues(pix, initialOrderData.paymentDetails.valuePaidPix)}
          </p>
        </InfoColumn>
        <InfoColumn className="align-start">
          <p className="title">Total</p>
          <p data-testid="payment_total">
            {isLoadingCalculate ? (
              <CircularProgress size={20} variant="indeterminate" />
            ) : (
              handleValues(total, initialOrderData.paymentDetails.total)
            )}
          </p>
        </InfoColumn>
        <OrderRefundValue
          paymentToReceive={paymentToReceive!}
          orderDetails={orderDetails}
          isLoadingCalculate={isLoadingCalculate}
          valueToPay={valueToPay!}
        />
        <InfoColumn className="align-start">
          <p className="title">Número do cartão</p>
          {number ? (
            <Row align="center" justify="start" margin={{ top: 1 }}>
              {ContextCreditCardIcon.get(getCreditCardIconName(number[0]))}
              <span
                data-testid="payment_card_number"
                style={{
                  marginLeft: '5px',
                  paddingTop: 1,
                  marginTop: 1,
                  lineHeight: 0.5,
                }}
              >
                {number}
              </span>
            </Row>
          ) : (
            <Row align="center" justify="start" margin={{ top: 2 }}>
              <span
                data-testid="payment_card_number"
                style={{
                  paddingTop: 1,
                  marginTop: 1,
                  lineHeight: 0.5,
                }}
              >
                ---
              </span>
            </Row>
          )}
        </InfoColumn>
        <InfoColumn className="align-start">
          <p className="title">Titular</p>
          <p data-testid="payment_card_holder_name">{holderName}</p>
        </InfoColumn>
      </Grid>
    </Column>
  );
}
