import { StoreState } from '@/ui/store/models';
import { useSelector } from 'react-redux';
import { useTheme } from 'styled-components';

export default function defaultInputStyles(readOnly = false) {
  const store = useSelector((state: StoreState) => state.toggleTheme.darkTheme);
  const currentTheme = useTheme();

  const inputStyles = {
    border: 'none',
    color: readOnly ? currentTheme.quaternary : currentTheme.secondary,
    fontSize: '14px',

    '&:disabled, & .Mui-disabled': {
      color: currentTheme.quaternary + '!important',
      '-webkit-text-fill-color': currentTheme.quaternary + '!important',
    },

    '&.small': {
      minWidth: '30px',
      maxWidth: '110px',
      margin: 0,
      padding: 0,
      input: {
        padding: '6px',
      },
      fieldset: {
        borderRadius: '10px',
      },
      label: {
        transform: 'translate(14px, 6px) scale(1)',
        '&.Mui-focused': {
          transform: 'translate(14px, -9px) scale(0.75)',
        },
      },
    },

    '& label.Mui-error': {
      color: '#d32f2f !important',
    },

    '& .MuiInputLabel-root': {
      color: currentTheme.quaternary,
      fontSize: '14px',
      '&.Mui-disabled': {
        color: currentTheme.quaternary,
        '-webkit-text-fill-color': currentTheme.quaternary,
      },
    },
    fieldset: {
      border: '1px solid ' + currentTheme.lines,
      borderRadius: '16px',
      fontSize: '14px',
    },
    '& MuiOutlinedInput-input': {
      color: readOnly ? currentTheme.quaternary : currentTheme.secondary,
      fontSize: '14px',
      '&.Mui-disabled': {
        color: currentTheme.quaternary,
        '-webkit-text-fill-color': currentTheme.quaternary,
      },
    },
    '& label.Mui-focused': {
      color: currentTheme.formTextColor,
      fontSize: '14px',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: currentTheme.formTextColor,
      fontSize: '14px',
    },
    '& .MuiOutlinedInput-root': {
      color: readOnly ? currentTheme.quaternary : currentTheme.secondary,
      fontSize: '14px',
      '&.Mui-disabled': {
        color: currentTheme.quaternary,
        '-webkit-text-fill-color': currentTheme.quaternary,
      },

      '& fieldset': {
        color: readOnly ? currentTheme.quaternary : currentTheme.secondary,
        borderColor: currentTheme.lines,
        fontSize: '14px',
      },
      '&:hover fieldset': {
        color: readOnly ? currentTheme.quaternary : currentTheme.secondary,
        borderColor: currentTheme.lines,
        fontSize: '14px',
      },
      '&.Mui-focused fieldset': {
        color: readOnly ? currentTheme.quaternary : currentTheme.secondary,
        borderColor: currentTheme.formTextColor,
        fontSize: '14px',
      },
      '.MuiSvgIcon-root': {
        color: readOnly ? currentTheme.quaternary : currentTheme.secondary,
        fontSize: '1.5rem',
      },
    },
    svg: {
      color: readOnly ? currentTheme.quaternary : currentTheme.secondary,
      fontSize: '1.5rem',
    },

    textarea: {
      border: 'none',
      borderRadius: 'none',
      padding: '2px 8px',
    },

    input: {
      colorScheme: store ? 'dark' : 'light',
      padding: '14px 16px',
    },
  };

  const chipStyles = {
    marginTop: '2px',
    marginBottom: '2px',
    border: '1px solid ' + currentTheme.quaternary,
    color: currentTheme.quaternary,
    '& label.Mui-error': {
      color: '#d32f2f !important',
    },
    '& .MuiInputLabel-root': {
      color: currentTheme.quaternary,
    },
    '& .MuiSvgIcon-root': {
      color: currentTheme.lines,
    },
    fieldset: {
      border: '1px solid ' + currentTheme.lines,
      borderRadius: '16px',
    },
    '& MuiOutlinedInput-input': {
      color: currentTheme.secondary,
      '&.Mui-disabled': {
        color: currentTheme.chartLegend,
        '-webkit-text-fill-color': currentTheme.quaternary,
      },
    },
    '& label.Mui-focused': {
      color: currentTheme.tertiary,
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: currentTheme.tertiary,
    },
    '& .MuiOutlinedInput-root': {
      color: currentTheme.secondary,
      '& fieldset': {
        color: currentTheme.secondary,
        borderColor: currentTheme.lines,
        '&.Mui-disabled': {
          color: currentTheme.quaternary,
          '-webkit-text-fill-color': currentTheme.quaternary,
        },
      },
      '&:hover fieldset': {
        color: currentTheme.tertiary,
        borderColor: currentTheme.lines,
        '&.Mui-disabled': {
          color: currentTheme.quaternary,
          '-webkit-text-fill-color': currentTheme.quaternary,
        },
      },
      '&.Mui-focused fieldset': {
        color: currentTheme.orderStatusLabel.problems.textColor,
        borderColor: currentTheme.tertiary,
        '&.Mui-disabled': {
          color: currentTheme.orderStatusLabel.problems.textColor,
          '-webkit-text-fill-color':
            currentTheme.orderStatusLabel.problems.textColor,
        },
      },
    },
    '.MuiChip-deleteIcon:hover': {
      color: currentTheme.orderStatusLabel.problems.textColor,
    },
  };

  const textFieldStyles = {
    border: 'none',
    color: readOnly ? currentTheme.quaternary : currentTheme.secondary,
    fontSize: 15,
    '& .MuiSvgIcon-root': {
      color: readOnly ? currentTheme.quaternary : currentTheme.secondary,
      fontSize: '1.5rem',
    },
    '& label.Mui-error': {
      color: '#d32f2f !important',
    },
    '& .MuiInputLabel-root': {
      color: currentTheme.quaternary,
      fontSize: 14,
    },
    fieldset: {
      border: '1px solid ' + currentTheme.lines,
      borderRadius: '16px',
      fontSize: 14,
      '&.MuiOutlinedInput-notchedOutline': {
        borderColor: currentTheme.lines + '!important',
      },
    },
    '& .MuiOutlinedInput-input': {
      color: readOnly ? currentTheme.quaternary : currentTheme.secondary,
      fontSize: 14,
      '&.Mui-disabled': {
        color: currentTheme.quaternary,
        '-webkit-text-fill-color': currentTheme.quaternary,
      },
    },
    '& label.Mui-focused': {
      color: currentTheme.formTextColor,
      fontSize: 14,
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: currentTheme.formTextColor,
      fontSize: 14,
    },
    '& .MuiOutlinedInput-root': {
      color: readOnly ? currentTheme.quaternary : currentTheme.secondary,
      fontSize: 14,
      '& fieldset': {
        color: readOnly ? currentTheme.quaternary : currentTheme.secondary,
        borderColor: currentTheme.lines,
        fontSize: 14,
      },
      '&:hover fieldset': {
        color: readOnly ? currentTheme.quaternary : currentTheme.secondary,
        borderColor: currentTheme.lines,
        fontSize: 14,
      },
      '&.Mui-focused fieldset': {
        color: readOnly ? currentTheme.quaternary : currentTheme.secondary,
        borderColor: currentTheme.formTextColor,
        fontSize: 14,
      },
      '& .MuiSvgIcon-root': {
        color: readOnly ? currentTheme.quaternary : currentTheme.secondary,
        fontSize: '1.5rem',
      },
      '.MuiChip-colorError': {
        textColor: currentTheme.orderStatusLabel.problems.textColor,
        borderColor: currentTheme.orderStatusLabel.problems.borderColor,
        '.MuiChip-label': {
          textColor:
            currentTheme.orderStatusLabel.problems.textColor + '!important',
          color: currentTheme.orderStatusLabel.problems.textColor,
        },
      },
      '.MuiChip-label': {
        color: readOnly ? currentTheme.quaternary : currentTheme.secondary,
      },
    },
    svg: {
      color: readOnly ? currentTheme.quaternary : currentTheme.secondary,
      fontSize: '1.5rem',
    },
  };

  const componentProps = {
    paper: {
      sx: {
        backgroundColor: currentTheme.cardBg,
        color: currentTheme.quaternary,
      },
    },
    popper: {
      sx: {
        '.MuiAutocomplete-option:hover': {
          color: currentTheme.secondary,
        },
      },
    },
  };

  return {
    inputStyles,
    chipStyles,
    textFieldStyles,
    componentProps,
  };
}
