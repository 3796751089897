import { Button } from '@/ui/components';
import { store } from '@/ui/store/config';
import { sidebarOpenAction } from '@/ui/store/modules';
import { OrderContestationModals } from '@/domain/models';
import { Row } from '@/ui/components/layout';

interface IOrderConfirmButtonsProps {
  isEnable: boolean;
  declineOrderContestation?: (reason: string) => void;
}

export default function OrderConfirmButtons(
  props: IOrderConfirmButtonsProps,
): JSX.Element {
  const { isEnable, declineOrderContestation } = props;

  const handleDeclineContestationModal = () => {
    store.dispatch(
      sidebarOpenAction({
        sidebarName: OrderContestationModals.ORDER_DECLINE_CONTESTATION,
        sidebarState: { right: true },
        elementId: '1',
        elementData: {
          declineOrderContestation: declineOrderContestation,
        },
      }),
    );
  };

  return (
    <Row align="end" justify="end" padding="24px 0" gap={6}>
      <Button
        dataTestId="deny_contestation_button"
        onClick={handleDeclineContestationModal}
        className="dark"
        text="Reprovar contestação"
        type="button"
      />
      <Button disabled={!isEnable} text="Aprovar contestação" type="submit" />
    </Row>
  );
}
